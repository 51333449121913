import React from "react";
import TeacherList  from "./TeacherList";
import {createBlankTeacherItem, useTeacherDataApi} from './TeacherData';
import { useCategoryDataApi } from "../category/CategoryData";
import { useEffect } from "react";
import { sharePointConfig } from "../../authConfig";
import { schoolConfig } from "../../appConfig";

function TeacherPage() { 
    const[{teacherItems, flagReloadAfterAddNew}, setAction] = useTeacherDataApi();
    const [{departmentItems, titleItems, subjectItems}] = useCategoryDataApi(['department','titlelist','subjects']);

    const handleAdd = () => {
        let blankItem = createBlankTeacherItem();
        setAction({
            type:'add',
            value: blankItem
        });
    }

    const handleSave = (item) => {
        setAction({
            type:'save',
            value: item
        });
    }

    const handleDelete = (item) =>{
        setAction({
            type:'del',
            value: item
        });
    }

    const subjectOptions = () => {
        let results=[];
        for (let i=0; i<subjectItems.length; i++) {
            const item = subjectItems[i];
            results.push({
                ...item,
                name: item.Title, 
                id: item.SubjectId,
            });
        }
        return results;
    }

    useEffect(()=>{
        if(flagReloadAfterAddNew) {
            setAction({type:'fetchdata',value:''});
        }
    },[flagReloadAfterAddNew]);

    return (
        <>
            <div className="row pt-3">
                <h4>Thông tin Giáo viên</h4>
            </div>
            <div className="row pt-1">
                <TeacherList 
                    data={teacherItems}
                    departmentList={departmentItems}
                    titleList={titleItems}
                    subjectList= {subjectOptions()}
                    roleList = {schoolConfig.UserRoles}
                    onAddItemClick = {handleAdd}
                    onSaveItemClick = {handleSave}
                    onDeleteItemClick = {handleDelete}
                />
            </div>
        </>
    )
}

export default TeacherPage;