import React, {useState} from "react";

function ClassItem(props){ 
    const [item, setItem] = useState(props.item);
    const [beforeEditItem, setBeforeEditItem] = useState({...props.item});
    const [action, setAction] = useState(props.action);
    
    const handleEdit = () => {
        setBeforeEditItem({...item});
        setAction('edit');
    }

    const handleSave = () => {
        props.onSaveItemClick(item);
        setAction('');
    }

    const handleCancel = () => {
        if(action==='new'){
            handleDelete(item);
        }else{ //edit action nên hủy các sửa đổi, khôi phục về giá trị trước khi sửa
            setItem(beforeEditItem);
        }
        setAction('');
    }

    const handleDelete = () => {
        if (action!=='edit' && action !=='new') {  
            if ( window.confirm(`Bạn chắc chắn xóa lớp học ${item.Title} không?`)) {
                props.onDeleteItemClick(item); 
            }            
        } else {
            props.onDeleteItemClick(item); 
        }
    }

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.type==='checkbox'?target.checked:target.value;
        if(name==='StartYear') {
            setItem({
                ...item,
                [name]: value,
                EndYear: value*1+1,
            });
        }else {
            setItem({
                ...item,
                [name]: value,
            });
        }
    }

    const handleKeyUp = (event, nextElementId) => {
        if (event.keyCode===13){
            const nextElm = document.getElementById(nextElementId);
            if (nextElm) nextElm.focus();
        }
    }

    const teacherObj = props.teachers.find(t=>t.TeacherId===item.HeadTeacher); 
    
    const rowElement = (action==='edit' || action==='new')
        ? <tr>
            <td>
                <input type='text' name='Title' style={{'width':'100%'}} 
                    className="form-control form-control-sm" autoFocus
                    value={item.Title} 
                    onKeyUp = {(event)=> handleKeyUp(event, "Grade")}
                    onChange={(e)=>handleChange(e)} />
            </td>
            <td>
                <input type='text' name='Grade' id="Grade" style={{'width':'100%'}} className="form-control form-control-sm"
                    value={item.Grade} 
                    onKeyUp = {(event)=> handleKeyUp(event, "StartYear")}
                    onChange={(e)=>handleChange(e)} />
            </td>            
            <td>
                <input type='text' name='StartYear' id="StartYear" style={{'width':'100%'}} className="form-control form-control-sm"
                    value={item.StartYear} 
                    onKeyUp = {(event)=> handleKeyUp(event, "EndYear")}
                    onChange={(e)=>handleChange(e)} />
            </td>
            <td>
                <input type='text' name='EndYear' id="EndYear" style={{'width':'100%'}} className="form-control form-control-sm"
                    value={item.EndYear} 
                    onKeyUp = {(event)=> handleKeyUp(event, "Students")}
                    onChange={(e)=>handleChange(e)} />
            </td>            
            <td>
                <input type='email' name='Students' id="Students" style={{'width':'100%'}} className="form-control form-control-sm"
                    value={item.Students} 
                    onKeyUp = {(event)=> handleKeyUp(event, "HeadTeacher")}
                    onChange={(e)=>handleChange(e)} />
            </td>
            <td>
                <select name='HeadTeacher' id="HeadTeacher" style={{'width':'100%'}} className="form-select form-select-sm"
                    value={item.HeadTeacher} 
                    onChange={(e)=>handleChange(e)}>
                        <option value=''></option>
                        {
                            props.teachers.map(t =>
                                <option key={t.TeacherId} value={t.TeacherId}>{t.Title}, {t.FullName}</option>
                            )
                        }
                </select>
            </td>            
            <td>
                <input type='text' name='Note' id="Note" style={{'width':'100%'}} className="form-control form-control-sm"
                    value={item.Note} 
                    onKeyUp = {(event)=> handleKeyUp(event, "IsActive")}
                    onChange={(e)=>handleChange(e)} />
            </td>
            <td>
                <select name='IsActive' id="IsActive" className="form-select form-select-sm"
                    value={item.IsActive} onChange={e=>handleChange(e)}>
                    <option value={true}>Có</option>
                    <option value={false}>Không</option>
                </select>
            </td>
            <td className="text-end">
                <button type="button" onClick={handleSave}   id="btnSaveItem" className="btn btn-primary btn-sm"><i className="fa fa-floppy-o"></i></button>&nbsp;
                <button type="button" onClick={handleCancel} id="btnCancel"   className="btn btn-secondary btn-sm"><i className="fa fa-ban"></i></button>                
            </td>            
        </tr>
        :<tr>
            <td>{item.Title}</td>
            <td>{item.Grade}</td>
            <td>{item.StartYear}</td>
            <td>{item.EndYear}</td>
            <td>{item.Students}</td>
            <td>
                {teacherObj===null||teacherObj===undefined?item.HeadTeacher: teacherObj.FullName}
            </td>
            <td>{item.Note}</td>
            <td>{item.IsActive==='true' || item.IsActive===true?'Có':'Không'}</td>
            <td className="text-end">
                <button type="button" onClick={handleEdit} className="btn btn-warning btn-sm"><i className="fa fa-pencil"></i></button>&nbsp;&nbsp;
                <button type="button" onClick={handleDelete} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i></button>                
            </td>
        </tr>;

    return(
        <>
            {rowElement}
        </>
    )
}

export default ClassItem;