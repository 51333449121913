import  {useEffect, useState} from 'react';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { v4 as uuidv4 } from 'uuid';
import { remove, orderBy as _orderBy} from 'lodash';

import { loginRequest, sharePointConfig } from '../../authConfig';
import {
    getSharePointListItems,
    updateSharePointItem,
    addSharePointListItem,
    addSharePointListItemBatch,
    deleteSharePointItem
} from '../../graph';

import { Teachers } from "../../mock/MockData";

export const createBlankTeacherItem = () =>{
    return [
        {
            id:0,
            TeacherId: uuidv4(),
            Title:'',
            FullName:'',
            FirstName:'',
            LastName:'',
            DepartmentId:'',
            SubjectId: [],
            TitleId: '',
            PhoneNumber:'',
            UserEmail:'',
            UserName:'',
            Roles:'',
            Address:'',
            IsActive: true
        }
    ]
}

export const useTeacherDataApiMock = () => {
    const [teacherItems, setTeacherItems] = useState(Teachers);
    const [action, setAction] = useState({type:'', value:''});

    useEffect(() => {     
        const fetchData = async () => {  
            setTeacherItems(Teachers);
        };

        const addBlankItem = async (item) => {
            setTeacherItems(item.concat(teacherItems));
        };

        const submitData = async (item) => {
            console.log('Submit data');
        };

        const deleteItem = async (item) => {
            let items = Array.from(teacherItems);
            remove(items, i=>i.TeacherId===item.TeacherId);        
            setTeacherItems(items); 
        }

        const processData = ()=> {
            switch(action.type){
                case 'add':
                    addBlankItem(action.value);
                    break;
                case 'save':
                    submitData(action.value);
                    break;
                case 'del':
                    deleteItem(action.value);
                    break;
                default:
                    fetchData();
            }
        }

        processData();
        
    },[action]);

    return [{teacherItems},  setAction];
}

export const useTeacherDataApi = (defaultValue={type:'fetchdata', value:''}) => {
    const [teacherItems, setTeacherItems] = useState([]);
    const [action, setAction] = useState(defaultValue);
    const [flagReloadAfterAddNew, setFlagReloadAfterAddNew] = useState(false);
    const [isProcessingTeacherData, setIsProcessingTeacherData] = useState(false);
    const [currentImportBatchNumber, setCurrentImportBatchNumber] = useState(0);

    const {instance, accounts} = useMsal();

    const fetchData = (accessToken) =>{
        getSharePointListItems(
            accessToken, 
            sharePointConfig.TeacherListId,
            "id,TeacherId,Title,FullName,FirstName,LastName,DepartmentId,TeachingSubjectIds,TitleId,UserEmail,UserName,Roles,PhoneNumber,Address,IsActive", 
            ""  
        ).then(response => {  
            let items = [];
            const listItemArray = response.value;
            for(let i=0; i<listItemArray.length; i++){
                // const spListItem = listItemArray[i];
                const fields = listItemArray[i].fields;
                items.push( {
                    ...fields,
                    Title:  fields.Title===undefined || fields.Title===null?"":  fields.Title,
                    //SubjectId: fields.SubjectId===undefined || fields.SubjectId==="" ? []: JSON.parse(fields.SubjectId),
                    SubjectId: fields.TeachingSubjectIds===undefined || fields.TeachingSubjectIds==="" ? []: JSON.parse(fields.TeachingSubjectIds),
                    Roles: fields.Roles===undefined || fields.Roles==="" ? []: JSON.parse(fields.Roles)
                });
            }
            //items = _orderBy(items, ['FirstName', 'LastName'],['asc','asc']);
            items = _orderBy(items, ['FullName'],['asc']);
            setTeacherItems(items);
            setIsProcessingTeacherData(false);
        })
    }

    const addBlankItem = (item) => {
        setTeacherItems(item.concat(teacherItems));
    };

    const saveItem = (accessToken, item) => {
        setFlagReloadAfterAddNew(false);

        let fieldValues = {};
        const lastSpaceChar = item.FullName.lastIndexOf(" ");
        const firstName= item.FullName.substring(lastSpaceChar+1);
        const lastName = item.FullName.substring(0,lastSpaceChar);
        
        if(item.id>0){ //Nếu đã có thì cập nhật nội dung của mục trong tuần đã có  
            fieldValues = {
                fields: {
                    Title: item.Title,
                    FullName: item.FullName,
                    FirstName: firstName,
                    LastName: lastName,
                    DepartmentId: item.DepartmentId,
                    // SubjectId: JSON.stringify(item.SubjectId),  
                    TeachingSubjectIds: JSON.stringify(item.SubjectId),                  
                    TitleId: item.TitleId,
                    PhoneNumber: item.PhoneNumber,
                    UserEmail: item.UserEmail,
                    UserName: item.UserEmail,
                    Roles: JSON.stringify(item.Roles),
                    Address: item.Address,
                    IsActive: item.IsActive==='true' || item.IsActive===true ? true:false
                }
            };  
            updateSharePointItem(accessToken, sharePointConfig.TeacherListId, item.id, fieldValues)
                .then(response => {
                    //console.log(response)
                    setIsProcessingTeacherData(false);
                });
        }
        else{ //Nếu chưa có thì thêm vào theo tuần
            fieldValues = {
                fields: {
                    TeacherId: item.TeacherId,
                    Title: item.Title,
                    FullName: item.FullName,
                    FirstName: firstName,
                    LastName: lastName,
                    DepartmentId: item.DepartmentId,
                    // SubjectId: JSON.stringify(item.SubjectId),
                    TeachingSubjectIds: JSON.stringify(item.SubjectId),
                    TitleId: item.TitleId,
                    PhoneNumber: item.PhoneNumber,
                    UserEmail: item.UserEmail,
                    UserName: item.UserName===""?item.UserEmail:item.UserName,
                    Roles: JSON.stringify(item.Roles),
                    Address: item.Address,
                    IsActive: item.IsActive==='true' || item.IsActive ===true?true:false
                }
            };
            addSharePointListItem(accessToken, sharePointConfig.TeacherListId, fieldValues)
                .then(response=>{
                    if(response.id){
                        setFlagReloadAfterAddNew(true);
                    } else {
                        console.error('Lỗi khi thêm mới giáo viên (response)',response);
                    }
                    setIsProcessingTeacherData(false);
                });
        }

    };

    const importTeacherItems = (accessToken, items) => {
        let fieldsValuesArray=[];
        items.forEach(item=>{
            const fieldValues = {
                fields: {
                    Title: item.Title,
                    TeacherId: item.TeacherId,                    
                    FullName: item.FullName,
                    FirstName: item.FirstName,
                    LastName: item.LastName,
                    DepartmentId: item.DepartmentId,
                    // SubjectId: JSON.stringify(item.SubjectId),
                    TeachingSubjectIds: JSON.stringify(item.SubjectId),
                    TitleId: item.TitleId,
                    UserEmail: item.UserEmail,
                    UserName: item.UserName===""?item.UserEmail:item.UserName,                    
                    PhoneNumber: item.PhoneNumber,
                    Address: item.Address,
                    IsActive: item.IsActive,
                }
            }
            fieldsValuesArray.push(fieldValues);        
        });
        //console.log('fieldsValuesArray:', fieldsValuesArray)
        addSharePointListItemBatch(accessToken, sharePointConfig.TeacherListId, fieldsValuesArray).then(response=> {
            console.log(response);
            setCurrentImportBatchNumber(currentImportBatchNumber+1);
            setIsProcessingTeacherData(false);
        })
    }

    const deleteItem = (accessToken, item) => {
        let items = Array.from(teacherItems);
        remove(items, i=>i.TeacherId===item.TeacherId);
        //Xóa phía server nếu item này đã tồn tại trên server
        if(item.id){
            deleteSharePointItem(accessToken,sharePointConfig.TeacherListId,item.id).then(response =>{
                //console.log('Completed delete item ' + item.TeacherId);
                //console.log(response);
                setIsProcessingTeacherData(false);
                setTeacherItems(items);
            });
        }else{
            setIsProcessingTeacherData(false);
            setTeacherItems(items);            
        }
    }

    useEffect(() => {     
        const accessTokenRequest = {
            ...loginRequest,
            account: accounts[0]
        }

        const processData = async () => {  
            instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
                const accessToken = accessTokenResponse.accessToken;
                setIsProcessingTeacherData(true);
                switch(action.type.toLowerCase()){
                    case 'add':
                        addBlankItem(action.value);
                        break;
                    case 'save':
                        saveItem(accessToken, action.value);
                        break;
                    case 'importteacheritems':
                        importTeacherItems(accessToken, action.value);
                        break;
                    case 'del':
                        deleteItem(accessToken, action.value);
                        break;
                    case "fetchdata":
                        fetchData(accessToken);
                        break;
                    default:   
                    setIsProcessingTeacherData(false);                 
                        break;
                }
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest);                    
                }
                setIsProcessingTeacherData(false);
                console.log(error);
            }) 
        }; 

        processData();       
    },[action]);

    return [{teacherItems, flagReloadAfterAddNew, isProcessingTeacherData, currentImportBatchNumber},  setAction];
}




