import React from "react";

import SubjectList from "./SubjectList";
import {createBlankSubjectItem, useSubjectDataApi} from './SubjectData';
import { useEffect } from "react";

function SubjectPage() {
    const [{subjectItems, flagReloadAfterAddNew}, setAction] = useSubjectDataApi();

    const handleAdd = () => {
        let blankItem = createBlankSubjectItem();
        setAction({
            type:'add',
            value: blankItem
        });       
    }

    const handleSave = (item) => {
        setAction({
            type:'save',
            value: item
        });
    }

    const handleDelete = (item) => {
        setAction({
            type:'del',
            value: item
        });
    }

    useEffect(()=>{
        if(flagReloadAfterAddNew){
            setAction({type:'',value:''});
        }
    },[flagReloadAfterAddNew])

    return(
        <>
            <div className="row pt-3">
                <h4>Thông tin Môn học</h4>
            </div>
            <div className="row">
                <SubjectList 
                    data = {subjectItems} 
                    onAddItemClick = {handleAdd}
                    onSaveItemClick = {handleSave}
                    onDeleteItemClick = {handleDelete}
                />
            </div>
        </>
    )
}

export default SubjectPage;