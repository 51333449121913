import React, {useState} from 'react';
import CategoryList from './CategoryList';
import CategorySelection from './CategorySelection';

const CategoryPage = () => {
    const [selectedCategory, setSelectedCategory] = useState("");

    const handleCategoryClick = (e, selectedCategory) => {
        setSelectedCategory(selectedCategory);
    }
    return (
        <>
            <div className="row">
                <h4>Cập nhật danh mục dữ liệu</h4>
            </div>
            <div className="row">
                <div className="col-md-2">
                    <CategorySelection onCategoryClick = {handleCategoryClick} />
                </div>
                <div className="col-md-10">
                    <CategoryList title={selectedCategory}/>
                </div>
            </div>
        </>
    )
}

export default CategoryPage;
