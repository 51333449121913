import { v4 as uuidv4 } from 'uuid';
import * as dayjs from 'dayjs';


export const TeachingSchedule = [
    {
        class_id: 'A12018',
        class_name: '9A1',
        year: 2022,
        week: 15,
        records: [
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 1,
                period_plan: 0,
                subject_id: 'Đạo đức',
                lecture_id: '',
                comment:'',
                teacher_id: 't01',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 2,
                subject_id: 'Văn',
                lecture_id: '',
                comment: '',
                teacher_id: 't01',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 3,
                subject_id: 'Văn',
                lecture_id: '',
                comment: '',
                teacher_id: 't01',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 4,
                subject_id: 'Toán',
                lecture_id: '',
                comment: '',
                teacher_id: 't02',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 5,
                subject_id: 'Toán',
                lecture_id: '',
                comment: '',
                teacher_id: 't02',
                week: 15,
                week_plan: 0,
                is_late: false
            },   
            {
                id: uuidv4(),
                schedule_date: 3,
                part_day: 'Sáng',
                period: 1,
                subject_id: 'T.Anh',
                lecture_id: '',
                comment: '',
                teacher_id: 't03',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 3,
                part_day: 'Sáng',
                period: 2,
                subject_id: 'T.Anh',
                lecture_id: '',
                comment: '',
                teacher_id: 't03',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 3,
                part_day: 'Sáng',
                period: 3,
                subject_id: 'Sinh',
                lecture_id: '',
                comment: '',
                teacher_id: 't04',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 3,
                part_day: 'Sáng',
                period: 4,
                subject_id: 'Sinh',
                lecture_id: '',
                comment: '',
                teacher_id: 't04',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 3,
                part_day: 'Sáng',
                period: 5,
                subject_id: 'Địa',
                lecture_id: '',
                comment: '',
                teacher_id: 't05',
                week: 15,
                week_plan: 0,
                is_late: false
            },                                                         
        ]
    },
    {
        class_id: 'A22018',
        class_name: '9A2',
        year: 2022,
        week: 15,
        records: [
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 1,
                subject_id: 'Đạo đức',
                lecture_id: '',
                comment: '',
                teacher_id: 't02',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 2,
                subject_id: 'Toán',
                lecture_id: '',
                comment: '',
                teacher_id: 't02',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 3,
                subject_id: 'Toán',
                lecture_id: '',
                comment: '',
                teacher_id: 't02',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 4,
                subject_id: 'Văn',
                lecture_id: '',
                comment: '',
                teacher_id: 't01',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 5,
                subject_id: 'Văn',
                lecture_id: '',
                comment: '',
                teacher_id: 't01',
                week: 15,
                week_plan: 0,
                is_late: false
            },                                                
        ]
    },
    {
        class_id: 'A32018',
        class_name: '9A3',
        year: 2022,
        week: 15,
        records: [
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 1,
                subject_id: 'Đạo đức',
                lecture_id: '',
                comment: '',
                teacher_id: 't03',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 2,
                subject_id: 'T.Anh',
                lecture_id: '',
                comment: '',
                teacher_id: 't03',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 3,
                subject_id: 'T.Anh',
                lecture_id: '',
                comment: '',
                teacher_id: 't03',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 4,
                subject_id: 'Văn',
                lecture_id: '',
                comment: '',
                teacher_id: 't06',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 5,
                subject_id: 'Văn',
                lecture_id: '',
                comment: '',
                teacher_id: 't06',
                week: 15,
                week_plan: 0,
                is_late: false
            },                                                
        ]
    },
    {
        class_id: 'A42018',
        class_name: '9A4',
        year: 2022,
        week: 15,
        records: [
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 1,
                subject_id: 'Đạo đức',
                lecture_id: '',
                comment: '',
                teacher_id: 't07',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 2,
                subject_id: 'Lý',
                lecture_id: '',
                comment: '',
                teacher_id: 't07',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 3,
                subject_id: 'Lý',
                lecture_id: '',
                comment: '',
                teacher_id: 't07',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 4,
                subject_id: 'T.Anh',
                lecture_id: '',
                comment: '',
                teacher_id: 't03',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 5,
                subject_id: 'T.Anh',
                lecture_id: '',
                comment: '',
                teacher_id: 't03',
                week: 15,
                week_plan: 0,
                is_late: false
            },                                                
        ]
    },
    {
        class_id: 'A52018',
        class_name: '9A5',
        year: 2022,
        week: 15,
        records: [
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 1,
                subject_id: 'Đạo đức',
                lecture_id: '',
                comment: '',
                teacher_id: 't02',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 2,
                subject_id: 'Toán',
                lecture_id: '',
                comment: '',
                teacher_id: 't02',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 3,
                subject_id: 'Toán',
                lecture_id: '',
                comment: '',
                teacher_id: 't02',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 4,
                subject_id: 'Văn',
                lecture_id: '',
                comment: '',
                teacher_id: 't01',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 5,
                subject_id: 'Văn',
                lecture_id: '',
                comment: '',
                teacher_id: 't01',
                week: 15,
                week_plan: 0,
                is_late: false
            },                                                
        ]
    },
    {
        class_id: 'A62018',
        class_name: '9A6',
        year: 2022,
        week: 15,
        records: [
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 1,
                subject_id: 'Đạo đức',
                lecture_id: '',
                comment: '',
                teacher_id: 't02',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 2,
                subject_id: 'Toán',
                lecture_id: '',
                comment: '',
                teacher_id: 't02',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 3,
                subject_id: 'Toán',
                lecture_id: '',
                comment: '',
                teacher_id: 't02',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 4,
                subject_id: 'Văn',
                lecture_id: '',
                comment: '',
                teacher_id: 't01',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 5,
                subject_id: 'Văn',
                lecture_id: '',
                comment: '',
                teacher_id: 't01',
                week: 15,
                week_plan: 0,
                is_late: false
            },                                                
        ]
    },
    {
        class_id: 'A72018',
        class_name: '9A7',
        year: 2022,
        week: 15,
        records: [
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 1,
                subject_id: 'Đạo đức',
                lecture_id: '',
                comment: '',
                teacher_id: 't02',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 2,
                subject_id: 'Toán',
                lecture_id: '',
                comment: '',
                teacher_id: 't02',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 3,
                subject_id: 'Toán',
                lecture_id: '',
                comment: '',
                teacher_id: 't02',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 4,
                subject_id: 'Văn',
                lecture_id: '',
                comment: '',
                teacher_id: 't01',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 5,
                subject_id: 'Văn',
                lecture_id: '',
                comment: '',
                teacher_id: 't01',
                week: 15,
                week_plan: 0,
                is_late: false
            },                                                
        ]
    },
    {
        class_id: 'A82018',
        class_name: '9A8',
        year: 2022,
        week: 15,
        records: [
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 1,
                subject_id: 'Đạo đức',
                lecture_id: '',
                comment: '',
                teacher_id: 't02',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 2,
                subject_id: 'Toán',
                lecture_id: '',
                comment: '',
                teacher_id: 't02',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 3,
                subject_id: 'Toán',
                teacher_id: 't02',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 4,
                subject_id: 'Văn',
                lecture_id: '',
                comment: '',
                teacher_id: 't01',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 5,
                subject_id: 'Văn',
                lecture_id: '',
                comment: '',
                teacher_id: 't01',
                week: 15,
                week_plan: 0,
                is_late: false
            },                                                
        ]
    },
    {
        class_id: 'A92018',
        class_name: '9A9',
        year: 2022,
        week: 15,
        records: [
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 1,
                subject_id: 'Đạo đức',
                lecture_id: '',
                comment: '',
                teacher_id: 't02',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 2,
                subject_id: 'Toán',
                lecture_id: '',
                comment: '',
                teacher_id: 't02',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 3,
                subject_id: 'Toán',
                lecture_id: '',
                comment: '',
                teacher_id: 't02',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 4,
                subject_id: 'Văn',
                lecture_id: '',
                comment: '',
                teacher_id: 't01',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 5,
                subject_id: 'Văn',
                lecture_id: '',
                comment: '',
                teacher_id: 't01',
                week: 15,
                week_plan: 0,
                is_late: false
            },                                                
        ]
    },
    {
        class_id: 'A102018',
        class_name: '9A10',
        year: 2022,
        week: 15,
        records: [
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 1,
                subject_id: 'Đạo đức',
                lecture_id: '',
                comment: '',
                teacher_id: 't02',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 2,
                subject_id: 'Toán',
                lecture_id: '',
                comment: '',
                teacher_id: 't02',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 3,
                subject_id: 'Toán',
                lecture_id: '',
                comment: '',
                teacher_id: 't02',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 4,
                subject_id: 'Văn',
                lecture_id: '',
                comment: '',
                teacher_id: 't01',
                week: 15,
                week_plan: 0,
                is_late: false
            },
            {
                id: uuidv4(),
                schedule_date: 2,
                part_day: 'Sáng',
                period: 5,
                subject_id: 'Văn',
                lecture_id: '',
                comment: '',
                teacher_id: 't01',
                week: 15,
                week_plan: 0,
                is_late: false
            },                                                
        ]
    },
]

export const ClassNotebookData = [
    {
        class_id:'A12018',
        class_name:'9A1',
        year: 2022,
        week: 15,
        records:[
            {
                id: uuidv4(),
                note_date: 'Thứ hai',
                part_day: 'Sáng',
                period: 1,
                period_plan: 1,
                subject_id: 'Toán',                
                lecture_id: 'Phương trình bậc 2', //PlanDetailId
                absen_student: 0,
                comment: 'Lớp học nghiêm túc',
                teacher_id:'t01',
                note: '',
                week: 12,
                week_plan:0,
                is_late:false,
            },
            {
                id: uuidv4(),
                note_date: 'Thứ hai',
                part_day: 'Sáng',
                period: 2,
                period_plan: 2,
                subject_id: '14',
                lecture_id: 'l02',
                absen_student: 0,
                comment: 'Lớp học sôi nổi, hăng hái phát biểu xây dựng bài',
                teacher_id:'t02',
                note: '',
                week: 12,
                week_plan:0,
                is_late:false,
            },
            {
                id: uuidv4(),
                note_date: 'Thứ hai',
                part_day: 'Sáng',
                period: 3,
                period_plan: 3,
                subject_id: 'Lịch Sử',
                lecture_id: 'Cuộc khởi nghĩa Lam Sơn',
                absen_student: 0,
                comment: 'Lớp học trầm',
                teacher_id:'t03',
                note: '',
                week: 12,
                week_plan:0,
                is_late:false,
            },
            {
                id: uuidv4(),
                Note_date: 'Thứ hai',
                part_day: 'Sáng',
                period: 4,
                period_plan: 4,
                subject_id: 'Địa lý',
                lecture_id: 'Khí hậu gió mùa',
                absen_student: 0,
                comment: 'Lớp học nghiêm túc',
                teacher_id:'t04',
                note: '',
                week: 12,
                week_plan:0,
                is_late:false,
            },
            {
                id: uuidv4(),
                note_date: 'Thứ hai',
                part_day: 'Sáng',
                period: 5,
                period_plan: 5,
                subject_id: 'Sinh hoạt',
                lecture_id: 'Kế hoạch học tập đầu tuần',
                absen_student: 0,
                comment: 'Lớp học nghiêm túc',
                teacher_id:'t05',
                note: '',
                week: 12,
                week_plan:0,
                is_late:false,
            },
            {
                id: uuidv4(),
                note_date: 'Thứ hai',
                part_day: 'Chiều',
                period: 1,
                period_plan: 1,
                subject_id: 'Toán',
                lecture_id: 'Phương trình bậc 2',
                absen_student: 0,
                comment: 'Lớp học nghiêm túc',
                teacher_id:'t06',
                note: '',
                week: 12,
                week_plan:0,
                is_late:false,
            },
            {
                id: uuidv4(),
                note_date: 'Thứ hai',
                part_day: 'Chiều',
                period: 2,
                period_plan: 2,
                subject_id: 'Ngữ văn',
                lecture_id: 'Phân tích tác phẩm Hòn Đất',
                absen_student: 0,
                comment: 'Lớp học sôi nổi, hăng hái phát biểu xây dựng bài',
                teacher_id:'t01',
                note: '',
                week: 12,
                week_plan:0,
                is_late:false,
            },
            {
                id: uuidv4(),
                note_date: 'Thứ hai',
                part_day: 'Chiều',
                period: 3,
                period_plan: 3,
                subject_id: 'Lịch Sử',
                lecture_id: 'Cuộc khởi nghĩa Lam Sơn',
                absen_student: 0,
                comment: 'Lớp học trầm',
                teacher_id:'t02',
                note: '',
                week: 12,
                week_plan:0,
                is_late:false,
            },
            {
                id: uuidv4(),
                note_date: 'Thứ hai',
                part_day: 'Chiều',
                period: 4,
                period_plan: 4,
                subject_id: 'Địa lý',
                lecture_id: 'Khí hậu gió mùa',
                absen_student: 0,
                comment: 'Lớp học nghiêm túc',
                teacher_id:'t04',
                note: '',
                week: 12,
                week_plan:0,
                is_late:false,
            },
            {
                id: uuidv4(),
                note_date: 'Thứ hai',
                part_day: 'Chiều',
                period: 5,
                period_plan: 5,
                subject_id: 'Sinh hoạt',
                lecture_id: 'Kế hoạch học tập đầu tuần',
                absen_student: 0,
                comment: 'Lớp học nghiêm túc',        
                teacher_id:'t05',
                note: '',
                week: 12,
                week_plan:0,
                is_late:false,
            }        
        ]
    },
    {
        class_id:'A22028',
        class_name:'9A2',
        year:2022,
        week: 15,
        records:[]
    },
]

export const Subjects=[
    {
        SubjectId:'11',
        Title: 'Địa lý',
        Category:'SC01',
        IsActive: true
    },
    {
        SubjectId:'12',
        Title: 'GDCD',
        Category:'SC01',
        IsActive: true
    },
    {
        SubjectId:'13',
        Title: 'Lịch sử',
        Category:'SC01',
        IsActive: true
    },
    {
        SubjectId:'14',
        Title: 'Ngữ Văn',
        Category:'SC01',
        IsActive: true
    },
    {
        SubjectId:'21',
        Title: 'Toán',
        Category: 'SC02',
        IsActive: true
    },
    {
        SubjectId:'22',
        Title: 'Lý',
        Category: 'SC02',
        IsActive: true
    },
    {
        SubjectId:'23',
        Title: 'Hóa',
        Category: 'SC02',
        IsActive: true
    },
    {
        SubjectId:'24',
        Title: 'Sinh',
        Category: 'SC02',
        IsActive: true
    }, 
    {
        SubjectId:'25',
        Title: 'Công nghệ',
        Category: 'SC02',
        IsActive: true
    },  
    {
        SubjectId:'26',
        Title: 'Tin học',
        Category: 'SC02',
        IsActive: true
    },
    {
        SubjectId:'27',
        Title: 'Tiếng Anh',
        Category: 'SC03',
        IsActive: true
    },   
    {
        SubjectId:'28',
        Title: 'Tiếng Pháp',
        Category: 'SC04',
        IsActive: true
    }                                   
]

export const Teachers=[
    {
        TeacherId: 't01',
        UserEmail:'hominhien@outlook.com',
        UserName:'hominhhien',
        FirstName: 'Hiền',
        LastName: 'Hồ Minh',
        PhoneNumber:'0913059605',
        Address:'Kim mã, Ba Đình, Hà Nội',     
        FullName:'Hồ Minh Hiền',
        IsActive: 0
    },
    {
        TeacherId: 't02',
        UserEmail:'thugiang@outlook.com',
        UserName:'thugiang',
        FirstName: 'Giang',
        LastName: 'Nguyễn Thu',
        PhoneNumber:'0913059605',  
        Address:'Kim Liên, Đống Đa, Hà Nội',  
        FullName:'Nguyễn Thu Giang',
        IsActive: 1
    },
    {
        TeacherId: 't03',
        UserEmail:'duongtiennam@outlook.com',
        UserName:'duongtienmanh',
        FirstName: 'Mạnh',
        LastName: 'Dương Tiến',
        PhoneNumber:'0913059605',  
        Address:'Lý Thái Tổ, Hoàn Kiếm, Hà Nội',  
        FullName:'Dương Tiến Nam',
        IsActive: 1
    },
    {
        TeacherId: 't04',
        UserEmail:'thanhhang@outlook.com',
        UserName:'thanhhang',
        FirstName: 'Hằng',
        LastName: 'Phạm Thanh',
        PhoneNumber:'0913059605',  
        Address:'Láng Hạ, Ba Đình, Hà Nội',  
        FullName:'Phạm Thanh Hằng',
        IsActive: 1
    },
    {
        TeacherId: 't05',
        UserEmail:'tranthanhtu@outlook.com',
        UserName:'tranthanhtu',
        FirstName: 'Tú',
        LastName: 'Trần Thanh',
        PhoneNumber:'0913059605',  
        Address:'Phạm Ngọc Thạch, Đống Đa, Hà Nội',  
        FullName:'Trần Thanh Tú',
        IsActive: 1
    },
    {
        TeacherId: 't06',
        UserEmail:'baquynh@outlook.com',
        UserName:'baquynh',
        FirstName: 'Quỳnh',
        LastName: 'Phạm Bá',
        PhoneNumber:'0913059605',  
        Address:'Kim mã, Ba Đình, Hà Nội',  
        FullName:'Phạm Bá Quỳnh',
        IsActive: 1
    },
    {
        TeacherId: 't07',
        UserEmail:'thuytien@outlook.com',
        UserName:'thuytien',
        FirstName: 'Tiên',
        LastName: 'Đặng Thủy',
        PhoneNumber:'0913059605',  
        Address:'Kim mã, Ba Đình, Hà Nội',  
        FullName:'Đặng Thủy Tiên',
        IsActive: 1
    },
]

export const ClassList=[
    {
        ClassId: 'A12018',
        Title:'9A1',
        StartYear: 2021,
        EndYear: 2022,
        Students: 40,
        Grade: 9, 
        HeadTeacher:'',
        Note:'',
        IsActive: true
    },
    {
        ClassId: 'A22020',
        Title:'9A2',
        StartYear: 2021,
        EndYear: 2022,
        Students: 40,
        Grade: 9, 
        HeadTeacher:'',
        Note:'',
        IsActive: true
    },
    {
        ClassId: 'A32020',
        Title:'9A3',
        StartYear: 2021,
        EndYear: 2022,
        Students: 40,
        Grade: 9, 
        HeadTeacher:'',
        Note:'',
        IsActive: true
    },
    {
        ClassId: 'A42020',
        Title:'9A4',
        StartYear: 2021,
        EndYear: 2022,
        Students: 40,
        Grade: 9, 
        HeadTeacher:'',
        Note:'',
        IsActive: true
    },
    {
        ClassId: 'A52020',        
        Title:'9A5',
        StartYear: 2021,
        EndYear: 2022,
        Students: 40,
        Grade: 9, 
        HeadTeacher:'',
        Note:'',
        IsActive: true
    }
]

export const SubjectPlans = [
    {
        SubjectPlanId: 'SB01',
        SubjectId: 'e7d58e7d-ec30-461e-a553-268a196a91ce',
        Title:'Kế hoạch giảng dạy môn Ngữ Văn',
        StartYear:2021,
        EndYear: 2022,
        Grade: 9,
        RefDocument:'công văn 4040 Bộ GDĐT  ngày 16/9/2021',
        Sem1_TotalWeeks: 18,
        Sem1_PeriodsPerWeek: 5,
        Sem2_TotalWeeks: 17,
        Sem2_PeriodsPerWeek: 5,
        IsActive: true
    },
    {
        SubjectPlanId: 'SB02',
        SubjectId: '086fd41c-da6b-438c-8dc2-ac25e9ec20d4',
        Title:'Kế hoạch giảng dạy môn Toán',
        StartYear:2021,
        EndYear: 2022,
        Grade: 9,
        RefDocument:'',
        Sem1_TotalWeeks: 18,
        Sem1_PeriodsPerWeek: 5,
        Sem2_TotalWeeks: 17,
        Sem2_PeriodsPerWeek: 5,
        IsActive: true
    }
]

export const SubjectPlanDetail = [
    {
        PlanDetailId:'SB01-01',
        SubjectPlanId:'SB01',
        Category:'',
        Title:'Phong cách Hồ Chí Minh',
        Semester: 1,
        Week: 1,
        Period: '1-2',
        AdjustContent:'',
        GuideLine: '',        
        StartYear:2021,
        EndYear: 2022,
        Grade:9,
        SubjectId:'e7d58e7d-ec30-461e-a553-268a196a91ce',
        IsActive: true,

    },
    {
        PlanDetailId:'SB01-02',
        SubjectPlanId:'SB01',
        Category:'',
        Title:'Các phương châm hội thoại',
        Semester: 1,
        Week: 1,
        Period: '3,4',
        AdjustContent:'Cả 2 bài',
        GuideLine: 'Tích hợp thành một bài tập trung vào các phần I, II, III.1, III.2, III, 4 bài các PCHT và các phần I, II, III, IV.3, VI.4...',
        StartYear:2021,
        EndYear: 2022,
        Grade:9,
        SubjectId:'086fd41c-da6b-438c-8dc2-ac25e9ec20d4',
        IsActive: true,
    },  
    {
        PlanDetailId:'SB01-03',
        SubjectPlanId:'SB01',
        Category:'',
        Title:'Sử dụng một số biện pháp nghệ thuật trong văn bản thuyết minh. Luyện tập sử dụng một số biện pháp nghệ thuật trong văn bản thuyết ming',
        Semester: 1,
        Week: 1,
        Period: '5',
        AdjustContent:'Cả 2 bài',
        GuideLine: 'Tích hợp thành một bài, tập trung hướng dẫn HS thực hành viết VBTM có SD một số biện pháp NT',
        IsActive: true
    },  
    {
        PlanDetailId:'SB01-04',
        SubjectPlanId:'SB01',
        Category:'',
        Title:'Sử dụng một số biiejn pháp nghệ thuật trong văn bản thuyết minh. Luyện tập sử dụng một số biện pháp nghệ thuật trong văn bản thuyết mình (tiếp)',
        Semester: 1,
        Week: 2,
        Period: '6',
        AdjustContent:'Cả 2 bài',
        GuideLine: 'Tích hợp thành một bài, tập trung hướng dẫn HS thực hành viết VBTM có SD một số biện pháp NT',
        StartYear:2021,
        EndYear: 2022,
        Grade:9,
        SubjectId:'086fd41c-da6b-438c-8dc2-ac25e9ec20d4',
        IsActive: true,
    },
    {
        PlanDetailId:'SB01-05',
        SubjectPlanId:'SB01',
        Category:'',
        Title:'Đấu tranh cho một thế giới hòa bình',
        Semester: 1,
        Week: 2,
        Period: '7,8',
        AdjustContent:'',
        GuideLine: '',
        StartYear:2021,
        EndYear: 2022,
        Grade:9,
        SubjectId:'086fd41c-da6b-438c-8dc2-ac25e9ec20d4',
        IsActive: true,
    },
    {
        PlanDetailId:'SB01-06',
        SubjectPlanId:'SB01',
        Category:'',
        Title:'Sử dụng yếu tố miêu tả trong văn bản thuyết minh. Luyện tập yếu tố miêu tả trong văn bản thuyết minh',
        Semester: 1,
        Week: 2,
        Period: '9,10',
        AdjustContent:'Cả 2 bài',
        GuideLine: 'Tích hợp thành một bài, tập trung hướng dẫn HS thực hành viết VBTM có SD yếu tố miêu tả',
        StartYear:2021,
        EndYear: 2022,
        Grade:9,
        SubjectId:'086fd41c-da6b-438c-8dc2-ac25e9ec20d4',
        IsActive: true,
    }
]

export const SchoolYearSettings = [
    {
        SettingId: uuidv4(),
        Title:'2021-2022',
        StartYear: 2021,
        EndYear: 2022,
        Sem1_StartDay: new Date(2021,9,6),
        Sem1_TotalWeeks: 18,
        Sem1_StartIsoWeek:36,
        Sem2_StartDay: new Date(2022,2,7),
        Sem2_TotalWeeks: 17,
        Sem2_StartIsoWeek:54,            
        Note:'Mock data'
    }
]

export const WeeklyCalendars = [
    {
        Id: 1,
        Title:'Họp BGH',
        StartDate:'',
        EndDate:'',
        Detail:'',
        Location:'',
        Responder:'',
        Priority:2,
        Category: 'Họp',
        UID: uuidv4()
    }
]

export const NewsBoard = [
    {
        NewsId: uuidv4(),
        Title:'Thông báo thi học kỳ 2',
        Summary:'Kế hoạch thi học kỳ 2....',
        Content:'Kế hoạch thi học kỳ 2.... - Nội dung chi tiết',
        Category:'Thông báo',
        ExpireDate: dayjs().add(365,'day').toISOString()
    }
]

export const WorkPlans=[
    {
        WorkplanId: '1',
        Title:'Dạy học theo kế hoạch dạy học',
        PlanType:'Kế hoạch',
        Result: 'Kế hoạch dạy học',
        StartDate: '2023-11-01',
        EndDate:'2023-11-30',
        ProcessingDuration:'1/11 - 30/11',     
        CompletedDuration:'Từng tuần',
        AssignedTo: 'Tất cả GV tổ XH',
        Leader:'Tiến Nam',
        Note:'',
        DepartmentId:'7b9e654e-db99-4b29-bb02-cddeadf7cbf1'
    },
    {
        WorkplanId: '2',
        Title:'Kiểm tra giữa kì I',
        PlanType:'Kế hoạch',
        Result: 'Kế hoạch thi giữa kì',
        StartDate: '2023-11-1',
        EndDate:'2023-11-6',
        ProcessingDuration:'Tuần 1',     
        CompletedDuration:'Tuần 1',
        AssignedTo: 'Tất cả GV tổ XH',
        Leader:'Tiến Nam',
        Note:'',
        DepartmentId:'7b9e654e-db99-4b29-bb02-cddeadf7cbf1'
    },
    {
        WorkplanId: '3',
        Title:'Bồi dưỡng HSG cấp Quận',
        PlanType:'Kế hoạch',
        Result: 'KH tổ chức các HĐGD',
        StartDate: '2023-11-01',
        EndDate:'2023-11-30',
        ProcessingDuration:'Tuần 1, 2,3',     
        CompletedDuration:'Tuần 1, 2,3',
        AssignedTo: 'H. Lan,Tr. Thủy, Nam, Miên, P. Lan',
        Leader:'Tiến Nam',
        Note:'',
        DepartmentId:'7b9e654e-db99-4b29-bb02-cddeadf7cbf1'
    },
    {
        WorkplanId: '4',
        Title:'Xây dựng đề cương học kì I',
        PlanType:'Kế hoạch',
        Result: 'KH thi HK 2',
        StartDate: '2023-11-01',
        EndDate:'2023-11-30',
        ProcessingDuration:'Tuần 3,4',     
        CompletedDuration:'Tuần 4',
        AssignedTo: 'Nhóm trưởng',
        Leader:'Tiến Nam',
        Note:'',
        DepartmentId:'7b9e654e-db99-4b29-bb02-cddeadf7cbf1'
    },
    {
        WorkplanId: '5',
        Title:'Thi HSG cấp Quận vòng 2',
        PlanType:'Kế hoạch',
        Result: 'KH tổ chức các HĐGD',
        StartDate: '2023-11-01',
        EndDate:'2023-11-30',
        ProcessingDuration:'Tuần 3,4',     
        CompletedDuration:'	Tuần 3',
        AssignedTo: 'Linh Chi, Hằng A',
        Leader:'Tiến Nam',
        Note:'',
        DepartmentId:'7b9e654e-db99-4b29-bb02-cddeadf7cbf1'
    },    
    {
        WorkplanId: '6',
        Title:'Chuyên đề tổ môn GDCD',
        PlanType:'Kế hoạch',
        Result: 'KH tổ chức các HĐGD',
        StartDate: '2023-11-01',
        EndDate:'2023-11-30',
        ProcessingDuration:'Tuần 3,4',     
        CompletedDuration:'Tuần 3',
        AssignedTo: 'Hằng B',
        Leader:'Tiến Nam',
        Note:'',
        DepartmentId:'7b9e654e-db99-4b29-bb02-cddeadf7cbf1'
    },
    {
        WorkplanId: '7',
        Title:'Tổ chức 20/11',
        PlanType:'Kế hoạch',
        Result: 'KH tổ chức các HĐGD',
        StartDate: '2023-11-01',
        EndDate:'2023-11-30',
        ProcessingDuration:'Tuần 3,4',     
        CompletedDuration:'Tuần 3',
        AssignedTo: 'Trịnh Hoa',
        Leader:'Tiến Nam',
        Note:'',
        DepartmentId:'7b9e654e-db99-4b29-bb02-cddeadf7cbf1'
    },                        
]