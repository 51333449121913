import React, {useState} from "react";

import dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

import WeeklyCalendarItem from './WeeklyCalendarItem';
import WeekPicker from "../common/WeekPicker";
import YearPicker from "../common/YearPicker";

function WeeklyCalendarList({data, onAddItemClick, onSaveItemClick, onDeleteItemClick}){
    return(        
        <form className="form-inline" name="frmWeeklyCalendarList">       
            <table className="table table-hover table-sm">
                <thead>
                    <tr>
                        <th style={{width:'5%'}} className='text-left'>Ngày</th>
                        <th style={{width:'4%'}} className='text-center'>Giờ BĐ</th>
                        <th style={{width:'4%'}} className="text-center">Giờ KT</th>
                        <th style={{width:'12%'}} className="text-left">Nội dung</th>
                        <th style={{width:'10%'}}>Người thực hiện</th>  
                        <th style={{width:'10%'}}>Địa điểm</th>    
                        <th style={{width:'5%'}}>Ưu tiên</th>    
                        <th style={{width:'5%'}}>Hình thức</th>    
                        <th style={{width:'15%'}}>Chi tiết</th>                              
                        <th style={{width:'8%'}} className='text-end'>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    { 
                        data.map((item) => 
                            <WeeklyCalendarItem 
                                key={item.UID}
                                item = {item} 
                                action = {item.NewRecord*1===1?'new':''}
                                onSaveItemClick = {onSaveItemClick}
                                onDeleteItemClick={onDeleteItemClick}   
                                onAddItemClick = {onAddItemClick}                             
                            />
                        )
                    }
                </tbody>
            </table>
        </form>
    )
}

export default WeeklyCalendarList;