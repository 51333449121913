import  {useEffect, useState} from 'react';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

import { v4 as uuidv4 } from 'uuid';
import * as dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

import { scheduleConfig } from '../../appConfig';
import {
    updateSharePointItem,
    addSharePointListItem,
    getSharePointListItemsBatch,
    deleteSharePointItemBatch,
} from '../../graph';

import { orderBy as _orderBy } from 'lodash';
import { loginRequest, sharePointConfig } from '../../authConfig';
import { UpdateDateForScheduleItems } from './ScheduleData';
import { ConsoleView } from 'react-device-detect';


/* **************************************************************************************
 * EmptyScheduleRecords
 * Khởi tạo dữ liệu trống chi tiết lịch phân tiết dạy cho một lớp trong một tuần
 * @param {*} year : Năm học
 * @param {*} week : Tuần học (tuần theo ISOweek)
 * @returns : Cấu trúc dữ liệu là một mảng các records chứa thông tin một tiết học
 * *************************************************************************************/
export const EmptyLectureScheduleRecords = (startDateOfWeekIsoString, schoolWeek) => {
    let resultRecs = [];
    for(let i=1; i<7; i++){
        let d = dayjs(startDateOfWeekIsoString).isoWeekday(i);
        let recs = Array(10).fill(
            {
                id: '',             //Lấy trùng với mục ID của thời khóa biểu nếu có
                item_id:0,          //chứa SPListItem ID của Item cha phục vụ xác định cập nhật hay thêm mới SP Item cha
                schedule_date: d.toISOString(), 
                class_id: '',       //Mã lớp dạy học
                class_name: '',     //Tên lớp dạy học
                part_day:'', 
                period: 0,          //Tiết học theo thời khóa biểu
                lecture_period: 0,  //Tiết học của bài học theo phân phối chương trình                
                lecture_id: '',     //Id bài giảng
                category: '',       //Phân môn nếu có
                teacher_id:'',      // mã giáo viên, lặp lại của bản ghi cha
                subject_id: '',     // Mã môn học
                school_week: schoolWeek,     //week theo tuần học theo phân công bởi Thời khóa biểu (tuần 1 bắt đầu từ ngày khai giảng)
                lecture_week: 0,    //week theo tuần học của bài giảng
                is_late: false,     //Được so sánh bởi week_school và week_plan. Nếu week_plan < week_school thì là chậm tiết
                comment: '', 
                status: '',
                type: '',           //Nếu là tiết học bình thường thì trống, còn không thì có thể là  tiết bù   
                action:'',          //Các thông tin liên quan khác đến tiết học như dự giờ, dạy bù...    
                is_free: true,      //Là tiết trống trong sổ báo giảng của 1 giáo viên - chú ý là chỉ của 1 gv, còn chưa chắc là cho tất cả                   
            }).map((item, idx) => {
                return ({
                    ...item, 
                    id: uuidv4(),                   
                    part_day: idx<5 ? 'Sáng': 'Chiều',  
                    period: idx < 5 ? idx + 1 : idx - 4,
                });
            });
        resultRecs = resultRecs.concat(recs);
    }
    return resultRecs;
}

export const EmptyLectureSchedule = (teacherId, teacherName, schoolYear, schoolWeek, startDateOfWeekIsoString) => {
    let emptyLs = {
        item_id: 0,  //ID của SharePoint List Item
        lecture_schedule_id: uuidv4(),
        teacher_id: teacherId,
        teacher_name: teacherName,
        school_year: schoolYear,
        school_week: schoolWeek,
        start_date_week_isostring: startDateOfWeekIsoString,     
        records: EmptyLectureScheduleRecords(startDateOfWeekIsoString, schoolWeek),
        flagDataChanged: false, //Báo hiệu mục dữ liệu này có thay đổi hay không
    };  
    return emptyLs;  
}



/* ******************************************************************************************
 * useLectureScheduleDataApi
 * Thực hiện các thao tác với dữ liệu Schedule phục vụ cho việc hiện Lịch báo giảng
 * *****************************************************************************************
 * @param {*} defautlAction 
 * @returns 
 * *****************************************************************************************/
export const useLectureScheduleDataApi = (defautlAction={}) => {
    dayjs.extend(isoWeek);

    const [lsScheduleItem, setLSScheduleItem] = useState(null);
    const [action, setLectureScheduleAction] = useState(defautlAction);
    const [isProcessingScheduleData, setIsProcessingScheduleData] = useState(false);
    const [flagReloadAfterAddNew, setFlagReloadAfterAddNew] = useState(false);
    
    const {instance, accounts} = useMsal();
    
    useEffect(() => {  
        const accessTokenRequest = {
            ...loginRequest,
            account: accounts[0]
        }   
         

        const fetchDataByImportedSchedule = (accessToken, actionValue) => {
            const {teacherId, firstDateOfWeekIsoString, schoolYear, schoolWeek} = actionValue;
            const lastDateOfWeekIsoString = dayjs(firstDateOfWeekIsoString).add(7,"d").toISOString();

            if(teacherId==="") {
                setIsProcessingScheduleData(false);
                setFlagReloadAfterAddNew(false);
                return;
            }

            // 1. Lọc lấy dữ liệu TKB của giáo viên từ Schedulue List và từ LectureScheduleXXX List
            const requestIds = [0,1];
            const spListIds = [
                sharePointConfig.ScheduleImportedListId, 
                sharePointConfig.LectureScheduleListId, 
            ];

            const selectFields = [
                "Title,SchoolYear,ApplySchoolWeek,StartDateOfWeek,ScheduleP1,ScheduleP2,ScheduleP3,ScheduleP4,ScheduleP5,id", //TKB
                "LectureScheduleId,TeacherId,Title,SchoolYear,SchoolWeek,StartDateOfWeek,LectureRecords,id", //So Bao Giang
            ];
                
            const queryClauses = [
                `((fields/SchoolYear eq ${schoolYear}) and (fields/StartDateOfWeek lt '${lastDateOfWeekIsoString}'))`,
                `((fields/StartDateOfWeek eq '${firstDateOfWeekIsoString}') and (fields/TeacherId eq '${teacherId}'))`,
            ];

            const orderClause = [
                "fields/StartDateOfWeek desc",
                "undefined"
            ]
            const topN = [1,null];
            
            let spScheduleItems = [];
            let spLectureScheduleItems = [];

            getSharePointListItemsBatch(accessToken, requestIds, spListIds, selectFields,queryClauses,orderClause,topN).then(response => {                
                response.responses.forEach(r=>{
                    if (r.status === 200 || r.status===201) {
                        switch(parseInt(r.id)) {
                            case 0:
                                spScheduleItems = r.body.value;  
                                break;
                            case 1:
                                spLectureScheduleItems = r.body.value;
                                break;
                            default:
                                //spScheduleLogItems =  r.body.value;
                        }
                    } else {
                        console.error(`Lỗi khi lấy dữ liệu ${r.id===0?'Schedule List':'LectureSchedule List'}`, r)
                    }
                });


                let lectureScheduleObj = EmptyLectureSchedule(teacherId,"", schoolYear, schoolWeek, firstDateOfWeekIsoString);

                // 2. Merge dữ liệu lấy được để sẵn sàng cho việc hiển thị báo giảng
                // 2.1 Xử lý dữ liệu Sổ báo giảng của giáo viên lecture records          
                let lectureRecords=[];
                if (spLectureScheduleItems.length>1) { 
                    //Sắp xếp để mục mới nhất ở trên cùng
                    spLectureScheduleItems = _orderBy(spLectureScheduleItems,['id'],['desc']);
                    
                    //Xóa các mục cũ khỏi SharePoint List/CSDL
                    let spItemIdArray = [];
                    for (let i=1; i<spLectureScheduleItems.length; i++) {
                        spItemIdArray.push(spLectureScheduleItems[i].id);
                    }
                    if(spItemIdArray.length>0){
                        deleteSharePointItemBatch(accessToken, sharePointConfig.LectureScheduleListId, spItemIdArray).then(response=>{
                            console.log('==>Xóa các mục báo giảng thừa: ',spItemIdArray, response);
                        });
                    }
                }
                var ITEM_ID_CUA_SO_BG = 0;
                if (spLectureScheduleItems.length>0){
                    const spItem = spLectureScheduleItems[0].fields;
                    lectureScheduleObj.item_id = spItem.id;
                    ITEM_ID_CUA_SO_BG = spItem.id;

                    lectureRecords = spItem.LectureRecords ? JSON.parse(spItem.LectureRecords) : [];  
                    if(lectureRecords.length>0){
                        lectureRecords = lectureRecords.map(rec=>{           
                            return {
                                ...rec,
                                item_id: spItem.id,
                                school_week: schoolWeek,
                                //start reset muc bao giang không hợp lệ vì 1 lý do nào đó - Sửa để fix lỗi báo giảng sai ngày 09/10/2023
                                teacher_id: rec.lecture_week!=="" && rec.lecture_week>0?rec.teacher_id:"",
                                subject_id: rec.lecture_week!=="" && rec.lecture_week>0?rec.subject_id:"",
                                class_id:  rec.lecture_week!=="" && rec.lecture_week>0?rec.class_id: "",
                                class_name: rec.lecture_week!=="" && rec.lecture_week!=="" && rec.lecture_week>0?rec.class_name: "",
                                category:  rec.lecture_week!=="" && rec.lecture_week>0?rec.category: "",
                                is_free:  rec.lecture_week!=="" && rec.lecture_week>0?false:true,
                                //end reset
                            }
                        });
                    } 
                    //console.log('lectureRecords - Du lieu tu so bao giang chua merge', lectureRecords);
                }
                if(ITEM_ID_CUA_SO_BG>0){
                    // lectureScheduleObj.records = lectureRecords.map(rec=>{
                    //     return {
                    //         ...rec,
                    //         item_id: ITEM_ID_CUA_SO_BG,
                    //     }
                    // })
                    lectureScheduleObj.records = lectureScheduleObj.records.map(rec=>{
                        return {
                            ...rec,
                            item_id: ITEM_ID_CUA_SO_BG,
                        }
                    })
                }

                let scheduleRecords = [];  
                //2.2 Lọc lấy dữ liệu TKB schedule records                
                if (spScheduleItems.length>0) {
                    let tempScheduleItems = [];
                    const spItem = spScheduleItems[0].fields;
                    tempScheduleItems = [].concat(
                        JSON.parse(spItem.ScheduleP1), 
                        JSON.parse(spItem.ScheduleP2),
                        JSON.parse(spItem.ScheduleP3),
                        JSON.parse(spItem.ScheduleP4),
                        JSON.parse(spItem.ScheduleP5)
                    );
                    spScheduleItems = UpdateDateForScheduleItems(tempScheduleItems, schoolYear, schoolWeek, spItem.ApplySchoolWeek, firstDateOfWeekIsoString, false, false);
                    spScheduleItems = spScheduleItems.map(item=>{
                        return {
                            ...item,
                            item_id: ITEM_ID_CUA_SO_BG, //spItem.id,
                            school_week: schoolWeek
                        }
                    });                   

                    //console.log('spScheduleItems - du lieu tu thoi khoa bieu',spScheduleItems);

                    let tempRecords = [];
                    //for (let i=0; i<spScheduleItems.length; i++){
                    spScheduleItems.forEach(item=>{
                        //const item = spScheduleItems[i]; 
                        const records = item.records; 
                        tempRecords = tempRecords.concat(records);
                        let filteredRecords = records.filter(s=>s.teacher_id===teacherId);
                        if (filteredRecords.length > 0){
                            filteredRecords = filteredRecords.map(rec=>{
                                return {
                                    ...rec,
                                    item_id: item.item_id,
                                    class_id: item.class_id,
                                    class_name: item.class_name,
                                    school_year: item.school_year,
                                    school_week: item.school_week,
                                }
                            })
                            scheduleRecords = scheduleRecords.concat(filteredRecords);                            
                        }
                    }); 
                }
                //console.log('scheduleRecords - Du lieu TKB da loc lay cho giao vien',scheduleRecords);

                //3. Trộn dữ liệu từ Schedule và LectureSchedule để tạo thành dữ liệu báo giảng hiển thị theo giáo viên
                //3.1 trộn từ dữ liệu Schedule
                scheduleRecords.forEach(rec=>{
                    // const rec = scheduleRecords[i];
                    const idx = lectureScheduleObj.records.findIndex(item => 
                        dayjs(item.schedule_date).format('DD/MM/YYYY') === dayjs(rec.schedule_date).format('DD/MM/YYYY')
                        && item.part_day === rec.part_day
                        && item.period === rec.period);

                    if(dayjs(rec.schedule_date).format('DD/MM/YYYY')==='06/11/2023' && rec.period===5){
                        console.log('TKB Tiet 5, thu hai 06/11/2023',rec);
                    }   

                    if(idx>=0){
                        lectureScheduleObj.records[idx] = {
                            ...lectureScheduleObj.records[idx],
                            id: rec.id,  
                            item_id: rec.item_id,
                            class_name: rec.class_name,
                            class_id: rec.class_id,                            
                            subject_id: rec.subject_id,
                            teacher_id: rec.teacher_id,
                            is_free: rec.subject_id !=="" ? false : lectureScheduleObj.records[idx].is_free,
                        }
                    }
                });

                //3.2 Trộn từ dữ liệu LectureSchedule
                // console.log('Trộn từ dữ liệu lấy từ LectureSchedule...')
                lectureRecords.forEach(rec => {
                    const idx = lectureScheduleObj.records.findIndex(item => 
                        dayjs(item.schedule_date).format('DD/MM/YYYY') === dayjs(rec.schedule_date).format('DD/MM/YYYY')
                        && item.part_day === rec.part_day
                        && item.period === rec.period);
                        
                    //Kiem tra xem muc trong bao giang co trong TKB khong (truong hop TKB thay doi sau khi gv da bao giang)
                    //Neu khong co thi khong tron vao bao gian nua, rieng muc bao giang day bu thi van xac dinh de tron
                    const idxTKB = rec.type===scheduleConfig.LectureScheduleType_DayBu? 10000: scheduleRecords.findIndex(item=>
                        dayjs(item.schedule_date).format('DD/MM/YYYY') === dayjs(rec.schedule_date).format('DD/MM/YYYY')
                        && item.part_day === rec.part_day
                        && item.period === rec.period);
                    //Het kiem tra
                    
                    if (idx>=0 && idxTKB>=0 && rec.lecture_id && rec.lecture_id!==""  
                        && ((lectureScheduleObj.records[idx].period===rec.period
                              && lectureScheduleObj.records[idx].subject_id===rec.subject_id 
                              && lectureScheduleObj.records[idx].class_id === rec.class_id) 
                              || rec.type===scheduleConfig.LectureScheduleType_DayBu)) //Nếu mục trong sổ báo giảng đã báo giảng thì mới trộn vào, đề phòng trường hợp TKB có điều chỉnh
                    {
                        lectureScheduleObj.records[idx] = {
                            ...rec
                        }; 
                    }               
                });

                //console.log('lectureScheduleObj - Sổ báo giảng',lectureScheduleObj);
                setLSScheduleItem(lectureScheduleObj); 
                setIsProcessingScheduleData(false);
                setFlagReloadAfterAddNew(false);
            });
        }

        
        const saveData = (accessToken, actionValue) => {            
            const lectureScheduleItem = actionValue;        
            //Lưu vào Lecture Schedule
            let fieldValues;
            if (parseInt(lectureScheduleItem.item_id)<=0) { 
                fieldValues = {
                    fields: {
                        Title: `LBG-${lectureScheduleItem.school_year}-${lectureScheduleItem.school_week}-${lectureScheduleItem.teacher_name}`,
                        LectureScheduleId: lectureScheduleItem.lecture_schedule_id,
                        TeacherId: lectureScheduleItem.teacher_id,
                        SchoolYear: lectureScheduleItem.school_year,
                        SchoolWeek: lectureScheduleItem.school_week,
                        StartDateOfWeek: lectureScheduleItem.start_date_week_isostring,
                        LectureRecords: JSON.stringify(lectureScheduleItem.records)
                    }
                };
                addSharePointListItem(accessToken, sharePointConfig.LectureScheduleListId,fieldValues).then(response => {                    
                    console.log('Hoàn thành việc ghi mới vào Sổ báo giảng', response);   
                    setIsProcessingScheduleData(false);
                    setFlagReloadAfterAddNew(true);

                });
            } else { 
                fieldValues = {
                    fields: {
                        Title: `LBG-${lectureScheduleItem.school_year}-${lectureScheduleItem.school_week}-${lectureScheduleItem.teacher_name}`,
                        SchoolWeek: lectureScheduleItem.school_week,
                        StartDateOfWeek: lectureScheduleItem.start_date_week_isostring,                        
                        LectureRecords: JSON.stringify(lectureScheduleItem.records)
                    }
                };
                updateSharePointItem(accessToken, sharePointConfig.LectureScheduleListId, lectureScheduleItem.item_id, fieldValues).then(response => {
                   console.log('Hoàn thành việc Sửa vào Sổ báo giảng', response);
                   setIsProcessingScheduleData(false);                  
                });
            }
        }
        
        const processData = async () => {              
            instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
                const accessToken = accessTokenResponse.accessToken;                
                switch(action.type){
                    case 'save':
                        setIsProcessingScheduleData(true);
                        saveData(accessToken, action.value);
                        break;
                    default:
                        if(action.value) {
                            setIsProcessingScheduleData(true);
                            //fetchData(accessToken, action.value);
                            fetchDataByImportedSchedule(accessToken, action.value);
                        }
                }
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest);                    
                }
                setIsProcessingScheduleData(false);
                console.log(error);
            }) 
        }; 

        processData(); 
    },[accounts, action, instance]);

    return [{lsScheduleItem, isProcessingScheduleData, flagReloadAfterAddNew}, setLectureScheduleAction]
}