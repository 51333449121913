import { Link } from 'react-router-dom';
import { useContext } from 'react';
import  Typography  from '@mui/material/Typography';
import SignInSignOutButton from "./SignInSignOutButton";
import { MyAppContext } from '../../context/MyAppContext';

const NavBar = () => {
    const [appContext,] = useContext(MyAppContext);
    return(
        <nav id="topNav" className="navbar navbar-expand-md navbar-expand-lg navbar-light bg-light d-print-none">
            <div className="container-fluid">
                <a className="navbar-brand" href={"/"}><img src={"/logo192.png"} alt={"THCS Nguyễn Trường Tộ"}/></a>
                <Link className="nav-link text-center text-decoration-none d-block d-sm-none ps-0" aria-current="page" to="/tkb">Thời<br/>khóa biểu</Link>
                <Link className="nav-link text-center text-decoration-none d-block d-sm-none" aria-current="page" to="/bg">Lịch<br/>báo giảng</Link>
                <Link className="nav-link text-center text-decoration-none d-block d-sm-none" aria-current="page" to="/khct">Kế hoạch<br/>công tác</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" 
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
                    aria-expanded="false" aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                         <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/">Trang nhất</a>
                        </li>  
                        <li className="nav-item">
                            <Link className="nav-link" aria-current="page" to="/tkb">Thời khóa biểu</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" aria-current="page" to="/bg">Báo giảng</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" aria-current="page" to="/khct">Kế hoạch công tác</Link>
                        </li>                        
                        {/* 
                        <li className="nav-item">
                            <Link className="nav-link" aria-current="page" to="/mypage">Công việc của tôi</Link>
                        </li>  
                        */}
                        {                          
                            (appContext.CurrentUser.Roles.includes('manager') || appContext.CurrentUser.Roles.includes('admin')) &&
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown-report" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Thống kê
                                </a>    
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown-report">
                                    <li>
                                        <Link className="dropdown-item" aria-current="page" to="/bc-bao-giang">Báo giảng - Chậm tiết</Link>
                                    </li>  
                                     {/* 
                                    <li>
                                        <Link className="dropdown-item" aria-current="page" to="/bc-bao-giang">Chưa báo giảng</Link>
                                    </li>  
                                   
                                    <li className="nav-item">
                                        <Link className="nav-link" aria-current="page" to="/report">Kiểm tra thực hiện HĐ trong tháng</Link>
                                    </li>                                                            
                                    */}
                                </ul>                        
                            </li>
                        }
                        {
                            appContext.CurrentUser.Roles.includes('admin') &&
                            <>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Quản trị
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <li>
                                        <Link className="dropdown-item" to="/nam-hoc">Năm học</Link>
                                    </li>
                                    <li>
                                        <Link className="dropdown-item" to="/lop">Lớp học</Link>
                                    </li>
                                    <li>
                                        <Link className="dropdown-item" to="/mon-hoc">Môn học</Link>
                                    </li>
                                    <li>
                                        <Link className="dropdown-item" to="/giao-vien">Giáo viên</Link>
                                    </li>
                                    {/* <li>
                                        <Link className="dropdown-item" to="/danh-muc">Cập nhật Danh mục</Link>
                                    </li>                                 */}
                                    <li><hr className="dropdown-divider"/></li>
                                    <li>
                                        <Link className="dropdown-item" aria-current="page" to="/tin-tuc">Bản tin nội bộ</Link>
                                    </li>
                                    {/* <li>
                                        <Link className="dropdown-item" aria-current="page" to="/weeklycalendar">Lịch công tác tuần</Link>
                                    </li> */}
                                    <li>
                                        <Link className="dropdown-item" aria-current="page" to="/lich-thang">Lịch công tác tháng</Link>
                                    </li>
                                    <li><hr className="dropdown-divider"/></li>
                                    {/* <li>
                                        <Link className="dropdown-item" to="/monthlyplan">Kế hoạch Công tác</Link>
                                    </li>  */}
                                    <li>
                                        <Link className="dropdown-item" to="/khdh">Phân phối chương trình</Link>
                                    </li>  
                                    <li><hr className="dropdown-divider"/></li>
                                    {/* <li>
                                        <Link className="dropdown-item" to="/notebook">Sổ ghi đầu bài</Link>
                                    </li> */}
                                    <li>
                                        {/* 
                                        <Link className="dropdown-item" to="/workoffday">Lịch nghỉ học</Link> */}
                                        <Link className="dropdown-item" to="/importSchedule">Nhập thời khóa biểu</Link>
                                    </li>
                                    <li>
                                        <Link className="dropdown-item" to="/tai-lieu">Quản lý files</Link>
                                    </li>                                   
                                </ul>
                            </li>
                            </>
                        }
                        {/* <li className="nav-item">
                            <a className="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Hệ thống</a>
                        </li> */}
                    </ul>
                    {/* <WelcomeName/> */}
                    {appContext.CurrentUser.DisplayName && <Typography variant='h6' style={{fontSize:'1rem'}}>{appContext.CurrentUser.DisplayName}</Typography>}
                    <SignInSignOutButton/>
                </div>
            </div>
        </nav>
    )
}

export default NavBar;