import SchoolYeatWeekItem from "./SchoolYearWeekItem";

const SchoolYearWeek = ({weeks}) => {
    return (
        <table className="table table-hover table-sm">
        <thead>
            <tr>
                <th style={{width:'90px'}}>Tuần</th>
                <th style={{width:'180px'}} className='text-center'>Từ ngày</th>
                <th style={{width:'180px'}} className='text-center'>Đến ngày</th>
            </tr>
        </thead>
        <tbody>
            {
                weeks.map((w,index)=> <SchoolYeatWeekItem key={index} item = {w}/> )
            }
        </tbody>
    </table>
    )
}
export default SchoolYearWeek;