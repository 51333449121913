import { useState } from "react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import vi from 'date-fns/locale/vi';


const SchoolYeatWeekItem = (props) => {
    registerLocale('vi', vi);

    const [item, setItem] = useState(props.item);

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.type==='checkbox'?target.checked:target.value;

        setItem({
            ...item,
            [name]:value
        });
    }
    return(
        <tr>
            <td style={{width:'90px'}}>
                <input type="number" name="week"  style={{'width':'100%'}} className="form-control form-control-sm"
                    value={item.week} onChange={(e)=>handleChange(e)} />
            </td>
            <td style={{width:'180px'}}>
                <DatePicker name="start" className="form-control form-control-sm"
                    locale='vi' dateFormat="dd/MM/yyyy" 
                    selected={new Date(item.start)}                       
                    onChange={(d)=>{setItem({
                        ...item,
                        start: d})}
                    }>
                </DatePicker>
            </td>
            <td style={{width:'180px'}}>
                <DatePicker name="start" className="form-control form-control-sm"
                    locale='vi' dateFormat="dd/MM/yyyy" 
                    selected={new Date(item.end)}                       
                    onChange={(d)=>{setItem({
                        ...item,
                        end: d})}
                    }>
                </DatePicker>
            </td>
        </tr>
    )
}

export default SchoolYeatWeekItem;