import React from "react";
import { BrowserView, MobileView, isBrowser, isMobile } from "react-device-detect";
import WeeklyCalendarView from "../calendar/WeeklyCalendarView";
import WeeklyCalendarView_Mb from "../calendar/WeeklyCalendarView_Mb";
import NewsboardViewer from "../newsboard/NewsboardViewer";

function DashboardPage(){
    return(
        <div className="row pt-2 px-1">
            <div className="col-md-9" style={{"padding":"10px 20px 10px 25px"}}>
                <BrowserView>
                    <WeeklyCalendarView />
                </BrowserView>
                <MobileView>
                    <WeeklyCalendarView_Mb />
                </MobileView>
            </div>
            <div className="col-md-3" style={{"padding":"10px 20px 10px 20px"}}>
                <NewsboardViewer />                
            </div>
        </div>
    )
}

export default DashboardPage