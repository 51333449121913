const SubjectPlanImportList = ({subjectPlanInfoItem, ppctItems}) => {
    return(
        <>
            <div className="row bg-info py-2 mx-0 my-1">
                <div className="col-md-6">
                    {
                        subjectPlanInfoItem && <>
                            <span className="me-3">PPCT Môn: <strong>{subjectPlanInfoItem.subjectTitle}</strong></span>                         
                            <span className="me-3">Khối lớp: <strong>{subjectPlanInfoItem.grade}</strong></span>
                            <span className="me-3">Năm học: <strong>{subjectPlanInfoItem.startYear}-{subjectPlanInfoItem.endYear}</strong></span>                                
                        </>
                    }
                </div>
                <div className="col-md-6 text-end">
                    {
                        subjectPlanInfoItem && <>
                            <span className="me-3">Học kỳ 1: <strong>{subjectPlanInfoItem.semester1_weeks}</strong> tuần ({subjectPlanInfoItem.semester1_periodperweek} tiết/tuần)</span>
                            <span className="me-1">Học kỳ 2: <strong>{subjectPlanInfoItem.semester2_weeks}</strong> tuần ({subjectPlanInfoItem.semester2_periodperweek} tiết/tuần)</span>
                        </>
                    }
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    {
                        ppctItems.length>0 && 
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th>Tuần</th>
                                    <th>Tiết</th>
                                    <th>Bài giảng</th>
                                    {/* <th>N/d cần đạt</th>
                                    <th>N/d chỉnh</th>
                                    <th>Hướng dẫn/TB</th> */}
                                    <th>Phân môn</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ppctItems.map((item,index) => {
                                        return <tr key={index}>
                                            <td>{item.Week}</td>
                                            <td>{item.Period}</td>
                                            <td>{item.Title}</td>
                                            {/* <td>{item.lecturePurpose}</td>
                                            <td>{item.lectureAdjust}</td>
                                            <td>{item.lectureGuidance}</td> */}
                                            <td>{item.Category}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    }
                </div>
            </div>          
        </>
    )
}

export default SubjectPlanImportList;