import React from "react";
import * as dayjs from 'dayjs';


function WeeklyCalendarItem(props){ 
    //const [item, setItem] = useState(props.item);
    const item = props.item;
    const todayBgColor = dayjs().isSame(dayjs(item.CalendarDate),'day') ? '#dfdfdf' : '#fff';   
    return(
        <tr style={{backgroundColor: todayBgColor}} className="align-top">
            <td className="text-center align-middle">
                <b>{dayjs(item.CalendarDate).locale('vi').format('dddd')}</b>  &nbsp; {dayjs(item.CalendarDate).format('DD/MM/YYYY')}
            </td>
            <td>
                <ul style={{"paddingLeft":"20px", "marginBottom":"1px"}}>
                    {item.MorningItems.map((item) => 
                        <li key={item.id+"cvs"} style={{"paddingBottom":"2px"}}>
                            {item.StartTime}- <b>{item.Title}</b>
                            <br/><i>Địa điểm: {item.Location}</i>
                        </li>
                    )}
                </ul>
            </td>
            <td>
                <ul style={{"listStyle":"none","paddingLeft":"1px","marginBottom":"0"}}>
                    {item.MorningItems.map((item) => 
                        <li key={item.id+"ths"} style={{"paddingBottom":"2px"}}>
                            {item.Responder ? item.Responder : " "}<br/>&nbsp;
                        </li>
                    )}
                </ul>
            </td>            
            <td>
                <ul style={{"paddingLeft":"20px","marginBottom":"1px"}}>
                    {item.AfternoolItems.map((item) => 
                        <li key={item.id+"cvc"} style={{"paddingBottom":"2px"}}>
                            {item.StartTime}- <b>{item.Title}</b>
                            <br/><i>Địa điểm: {item.Location}</i>
                        </li>
                    )}
                </ul>
            </td>
            <td>
                <ul style={{"listStyle":"none","paddingLeft":"1px","marginBottom":"0"}}>
                    {item.AfternoolItems.map((item) => 
                        <li key={item.id+"thc"} style={{"paddingBottom":"2px"}}>
                            {item.Responder ? item.Responder : " "}<br/>&nbsp;
                        </li>
                    )}
                </ul>
            </td>            
        </tr>
    )
}
export default WeeklyCalendarItem;