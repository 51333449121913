import { useState } from 'react';

function UploadFileDlg({documenttypeItems, handleUploadFile}) {
    const [selectedFiles, setSelectedFiles] = useState(null);
    const [fieldValues, setFieldValues] = useState(null);

    const handleReadFile = ($event) => {
        const files = $event.target.files;  
        let fileNames = "";
        if(files && files.length>0){
            for(let i=0; i<files.length; i++){
                let fileName = files[i].name;
                fileNames = `${fileNames}${fileName.substring(fileName.lastIndexOf('\\')+1)}; `;                  
            };
        }      
        document.getElementById('lblFile').innerHTML = fileNames;

        if(files.length) {
            setSelectedFiles(files);
        } else {
            setSelectedFiles(null);
        }
    }
    
    const uploadFileFromControl = async ()=> {
        if(selectedFiles && fieldValues && fieldValues.DocTypeId!=="" && fieldValues.SharingScope!==""){
            handleUploadFile(selectedFiles, fieldValues);
            setSelectedFiles(null);
            alert('Đã gửi file...');             
        } else { 
            alert('Chưa chọn tệp tài liệu hoặc chưa nhập đủ thông tin cho các mục Loại văn tài liệu, Phạm vi báo cáo/chia sẻ!');       
        }
    }

    const handleInputChange = (e) => {
        const target = e.target;
        let name = target.name;
        let value = (target.type==='checkbox' || target.type==="radio") ? target.checked : target.value;

        setFieldValues({
            ...fieldValues,
            [name]: value,
        });
    }    

    return (
        <div className="modal fade" id="dlgFileManUpload" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" 
             aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Tải tệp tài liệu lên</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>                        
                    </div>  
                    <div className="modal-body">
                        <div className='pt-3'>
                            <div className="col-md-12">
                                <div className="input-group pb-3">
                                    <div className="custom-file">
                                        <label className="custom-file-label" htmlFor="inputGroupFile" id="lblFile">Bấm để chọn tệp tài liệu tải lên</label>
                                        <input type="file" multiple={true} className="custom-file-input" name="file" id="inputGroupFile" required onChange={handleReadFile}
                                            accept=".csv,.doc,.docx,.pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                    </div>    
                                </div>   
                                <div className='mb-3'>
                                    <label htmlFor='DocTypeId' className='form-label'>Loại tài liệu</label>
                                    <select id="DocTypeId" name="DocTypeId" className='form-select form-select-sm'
                                        onChange={(e)=>handleInputChange(e)}
                                        value = {fieldValues?fieldValues.DocTypeId:""}
                                    >
                                        <option value=""></option>
                                        {
                                            documenttypeItems.map((item,index) => 
                                                <option key={index} value={item.TypeId}>{item.Name}</option>
                                            )
                                        }
                                    </select>
                                </div> 
                                <div className='mb-3'>
                                    <label className='form-label'>Phạm vi báo cáo/chia sẻ</label><br/>                                   
                                    <select id="SharingScope" name="SharingScope" className='form-select form-select-sm'
                                        value={fieldValues?.SharingOption}
                                        onChange={e=>handleInputChange(e)}
                                    >
                                        <option value=""></option>
                                        <option value="cn">Cá nhân</option>
                                        <option value="to">Tổ</option>
                                        <option value="bgh">Ban giám hiệu</option>
                                    </select>
                                </div>
                                <div className='mb3'>
                                    <label htmlFor='Comment' className='col-form-label'>Ghi chú</label>
                                    <textarea rows='3' name='Comment' className='form-control form-control-sm'
                                        value = {fieldValues?.Comment}
                                        onChange={(e)=>handleInputChange(e)}
                                    />
                                </div>                         
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button className='btn btn-primary btn-sm' onClick={() => uploadFileFromControl()}
                            disabled = {selectedFiles ? "" : "disabled"}
                        >Upload</button>
                        <button 
                            type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"
                            // disabled = {currentImportBatchNumber>=numOfBatchImporting ? "" : "disabled"}                         
                        >Đóng lại</button>
                    </div>
                </div>
            </div>
        </div>   
    ); 
}

export default UploadFileDlg;