import React from "react";
import SchoolYearItem from './SchoolYearItem';

function SchoolYearList({data, onAddItemClick, onSaveItemClick, onDeleteItemClick}){
    return(        
        <form className="form-inline" name="frmSchoolYearList">       
            <table className="table table-hover">
                <thead>
                    <tr>
                        {/* <th style={{width:'200px',"whiteSpace":"nowrap"}} className='text-center'>Năm học</th> */}
                        <th style={{width:'150px',"whiteSpace":"nowrap"}} className='text-center'>Năm BĐ</th>
                        <th style={{width:'150px',"whiteSpace":"nowrap"}} className='text-center'>Năm KT</th>
                        <th style={{width:'200px',"whiteSpace":"nowrap"}} className="text-center">Ngày BĐ HK1</th>
                        <th style={{width:'100px',"whiteSpace":"nowrap"}} className="text-center">Số tuần HK1</th>
                        <th style={{width:'200px',"whiteSpace":"nowrap"}} className="text-center">Ngày BĐ HK2</th>
                        <th style={{width:'100px',"whiteSpace":"nowrap"}} className="text-center">Số tuần HK2</th>
                        <th style={{width:'25%'}}>Các tuần học kỳ 1</th>
                        <th style={{width:'25%'}}>Các tuần học kỳ 2</th>
                        <th style={{"whiteSpace":"nowrap"}} className='text-right text-end'>
                            <button type="button" 
                                onClick={onAddItemClick} className="btn btn-primary btn-sm">Thêm
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    { 
                        data.map((item, index) => 
                            <SchoolYearItem 
                                key={item.id}
                                item = {item} 
                                action = {item.Title === ''? 'new' : ''} 
                                onSaveItemClick = {onSaveItemClick}
                                onDeleteItemClick={onDeleteItemClick}                                
                            />
                        )
                    }
                </tbody>
            </table>
        </form>
    )
}

export default SchoolYearList;