import  {useEffect, useState} from 'react';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { remove, orderBy as _orderBy} from 'lodash';
import dayjs from 'dayjs';
import * as isSameOrBefore  from 'dayjs/plugin/isSameOrBefore';

import { v4 as uuidv4 } from 'uuid';

import { loginRequest, sharePointConfig } from '../../authConfig';
import {
    getSharePointListItems,
    updateSharePointItem,
    addSharePointListItem,
    deleteSharePointItem
} from '../../graph';

import { WeeklyCalendars } from "../../mock/MockData";

export const createBlankWeeklyCalendarItem = () =>{
    return [
        {
            Id: 0,
            Title:'',
            StartDate: dayjs().toISOString(),
            EndDate: dayjs().add(2,'hour').toISOString(),
            Detail:'',
            Location:'',
            Responder:'',
            Review: '',
            AmComment: '',
            PmComment: '',
            Note: '',
            Priority:2,
            Category: '',            
            IsFirstColumn: 0,
            IsDefault: 0,
            UID: uuidv4()   
        }
    ]
}

export const useWeeklyCalendarDataApiMock = () => {
    const [weeklyCalendarItems, setWeeklyCalendarItems] = useState(WeeklyCalendars);
    const [action, setAction] = useState({type:'', value:''});

    useEffect(() => {     
        const fetchData = async () => {  
            setWeeklyCalendarItems(WeeklyCalendars);
        };

        const addBlankItem = async (item) => {
            setWeeklyCalendarItems(item.concat(WeeklyCalendars));
        };

        const submitData = async (item) => {
            console.log('Submit data');
        };

        const deleteItem = async (item) => {
            let items = Array.from(WeeklyCalendars);
            remove(items, i=>i.TeacherId===item.TeacherId);        
            setWeeklyCalendarItems(items); 
        }

        const processData = ()=> {
            switch(action.type){
                case 'add':
                    addBlankItem(action.value);
                    break;
                case 'save':
                    submitData(action.value);
                    break;
                case 'del':
                    deleteItem(action.value);
                    break;
                default:
                    fetchData();
            }
        }

        processData();
        
    },[action]);

    return [{weeklyCalendarItems},  setAction];
}

export const reOrderCaledarItems = (items, currentStartDate) =>{
    items = _orderBy(items,['StartDate'],['asc']);

    //Tính số items của ngày đang được thêm
    const numOfItems = items.filter(i => dayjs(i.StartDate).isSame(dayjs(currentStartDate),'day')).length;

    //Duyệt và sắp xếp lại các mục trong danh sách các items: gán lại UID để react cập nhận hiển thị trên giao diện
    let firstTime = 1;
    for(let i=0; i<items.length;i++){
        const currentItem  = items[i];
        //Mục mới của ngày đang thêm
        if(dayjs(currentItem.StartDate).isSame(dayjs(currentStartDate),'day')){
            items[i] = {
                ...currentItem,
                IsFirstColumn: firstTime,   //dấu hiệu nhận biết để rowspan cho column đầu tiên trong phần hiển thị, mục đầu tiên thôi
                NumOfItems: numOfItems,     //Xác định giá trị của rowspan
                UID: uuidv4()               //Gán lại UID
            };
            firstTime=0;
        }
        else{ // mục không phải của ngày đang thêm thì chỉ thay đổi UID
            items[i]={
                ...currentItem,
                UID: uuidv4()
            }
        }
    }
    return items;
}

export const useWeeklyCalendarDataApi = (initialAction) => {
    dayjs.extend(isSameOrBefore)

    const [weeklyCalendarItems, setWeeklyCalendarItems] = useState([]);
    const [monthlyCalendarItems, setMonthlyCalendarItems] = useState([]);

    const [action, setAction] = useState(initialAction);

    const {instance, accounts} = useMsal();

    const fetchData = (accessToken, startDate, endDate, viewMode) =>{
        getSharePointListItems(
            accessToken, 
            sharePointConfig.WeeklyCalendarListId,
            "id,Title,StartDate,EndDate,Detail,Responder,Location,Priority,Category", 
            `((fields/StartDate ge '${startDate}') and (fields/EndDate le '${endDate}'))`,
            `fields/StartDate asc`
        ).then(response => {  
            let items = [];
            const listItemArray = response.value;
            for(let i=0; i<listItemArray.length; i++){                
                items.push(listItemArray[i].fields);
            }

            let calendarItems = [];
            //Duyệt từng ngày trong tuần/khoảng thời gian cần lấy lịch
            for(let n=0; n<7; n++){ //Từ thứ 2 đến CN
                const d = dayjs(startDate).add(n,'day');
                //Lọc lấy các mục lịch của ngày đang xét
                const filteredItems = items.filter(item => dayjs(d).isSame(dayjs(item.StartDate),'day'));                  
                if(filteredItems.length===0){ //mặc định một item trắng cho ngày
                    if(viewMode!==undefined && viewMode==='edit'){ //lấy dữ liệu để chỉnh sửa/thêm
                        let blankItem = createBlankWeeklyCalendarItem()[0];
                        calendarItems.push({
                            ...blankItem,
                            StartDate: d.toISOString(),
                            EndDate: d.add(2,'hour').toISOString(),    
                            Priority:'',      
                            IsFirstColumn: 1,
                            IsDefault: 1,
                            NumOfItems:1,
                            UID: uuidv4()                                             
                        });
                    }
                    else{  //Lấy dữ liệu lịch cho chế độ xem 
                        calendarItems.push ({
                            id: n,
                            CalendarDate: d.toISOString(),
                            Responder:'',
                            MorningItems: [],
                            AfternoolItems: [],
                            UID: uuidv4()   
                        })
                    }

                }else { //Các mục lịch của một ngày đang xét
                    const numOfItems= filteredItems.length+1;
                    //Tạo mục lịch ứng với ngày đang xét
                    if(viewMode!==undefined && viewMode==='edit'){  //Lấy dữ liệu chế cho chế độ edit
                        for(let i=0; i<filteredItems.length; i++){
                            const item = filteredItems[i];
                            calendarItems.push({
                                ...item,
                                IsFirstColumn:  i===0?1:0, 
                                NumOfItems: numOfItems,
                                IsDefault: 0,
                                UID: uuidv4()   
                            })
                        }
                        //Đẩy một mục trắng vào cuối để chỗ đặt nút Thêm
                        let blankItem = createBlankWeeklyCalendarItem()[0];
                        calendarItems.push({
                            ...blankItem,
                            StartDate: d.hour(23).toISOString(),
                            EndDate: d.hour(23).toISOString(),    
                            Priority:'',      
                            IsFirstColumn: 0,
                            IsDefault: 1,
                            NumOfItems: numOfItems,
                            UID: uuidv4()                                             
                        });
                    }
                    else{ //Lấy dữ liệu cho chế độ xem
                        let newItem = {
                            CalendarDate: d.toISOString(),
                            Responder:filteredItems[0].Responder,
                            MorningItems:[],
                            AfternoolItems:[],   
                            UID: uuidv4()                                          
                        };
                        
                        let morningItems=[];
                        let afternoonItems=[];
                        for(let i=0; i<filteredItems.length; i++){
                            const item = filteredItems[i];
                            if(dayjs(item.StartDate).hour()<=12 || dayjs(item.EndDate).hour()<=12){
                                morningItems.push({
                                    ...item,
                                    StartTime: dayjs(item.StartDate).format('HH:mm'),
                                    EndTime: dayjs(item.EndDate).format('HH:mm'),
                                    UID: uuidv4()   
                                });
                            }
                            else {
                                afternoonItems.push({
                                    ...item,
                                    StartTime: dayjs(item.StartDate).format('HH:mm'),
                                    EndTime: dayjs(item.EndDate).format('HH:mm'),
                                    UID: uuidv4()   
                                });
                            }                       
                        }

                        calendarItems.push({
                            ...newItem, 
                            MorningItems: morningItems, 
                            AfternoolItems: afternoonItems
                        });
                    }


                }
            }
            setWeeklyCalendarItems(calendarItems);
        });
    }

    /**
     * fetchMonthlyData - Lấy dữ liệu lịch một tháng
     * @param {*} accessToken 
     * @param {*} startDate - Ngày đầu tháng, hoặc là ngày cuối tháng của tháng ngay trước
     * @param {*} endDate - Ngày cuối tháng hoặc ngày đầu của tháng kế tiếp
     */
    const fetchMonthlyData = (accessToken, startDate, endDate) => {    
        getSharePointListItems(
            accessToken, 
            sharePointConfig.WeeklyCalendarListId,
            "id,Title,StartDate,EndDate,Detail,Responder,Location,Priority,Category", 
            `((fields/StartDate ge '${startDate}') and (fields/EndDate le '${endDate}'))`,
            `fields/StartDate asc`
        ).then(response => {  
            let items = [];
            const listItemArray = response.value;
            for(let i=0; i<listItemArray.length; i++){                
                items.push(listItemArray[i].fields);
            }
            let calendarItems = [];

            //Duyệt từng ngày trong khoảng thời gian được yêu cầu
            let d=dayjs(startDate);
            const eDate = dayjs(endDate);
            while (d.isSameOrBefore(eDate)) {             
                //Lọc lấy các mục lịch của ngày đang xét
                const currentDate = d;
                const filteredItems = items.filter(item => currentDate.isSame(dayjs(item.StartDate),'d'));                  
                if(filteredItems.length===0){ //mặc định một item trắng cho ngày
                    let blankItem = createBlankWeeklyCalendarItem()[0];
                    calendarItems.push({
                        ...blankItem,
                        StartDate: d.toISOString(),
                        EndDate: d.add(2,'hour').toISOString(),    
                        Priority:'',      
                        IsFirstColumn: 1,
                        IsDefault: 1,
                        NumOfItems:1,
                        UID: uuidv4()                                             
                    });
                }else { //Các mục lịch của một ngày đang xét
                    const numOfItems= filteredItems.length+1;
                    for(let i=0; i<filteredItems.length; i++){
                        const item = filteredItems[i];
                        calendarItems.push({
                            ...item,
                            IsFirstColumn:  i===0?1:0, 
                            NumOfItems: numOfItems,
                            IsDefault: 0,
                            UID: uuidv4()   
                        })
                    }
                    //Đẩy một mục trắng vào cuối để chỗ đặt nút Thêm
                    let blankItem = createBlankWeeklyCalendarItem()[0];
                    calendarItems.push({
                        ...blankItem,
                        StartDate: d.hour(23).toISOString(),
                        EndDate: d.hour(23).toISOString(),    
                        Priority:'',      
                        IsFirstColumn: 0,
                        IsDefault: 1,
                        NumOfItems: numOfItems,
                        UID: uuidv4()                                             
                    });
                }
                d = d.add(1,"d");
            }
            setMonthlyCalendarItems(calendarItems);
        })
    }

    const addBlankItem = (addedItemArray) => {
        //Concat blank item vào các items hiện có rồi sắp xếp theo thứ tự thời gian mới nhất tăng dần
        let items = addedItemArray.concat(weeklyCalendarItems);
        items = reOrderCaledarItems(items, addedItemArray[0].StartDate);
        setWeeklyCalendarItems(items);
    };

    const saveItem = (accessToken, item) => {
        let fieldValues = {};
        if(item.id*1>0){ //Nếu đã có thì cập nhật nội dung của mục trong tuần đã có
            console.log('Updating weekly calendar...');       
            fieldValues = {
                fields: {
                    Title: item.Title,
                    StartDate: new Date(item.StartDate).toISOString(),
                    EndDate: new Date(item.EndDate).toISOString(),
                    Detail: item.Detail,
                    Location: item.Location,
                    Responder: item.Responder,
                    // Review: item.Review,
                    // AmComment: item.AmComment,
                    // PmComment: item.PmComment,
                    // Note: item.Note,
                    Priority: item.Priority,
                    Category: item.Category                    
                }
            };  
            updateSharePointItem(accessToken, sharePointConfig.WeeklyCalendarListId, item.id, fieldValues)
                .then(response => {
                    console.log(response);
                }
            );
        }
        else{ //Nếu chưa có thì thêm vào theo tuần. 
            console.log('Adding Weekly calendar....');
            fieldValues = {
                fields: {
                    Title: item.Title,
                    StartDate: new Date(item.StartDate).toISOString(),
                    EndDate: new Date(item.EndDate).toISOString(),
                    Detail: item.Detail,
                    Location: item.Location,
                    Responder: item.Responder,
                    // Review: item.Review,
                    // AmComment: item.AmComment,
                    // PmComment: item.PmComment,
                    // Note: item.Note,
                    Priority: item.Priority,
                    Category: item.Category                    
                }
            };
            addSharePointListItem(accessToken, sharePointConfig.WeeklyCalendarListId, fieldValues)
                .then(response=>{
                    console.log(response)
                });
        }
    };

    const deleteItem = (accessToken, item) => {
        let items = Array.from(weeklyCalendarItems);
        remove(items, i=>i.UID===item.UID);

        //Xóa phía server nếu item này đã tồn tại trên server  
        if(item.id*1>0){
            deleteSharePointItem(accessToken,sharePointConfig.WeeklyCalendarListId,item.id).then(response =>{
                console.log('Completed delete item ' + item.id);                
                console.log(response);
              
            });
        }

        items = reOrderCaledarItems(items, item.StartDate);
        setWeeklyCalendarItems(items);
    }

    useEffect(() => {     
        const accessTokenRequest = {
            ...loginRequest,
            account: accounts[0]
        }
        
        const processData = async () => { 
            instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
                const accessToken = accessTokenResponse.accessToken;
                switch(action.type.toLowerCase()){
                    case 'add':
                        addBlankItem(action.value);
                        break;
                    case 'save':
                        saveItem(accessToken, action.value);
                        break;
                    case 'del':
                        deleteItem(accessToken, action.value);
                        break;
                    case 'fetchmonthlycalendar':
                        fetchMonthlyData(accessToken, action.value.StartDate, action.value.EndDate);
                        break;
                    default:
                        fetchData(accessToken, action.value.StartDate, action.value.EndDate, action.value.ViewMode);
                }
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest);                    
                }
                console.log(error);
            }) 
        }; 

        processData();       
    },[action]);

    return [{weeklyCalendarItems, monthlyCalendarItems},  setAction];
}