import React, {useState} from 'react';
import {Tabs, Tab} from 'react-bootstrap';
import DOMPutify from 'dompurify';


const ScheduleItemDialog = (props) => {
    const [scheduleCell, setItem] = useState(props.scheduleCell);
    const {subjects, teachers, scheduleRowId, classId, partDay, dialogId} = props;
    const [teacherOptions, setTeacherOptions] = useState(props.teachers.filter(item=>item.SubjectId.includes(scheduleCell.subject_id)));

    //const [beforeEditItem, setBeforeEditItem] = useState({...props.item});

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.type==='checkbox'?target.checked:target.value;
        
        //Lọc để chỉ hiện giáo viên dạy môn được chọn
        if (name==="subject_id") {
            setTeacherOptions(teachers.filter(item=>item.SubjectId.includes(value)));
        }
       setItem({
            ...scheduleCell,
            [name]: value,
            teacher_id: name=== "subject_id" //trường hợp không chọn môn học thì xóa giá trị của mục giáo viên dạy học
                ? (value==="" ? "" : scheduleCell.teacher_id) 
                : (name==="teacher_id" ? value: scheduleCell.teacher_id)          
        });
       
    }

    const createMarkup = (html) => {
        return {
            __html: DOMPutify.sanitize(html)
        }
    }

    const dgElement = props.action==='edit' || props.action==='new'
    ? 
    <div className="modal fade" id={dialogId} 
         data-bs-backdrop="static" data-bs-keyboard="false" 
         tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{props.title}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>                        
                </div>
                <div className="modal-body text-start pt-1">
                    <Tabs defaultActiveKey="tab-TKB" className="mb-1 p-0">
                        <Tab eventKey="tab-TKB" title="Thời khóa biểu">
                            <div className='ps-3'>
                                <div className='mb3'>
                                    <label htmlFor='subject_id' className='col-form-label'>Môn học&nbsp;</label>
                                    <select name='subject_id' 
                                        className='select-control select-control-sm' autoFocus
                                        value = {scheduleCell.subject_id}
                                        onChange={(e)=>handleChange(e)}
                                    >
                                        <option value=''></option>
                                        {
                                            subjects.map((subjectItem, idx) => 
                                                <option key={idx} value={subjectItem.SubjectId}>{subjectItem.Title}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                <div className='mb3'>
                                    <label htmlFor='teacher_id' className='col-form-label'>Giáo viên&nbsp;</label>
                                    <select name='teacher_id' className='select-control select-control-sm' 
                                        value = {scheduleCell.teacher_id}
                                        onChange={(e)=>handleChange(e)}
                                    >
                                        <option value=''></option>
                                        {
                                            teacherOptions.map((teacherItem, idx) => 
                                                <option key={idx} value={teacherItem.TeacherId}>{teacherItem.FirstName}, {teacherItem.LastName}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                <div className='mb3'>
                                    <label htmlFor='comment' className='col-form-label'>Ghi chú</label>
                                    <textarea rows='3' name='comment' className='form-control form-control-sm'
                                        value = {scheduleCell.comment}
                                        onChange={(e)=>handleChange(e)}
                                    />
                                </div>   
                            </div>    
                        </Tab>
                        <Tab eventKey="tab-DuGio" title="Dự giờ">
                            <div className='ps-3'>
                                <p>Thông tin dự giờ</p>
                                <p>- Nội dung</p>
                                <p>- Thành phần tham dự</p>
                            </div>
                        </Tab>
                        <Tab eventKey="tab-other" title="Hành động khác">
                            <div className="ps-3">
                                Các hành động như: Nghỉ tiết, khóa cập nhật ở sổ báo giảng....
                            </div>
                        </Tab>
                    </Tabs>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary btn-sm" data-bs-dismiss="modal"
                        onClick={()=> props.onSaveCellClick(scheduleCell, scheduleRowId, classId, partDay)}
                    >&nbsp;&nbsp;Chọn&nbsp;&nbsp;</button>
                    
                    <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"
                       onClick={props.handleCancel}
                    >&nbsp;Đóng&nbsp;</button>
                </div>
            </div>
        </div>
    </div>
    :
    <div className="modal fade" id={"dlg" + scheduleCell.id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Thời khóa biểu tiết {scheduleCell.period}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>                        
                </div>
                <div className="modal-body">
                    <Tabs defaultActiveKey="tab-1" className="mb-1 p-0">
                        <Tab eventKey="tab-1" title="Thời khóa biểu">
                            <p>{scheduleCell.subject_id}</p>
                            <div 
                                className='preview' 
                                style={{"margin":"5px 0px 20px 0px"}}
                                dangerouslySetInnerHTML={createMarkup(scheduleCell.subject_id)}
                            >                        
                            </div>  
                            <div>
                                <p>Giáo viên:{scheduleCell.teacher_id}</p>
                            </div>  
                        </Tab>
                        <Tab eventKey="tab-2" title="Dự giờ">
                            Nội dung cập nhật dự giờ... Thành phần tham dự...
                        </Tab>                        
                    </Tabs>                          
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Đóng lại</button>
                </div>
            </div>
        </div>
    </div>

    return(
        <>
            { dgElement }
        </>
    )
}

export default ScheduleItemDialog;