import React, { useState, useContext, useEffect } from "react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import vi from 'date-fns/locale/vi';
import * as dayjs from 'dayjs';

import {write, utils} from 'xlsx';
import * as FileSaver from 'file-saver';

import { MyAppContext } from '../../context/MyAppContext';
import { useCategoryDataApi } from "../category/CategoryData";
import WorkPlanList from "./WorkPlanList";
import { creatBlankWorkPlanItem, useWorkPlanDataApi } from "./WorkPlanData";
import { getMonthInfo } from '../../globalFuntions';

const WorkPlanPage = () => {
    registerLocale('vi', vi);

    const [appContext,] =  useContext(MyAppContext);
    const [startDate, setStartDate] = useState(new Date());    
    const [selectedDepartmentId, setSelectedDepartmentId] = useState(appContext.CurrentUser.DepartmentId);
    const[{departmentItems}] = useCategoryDataApi(['department']);

    const monthInfo = getMonthInfo(startDate);
    const [{workPlanItems}, setAction] = useWorkPlanDataApi({
        type:'fetchdata',
        value:{
            departmentId:appContext.CurrentUser.DepartmentId,
            firstDayOfMonth: monthInfo.FirstDayOfMonth.toISOString(), // (new Date(currentDate.getFullYear(), currentDate.getMonth(),1)).toISOString(),
            lastDayOfMonth:  monthInfo.LastDayOfMonth.toISOString()   // (new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)).toISOString(), 
        }
    });

    const handleDepartmentSelectionChange = (departmentId) => {
        setSelectedDepartmentId(departmentId);
        const monthInfo = getMonthInfo(startDate);
        setAction({
            type:'fetchdata',
            value: {
                departmentId: departmentId,
                firstDayOfMonth: monthInfo.FirstDayOfMonth.toISOString(),
                lastDayOfMonth: monthInfo.LastDayOfMonth.toISOString()
            }
        })
    }

    const handleCreateWorkPlan = () => {
        alert('Comming soon');
    }

    const handleAdd = ()=>{
        let blankItem = creatBlankWorkPlanItem();
        blankItem.DepartmentId = selectedDepartmentId;

        setAction({
            type:'add',
            value: blankItem
        });
    }

    const handleSave = (item)=>{
        if(item.AssignedTo.includes('All')){ //Nếu chứa All thì loại bỏ tất cả, chỉ giữ lại All
            item.AssignedTo=["All"];
        }
        setAction({
            type:'save',
            value:{...item, DepartmentId: selectedDepartmentId}
        });
    }

    const handleDelete = (item)=>{
        setAction({
            type: 'del',
            value: item
        });
    }

    const getTeacherNames = (selectedTeacherIdArray) => {
        let teacherNames ="";
        const teacherList =  appContext.Teachers;
        for (let i=0; i<selectedTeacherIdArray.length; i++) {
            const idx = teacherList.findIndex(item=>item.TeacherId === selectedTeacherIdArray[i]);
            if (idx>=0) teacherNames = teacherNames +  teacherList[idx].Title + ", ";           
        }   
        const len = teacherNames.length; 
        return len<=0? "" : teacherNames.substring(0,len-2);
    }

    const getDepartmentName = (departmentId) => {
        const departmentObj = departmentItems.find(item=>item.DepartmentId===departmentId);
        return departmentObj?.Title;
    }


    const exportWorkplan2XLSX = () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let jsonData = [];
        let count;

        count=0;
        workPlanItems.forEach(row => {
            count = count+1;
            jsonData.push({
                'STT': count,
                'Nội dung công việc': row.Title,
                'Sản phẩm/kết quả': row.Result,
                'Từ ngày': dayjs(row.StartDate).format('DD/MM/YYYY'),
                'Đến ngày': dayjs(row.EndDate).format('DD/MM/YYYY'),
                'Ngày HT': dayjs(row.CompletedDate).format('DD/MM/YYYY'),
                'Thực hiện': selectedDepartmentId==='All'? getDepartmentName(row.DepartmentId)  : row.AssignedTo.includes("All")?"Cả tổ" : getTeacherNames(row.AssignedTo),
                'Phụ trách': getTeacherNames(row.Leader),
                'Ghi chú': row.Note,
            });      
        });


        const ws = utils.json_to_sheet(jsonData);
        const wb = {Sheets: {'data':ws}, SheetNames:['data']};
        const excelBuffer = write(wb, {bookType:'xlsx', type: 'array'});
        const data = new Blob([excelBuffer],{type:fileType});
        FileSaver.saveAs(data, selectedDepartmentId==='All'?`KHCT-TOAN-TRUONG-T${dayjs(startDate.toISOString()).format('MM.YYYY')}.xlsx`:`KHCT-${getDepartmentName(selectedDepartmentId).replace(/ /g,'-')}-T${dayjs(startDate.toISOString()).format('MM.YYYY')}.xlsx`);

    };  

    useEffect(()=>{
        if(startDate){
            const monthInfo = getMonthInfo(startDate);
            setAction({
                type:'fetchdata',
                value: {
                    departmentId: selectedDepartmentId,
                    firstDayOfMonth: monthInfo.FirstDayOfMonth.toISOString(),
                    lastDayOfMonth: monthInfo.LastDayOfMonth.toISOString()
                }
            })           
        }
    },[startDate]);

    return (
        <div id="workplan-container" className="ps-3">
            <div className="row pt-3">
                <h4>{selectedDepartmentId==='All'?'KẾ HOẠCH CÔNG TÁC TOÀN TRƯỜNG':'KẾ HOẠCH CÔNG TÁC CỦA TỔ'}</h4>
            </div>
            <div className="row pt-1">
                <div className="col-md-12">
                    <div className="pe-3" style={{"display":"inline-block"}}>
                        <span className="pe-1">Đơn vị:</span>
                        {   
                            departmentItems && <select className="form-select form-select-sm"
                                value={selectedDepartmentId}
                                onChange = {(e) => handleDepartmentSelectionChange (e.target.value)}                            
                            >                   
                                <option value=""></option>         
                                {
                                    departmentItems.map((item,index)=>{
                                        return <option key={index} value={item.DepartmentId}>{item.Title}</option>
                                    })
                                }
                                <option value="All">Toàn trường</option>   
                            </select>
                        }
                    </div>
                    <div className="pe-3" style={{"display":"inline-block"}}>
                        <span className="pe-1">Tháng: </span>
                        <DatePicker className="form-select form-select-sm"
                            selected={startDate}
                            locale='vi' dateFormat="MM/yyyy" 
                            showMonthYearPicker           
                            onChange={(date)=>setStartDate(date)}            
                        ></DatePicker>                        
                    </div>
                    <div className="pe-3" style={{"display":"inline-block"}}>
                        <button className="btn btn-info btn-sm" 
                            disabled={workPlanItems.length>0?"":"disabled"}
                            onClick={e=>{exportWorkplan2XLSX();}}
                        >Xuất ra file</button>
                    </div>
                </div>
            </div>
            <div className="row pt-1">
                <WorkPlanList 
                    data={workPlanItems} 
                    teacherList= {selectedDepartmentId==='All'? appContext.Teachers : appContext.Teachers.filter(t=>t.DepartmentId===selectedDepartmentId)}
                    selectedDepartmentId={selectedDepartmentId}
                    departmentItems = {departmentItems}
                    allowChangeWorkPlan = {(appContext.CurrentUser.Roles.includes('teamlead') || appContext.CurrentUser.Roles.includes('viceteamlead')) && appContext.CurrentUser.DepartmentId===selectedDepartmentId}
                    onAddItemClick={handleAdd}
                    onSaveItemClick={handleSave}
                    onDeleteItemClick={handleDelete}
                    onCreatWorkPlanClick = {handleCreateWorkPlan}
                />
            </div>
        </div>
    )
}

export default WorkPlanPage;