
const ClassSelection = (props) => {
    return(
        <div>
            { props.children }
            <select name="selectedClass" className="form-select form-select-sm" 
                value={props.class_id}
                onChange={(e) => props.onClassChange(e.target.value)}
            >
                <option value=''></option>
                {
                    props.classList && props.classList.map((c)=>
                        <option key={c.ClassId} value={c.ClassId}>{c.Title}</option>
                    )
                }
            </select>
        </div>
    )
}

export default ClassSelection;