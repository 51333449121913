import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import  IconButton  from "@mui/material/IconButton";
import  AccountCircle  from "@mui/icons-material/AccountCircle";
import  MenuItem  from "@mui/material/MenuItem";
import  Menu  from "@mui/material/Menu";
import { AccountPicker } from './AccountPicker'

export const SignOutButton = () => {
    const { instance } = useMsal();
    const [accountSelectorOpen, setOpen] = useState(false);

    const[anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl);

    const handleLogout = (loginType) => {
        setAnchorEl(null);

        if(loginType ==="popup") {
            instance.logoutPopup();
        }else if(loginType==='redirect'){
            instance.logoutRedirect();
        }
    }

    const handleAccountSelection = () => {
        setAnchorEl(null);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return(
        <div>
            <IconButton onClick={(event)=>setAnchorEl(event.currentTarget)} color='inherit'>
                <AccountCircle/>
            </IconButton>
            <Menu
                id="menu-appbar" 
                anchorEl={anchorEl} 
                anchorOrigin={{vertical:'top', horizontal:'right'}}
                keepMounted
                transformOrigin={{vertical:'top', horizontal:'right'}}
                open={open}
                onClose={()=>setAnchorEl(null)}
            >
                 <MenuItem onClick={() => handleAccountSelection("popup")} key="switchAccount">Chuyển sang account khác</MenuItem>
                 {/* <MenuItem onClick={() => handleLogout("popup")} key="logoutPopup">Đăng xuất dùng Popup</MenuItem> */}
                 <MenuItem onClick={() => handleLogout("redirect")} key="logoutRedirect">Đăng xuất</MenuItem>
            </Menu>
            <AccountPicker open={accountSelectorOpen} onClose={handleClose}/>
        </div>
    )
}