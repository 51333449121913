import React, { useState, useEffect } from "react";
import { remove} from 'lodash';
import { orderBy as _orderBy} from "lodash";

import SubjectPlanDetailList from "./SubjectPlanDetailList";
import {useSubjectDataApi} from '../subject/SubjectData';
import GradeSelection from "../common/GradeSelection";
import YearSchoolSelection from "../common/YearSchoolSelection";

import {useSubjectPlanDataApi, createBlankSubjectPlanItem, createBlankSubjectPlanDetailItem} from './SubjectPlanData';
import SubjectPlanList from "./SubjectPlanList";
import SubjectPlanItem from "./SubjectPlanItem";
import MessageBox from '../common/MessageBox';
import { getSchoolYear } from "../../globalFuntions";


function SubjectPlanPage () {
    const [selectedStartYear, setSelectedStartYear] = useState(getSchoolYear); // useState(new Date().getFullYear());
    const [selectedGrade, setSelectedGrade] = useState(0);
    
    const [{subjectItems}] = useSubjectDataApi();
    const [{subjectPlanApiMessage, subjectPlanItems}, setAction, ] = useSubjectPlanDataApi();

    const [subjectPlanId, setSubjectPlanId] = useState('');  //subjectPlanId đang được chọn
    const [selectedSubjectPlan, setSelectedSubjectPlan] = useState(null);   
    const [lectureItems, setLectureItems] = useState([]);  //useState(subjectPlanDetailItems);

    const [readyToAddNewSubjectPlan, setReadyToAddNewSubjectPlan] = useState(false); 
    const [updateState, setUpdateState] = useState("");
    const [updateSubjectPlanId, setUpdateSubjectPlanId] = useState("");


    const handleResetToAdd = ()=> {
        if(subjectPlanId!==''){  //Unselected if it is selected
            let oldElement = document.getElementById(subjectPlanId);
            if(oldElement!==null || oldElement!==undefined) {
                oldElement.setAttribute('style','font-weight:normal');
            }
        }         
        setSubjectPlanId('');
        setSelectedSubjectPlan(null);
        setReadyToAddNewSubjectPlan(true);
    }

    const handleGradeChange = (gradeValue) =>{
        setSelectedGrade(gradeValue*1);
        setAction({
            type:'fetch_plan_list',
            value: {
                year: selectedStartYear,
                grade: gradeValue
            }
        });
        setSubjectPlanId(''); //Không chọn mục kế hoạch giảng dạy nào sau khi gửi lệnh lấy dữ liệu
        setReadyToAddNewSubjectPlan(false);
    }

    const handleYearChange = (yearValue) => {
        setSelectedStartYear(yearValue*1);
        setAction({
            type:'fetch_plan_list',
            value: {
                year: yearValue*1,
                grade: selectedGrade*1
            }
        });
        setSubjectPlanId(''); //Không chọn mục kế hoạch giảng dạy nào sau khi gửi lệnh lấy dữ liệu
        setReadyToAddNewSubjectPlan(false);
    }

    const handleSubjectPlanSelection = (e, subjectPlanIdValue, selectedSubjectPlanObj) => {
        console.log(`handleSubjectPlanSelection called, subjectPlanIdValue = ${subjectPlanIdValue}; subjectPlanId=${subjectPlanId}`);
        if(subjectPlanId!==''){
            let oldElement = document.getElementById(subjectPlanId);
            if(oldElement!==null || oldElement!==undefined) {
                oldElement.setAttribute('style','font-weight:normal');
            }
        } 

        document.getElementById(subjectPlanIdValue).setAttribute('style','font-weight:500;color:green;');
        setSubjectPlanId(subjectPlanIdValue);   
        setSelectedSubjectPlan(selectedSubjectPlanObj);
        setLectureItems(selectedSubjectPlanObj.PlanDetail);
    }

    const handleAddPlanDetail = (numOfItems, nextWeekIndex, nextPeriodIndex) => {
        //console.log('handleAddPlanDetail', numOfItems, nextWeekIndex, nextPeriodIndex);
        let blankItems = createBlankSubjectPlanDetailItem(numOfItems);
        let nextPeriod = nextPeriodIndex - 1;

        blankItems = blankItems.map(item=>{
            nextPeriod = nextPeriod + 1;
            return {...item, 
                Week: nextWeekIndex,
                Period: nextPeriod //numOfItems===1 ?  nextWeekIndex: 0
            };
        });

        let newLectureItems = selectedSubjectPlan.PlanDetail ? blankItems.concat(selectedSubjectPlan.PlanDetail) : blankItems;
        setSelectedSubjectPlan({...selectedSubjectPlan, PlanDetail: newLectureItems});

        setLectureItems(newLectureItems);

        //console.log('new blank rows',newLectureItems);
    }

    const handleSavePlan = (item) => {
        const subjectObj=subjectItems.find(s=>s.SubjectId===item.SubjectId);
        const subjectTitle = subjectObj===null ? item.SubjectId: subjectObj.Title;
        const planTitle = `${subjectTitle} ${item.Grade} (${item.StartYear}-${item.EndYear})`; 
        //Gửi lệnh cập nhật dữ liệu lên server
        setAction({
            type:'save_plan',
            value: {
                ...item,
                Title: planTitle
            }
        });
        setUpdateSubjectPlanId(item.SubjectPlanId);
        setSubjectPlanId(item.SubjectPlanId); //Cập nhật thành subject plan đang đc chọn
        setUpdateState("newsubjectplan-saved");
    }

    const handleSaveImportedPlanDetail = (importedSubjectPlan) => {
        setAction({
            type:"save_plan",
            value: importedSubjectPlan
        });

        setUpdateSubjectPlanId(importedSubjectPlan.SubjectPlanId);
        setUpdateState("subjectplan-imported");
        alert(`Đã import xong PPCTT ${importedSubjectPlan.Title}. Đóng cửa sổ và bấm lại vào bên trái để hiển thị PPCT...`)
    }

    const handleSavePlanDetail = (editingPlanDetailItem) => {
        let subjectPlainDetailItems = selectedSubjectPlan.PlanDetail;
        const index = subjectPlainDetailItems.findIndex(item => item.LectureId === editingPlanDetailItem.LectureId);

        console.log('Mục được sửa', editingPlanDetailItem);
        console.log('Index', index);
        console.log('subjectPlainDetailItems full item gốc', subjectPlainDetailItems);

        if (index>=0) { //sửa mục PPCT chi tiết đã có
            subjectPlainDetailItems[index] = editingPlanDetailItem;
            console.log('Sửa');
        } else { //thêm mới một mục chi tiết PPCT
            subjectPlainDetailItems.push(editingPlanDetailItem);
            console.log('thêm mới')
        }
        //Gửi lệnh cập nhật dữ liệu lên server
        let updatedSubjectPlanItem = {
            ...selectedSubjectPlan,
            PlanDetail: subjectPlainDetailItems
        }
        setAction({
            type:'save_plan',
            value: updatedSubjectPlanItem
        });

        setSelectedSubjectPlan(updatedSubjectPlanItem);
    }

    //Su dung tam thoi de covert du lieu nam 2022 cua subjectplan hien được chọn
    // const handleConvertSubjectPlainDetail = ()=> {
    //     if(selectedSubjectPlan.SubjectPlanId!=="" && lecturesItems && lecturesItems.length>0) {
    //         setAction({
    //             type:"save_plan",
    //             value: {...selectedSubjectPlan, PlanDetail: lecturesItems}
    //         });
    //         alert('Finish covert for this SubjectPlan')
    //     } else {
    //         alert('Khong hop le');
    //     }
    // }


    const handleDeletePlan = (item) => {   
        //Xoa phía server     
        setAction({
            type:'del_plan',
            value: item
        }); 
        //Xóa tại client để k phải mất công nạp lại dữ liệu từ server
        // remove(subjectPlanItems, s=>s.SubjectPlanId===item.subjectPlanId);
        setSubjectPlanId('');
        setSelectedSubjectPlan(null);
        setReadyToAddNewSubjectPlan(false);
    }

    const handleDeletePlanDetail = (deletingPlanDetailItem, action = "") => {
        //Tim mục cần xóa để đặt IsActive=false
        let subjectPlanDetailItems = selectedSubjectPlan.PlanDetail;

        if(action==="cancel-newitem"){ //hủy bỏ một mục subject plan detail/row vừa thêm vào (chưa có trên server)
            subjectPlanDetailItems = subjectPlanDetailItems.filter(item=>item.LectureId !== deletingPlanDetailItem.LectureId);
            setSelectedSubjectPlan({...selectedSubjectPlan, PlanDetail: subjectPlanDetailItems});
            return;
        }

        const index = subjectPlanDetailItems.findIndex(item => item.LectureId === deletingPlanDetailItem.LectureId);
        if (index>=0) { //tìm thấy mục cần xóa
            const mustDelete = subjectPlanDetailItems[index].IsActive ? false : true; //Nếu mục đang xóa không hiện hoạt thì sẽ được đánh cờ xóa hẳn
            
            subjectPlanDetailItems[index].IsActive = false;
       
            //Gửi lệnh cập nhật dữ liệu lên server
            let updatedSubjectPlanItem = {
                ...selectedSubjectPlan,
                PlanDetail: mustDelete ? subjectPlanDetailItems.filter(item=>item.LectureId !== deletingPlanDetailItem.LectureId) : subjectPlanDetailItems
            }
            setAction({
                type:'save_plan',
                value: updatedSubjectPlanItem
            });
        }
    }

    ///Chuyển đổi PPCT lưu riêng trong list SubjectPlanDetail của năm học 2022-2023 về thành giá trị của cột PlanDetail trong list SubjectPlan
    const handlePlanDetailSort = (columnName, sortDirection) => {
        setLectureItems( _orderBy(lectureItems, [columnName],[sortDirection]));
        //setSelectedSubjectPlan({...selectedSubjectPlan, PlanDetail: _orderBy(selectedSubjectPlan.PlanDetail, [columnName],[sortDirection])});
    }
   
    //Chọn lại mục subject plan để hiển thị đúng các thông tin của nó. Mục subject plan này thuộc một trong các tình huống
    // - Mới được thêm vào và lưu xong
    // - Đã có và vừa được import PPCT từ file excel xong
    useEffect(()=>{
        if(updateState !=="" && updateSubjectPlanId !== "" && subjectPlanItems.length > 0){        
           const updateSubjectPlanObj = subjectPlanItems.find(item => item.SubjectPlanId === updateSubjectPlanId);
           if(updateSubjectPlanObj) {     
                handleSubjectPlanSelection(null, updateSubjectPlanId, updateSubjectPlanObj);
                setUpdateSubjectPlanId("");
                setUpdateState("");
           }
        }
    },[updateSubjectPlanId, updateState, subjectPlanItems.length])


    useEffect(()=> {
        if(subjectPlanApiMessage === "subjectplan-fetched"){
            let subjectPlanObj = subjectPlanItems.find(item=>item.SubjectPlanId === subjectPlanId); 
            setSelectedSubjectPlan(subjectPlanObj);
            setLectureItems(subjectPlanObj?.PlanDetail);
        }
    },[subjectPlanApiMessage]);


    return(
        <>
            <div className="row pt-3">               
                <h4>Kế hoạch dạy học (KHDH)</h4>
            </div>
            <div className="row pt-2">
                <div className="col-md-2 border">
                    <div className="row">
                        <table className="table table-borderless">
                            <tbody>
                                <tr>
                                    <td>Năm học</td>
                                    <td>
                                        <YearSchoolSelection 
                                            year = {selectedStartYear}
                                            onYearChange = {handleYearChange} />
                                    </td>
                                </tr>                                
                                <tr>
                                    <td>Khối lớp</td>
                                    <td>
                                        <GradeSelection grade={selectedGrade} onGradeChange = {handleGradeChange}/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>                      
                    </div>
                    <div className="row">
                        <SubjectPlanList 
                            subjectPlanItems = {_orderBy(subjectPlanItems,['Title'],['asc'])} 
                            onSubjectPlanClick = {handleSubjectPlanSelection}
                        />                     
                    </div>
                </div>
                <div className="col-md-10">
                    <div className="row mx-2">
                        {   
                            subjectPlanId !== '' && selectedSubjectPlan?
                            <SubjectPlanItem
                                key={subjectPlanId}
                                item = {selectedSubjectPlan} //{subjectPlanItems.find(item=>item.SubjectPlanId===subjectPlanId)}
                                subjectItems = {subjectItems}
                                action= {""}
                                startYear = {selectedStartYear}
                                grade = {selectedGrade}
                                onResetSelectionToAdd = {handleResetToAdd}
                                onDeleteItemClick = {handleDeletePlan}
                                onSaveItemClick = {handleSavePlan}
                                onSaveImportedPlanDetailItems = {handleSaveImportedPlanDetail}
                            />
                            :
                            <SubjectPlanItem
                                item = {readyToAddNewSubjectPlan ? {...createBlankSubjectPlanItem()[0], StartYear: selectedStartYear, Grade: selectedGrade} : null}
                                subjectItems = {subjectItems.filter(subjectObj=>subjectPlanItems.findIndex(sp=>sp.SubjectId===subjectObj.SubjectId)<0)} //Chỉ lấy các môn học chưa có khai báo KHGD
                                action= {readyToAddNewSubjectPlan ? "add" : "new"}
                                startYear = {selectedStartYear}
                                grade = {selectedGrade}
                                onResetSelectionToAdd = {handleResetToAdd}                            
                                onSaveItemClick = {handleSavePlan}
                                onSaveImportedPlanDetailItems = {handleSaveImportedPlanDetail}
                            /> 
                        }
                    </div>                    
                    <div className="row mx-2">
                    {   
                        selectedSubjectPlan && subjectPlanId!=='' 
                        ?
                        <SubjectPlanDetailList 
                                subjectPlandetail = {lectureItems} //{subjectPlanItems.find(item=>item.SubjectPlanId===subjectPlanId).PlanDetail} // {selectedSubjectPlan.PlanDetail}  //
                                sem1TotalWeeks = {selectedSubjectPlan.Sem1_TotalWeeks}
                                sem1PeriodPerWeek = {selectedSubjectPlan.Sem1_PeriodsPerWeek}
                                sem2TotalWeeks = {selectedSubjectPlan.Sem2_TotalWeeks}
                                sem2PeriodPerWeek = {selectedSubjectPlan.Sem2_PeriodsPerWeek}
                                onAddItemClick = {handleAddPlanDetail}
                                onSaveItemClick = {handleSavePlanDetail}
                                onDeleteItemClick = {handleDeletePlanDetail}
                                onColumnSortClick = {handlePlanDetailSort}
                                // onConvertButtonClick = {handleConvertSubjectPlainDetail}  //Su dung tam thoi de covert du lieu nam 2022
                                startYear = {selectedStartYear} //Su dung tam thoi de covert du lieu nam 2022
                        />
                        :
                        <MessageBox>Chưa chọn kế hoạch cần xem hoặc chưa có thông tin chi tiết của kế hoạch đã chọn</MessageBox>
                    }
                    </div>
                </div>
            </div>
        </>
    )
}

export default SubjectPlanPage;
