import React, {useState} from 'react';
import * as dayjs from 'dayjs';
import DOMPutify from 'dompurify';
import DateSelection from "../common/DateSelection";
import {createBlankNewsItem} from './NewsboardData';

const NewsboardItemDialog = (props) => {
    const [item, setItem] = useState(props.item);
    //const [beforeEditItem, setBeforeEditItem] = useState({...props.item});

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.type==='checkbox'?target.checked:target.value;
        setItem({
            ...item,
            [name]:value
        });
    }

    const handleExpiredDateChange = (date) => {
        setItem({
            ...item,
            ExpiredDate: dayjs(date).toISOString()
        })
    }

    const handleSave = () => {
        props.onSaveItemClick(item);
    }

    const createMarkup = (html) => {
        return {
            __html: DOMPutify.sanitize(html)
        }
    }

    const dgElement = props.action==='edit' || props.action==='new'
    ? 
    <div className="modal fade" id={"dlg" + item.NewsId} 
         data-bs-backdrop="static" data-bs-keyboard="false" 
         tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{item.Title}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>                        
                </div>
                <div className="modal-body">
                    <form>
                        <div className='mb3'>
                            <label htmlFor='Title' className='col-form-label'>Tiêu đề</label>
                            <input type='text' name='Title' className='form-control form-control-sm' 
                                value = {item.Title}
                                onChange={(e)=>handleChange(e)}
                            />
                        </div>
                        <div className='mb3'>
                            <label htmlFor='Summary' className='col-form-label'>Tóm tắt/Mô tả</label>
                            <textarea rows='3' name='Summary' className='form-control form-control-sm'
                                value = {item.Summary}
                                onChange={(e)=>handleChange(e)}                            
                            />
                        </div>
                        <div className='mb3'>
                            <label htmlFor='Content' className='col-form-label'>Nội dung</label>
                            <textarea rows='3' name='Content' className='form-control form-control-sm'
                                value = {item.Content}
                                onChange={(e)=>handleChange(e)}
                            />
                        </div>   
                        <div className='mb3'>
                            <label htmlFor='Category' className='col-form-label'>Loại bản tin</label>
                            <select name='Category' style={{'width':'100%'}} className="form-select form-select-sm"
                                value={item.Category} 
                                onChange={(e)=>handleChange(e)}
                            >
                                    <option value={''}></option>
                                    <option value={'Thông báo'}>Thông báo</option>
                                    <option value={'Bản tin'}>Bản tin</option>
                                    <option value={'Khác'}>Khác</option>
                            </select>
                        </div>    
                        <div className='mb3'>
                            <label htmlFor='ExpiredDate' className='col-form-label'>Ngày hết hạn</label>
                            <DateSelection selectedDate={new Date(item.ExpiredDate)} onDateChange={handleExpiredDateChange} />
                        </div>                                         
                    </form>                              
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary btn-sm" data-bs-dismiss="modal"
                        onClick={handleSave}
                    >&nbsp;&nbsp;Lưu&nbsp;&nbsp;</button>
                    
                    <button type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"
                       onClick={props.handleCancel}
                    >&nbsp;Đóng&nbsp;</button>
                </div>
            </div>
        </div>
    </div>
    :
    <div className="modal fade" id={"dlg" + item.NewsId} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{item.Title}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>                        
                </div>
                <div className="modal-body">
                    <p>{item.Summary}</p>
                    <div 
                        className='preview' 
                        style={{"margin":"5px 0px 20px 0px"}}
                        dangerouslySetInnerHTML={createMarkup(item.Content)}
                    >                        
                    </div>  
                    <div>
                        <p>Ngày hết hạn: {dayjs(item.ExpiredDate).format('DD/MM/YYYY')}</p>
                        <p>Người đăng: {item.CreatedBy.displayName}</p>
                    </div>                              
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Đóng lại</button>
                </div>
            </div>
        </div>
    </div>


    return(
        <>
            { dgElement }
        </>
    )
}

export default NewsboardItemDialog