import  {useEffect, useState} from 'react';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { v4 as uuidv4 } from 'uuid';
import * as dayjs from 'dayjs';
import { remove} from 'lodash';

import { loginRequest, sharePointConfig } from '../../authConfig';
import {
    getSharePointListItems,
    updateSharePointItem,
    addSharePointListItem,
    deleteSharePointItem
} from '../../graph';

import { NewsBoard } from "../../mock/MockData";

export const createBlankNewsItem = () =>{
    return [
        {
            NewsId: uuidv4(),
            Title:'',
            Summary:'',
            Content:'',
            Category:'',
            ExpiredDate: dayjs().add(365,'day').toISOString(),
            CreatedBy: {
                displayName:'',
                email:'',
                id:''
            },
            ModifiedBy: {
                displayName:'',
                email:'',
                id:''             
            }
        }
    ]
}

export const useNewsBoardDataApiMock = () => {
    const [newsItems, setNewsItems] = useState(NewsBoard);
    const [action, setAction] = useState({type:'', value:''});

    useEffect(() => {     
        const fetchData = async () => {  
            setNewsItems(newsItems);
        };

        const addBlankItem = async (item) => {
            setNewsItems(item.concat(newsItems));
        };

        const submitData = async (item) => {
            console.log('Submit data');
        };

        const deleteItem = async (item) => {
            let items = Array.from(newsItems);
            remove(items, i=>i.TeacherId===item.TeacherId);        
            setNewsItems(items); 
        }

        const processData = ()=> {
            switch(action.type){
                case 'add':
                    addBlankItem(action.value);
                    break;
                case 'save':
                    submitData(action.value);
                    break;
                case 'del':
                    deleteItem(action.value);
                    break;
                default:
                    fetchData();
            }
        }

        processData();
        
    },[action]);

    return [{newsItems},  setAction];
}

export const useNewsBoardDataApi = () => {
    const [newsItems, setNewsItems] = useState([]);
    const [action, setAction] = useState({type:'', value:''});

    const {instance, accounts} = useMsal();

    const fetchData = (accessToken) =>{
        getSharePointListItems(
            accessToken, 
            sharePointConfig.NewsBoardListId,
            "id,NewsId,Title,Summary,Content,Category,ExpiredDate,Created,Modified",
            "",
            "fields/Modified desc"            
        ).then(response => {  
            let items = [];
            const listItemArray = response.value;
            for(let i=0; i<listItemArray.length; i++){
                let item = listItemArray[i].fields;
                items.push({
                    ...item,
                    CreatedBy: listItemArray[i].createdBy.user,
                    ModifiedBy: listItemArray[i].lastModifiedBy.user
                });
            }
            setNewsItems(items);
        })
    }

    const addBlankItem = (item) => {
        setNewsItems(item.concat(newsItems));
    };

    const saveItem = (accessToken, item) => {
        console.log(item);
        let fieldValues = {};
        if(item.id*1>0){ //Nếu đã có thì cập nhật nội dung của mục trong tuần đã có
            console.log('Updating...');       
            fieldValues = {
                fields: {
                    Title: item.Title,
                    Summary: item.Summary,
                    Content: item.Content,
                    Category: item.Category,
                    ExpiredDate: item.ExpiredDate     
                }
            };  
            updateSharePointItem(accessToken, sharePointConfig.NewsBoardListId, item.id, fieldValues)
                .then(response => {
                    console.log(response)}
                );
        }
        else{ //Nếu chưa có thì thêm vào theo tuần
            console.log('Adding....');
            fieldValues = {
                fields: {
                    NewsId: item.NewsId,
                    Title: item.Title,
                    Summary: item.Summary,
                    Content: item.Content,
                    Category: item.Category,
                    ExpiredDate: item.ExpiredDate 
                }
            };
            addSharePointListItem(accessToken, sharePointConfig.NewsBoardListId, fieldValues)
                .then(response=>{
                    console.log(response)
                });
        }

    };

    const deleteItem = (accessToken, item) => {
        let items = Array.from(newsItems);
        remove(items, i=>i.NewsId===item.NewsId);

        //Xóa phía server nếu item này đã tồn tại trên server
        if(item.id){
            deleteSharePointItem(accessToken,sharePointConfig.NewsBoardListId,item.id).then(response =>{
                console.log('Completed delete item ' + item.NewsId);
                console.log(response);
            });
        }
        setNewsItems(items);
    }

    useEffect(() => {     
        const accessTokenRequest = {
            ...loginRequest,
            account: accounts[0]
        }

        const processData = async () => {  
            instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
                const accessToken = accessTokenResponse.accessToken;
                switch(action.type){
                    case 'add':
                        addBlankItem(action.value);
                        break;
                    case 'save':
                        saveItem(accessToken, action.value);
                        break;
                    case 'del':
                        deleteItem(accessToken, action.value);
                        break;
                    default:
                        fetchData(accessToken);
                }
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest);                    
                }
                console.log(error);
            }) 
        }; 

        processData();       
    },[action]);

    return [{newsItems},  setAction];
}




