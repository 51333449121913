import React from "react";
import TeacherImportDlg from "./TeacherImportDlg";
import TeacherItem from './TeacherItem';

function TeacherList({data, departmentList, titleList, subjectList, roleList, onAddItemClick, onSaveItemClick, onDeleteItemClick}){
    return(        
        <form className="form-inline" name="frmTeacherList">       
            <table className="table table-hover table-sm caption-top">
                <caption>
                    <div className="row">
                        <div className="col-sm-10">
                            Danh sách giáo viên - Tổng số: {data.length}  người
                            <TeacherImportDlg />
                        </div>
                        <div className="col-sm-2 text-end pe-3">
                            <button type="button" className="btn btn-primary btn-sm"
                                data-bs-toggle="modal" data-bs-target="#dlgImportTeacher">Import&nbsp;<i className="fa fa-download"></i></button>
                        </div>  
                    </div>                  
                </caption>
                <thead>
                    <tr>
                        <th style={{width:'12%'}} className='text-left'>Họ tên</th>
                        <th style={{width:'8%'}} className='text-left'>Tên TKB</th>
                        <th style={{width:'8%'}} className='text-left'>Tổ CM</th>
                        <th style={{width:'8%'}} className='text-left'>Môn dạy</th>
                        {/* <th style={{width:'8%'}} className='text-left'>Chức danh</th> */}
                        <th style={{width:'7%'}} className='text-left'>Điện thoại</th>
                        <th style={{width:'15%'}} className="text-left">Email (dùng đăng nhập Office 365)</th>
                        {/* <th style={{width:'5%'}} className="text-left">User name</th> */}
                        <th style={{width:'8%'}} className="text-left">Roles</th>
                        {/* <th style={{width:'10%'}}>Địa chỉ</th> */}
                        <th style={{width:'5%'}}>Còn DH</th>                             
                        <th style={{width:'10%'}} className='text-end'>
                            <button type="button" 
                                onClick={onAddItemClick} className="btn btn-info btn-sm">Thêm&nbsp;&nbsp;<i className="fa fa-plus"></i>&nbsp;</button>
                        </th>
                    </tr>
                </thead>
                <tbody className="align-middle">
                    { 
                        data.map((item, index) => 
                            <TeacherItem 
                                key={item.id}
                                item = {item} 
                                teacherList = {data}
                                subjectList = {subjectList}
                                departmentList = {departmentList}
                                titleList = {titleList}
                                roleList = {roleList}
                                action = {item.FullName === ''? 'new' : ''} 
                                onSaveItemClick = {onSaveItemClick}
                                onDeleteItemClick={onDeleteItemClick}                                
                            />
                        )
                    }
                </tbody>
            </table>
        </form>
    )
}

export default TeacherList;