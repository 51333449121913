import  {useEffect, useState} from 'react';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

import { v4 as uuidv4 } from 'uuid';
import {
    getSharePointListItems,
    updateSharePointItem,
    addSharePointListItem
} from '../../graph';

import { loginRequest, sharePointConfig } from '../../authConfig';

import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';


export const createEmptyNotebookRecords = (year,week) => {
    let resultRecs = [];
    for(let i=1; i<7; i++){
        let d = dayjs().year(year).isoWeek(week).day(i);
        let recs = Array(10).fill(
            {
                id: '',
                note_date: d.toISOString(), 
                part_day:'', 
                period: 0,
                period_plan: 0,
                subject_id: '',
                lecture_id: '',
                absen_student: 0,
                comment: '',
                teacher_id:'',
                note: '',
                week: week,
                week_plan:0,
                is_late: false                
            });

        recs = recs.map((obj,idx) => {
            if(idx<5)
                return {...obj, id:uuidv4(), period:idx+1, part_day:'Sáng'}
            else
                return {...obj, id:uuidv4(), period:idx-4, part_day:'Chiều'}
        })

        resultRecs = resultRecs.concat(recs);
    }
    return resultRecs;
}

export const createEmptyNotebook = (classId, className, year, week) => {
    let emptyNotebook = {
        class_id: classId,
        class_name: className,
        year: year,
        records: classId===''?[]:createEmptyNotebookRecords(year, week)
    };  
    return emptyNotebook;  
}

export const useNotebookDataApi = () => {
    dayjs.extend(isoWeek);
    const [notebookRecordItems, setNotebookRecordItems] = useState([]);
    const [action, setAction] = useState({type:'fetchData', value:{classId:'',schoolYear: dayjs().year(), schoolWeek:dayjs().isoWeek()}});
    const [isProcessingNotebookData, setIsProcessingNotebookData] = useState(false);
    
    const {instance, accounts} = useMsal();

    useEffect(() => {     
        const accessTokenRequest = {
            ...loginRequest,
            account: accounts[0]
        }

        const fetchData = (accessToken, actionValue) => {
            if(actionValue.classId===''){
                setIsProcessingNotebookData(false);
                return;
            }
            getSharePointListItems(
                accessToken, 
                sharePointConfig.ClassNotebookListId,
                "Title,ClassId,SchoolYear,SchoolWeek,Records", 
                `((fields/ClassId eq '${actionValue.classId}') and (fields/SchoolYear eq ${actionValue.schoolYear}) and (fields/SchoolWeek eq ${actionValue.schoolWeek}))`                
            ).then(response => {
                let records = []
                if(response.value.length>0){      
                    records = JSON.parse(response.value[0].fields.Records)                        
                }  
                if (records.length===0){
                    records = createEmptyNotebookRecords(actionValue.schoolYear, actionValue.schoolWeek);
                }   
              
                setNotebookRecordItems(records); 
                setIsProcessingNotebookData(false);
            })        
        }

        const saveData = (accessToken, actionValue) => {
            const {classId, schoolYear, schoolWeek, notebookRecordItem, notebookRecords} = actionValue;

            getSharePointListItems(
                accessToken, 
                sharePointConfig.ClassNotebookListId,
                "Title, ClassId, SchoolYear, Records", 
                `((fields/ClassId eq '${classId}') and (fields/SchoolYear eq ${schoolYear}) and (fields/SchoolWeek eq ${schoolWeek}))`                
            ).then(response => {
                const items = response.value;
                let fieldValues = {};
                if(items.length>0){ //Nếu đã có thì cập nhật nội dung của mục trong tuần đã có
                    console.log('Updating...');  
                    console.log(notebookRecordItem);
                    let records = JSON.parse(items[0].fields.Records);

                    //Duyệt để tìm và cập nhật mục có thay đổi
                    records = records.map(r=>{                            
                        if(r.id===notebookRecordItem.id){ 
                            return {
                                ...r, 
                                note_date: notebookRecordItem.note_date,
                                period: notebookRecordItem.period,
                                part_day: notebookRecordItem.part_day,
                                period_plan: notebookRecordItem.period_plan,
                                subject_id: notebookRecordItem.subject_id,
                                lecture_id: notebookRecordItem.lecture_id,
                                absen_student: notebookRecordItem.absen_student,
                                teacher_id: notebookRecordItem.teacher_id,
                                comment: notebookRecordItem.comment,
                                note: notebookRecordItem.note,
                                week_plan: notebookRecordItem.week_plan,
                                is_late: notebookRecordItem.is_late
                            }
                        }
                        else{ 
                            return r;
                        }
                    })
    
                    fieldValues = {
                        fields: {
                            Records: JSON.stringify(records)
                        }
                    }    
                    updateSharePointItem(accessToken, sharePointConfig.ClassNotebookListId, items[0].id, fieldValues)
                        .then(response => {
                            console.log(response);
                            setIsProcessingNotebookData(false);
                        });
                }
                else{ //Nếu chưa có thì thêm vào theo tuần
                    console.log('Adding....');
                    fieldValues = {
                        fields: {
                            Title: classId,
                            ClassId: classId,
                            SchoolYear: schoolYear,
                            SchoolWeek: schoolWeek,
                            Records: JSON.stringify(notebookRecords)    
                        }
                    };
                    addSharePointListItem(accessToken, sharePointConfig.ClassNotebookListId, fieldValues)
                        .then(response=>{
                            console.log(response);
                            setIsProcessingNotebookData(false);
                        });
                }
            })  
        }

        const processData = async () => {  
            setIsProcessingNotebookData(true);
            instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
                const accessToken = accessTokenResponse.accessToken;
                switch(action.type){
                    case 'save':
                        saveData(accessToken, action.value);
                        break;
                    default:
                        fetchData(accessToken, action.value);
                }
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest);                    
                }
                setIsProcessingNotebookData(false);
                console.log(error);
            }) 
        }; 

        processData();  

    },[action]);

    return [{notebookRecordItems, isProcessingNotebookData}, setAction]

}

