import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import vi from 'date-fns/locale/vi';

function DateTimePicker(props){
    registerLocale('vi', vi);
    const [selectedDateTime, setSelectedDateTime] = useState(props.selectedDate);
    return (
        <DatePicker className="form-control form-control-sm"
            selected={selectedDateTime}
            locale='vi' 
            showTimeSelect
            timeFormat='HH:mm'
            timeIntervals={5}
            dateFormat= "HH:mm"
            onChange={(dateTime)=> {
                setSelectedDateTime(dateTime);
                props.onDateChange(dateTime);
            }}>            
        </DatePicker>
    )
}
export default DateTimePicker;