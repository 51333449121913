const CategorySelection = ({onCategoryClick}) => {
    return (
      <ul className="list group">         
          <li className="list-group-item list-group-item-sm">
            <a className="nav-link" role='button' onClick ={(e) => onCategoryClick(e,"Tổ chuyên môn")} >
                Tổ chuyên môn
            </a>
          </li> 
          <li className="list-group-item list-group-item-sm">
            <a className="nav-link" role='button' onClick ={(e) => onCategoryClick(e,"Chức danh")}>
                Chức danh
            </a>
          </li>
          <li className="list-group-item list-group-item-sm">
            <a className="nav-link" role='button' onClick ={(e) => onCategoryClick(e,"Môn học")} >
                Môn học
            </a>
          </li>            
      </ul>
    );
  }
  
  export default CategorySelection;