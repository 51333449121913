import  {useEffect, useState, useContext} from 'react';
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { MyAppContext } from '../../context/MyAppContext';
import { getSchoolWeek } from '../../globalFuntions';

import { v4 as uuidv4 } from 'uuid';
import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

import {orderBy as _orderBy} from 'lodash';

import {
    getSharePointListItems,
    getSharePointListItemsBatch,
    addSharePointListItem,
    addSharePointListItemBatch,
    updateSharePointItem,
    updateSharePointItemBatch,
    deleteSharePointItem,
    
} from '../../graph';

import { loginRequest, sharePointConfig } from '../../authConfig';
import { scheduleConfig } from '../../appConfig';


/* **************************************************************************************
 * EmptyScheduleRecords
 * Khởi tạo dữ liệu trống chi tiết lịch phân tiết dạy cho một lớp trong một tuần
 * @param {*} year : Năm học
 * @param {*} week : Tuần học (tuần theo ISOweek)
 * @returns : Cấu trúc dữ liệu là một mảng các records chứa thông tin một tiết học
 * *************************************************************************************/
export const EmptyScheduleRecords = (startDateOfWeekIsoString, schoolWeek) => {
    let resultRecs = [];
    for(let i=1; i<7; i++){
        let d = dayjs(startDateOfWeekIsoString).isoWeekday(i);
        let recs = Array(10).fill(
            {
                id: '',
                schedule_date: d.toISOString(), 
                part_day:'', 
                period: 0,
                lecture_period: 0,
                subject_id: '',
                lecture_id: '',
                comment: '',
                teacher_id:'',
                school_week: schoolWeek,  //tuần học, 1 bắt đầu từ ngày khai giảng                
                lecture_week: 0,
                is_late: false          
            }).map((item, idx) => {
                return ({
                    ...item, 
                    id: uuidv4(),                   
                    part_day: idx<5 ? 'Sáng': 'Chiều',
                    period: idx < 5 ? idx + 1 : idx - 4,
                });
            });
        resultRecs = resultRecs.concat(recs);
    }
    return resultRecs;
}

export const EmptySchedule = (classId, className, grade, schoolYear, schoolWeek, startDateOfWeekIsoString ) => {
    let emptySchedule = {
        item_id: 0,
        class_name: className,
        class_id: classId,
        grade: grade,
        school_year: schoolYear,
        school_week: schoolWeek,  
        start_date_week_isostring: startDateOfWeekIsoString,
        apply_school_week: 0, //cho biết áp dụng dữ liệu TKB import từ tuần nào, 0 là nhập tay
        records: classId===''? [] : EmptyScheduleRecords(startDateOfWeekIsoString, schoolWeek),
        flagDataChanged: false, //Báo hiệu mục dữ liệu này có thay đổi hay không
    };  
    return emptySchedule;  
}

export const UpdateDateForScheduleRecords = (scheduleRecords, startDateOfWeekIsoString, schoolWeek, flagChangeRecordId) => {
    let records = scheduleRecords;
    let d=0;
    for (let i=0; i<records.length; i++) {
        if (i%10===0) d=d+1;
        records[i].schedule_date = dayjs(startDateOfWeekIsoString).isoWeekday(d).toISOString();
        records[i].school_week =  schoolWeek; //records[i].subject_id!==""? schoolWeek : 0;
        if (flagChangeRecordId) records[i].id = uuidv4();        
    }
    return records;
}

export const UpdateDateForScheduleItems = (scheduleItems, schoolYear, schoolWeek, applySchoolWeek, startDateOfWeekIsoString, 
    flagChangeScheduleItem, flagChangeRecordId) => {
    let schedules = scheduleItems;
    for (let i=0; i<schedules.length; i++) {
        schedules[i].apply_school_week = applySchoolWeek;
        schedules[i].start_date_week_isostring = startDateOfWeekIsoString;
        schedules[i].school_week = schoolWeek;
        schedules[i].school_year = schoolYear;
        schedules[i].records = UpdateDateForScheduleRecords(schedules[i].records,startDateOfWeekIsoString, schoolWeek, flagChangeRecordId);
        scheduleItems[i].flagDataChanged = flagChangeScheduleItem;
    }
    return schedules;    
}

const ProcessScheduleItemData = (scheduleItems, classItems, grade, schoolYear, schoolWeek, firstDateOfWeekIsoString) => {
    //Điền thêm các lớp trống vào danh sách lớp phục vụ làm đẹp đội hình khi hiển thị TKB
    const numOfClasses = classItems.length;
    let numOfSegmen = numOfClasses % scheduleConfig.NumOfClassColsPerTable ===0 
    ? numOfClasses / scheduleConfig.NumOfClassColsPerTable
    : Math.floor(numOfClasses / scheduleConfig.NumOfClassColsPerTable) + 1;

    const maxOfClasses = numOfSegmen * scheduleConfig.NumOfClassColsPerTable; 

    if(numOfClasses < maxOfClasses){
        for(let n=numOfClasses; n < maxOfClasses; n++){
            classItems.push({
                Title: "NA",
                Grade: grade,
                ClassId:uuidv4(),
            })
        }
    };

    if (scheduleItems.length > 0) { //xử lý dữ liệu lấy được từ server
        if(scheduleItems.length < classItems.length) { //Điền thêm lớp trống cho đẹp đội hình
            let missingClasses = [];
            for (let m=0; m < classItems.length; m++) {
                const idx = scheduleItems.findIndex(s=>s.class_id=== classItems[m].ClassId);
                if (idx<0) {
                    missingClasses.push(classItems[m]);
                }
            }
            //Điền thông tin trắng úng với các lớp còn thiếu và dữ liệu để hiển thị
            for (let m=0; m<missingClasses.length; m++) {
                const missingClass = missingClasses[m];
                scheduleItems.push({
                    item_id: 0,                                
                    class_name: missingClass.Title,
                    class_id: missingClass.ClassId,
                    grade: missingClass.Grade,
                    school_year: schoolYear,
                    school_week: schoolWeek,
                    records: EmptyScheduleRecords(firstDateOfWeekIsoString, schoolWeek),
                    flagDataChanged: false,
                });
            }
        }
    } else { //Chưa có dữ liệu trên server, xử lý để làm dữ liệu trống theo danh sách lớp
        if (classItems.length>0){
            for (let c=0; c<classItems.length; c++){
                const classItem = classItems[c];
                scheduleItems.push({
                    item_id: 0,
                    class_name: classItem.Title,
                    class_id: classItem.ClassId,
                    grade: classItem.Grade,
                    school_year: schoolYear,
                    school_week: schoolWeek,
                    records: EmptyScheduleRecords(firstDateOfWeekIsoString, schoolWeek),
                    flagDataChanged: false,
                });                            
            };
        } else {
            scheduleItems.push({
                item_id: 0,
                class_name: 'NA',
                class_id: uuidv4(),
                grade: grade,
                school_year: schoolYear,
                school_week: schoolWeek,
                records: EmptyScheduleRecords(firstDateOfWeekIsoString, schoolWeek),
                flagDataChanged: false, 
            });  
        }           
    }

    let reshapedScheduleItems = ReShapeScheduleData(scheduleItems, firstDateOfWeekIsoString);
    const schedules = {
        ClassList: classItems,  //Danh sách lớp in tiêu đề cột TKB 
        DisplayData: reshapedScheduleItems, //Dữ liệu TKB sắp xếp theo danh sách lớp
        StorageData: scheduleItems,  //Dữ liệu thời khóa biểu phục vụ cho việc thêm, sửa, lưu về server
    }
    return schedules;
}

/* **************************************************************************************
 * ReShapeScheduleData
 * Chỉnh lại dữ liệu lấy được từ CSDL/SharePoint trên server về dạng để trình bày TKB
 * **************************************************************************************
 * @param {*} scheduleData: Dữ liệu đầu vào lấy từ CSDL
 * @param {*} schoolYear: Năm học
 * @param {*} schoolWeek: Tuần học theo ISO
 * @returns: 
 * Cấu trúc dữ liệu để hiển thị thời khóa biểu mỗi dòng là một tiết học gồm các
 * cột: Thứ, Tiết, Lớp (gồm nhiều lớp, mỗi lớp có 2 cột sáng chiều). Mỗi cell ứng với cột
 * lớp là thông tin Môn học, Giáo viên dạy và có thể các thông tin khác liên quan như dự
 * giờ...
 * **************************************************************************************/
const ReShapeScheduleData = (scheduleData, firstDateOfWeekIsoString) => {
    const firstDateOfWeek = dayjs(firstDateOfWeekIsoString);
    let weekData = [];
    for (let d=1; d<7; d++){
        for (let p=1; p<6; p++) {
            weekData.push({
                row_id: uuidv4(),
                item_id: 0, // Ứng với SharePoint Item ID, nếu chưa có trong SPList thì đặt =0
                schedule_date: firstDateOfWeek.isoWeekday(d).toISOString(),  
                period: p, 
                class_subjects:[],
            });
        }        
    }

    for (let i=0; i< weekData.length; i++) {
        let dataItem = weekData[i];
        let scheduleItems=[];

        for (let j = 0; j < scheduleData.length; j++){            
            const records = scheduleData[j].records;

            scheduleItems = records.filter(r => 
                dayjs(r.schedule_date).format('DD/MM/YYYY') === dayjs(dataItem.schedule_date).format('DD/MM/YYYY')
                && r.period === dataItem.period
            );
        
            const morningItems = scheduleItems.filter(item=>item.part_day==='Sáng');
            const afternoonItems = scheduleItems.filter(item=>item.part_day==='Chiều');
            for(let k= 0; k < morningItems.length; k++){
                const sItem = morningItems[k];
                const cItem = afternoonItems[k];

                weekData[i].item_id = scheduleData[j].item_id;
                weekData[i].class_subjects.push({
                    item_id: scheduleData[j].item_id,
                    class_id: scheduleData[j].class_id,
                    class_name: scheduleData[j].class_name,  
                    grade: scheduleData[j].grade,                  
                    morning: {
                        id: sItem.id,
                        subject_id: sItem.subject_id,
                        teacher_id: sItem.teacher_id,
                        comment: sItem.comment,
                        lesson_id: '',                        
                        period_plan: 0,
                        week: sItem.week,
                        week_plan: 0,
                        is_late: false,
                    },
                    afternoon: {
                        id: cItem.id,
                        subject_id: cItem.subject_id,
                        teacher_id: cItem.teacher_id,
                        comment: sItem.comment,
                        lesson_id: '',
                        period_plan: 0,
                        week: sItem.week,
                        week_plan: 0,
                        is_late: false,
                    }           
                })
            }
        }
        //Xếp lại thứ tự các lớp cho phù hợp với danh sách lớp
        weekData[i].class_subjects = _orderBy(weekData[i].class_subjects,['class_name'],['asc']);
    }
    return weekData;
}


export const useScheduleDataApi = (defaultAction={type:'', value:null}) => {
    dayjs.extend(isoWeek);

    const [appContext,] = useContext(MyAppContext);
    const [schedules, setSchedules] = useState(null);    //Thời khóa biểu
    const [freePeriodItems, setFreePeriodItems] = useState([]);  //Tiết trống
    const [action, setScheduleAction] = useState(defaultAction);
    const [isProcessingScheduleData, setIsProcessingScheduleData] = useState(false);
    const [flagAutoSaveSchedule, setFlagAutoSaveSchedule] = useState(0);

    const [statusMessage, setStatusMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    
    const {instance, accounts} = useMsal();

    useEffect(() => {     
        const accessTokenRequest = {
            ...loginRequest,
            account: accounts[0]
        }

        /***********************************************************************************
         * fetchFreePeriods - Tìm tiết trống theo lớp và ngày và sau đó 1 tuần
         * @param {*} accessToke 
         * @param {*} actionValue: classId, fromDateIsoString
         ************************************************************************************/
        const fetchFreePeriods = (accessToken, actionValue) => {
            const {classIds, fromDateIsoString, numOfWeeks} = actionValue;    

            const nextWeeks = numOfWeeks===undefined ? 1: numOfWeeks;
            const firstDateOfWeekIsoString = dayjs(fromDateIsoString).isoWeekday(1).subtract(1,'d').toISOString();
            const firstDateOfLastWeekIsoString = dayjs(firstDateOfWeekIsoString).add(7*nextWeeks,'d').toISOString();             

            const requestIds = [1,2];
            const listIds = [
                sharePointConfig.ScheduleListId, 
                sharePointConfig.ScheduleLogListId
            ];
            const selectFields= [
                "ClassId,Title,Grade,SchoolYear,SchoolWeek,Records,id",
                "ClassId,Title,SchoolYear,SchoolWeek,PartDay,Period,ScheduleDate,SubjectId,TeacherId,id",
            ];

            let classIdQuery = "";
            classIds.forEach(classId=> {
                if (classIdQuery==="") {
                    classIdQuery = `(fields/ClassId eq '${classId}')`;
                } else {
                    classIdQuery = classIdQuery + ` or (fields/ClassId eq '${classId}')`;
                }

            })
            const queryClauses = [
                `((fields/StartDateOfWeek ge '${firstDateOfWeekIsoString}') and (fields/StartDateOfWeek le '${firstDateOfLastWeekIsoString}') and (${classIdQuery}))`,   
                `((fields/ScheduleDate ge '${firstDateOfWeekIsoString}') and (fields/ScheduleDate le '${firstDateOfLastWeekIsoString}') and (${classIdQuery}) and (fields/ActionType eq '${scheduleConfig.ScheduleLogType_DayBu}'))`,         
            ];

            getSharePointListItemsBatch(accessToken, requestIds, listIds, selectFields, queryClauses).then(response=>{
                let scheduleItems = [];
                let scheduleLogItems = [];

                //1. Truy vấn dữ liệu trên SP Lists
                response.responses.forEach(r=>{
                    if (r.status === 200 || r.status===201) {
                        if (parseInt(r.id)===1) {
                            scheduleItems = r.body.value;
                        }else {
                            scheduleLogItems = r.body.value;
                        }
                    } else {
                        console.error(`Lỗi khi lấy dữ liệu tìm tiết trống ${r.id===1?'Schedule List':'LectureSchedule List'}`, r)
                    }
                });

                //2. Trộn TKB lại trước khi tìm tiết trống
                let mainRecs = scheduleItems.length> 0 ? JSON.parse(scheduleItems[0].fields.Records) : []; //lây các mục TKB của item đầu tiên
               
                //2.1 Đặt lại giá trị tuần cho tất cả các mục trong TKB
                mainRecs = mainRecs.map(item=>{
                    return {...item, school_week: scheduleItems[0].fields.SchoolWeek}
                })
                //2.2 Trộn tất cả TKB biểu của các mục còn lại với mục đầu tiên từ dữ liệu lấy được từ Schedule List
                for (let i=1; i<scheduleItems.length; i++) {
                    let nextRecs = JSON.parse(scheduleItems[i].fields.Records);
                    const schoolWeek = scheduleItems[i].fields.SchoolWeek;
                    nextRecs = nextRecs.map(item=>{
                        return {...item, school_week: schoolWeek}
                    })
                    for (let j=0; j<mainRecs.length; j++) {
                        if (mainRecs[j].subject_id===""){
                            mainRecs[j].subject_id = nextRecs[j].subject_id;
                            mainRecs[j].teacher_id = nextRecs[j].teacher_id;
                            if (mainRecs[j].school_week===0)  { mainRecs[j].school_week =  nextRecs[j].school_week; } 
                        }
                    }
                }

                //2.3 Lọc lấy tiết trống của tuần hiện tại (tuần chứa ngày bắt đầu tìm tiết trống)  
                let nextWeekRecs = [];
                //nối với các ngày trống của các tuần tiếp theo
                for(let i=1; i<= nextWeeks; i++) {
                    let tempRecs  = mainRecs.map(item=> {
                        return {
                            ...item,
                            schedule_date: dayjs(item.schedule_date).add(7*i,'d').toISOString(),
                            school_week: item.school_week+1,
                        }
                    });
                    nextWeekRecs = nextWeekRecs.concat(tempRecs);
                }
                
                let freePeriods = mainRecs.concat(nextWeekRecs);

                //2.4 Trộn với dữ liệu từ ScheduleLog, mỗi item trong log là một mục TKB ứng với 1 tiết
                //Trộn tiếp ở bước này để đảm bảo lấy đủ

                for (let i=0; i<scheduleLogItems.length; i++) {
                    const logItem = scheduleLogItems[i].fields;
                    const idx = freePeriods.findIndex(item => 
                        dayjs(item.schedule_date).isSame(dayjs(logItem.ScheduleDate),"d")
                        && item.period === logItem.Period
                        && item.part_day === logItem.PartDay
                    )
                    if (idx > -1) {
                        freePeriods[idx].subject_id = logItem.SubjectId;
                        freePeriods[idx].teacher_id = logItem.TeacherId;
                        freePeriods[idx].school_week = logItem.SchoolWeek;
                    } 
                }
                
                //2.5 Lọc tìm các tiết trống
                freePeriods = freePeriods.filter(item=>item.subject_id==="" && item.teacher_id==="");

                setFreePeriodItems(freePeriods);
                setIsProcessingScheduleData(false);
            })
        }

        const fetchFreePeriodsByImportedSchedule = (accessToken, actionValue) => {
            const {classIds, fromDateIsoString, numOfWeeks} = actionValue;    

            const nextWeeks = numOfWeeks===undefined ? 1: numOfWeeks;
            const firstDateOfWeekIsoString = dayjs(fromDateIsoString).isoWeekday(1).toISOString();
            const firstDateOfLastWeekIsoString = dayjs(firstDateOfWeekIsoString).add(7*nextWeeks,'d').toISOString();             

            const requestIds = [1,2];
            const listIds = [
                sharePointConfig.ScheduleImportedListId, 
                sharePointConfig.ScheduleLogListId
            ];
            const selectFields= [
                //"ClassId,Title,Grade,SchoolYear,SchoolWeek,Records,id",
                "Title,SchoolYear,ApplySchoolWeek,StartDateOfWeek,ScheduleP1,ScheduleP2,ScheduleP3,ScheduleP4,ScheduleP5,id",
                "ClassId,Title,SchoolYear,SchoolWeek,PartDay,Period,ScheduleDate,SubjectId,TeacherId,id",
            ];

            let classIdQuery = "";
            classIds.forEach(classId=> {
                if (classIdQuery==="") {
                    classIdQuery = `(fields/ClassId eq '${classId}')`;
                } else {
                    classIdQuery = classIdQuery + ` or (fields/ClassId eq '${classId}')`;
                }

            })
            const queryClauses = [
                `((fields/StartDateOfWeek le '${firstDateOfWeekIsoString}'))`,   //Lấy mục chứa TKB được áp dụng
                `((fields/ScheduleDate ge '${firstDateOfWeekIsoString}') and (fields/ScheduleDate le '${firstDateOfLastWeekIsoString}') and (${classIdQuery}) and (fields/ActionType eq '${scheduleConfig.ScheduleLogType_DayBu}'))`,         
            ];

            const orderClauses = [
                "fields/StartDateOfWeek desc",
                ""
            ];
            const topN = [1, null];

            getSharePointListItemsBatch(accessToken, requestIds, listIds, selectFields, queryClauses, orderClauses, topN).then(response=>{
                let scheduleItems = [];
                let scheduleLogItems = [];
                //1. Truy vấn dữ liệu trên SP Lists
                response.responses.forEach(r=>{
                    if (r.status === 200 || r.status===201) {
                        if (parseInt(r.id)===1) {
                            scheduleItems = r.body.value;
                        }else {
                            scheduleLogItems = r.body.value;
                        }
                    } else {
                        console.error(`Lỗi khi lấy dữ liệu tìm tiết trống ${r.id===1?'Schedule List':'LectureSchedule List'}`, r)
                    }
                });

                //2. Trộn TKB lại trước khi tìm tiết trống
                let tempItems = [];
                if (scheduleItems.length>0) {
                    const dataItem = scheduleItems[0].fields; 
                    const s1 = JSON.parse(dataItem.ScheduleP1).filter(item=>classIds.includes(item.class_id));
                    const s2 = JSON.parse(dataItem.ScheduleP2).filter(item=>classIds.includes(item.class_id));
                    const s3 = JSON.parse(dataItem.ScheduleP3).filter(item=>classIds.includes(item.class_id));
                    const s4 = JSON.parse(dataItem.ScheduleP4).filter(item=>classIds.includes(item.class_id));
                    const s5 = JSON.parse(dataItem.ScheduleP5).filter(item=>classIds.includes(item.class_id));
                    tempItems = [].concat(s1,s2,s3, s4, s5);
                }

                //2.1 Chỉ giữ lại TKB theo danh sách lớp truyền vào
                scheduleItems = tempItems; 

                //2.2 Đặt lại thời gian cho phù hợp với thời gian truyền vào
                if(scheduleItems.length>0) {
                    const schoolWeek = getSchoolWeek(appContext.SchoolYearInfo[0]);
                    scheduleItems  = UpdateDateForScheduleItems(scheduleItems, 
                        scheduleItems[0].school_year,
                        schoolWeek, 
                        scheduleItems[0].apply_school_week, 
                        firstDateOfWeekIsoString, false, false );
                }

                //2.3 Trộn tất cả TKB biểu của các mục còn lại với mục đầu tiên từ dữ liệu lấy được từ Schedule List
                let mainRecs = scheduleItems[0].records;                 
                for (let i=1; i<scheduleItems.length; i++) {
                    let nextRecs = scheduleItems[i].records;
                    for (let j=0; j<mainRecs.length; j++) {
                        if (mainRecs[j].subject_id===""){
                            mainRecs[j].subject_id = nextRecs[j].subject_id;
                            mainRecs[j].teacher_id = nextRecs[j].teacher_id;
                            mainRecs[j].school_week =  nextRecs[j].school_week; 
                        }
                    }
                }

                //2.3 Lọc lấy tiết trống của tuần hiện tại (tuần chứa ngày bắt đầu tìm tiết trống)  
                let nextWeekRecs = [];
                //nối với các ngày trống của các tuần tiếp theo
                for(let i=1; i<= nextWeeks; i++) {
                    let tempRecs  = mainRecs.map(item=> {
                        return {
                            ...item,
                            schedule_date: dayjs(item.schedule_date).add(7*i,'d').toISOString(),
                            school_week: item.school_week+1,
                        }
                    });
                    nextWeekRecs = nextWeekRecs.concat(tempRecs);
                }
                
                let freePeriods = mainRecs.concat(nextWeekRecs);

                //2.4 Trộn với dữ liệu từ ScheduleLog, mỗi item trong log là một mục TKB ứng với 1 tiết
                //Trộn tiếp ở bước này để đảm bảo lấy đủ

                for (let i=0; i<scheduleLogItems.length; i++) {
                    const logItem = scheduleLogItems[i].fields;
                    const idx = freePeriods.findIndex(item => 
                        dayjs(item.schedule_date).isSame(dayjs(logItem.ScheduleDate),"d")
                        && item.period === logItem.Period
                        && item.part_day === logItem.PartDay
                    )
                    if (idx > -1) {
                        freePeriods[idx].subject_id = logItem.SubjectId;
                        freePeriods[idx].teacher_id = logItem.TeacherId;
                        freePeriods[idx].school_week = logItem.SchoolWeek;
                    } 
                }
                
                //2.5 Lọc tìm các tiết trống
                freePeriods = freePeriods.filter(item=>item.subject_id==="" && item.teacher_id==="");

                setFreePeriodItems(freePeriods);
                setIsProcessingScheduleData(false);
            })
        }

        const fetchData = (accessToken, actionValue) => {
            const {classItems, grade, firstDateOfWeekIsoString, schoolWeek, schoolYear} = actionValue;
            
            getSharePointListItems(
                accessToken, 
                sharePointConfig.ScheduleListId,
                "Title,ClassId,Grade,SchoolYear,SchoolWeek,Records,id", 
                classItems.length === 1 ?
                `((fields/ClassId eq '${classItems[0].ClassId}') and (fields/SchoolYear eq ${schoolYear}) and (fields/SchoolWeek eq ${schoolWeek}))`
                :
                grade !== '' ?
                `((fields/Grade eq ${grade}) and (fields/SchoolYear eq ${schoolYear}) and (fields/SchoolWeek eq ${schoolWeek}))`
                :
                `((fields/SchoolYear eq ${schoolYear}) and (fields/SchoolWeek eq ${schoolWeek}))`
                , ""
            ).then(response => {
                let rawData = response.value;  
                let scheduleItems = [];              
                if(rawData.length>0){                    
                    for(let i=0; i<rawData.length; i++){
                        const dataItem = rawData[i].fields;
                        let scheduleInfo = {
                            item_id: dataItem.id,
                            class_name: dataItem.Title,
                            class_id: dataItem.ClassId,
                            grade: dataItem.Grade,
                            school_week: dataItem.SchoolWeek,
                            school_year: dataItem.SchoolYear,
                            records: dataItem.Records.length === 0 
                            ? EmptyScheduleRecords(firstDateOfWeekIsoString, schoolWeek) 
                            : JSON.parse(dataItem.Records),
                            flagDataChanged: false,
                        }
                        scheduleItems.push(scheduleInfo);
                    };

                    let schedules = ProcessScheduleItemData(scheduleItems,classItems,grade, schoolYear, schoolWeek, firstDateOfWeekIsoString);
                    setSchedules(schedules); 
                    setIsProcessingScheduleData(false);
                    console.log('schedules data ready for show TKB',schedules);
                } else { //Lấy dữ liệu TKB import được từ file excel trong SPList ScheduleImported
                    getSharePointListItems(
                        accessToken, 
                        sharePointConfig.ScheduleImportedListId,
                        "Title,SchoolYear,ApplySchoolWeek,ScheduleP1,ScheduleP2,ScheduleP3,ScheduleP4,ScheduleP5", 
                        `((fields/SchoolYear eq ${schoolYear}) and (fields/ApplySchoolWeek le ${schoolWeek}))`,
                        "fields/ApplySchoolWeek desc",
                        1
                    ).then(response => {     
                        console.log('Mục dữ liệu TKB Import được', response); 
                        let schedules; 
                        const listItems =  response.value;
                        if (listItems.length > 0) {       
                            const dataItem = listItems[0].fields;
                            scheduleItems = scheduleItems.concat(JSON.parse(dataItem.ScheduleP1));
                            scheduleItems = scheduleItems.concat(JSON.parse(dataItem.ScheduleP2));
                            scheduleItems = scheduleItems.concat(JSON.parse(dataItem.ScheduleP3));
                            scheduleItems = scheduleItems.concat(JSON.parse(dataItem.ScheduleP4));
                            scheduleItems = scheduleItems.concat(JSON.parse(dataItem.ScheduleP5));
                            scheduleItems = scheduleItems.filter(item=>item.grade==grade);  

                            //Cập nhật lại dữ liệu TKB theo ngày tháng của tuần đang xem  
                            scheduleItems = UpdateDateForScheduleItems(scheduleItems, schoolYear, schoolWeek, dataItem.ApplySchoolWeek, firstDateOfWeekIsoString, true, true);                  
                            console.log('Dữ liệu TKB mẫu: ',scheduleItems);

                            schedules = ProcessScheduleItemData(scheduleItems,classItems,grade, schoolYear, schoolWeek, firstDateOfWeekIsoString);
                        } else { //không có dữ liệu TKB, để nhập tay
                            schedules = ProcessScheduleItemData(scheduleItems,classItems,grade, schoolYear, schoolWeek, firstDateOfWeekIsoString);
                        }
                        setSchedules(schedules); 
                        setIsProcessingScheduleData(false);
                        setFlagAutoSaveSchedule(1);
                        console.log('schedules data ready for show TKB',schedules);
                    });
                } 
                
            });       
        }

        const fetScheduleByImportedData = (accessToken, actionValue) => {
            const {classItems, grade, firstDateOfWeekIsoString, schoolWeek, schoolYear} = actionValue;
            //console.log(`Lay TKB tuan ${schoolWeek} nam ${schoolYear} ngay dau tuan ${firstDateOfWeekIsoString} ` );
            const lastDateOfWeekIsoString = dayjs(firstDateOfWeekIsoString).add(7,'day').toISOString();

            //console.log('lastDateOfWeekIsoString',lastDateOfWeekIsoString);
            //console.log('schoolWeek',schoolWeek);
            
            getSharePointListItems(
                accessToken, 
                sharePointConfig.ScheduleImportedListId,
                "Title,SchoolYear,ApplySchoolWeek,StartDateOfWeek,ScheduleP1,ScheduleP2,ScheduleP3,ScheduleP4,ScheduleP5", 
                `((fields/SchoolYear eq ${schoolYear}) and (fields/StartDateOfWeek lt '${lastDateOfWeekIsoString}'))`,
                "fields/StartDateOfWeek desc", 
                1
            ).then(response => {   
                let scheduleItems = [];   
                //console.log('Mục dữ liệu TKB Import được', response); 
                let schedules; 
                const listItems =  response.value;
                if (listItems.length > 0) {       
                    const dataItem = listItems[0].fields;
                    //console.log(`Muc TKB ApplySchoolWeek: ${dataItem.ApplySchoolWeek}`);
                    scheduleItems = scheduleItems.concat(JSON.parse(dataItem.ScheduleP1));
                    scheduleItems = scheduleItems.concat(JSON.parse(dataItem.ScheduleP2));
                    scheduleItems = scheduleItems.concat(JSON.parse(dataItem.ScheduleP3));
                    scheduleItems = scheduleItems.concat(JSON.parse(dataItem.ScheduleP4));
                    scheduleItems = scheduleItems.concat(JSON.parse(dataItem.ScheduleP5));
                    scheduleItems = scheduleItems.filter(item=>item.grade==grade);  //== để trường hợp một trong hai là giá trị số nhưng dạng string
                    
                    //Cập nhật lại dữ liệu TKB theo ngày tháng của tuần đang xem  
                    scheduleItems = UpdateDateForScheduleItems(scheduleItems, schoolYear, schoolWeek, dataItem.ApplySchoolWeek, firstDateOfWeekIsoString, true, true);                  
                    //console.log('Dữ liệu TKB mẫu: ',scheduleItems);

                    schedules = ProcessScheduleItemData(scheduleItems,classItems,grade, schoolYear, schoolWeek, firstDateOfWeekIsoString);
                } else { //không có dữ liệu TKB, để nhập tay
                    schedules = ProcessScheduleItemData(scheduleItems,classItems,grade, schoolYear, schoolWeek, firstDateOfWeekIsoString);
                }
                setSchedules(schedules); 
                setIsProcessingScheduleData(false);
                //console.log('schedules data ready for show TKB',schedules);
            });
      
        }

        const saveData = (accessToken, actionValue) => {
            console.warn('flagAutoSaveSchedule in saveData', flagAutoSaveSchedule);
            if ((flagAutoSaveSchedule-2)%4===0) {
                setStatusMessage(`${(flagAutoSaveSchedule-2)/4}`);
            }
            const localScheduleData = actionValue;
            let fieldValuesArrayToAdd = [];
            let fieldValuesArrayToUpdate = [];
            let itemIdArray = [];  //Chứa mảng giá trị ID của các mục dữ liệu đã tồn tại trong CSDL/SP list
            
            for(let i=0; i<localScheduleData.length; i++) {
                const dataItem = localScheduleData[i];
                let fieldValues = {};                
                if (parseInt(dataItem.item_id)>0) { //Dữ liệu đã có, chuẩn bị dữ liệu để cập nhật
                    itemIdArray.push(dataItem.item_id);
                    fieldValues = {
                        fields: {
                            ApplySchoolWeek: dataItem.apply_school_week,
                            Records: JSON.stringify(dataItem.records)
                        }
                    };
                    fieldValuesArrayToUpdate.push(fieldValues);
                } else { //Chưa có trong CSDL/SharePoint, chuẩn bị dữ liệu để thêm vào
                    fieldValues = {
                        fields: {
                            Title: dataItem.class_name,
                            ClassId: dataItem.class_id,
                            Grade: dataItem.grade,
                            SchoolYear: dataItem.school_year,
                            SchoolWeek: dataItem.school_week,
                            StartDateOfWeek: dataItem.start_date_week_isostring,
                            ApplySchoolWeek: dataItem.apply_school_week,
                            Records: JSON.stringify(dataItem.records)    
                        }
                    }; 
                    fieldValuesArrayToAdd.push(fieldValues);                
                }
            }

            console.log('fieldValuesArrayToUpdate',fieldValuesArrayToUpdate);
            console.log('fieldValuesArrayToAdd',fieldValuesArrayToAdd);

            if (fieldValuesArrayToAdd.length>0){
                addSharePointListItemBatch(accessToken, sharePointConfig.ScheduleListId, fieldValuesArrayToAdd)
                .then(response => {
                    console.log('Add New Item... ', response);
                    let hasError = false;
                    response.responses.forEach(r=>{
                        if (r.status !== 200 && r.status !== 201) {
                            hasError=true;
                            console.error('Lỗi ADD dữ liệu TKB (ScheduleData.saveData):', r)
                        }
                    });
                    if(hasError === false && fieldValuesArrayToUpdate.length <= 0){
                        localStorage.removeItem(scheduleConfig.SCHEDULE_LS_KEY);                        
                        setIsProcessingScheduleData(false);
                        setFlagAutoSaveSchedule(flagAutoSaveSchedule>1 ? flagAutoSaveSchedule+1 : 0);                        
                    } 
                    fieldValuesArrayToAdd = [];     
                });
            };

            if (fieldValuesArrayToUpdate.length>0) {
                updateSharePointItemBatch(accessToken, sharePointConfig.ScheduleListId, itemIdArray, fieldValuesArrayToUpdate)
                    .then(response => {
                        let hasError = false;
                        response.responses.forEach(r=>{
                            if (r.status !== 200 && r.status !== 201) {
                                hasError=true;
                                console.error('Lỗi UPDATE dữ liệu TKB (ScheduleData.saveData):', r);                                
                            }
                        });

                        if(hasError === false && fieldValuesArrayToAdd.length <= 0){
                            localStorage.removeItem(scheduleConfig.SCHEDULE_LS_KEY);
                            setIsProcessingScheduleData(false);  
                            setFlagAutoSaveSchedule(flagAutoSaveSchedule>1 ? flagAutoSaveSchedule+1 : 0);    
                        }
                         
                        fieldValuesArrayToUpdate=[];  
                });                    
            };
        }

        const importData = (accessToken, actionValue) => {
            const scheduleDataItem = actionValue;
            getSharePointListItems(
                accessToken, 
                sharePointConfig.ScheduleImportedListId,
                "id", 
                `((fields/SchoolYear eq ${scheduleDataItem.school_year}) and (fields/ApplySchoolWeek eq ${scheduleDataItem.apply_school_week}))`
                , ""
            ).then(response => {   
                const listItems = response.value;
                let fieldValues = {};
                if (listItems.length > 0 ) { //Dữ liệu đã có, chuẩn bị dữ liệu để cập nhật
                    console.log('cập nhật dữ liệu đã có...');
                    fieldValues = {
                        fields: {
                            ScheduleP1: JSON.stringify(scheduleDataItem.schedule_1),
                            ScheduleP2: JSON.stringify(scheduleDataItem.schedule_2),
                            ScheduleP3: JSON.stringify(scheduleDataItem.schedule_3),
                        }
                    };
                    updateSharePointItem(accessToken, sharePointConfig.ScheduleImportedListId, listItems[0].id, fieldValues)
                    .then(response => {
                        if(response.error) {
                            const error = response.error;
                            setIsProcessingScheduleData(false);
                            setErrorMessage(`Lỗi khi import dữ liệu - ${error.code}:  ${error.message} (ScheduleData.importData)`);
                            console.error('Lỗi ghi dữ liệu: ' , error);
                        } else {
                            const existingItemId = response.id;
                            fieldValues = {
                                fields: {
                                    ScheduleP4: JSON.stringify(scheduleDataItem.schedule_4),
                                    ScheduleP5: JSON.stringify(scheduleDataItem.schedule_5),
                                }
                            };
                            updateSharePointItem(accessToken, sharePointConfig.ScheduleImportedListId, existingItemId, fieldValues)
                            .then(response => {
                                if(response.error) {
                                    const error = response.error;
                                    setErrorMessage(`Lỗi khi import dữ liệu - ${error.code}:  ${error.message} (ScheduleData.importData)`);
                                    console.error('Lỗi ghi dữ liệu: ' , error);
                                } else {
                                    setErrorMessage("");
                                    //setFlagAutoSaveSchedule(2);
                                }
                                setIsProcessingScheduleData(false);
                            });
                        }
                        
                    });
                } else { //Chưa có trong CSDL/SharePoint, chuẩn bị dữ liệu để thêm vào
                    console.log('thêm mới dữ liệu...');
                    fieldValues = {
                        fields: {
                            Title: scheduleDataItem.title,
                            SchoolYear: scheduleDataItem.school_year,
                            ApplySchoolWeek: scheduleDataItem.apply_school_week,
                            StartDateOfWeek: scheduleDataItem.start_date_week_isostring,
                            ScheduleP1: JSON.stringify(scheduleDataItem.schedule_1),
                            ScheduleP2: JSON.stringify(scheduleDataItem.schedule_2),
                            ScheduleP3: JSON.stringify(scheduleDataItem.schedule_3),
                        }
                    }; 
                    addSharePointListItem(accessToken, sharePointConfig.ScheduleImportedListId, fieldValues)
                    .then(response => {
                        if(response.error) {
                            const error = response.error;                        
                            setIsProcessingScheduleData(false);
                            setErrorMessage(`Lỗi khi import dữ liệu - ${error.code}:  ${error.message} (ScheduleData.importData)`);
                            console.error('Lỗi ghi dữ liệu: ' , error);
                        } else {
                            const addedItemId = response.id;
                            fieldValues = {
                                fields: {
                                    ScheduleP4: JSON.stringify(scheduleDataItem.schedule_4),
                                    ScheduleP5: JSON.stringify(scheduleDataItem.schedule_5),
                                }
                            };
                            updateSharePointItem(accessToken, sharePointConfig.ScheduleImportedListId, addedItemId, fieldValues)
                            .then(response => {
                                if(response.error) {
                                    const error = response.error;
                                    setErrorMessage(`Lỗi khi import dữ liệu - ${error.code}:  ${error.message} (ScheduleData.importData)`);
                                    console.error('Lỗi ghi dữ liệu: ' , error);

                                    deleteSharePointItem(accessToken,sharePointConfig.ScheduleImportedListId, addedItemId)
                                    .then(response =>{
                                        //console.log('Đã dọn dẹp dữ liệu dở dang. Hoàn tác xong', response);
                                    });
                                } else {
                                    //console.log('Đã hoàn tất việc import dữ liệu!');
                                    //setFlagAutoSaveSchedule(2);
                                    setErrorMessage("");
                                }
                                setIsProcessingScheduleData(false);
                            });
                        }
                    });                

                }
            });
        }

        const processData = async () => {
            instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
                if (action===undefined || action===null) return;
                const accessToken = accessTokenResponse.accessToken;    
                         
                switch(action.type.toLocaleLowerCase()){
                    case 'save':
                        setIsProcessingScheduleData(true)
                        saveData(accessToken, action.value);
                        break;
                    case 'import':
                        setIsProcessingScheduleData(true)
                        importData(accessToken, action.value);
                        break;    
                    case 'saveafterimport':
                        setIsProcessingScheduleData(true);
                        saveData(accessToken, action.value.scheduleItems);
                        break;
                    case 'fetchfreeperiods':
                        setIsProcessingScheduleData(true);
                        //fetchFreePeriods(accessToken, action.value);
                        fetchFreePeriodsByImportedSchedule(accessToken, action.value);
                        break;
                    default:
                        if (action.value!==undefined && action.value!==null) {                             
                            setIsProcessingScheduleData(true);   
                            setFlagAutoSaveSchedule(0);            
                            //fetchData(accessToken, action.value);
                            fetScheduleByImportedData(accessToken, action.value);
                        }
                }
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest);                    
                }
                setIsProcessingScheduleData(false);
                console.error(error);
            }) 
        }; 
        processData();  

    },[accounts, action, instance]);

    return [{schedules, freePeriodItems, isProcessingScheduleData, flagAutoSaveSchedule, statusMessage, errorMessage}, setScheduleAction]

}

