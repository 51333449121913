import React, {useEffect, useState} from 'react';
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";

import {callSPGraph} from './graph';
import { loginRequest  } from './authConfig';

export function ProtectedComponent() {
    const {instance, inProgress, accounts} = useMsal();
    const [apiData, setApiData] = useState(null);


    useEffect(() => {
        const accessTokenRequest = {
            ...loginRequest,
            account: accounts[0]
        }
        if (!apiData && inProgress === InteractionStatus.None) {
            instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
                let accessToken = accessTokenResponse.accessToken;
                callSPGraph(accessToken).then((response) => {setApiData(response.value); console.log(response.value)});
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest);
                }
                console.log(error);
            })
        }
    }, [instance, accounts, inProgress, apiData]);

    return (
         apiData ? <DisplayData data = {apiData}/>:<p>No data available</p>
    )
}

export const DisplayData = (props) => {
    return(
        <ul>{
            props.data.map((obj,idx) => 
                <li key={idx}>{obj.fields.Title}</li>
            )}
        </ul>    
    )
}

