import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import * as isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear'; 
import * as isLeapYear from 'dayjs/plugin/isLeapYear';
//import * as isBetween from 'dayjs/plugin/isBetween';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { schoolConfig } from './appConfig';

export const getCurrentDateInfo = () => {
    dayjs.extend(isoWeek);
    dayjs.extend(isoWeeksInYear);    
    dayjs.extend(isLeapYear);  

    return {
        FirstDateOfWeekIsoString: dayjs(dayjs().format("MM/DD/YYYY")).isoWeekday(1).toISOString(), //Ngày đầu tuần của tuần hiện tại
        FirstDateOfNextWeekIsoString: dayjs(dayjs().add(1,"week")).isoWeekday(1).toISOString(), //Ngày đầu tuần của tuần tiep theo
    }
}

//Return current school year base on current date
export const getSchoolYear = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); //Month number is 0 to 11
    const currentYear = currentDate.getFullYear();
    return currentMonth > (schoolConfig.StartMonth-2) ? currentYear : currentYear - 1; //Always is start year
}

// ==================================================================================================
//  getSchoolWeek
//  Xác định và trả về tuần học ứng với ngày truyền vào, mặc định ngày truyền vào là ngày hiện tại
// ==================================================================================================
export const getSchoolWeek = (schoolYearObject, dateStringToCheck = new Date().toDateString()) => {
    //dayjs.extend(isBetween);
    dayjs.extend(isSameOrAfter);
    dayjs.extend(isSameOrBefore);

    const schoolWeekArray = schoolYearObject.Note.sem1.concat(schoolYearObject.Note.sem2);
    let schoolWeek=1;
    const totalWeeks = schoolWeekArray.length;
    const dateToCheck = dayjs(dateStringToCheck);
    for(let i=0; i<totalWeeks; i++ ){
        const startDate = dayjs(schoolWeekArray[i].start);
        const endDate = dayjs(schoolWeekArray[i].end).add(1,'day'); //+ them ngay chu nhat
        if(dateToCheck.isSameOrAfter(startDate,'day') && dateToCheck.isSameOrBefore(endDate,'day')) {
            schoolWeek = schoolWeekArray[i].week;
            break;
        }
    }
    return schoolWeek;
}

export const getMonthInfo = (inputDate) => {
    const fullYear = inputDate.getFullYear();
    const theMonth = inputDate.getMonth();
    return {
        FirstDayOfMonth: (new Date(fullYear, theMonth, 1)),
        LastDayOfMonth: (new Date(fullYear, theMonth + 1, 1))
    }
}

//==============================================================
//Lấy danh sách các tuần học dựa vào các tham số đầu vào
//==============================================================
//- startDate:  Ngày bắt đầu của một học kỳ và là ngày đầu tuần
//- numOfWeeks: Số tuần cần lấy
//==============================================================
export const getSchoolWeeks =  (startDate, numOfWeeks, startWeekIndex) => {
    var weeks = [];
    var startDateOfWeek = new Date(startDate);
    var lastDateOfWeek  = new Date(startDate);
    for(var w=0; w<numOfWeeks; w++){
        lastDateOfWeek.setDate(lastDateOfWeek.getDate()+ 5);
        weeks.push({
            "week": w+startWeekIndex,
            "start": startDateOfWeek.toISOString(),
            "end": lastDateOfWeek.toISOString()
        })

        startDateOfWeek = new Date(startDateOfWeek);
        startDateOfWeek.setDate(startDateOfWeek.getDate()+7);
        lastDateOfWeek = new Date(startDateOfWeek);
    }
    // console.log("weeks",weeks);
    return weeks;
}
