//  This component using ag-grid.com
//  npm install @inovua/reactdatagrid-community --save
//  npm install moment --save  (https://momentjs.com/)

//===========================================================================================================================
import React,  { useState } from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'

import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter'
import DateFilter from '@inovua/reactdatagrid-community/DateFilter'
import moment from 'moment';

const gridStyle = { minHeight: 550, marginTop: 5 };

function FileList({fileItems, documentTypeItems}){
    window.moment = moment;
    const [enableFiltering, setEnableFiltering] = useState(false);
       
    // let fileItemsWithName = fileItems;
    // fileItemsWithName = fileItemsWithName.map(item=>{
    //     return {...item, 
    //             DocTypeName : documentTypeItems.find(element=>element.id===item.DocTypeId)?.label,
    //             DepartmentName : departmentItems.find(element=>element.DepartmentId===item.DepartmentId)?.Title,
    //             TeacherName: currentTeacher.FullName,
    //     }
    // });    

    const filterValue = [
        { name: 'Title', operator: 'startsWith', type: 'string', value: '' },
        { name: 'DocTypeId', operator: 'eq', type: 'select',value: '2d8b9982-8a59-4c9f-afda-759a71dfc495'},
        { name: 'DepartmentName', operator: 'startsWith', type: 'string', value: '' },
        // { name: 'TeacherName', operator: 'startsWith', type: 'string', value: '' },  
        // { name: 'CreatedDateTime',
        //   operator: 'before',
        //   type: 'date',
        //   value: ''
        // },
      ];


    const columns = [
        { name: 'id', header: 'ID', type: 'number', maxWidth: 40,  defaultVisible: false },
        { name: 'Title', header: 'Tên tệp', defaultFlex: 2, },
        { name: 'DocTypeId', header: 'Loại tài liệu', defaultFlex: 2,
          filterEditor: SelectFilter,
          filterEditorProps: {
            placeholder: 'Tất cả',
            dataSource: documentTypeItems
          },
          render: ({data}) => data.DocTypeName
        },
        { name: 'DepartmentName', header: 'Tổ', defaultFlex: 1 },
        { name: 'TeacherName', header:'Giáo viên', defaultFlex:1},
        { name: 'CreatedDateTime', header: 'Ngày tạo', defaultFlex:1, 
        //   filterEditor: DateFilter,
        //   filterEditorProps: (props, {index}) => {
        //     return {
        //         dateFormat: 'MM-DD-YYYY',
        //         cancelButton: false,
        //         highlightWeekends: false,
        //         placeholder: index === 1 ? "Trước ngày...": "Sau ngày..."
        //     }
        //   },
          render: ({ value }) => moment(value).format('DD-MM-YYYY hh:mm:ss')
        }
      ];

     const renderRowContextMenu = (menuProps, { rowProps, cellProps }) => {
        menuProps.autoDismiss = true;
        menuProps.items = [
            {
                label: 'Xóa tệp ' + rowProps.data.Title
            },
            {
                label: 'Sửa thuộc tính' // + cellProps.id 
            }
        ];
    }

    console.log('fileItems', fileItems);
   
    return (
        <div className="mt-3">
            <p className="text-end">
                <input type="checkbox" checked = {enableFiltering} onChange={e=>setEnableFiltering(e.target.checked)}/>
                <span className="ms-1">Bật chế độ lọc</span>
            </p>
            <ReactDataGrid
                idProperty="id"
                style={gridStyle}
                renderRowContextMenu={renderRowContextMenu}
                defaultFilterValue={filterValue}
                enableFiltering={enableFiltering}
                columns={columns}
                pagination
                dataSource={fileItems}
                defaultLimit={10}
            />
      </div>
    );
}
export default FileList;