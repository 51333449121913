
import dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

const WeeklyCalendarViewItem_Mb = ({item}) => { dayjs.extend(isoWeek);
    dayjs.extend(isoWeek);
    console.log(item);
    return (
        <div className="card mb-1">
            <div className="card-body">
                <h6 className="card-title">                
                    <b>{dayjs(item.CalendarDate).locale('vi').format('dddd')}</b>  &nbsp; {dayjs(item.CalendarDate).format('DD/MM/YYYY')}
                </h6>
                <div className="card-text">
                    <ul style={{"paddingLeft":"20px", "marginBottom":"1px"}}>
                        {item.MorningItems.map((item) => 
                            <li key={item.id+"cvs"} style={{"paddingBottom":"2px"}}>
                                {item.StartTime}- <b>{item.Title}</b>
                                {item.Location && <><br/><i>Địa điểm: {item.Location}</i></>}
                                {item.Responder && <><br/><i>Thực hiện: {item.Responder}</i></>}
                            </li>
                        )}
                        {item.AfternoolItems.map((item) => 
                            <li key={item.id+"cvc"} style={{"paddingBottom":"2px"}}>
                                {item.StartTime}- <b>{item.Title}</b>
                                {item.Location && <><br/><i>Địa điểm: {item.Location}</i></>}
                                {item.Responder && <><br/><i>Thực hiện: {item.Responder}</i></>}
                            </li>
                        )}                        
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default WeeklyCalendarViewItem_Mb;