import React from 'react';
import * as dayjs from 'dayjs';
import vi from 'dayjs/locale/vi'

import ScheduleItemDialog from './ScheduleItemDialog';

const ScheduleItem = ({scheduleItem, subjects, showForTeacherId, teachers, firstItemIndex, lastItemIndex, onSaveCellClick}) => {
    // console.log('scheduleItem',scheduleItem);
    const getSubjectTitle = (subjectId) => {
        const idx = subjects.findIndex(subject=>subject.SubjectId===subjectId);
        return idx >= 0 ? subjects[idx].AliasName : "";    
    }

    const getTeacherName = (teacherId) => {
        const idx = teachers.findIndex(teacher=>teacher.TeacherId===teacherId);
        return idx >= 0 ? teachers[idx].Title: "";
    }
    
    return (
        <tr style={scheduleItem.period===1 ? {"fontSize":"0.8em", "backgroundColor":"#e9ecef"} : {"fontSize":"0.8em"}}>
            {
                scheduleItem.period === 1 && 
                <td rowSpan='5' className='text-center align-middle' style={{'backgroundColor':'#f8f9fa'}}>
                    <span className='fw-bold fs-5 p-0'>{parseInt(dayjs(scheduleItem.schedule_date).format("d"))+1}</span>
                    <br/><br/>
                    {dayjs(scheduleItem.schedule_date).format("DD/MM")}
                </td>
            }          
            <td className="text-center align-middle p-0">{scheduleItem.period}</td>
            {
                scheduleItem.class_subjects.map((s, index) => {
                    if(index>=firstItemIndex &&  index<=lastItemIndex){                        
                        let subjectNameAM = s.morning.subject_id !== "" ? getSubjectTitle(s.morning.subject_id) : "";  
                        const teacherNameAM = s.morning.teacher_id !== "" ? getTeacherName(s.morning.teacher_id) : "";                        
                        let missingTeacherAM = false;
                        let boldOrNotFontAM = 'fw-bold';

                        if((teacherNameAM === "" && subjectNameAM === "")){
                            subjectNameAM =".....";
                            boldOrNotFontAM = 'fw-normal fw-lighter';
                        }
                        else{
                            if (teacherNameAM==="") missingTeacherAM = true;
                        } 

                        let subjectNamePM = s.afternoon.subject_id !== "" ? getSubjectTitle(s.afternoon.subject_id) : "";                        
                        let teacherNamePM = s.afternoon.teacher_id !== "" ? getTeacherName(s.afternoon.teacher_id) : "";
                        
                        let missingTeacherPM = false;
                        let boldOrNotFontPM = 'fw-bold';
                        if(teacherNamePM === "" && subjectNamePM === ""){
                            subjectNamePM =".....";
                            boldOrNotFontPM = 'fw-normal fw-lighter';                            
                        }
                        else {
                            if (teacherNamePM==="") missingTeacherPM = true;
                        } 

                        const dlgId_AM = `dlg${s.morning.id}`;  
                        const dlgId_PM = `dlg${s.afternoon.id}`;  

                        const showScheduleItem = showForTeacherId==="" 
                            ? true
                            : (s.morning.teacher_id===showForTeacherId || s.afternoon.teacher_id===showForTeacherId) ? true : false;
                        
                        return <>
                            <td className='text-center fs-6 p-0' key={s.morning.id}>
                                {
                                    s.class_name!=='NA' && showScheduleItem &&
                                    <>
                                        <a className={subjectNameAM==='' ? 'text-decoration-none d-print-none align-middle': 'text-decoration-none align-middle'} 
                                            style={missingTeacherAM===true?{"fontSize":"0.7em", "color":"red"}:{"fontSize":"0.7em", "color":"black"}}
                                            href={`#${dlgId_AM}`} data-bs-toggle="modal" 
                                            data-bs-target={`#${dlgId_AM}`} 
                                            ><span className={boldOrNotFontAM} style={{"fontSize":"0.95em"}}>{subjectNameAM}</span> 
                                             {teacherNameAM!=="" && <span style={{"fontSize":"0.95em"}}> - {teacherNameAM}</span>}
                                        </a>
                                        <ScheduleItemDialog 
                                            dialogId = {dlgId_AM}
                                            title={`Lớp ${s.class_name} - Sáng ${dayjs(scheduleItem.schedule_date).locale('vi').format('dddd, DD/MM/YYYY')} - Tiết ${scheduleItem.period}`}
                                            action="edit"
                                            scheduleCell={s.morning} 
                                            scheduleRowId = {scheduleItem.row_id}
                                            classId = {s.class_id}
                                            partDay='Sáng'
                                            subjects={subjects}
                                            teachers={teachers}
                                            onSaveCellClick = {onSaveCellClick}
                                        />
                                    </> 
                                }                                

                            </td>
                            <td className='text-center align-top fs-6 p-0' key={s.afternoon.id}>   
                                {   s.class_name!=='NA' && showScheduleItem &&
                                    <>
                                        <a className={subjectNamePM==='' ? 'text-decoration-none d-print-none': 'text-decoration-none'}
                                            style={missingTeacherPM===true?{"fontSize":"0.7em", "color":"red"}:{"fontSize":"0.7em","color":"black"}}
                                            href={`#${dlgId_PM}`} data-bs-toggle="modal" 
                                            data-bs-target={`#${dlgId_PM}`} 
                                        >
                                            <span className={boldOrNotFontPM} style={{"fontSize":"0.95em"}}>{subjectNamePM}</span> 
                                            {teacherNamePM!=="" && <span style={{"fontSize":"0.95em"}}> - {teacherNamePM}</span>}
                                        </a>
                                        <ScheduleItemDialog
                                            dialogId = {dlgId_PM}
                                            title={`${s.class_name} - Chiều ${dayjs(scheduleItem.schedule_date).locale('vi').format('dddd, DD/MM/YYYY')} - Tiết ${scheduleItem.period}`}
                                            action="edit"
                                            scheduleCell={s.afternoon} 
                                            scheduleRowId = {scheduleItem.row_id}
                                            classId = {s.class_id}
                                            partDay='Chiều'
                                            subjects={subjects}
                                            teachers={teachers}
                                            onSaveCellClick = {onSaveCellClick}
                                        />
                                    </>
                                }
                            </td>
                        </>
                    } else {
                        return null
                    }                    
                })
            }
        </tr>
    )
}

export default ScheduleItem;