import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
//import { registerLocale } from  "react-datepicker";
//import vi from 'date-fns/locale/vi';

function YearPicker(props){
    //registerLocale('vi', vi);
    const [selectedDate, setSelectedDate] = useState(props.selectedDate);
    return (
        <DatePicker  className='form-control form-control-sm'
            selected={selectedDate}
            dateFormat= "yyyy"
            showYearPicker
            onChange={(date)=> {
                setSelectedDate(date);
                props.onDateChange(date);
            }}>        
        </DatePicker>
    )
}
export default YearPicker;