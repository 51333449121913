import React from "react";
import NewsBoardList  from "./NewsboardList";
import {createBlankNewsItem, useNewsBoardDataApi} from './NewsboardData';

function NewsBoardPage() { 
    const[{newsItems}, setAction] = useNewsBoardDataApi();

    // const handleAdd = () => {
    //     let blankItem = createBlankNewsItem();
    //     setAction({
    //         type:'add',
    //         value: blankItem
    //     });
    // }

    const handleSave = (item) => {
        setAction({
            type:'save',
            value: item
        });
    }

    const handleDelete = (item) =>{
        setAction({
            type:'del',
            value: item
        });
    }

    return (
        <>
            <div className="row">
                <h4 className="pt-3">Bản tin nội bộ</h4>
            </div>
            <div className="row">
                <NewsBoardList 
                    data={newsItems}
                    //onAddItemClick = {handleAdd}
                    onSaveItemClick = {handleSave}
                    onDeleteItemClick = {handleDelete}
                />
            </div>
        </>
    )
}

export default NewsBoardPage;