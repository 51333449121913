import  {useEffect, useState} from 'react';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { v4 as uuidv4 } from 'uuid';
import {orderBy as _orderBy, remove} from 'lodash';

import { loginRequest, sharePointConfig } from '../../authConfig';
import {
    getSharePointListItems,
    getSharePointListItemsBatch,
    updateSharePointItem,
    addSharePointListItem,
    deleteSharePointItem
} from '../../graph';


export const createBlankDataItem = () =>{
    return [
        {
            ItemId: uuidv4(),  //Sau này có thể ứng với DepartmentId, TitleId,....
            Title:'',
            ShortName: '',
            Note:'',
        }
    ]
}

export const useCategoryDataApi = (categoryNameArray = []) => {
    const [departmentItems, setDepartmentItems] = useState([]);
    const [titleItems, setTitleItems] = useState([]);
    const [subjectItems, setSubjectItems] = useState([]);

    const [action, setAction] = useState({type:'', value: categoryNameArray});

    const {instance, accounts} = useMsal();

    const fetchData = (accessToken, categoryName) => {
        if(categoryNameArray.length === 0) {
            return;
        }

        let selectFields = [];
        let spListIds= [];
        let requestIds = [];
        for (let c=0; c < categoryNameArray.length; c++) {
            const catName = categoryNameArray[c].toLowerCase();
            requestIds.push(catName);
            switch(catName) {
                case 'department':
                    selectFields.push("Title,DepartmentId,ShortName,Note,id");
                    spListIds.push(sharePointConfig.DepartmentListId);                    
                    break;
                case 'titlelist':
                    selectFields.push("Title,TitleId,ShortName,Note,id");
                    spListIds.push(sharePointConfig.TitleListId);
                    break;
                case 'subjects':
                    selectFields.push("SubjectId,Title,Category,IsActive, id");
                    spListIds.push(sharePointConfig.SubjectListId);
                    break;
                default:
                    console.warn(`Giá trị ${catName} không nằm trong danh sách được hỗ trợ (department, titlelist, subjects)`)
                    return;      
            } 
        }

        getSharePointListItemsBatch(accessToken, requestIds, spListIds, selectFields).then(response => { 
            response.responses.forEach(r=> {
                if (r.status===200){
                    //console.log(r.body.value);
                    let items = [];
                    const listItemArray = r.body.value;
                    for (let i = 0; i < listItemArray.length; i++){
                        items.push(listItemArray[i].fields);
                    }
                    items =_orderBy(items,['Title'], ['asc']);
                    switch (r.id) {
                        case "department":
                            setDepartmentItems(items);
                            break;
                        case "titlelist":
                            setTitleItems(items);
                            break;
                        case "subjects":
                            setSubjectItems(items);
                            break;
                        default:
                    }
                }
                else
                    console.log('lỗi: ', r.body.error);
            })
            //console.log(response);
        })
    }

    const saveItem = (accessToken, item) => {
        getSharePointListItems(
            accessToken, 
            sharePointConfig.ClassesListId,
            "ClassId", 
            `(fields/ClassId eq '${item.ClassId}')`
        ).then(response => {
            let fieldValues = {};
            const listItems = response.value
            if(listItems.length>0){ //Nếu đã có thì cập nhật nội dung 
                fieldValues = {
                    fields: {
                        Title: item.Title,
                        Grade: item.Grade,
                        StartYear: item.StartYear,
                        EndYear: item.StartYear*1+1,
                        Students: item.Students,
                        HeadTeacher: item.HeadTeacher,
                        Note: item.Note,
                        IsActive: item.IsActive==='true' || item.IsActive===true ? true:false
                    }
                };  
                updateSharePointItem(accessToken, sharePointConfig.ClassesListId, listItems[0].id, fieldValues)
                    .then(response => {
                        //console.log(response)
                    });
            }
            else{ //Nếu chưa có thì thêm vào 
                fieldValues = {
                    fields: {
                        ClassId: item.ClassId,
                        Title: item.Title,
                        Grade: item.Grade,
                        StartYear: item.StartYear,
                        EndYear: item.EndYear,
                        Students: item.Students,
                        HeadTeacher: item.HeadTeacher,
                        Note: item.Note,
                        IsActive: item.IsActive==='true' || item.IsActive===true ? true:false
                    }
                };
                addSharePointListItem(accessToken, sharePointConfig.ClassesListId, fieldValues)
                    .then(response=>{
                        //console.log(response)
                    });
            }
        }) 
    };

    const deleteItem = (accessToken, item) => {
        let items = Array.from(departmentItems);
        remove(items, i=>i.ClassId===item.ClassId);

        //Xóa phía server nếu item này đã tồn tại trên server dựa vào SharePoint List Item ID field
        if(item.id){
            deleteSharePointItem(accessToken,sharePointConfig.ClassesListId,item.id).then(response =>{
                console.log('Completed delete item ' + item.ClassId);
                console.log(response);
            });
        }
        setDepartmentItems(items);
    }

    const addBlankItem = (item) => {
        setDepartmentItems(item.concat(departmentItems));
    };

    useEffect(() => {     
        const accessTokenRequest = {
            ...loginRequest,
            account: accounts[0]
        }

        const processData = async () => {  
            instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
                const accessToken = accessTokenResponse.accessToken;
                switch(action.type){
                    case 'add':
                        addBlankItem(action.value);
                        break;
                    case 'save':
                        saveItem(accessToken, action.value);
                        break;
                    case 'del':
                        deleteItem(accessToken, action.value);
                        break;
                    default:                        
                        fetchData(accessToken, action.value);
                }
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest);                    
                }
                console.log(error);
            }) 
        }; 

        processData();  

    },[action]);

    return [{departmentItems, titleItems, subjectItems},  setAction];
}




