import React, { useState } from "react";
import Multiselect from "multiselect-react-dropdown";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import vi from 'date-fns/locale/vi';
import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

const WorkPlanItem = (props) => {
    registerLocale('vi', vi);
    dayjs.extend(isoWeek);

    const [item, setItem] = useState(props.item);
    const {teacherList, departmentItems, selectedDepartmentId} = props;
    const [beforeEditItem, setBeforeEditItem] = useState({...props.item});
    const [mode, setMode] = useState(props.mode);

    const handleEdit =()=>{
        setBeforeEditItem({...item});
        setMode('edit');
    }

    const handleSave = ()=>{
        props.onSaveItemClick(item);
        setMode('');
    }

    const handleCancel = ()=>{
        if(mode==='new'){
            handleDelete(item.WorkingPlanId);
        }else{
            setItem(beforeEditItem);
        }
        setMode('');
    }

    const handleDelete = ()=>
    {
        if (mode!=='edit' && mode !=='new') {  
            if ( window.confirm(`Bạn chắc chắn xóa mục công tác ${item.Title} không?`)) {
                props.onDeleteItemClick(item); 
            }            
        } else {
            props.onDeleteItemClick(item); 
        }  
    }

    // const handleAssignedToSelect = (selectedList, selectedValue) => {
    //     let selectedTeacherIdsArray = [];
    //     if(selectedValue.TeacherId==="All"){
    //         for (let i=0; i<teacherList.length; i++) {
    //             selectedTeacherIdsArray.push(teacherList[i].TeacherId);
    //         }            
    //     }else {
    //         for (let i=0; i<selectedList.length; i++) {
    //             selectedTeacherIdsArray.push(selectedList[i].TeacherId);
    //         }
    //     }
    //     setItem({
    //         ...item,
    //         AssignedTo: selectedTeacherIdsArray
    //     })
    // }

    // const handleAssignedToRemove = (selectedList, removedValue) => {
    //     let selectedTeacherIdsArray = [];
    //     if(removedValue.TeacherId !== "All"){
    //         for (let i=0; i<selectedList.length; i++) {
    //             if(selectedList[i].TeacherId !=='All')
    //                 selectedTeacherIdsArray.push(selectedList[i].TeacherId);
    //         }
    //     }
    //     setItem({
    //         ...item,
    //         AssignedTo: selectedTeacherIdsArray
    //     })
    // }

    const handleSelectTeacher = (selectedList, selectedValue, fieldName) =>{
        let selectedTeacherIdsArray = [];
        if(selectedValue.TeacherId==="All"){
            for (let i=0; i<teacherList.length; i++) {
                selectedTeacherIdsArray.push(teacherList[i].TeacherId);
            }            
        }else {
            for (let i=0; i<selectedList.length; i++) {
                selectedTeacherIdsArray.push(selectedList[i].TeacherId);
            }
        }
        if(fieldName==="AssignedTo"){
            setItem({
                ...item,
                AssignedTo: selectedTeacherIdsArray
            })
        } else{
            setItem({
                ...item,
                Leader: selectedTeacherIdsArray
            })     
        } 
    }

    const handleUnselectTeacher = (selectedList, removedValue, fieldName) =>{
        let selectedTeacherIdsArray = [];
        if(removedValue.TeacherId !== "All"){
            for (let i=0; i<selectedList.length; i++) {
                if(selectedList[i].TeacherId !=='All')
                    selectedTeacherIdsArray.push(selectedList[i].TeacherId);
            }
        }
        if(fieldName==="AssignedTo"){
            setItem({
                ...item,
                AssignedTo: selectedTeacherIdsArray
            })
        } else{
            setItem({
                ...item,
                Leader: selectedTeacherIdsArray
            })     
        }   
    }

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.type==='checkbox'?target.checked:target.value;
        setItem({
            ...item,
            [name]: value
        });
    }

    const getTeacherNamePreSelectedValues = (selectedTeacherIdArray) => {
        const selectedValues = [];
        for (let i=0; i<selectedTeacherIdArray.length; i++) {
            const idx = teacherList.findIndex(item=>item.TeacherId === selectedTeacherIdArray[i]);
            if (idx >=0) selectedValues.push(teacherList[idx]);            
        }
        return selectedValues;
    }

    const getTeacherNames = (selectedTeacherIdArray) => {
        let teacherNames ="";
        for (let i=0; i<selectedTeacherIdArray.length; i++) {
            const idx = teacherList.findIndex(item=>item.TeacherId === selectedTeacherIdArray[i]);
            if (idx>=0) teacherNames = teacherNames +  teacherList[idx].Title + ", ";           
        }   
        const len = teacherNames.length; 
        return len<=0? "" : teacherNames.substring(0,len-2);
    }
    
    const getTeacherName = (teacherId) => {
        const teacherObj = teacherList.find(item=>item.TeacherId===teacherId);
        return teacherObj?.Title;
    }

    const getDepartmentName = (departmentId) => {
        const departmentObj = departmentItems.find(item=>item.DepartmentId===departmentId);
        return departmentObj?.Title;
    }

    const rowElement = (mode==='edit' || mode==='new') 
    ? <tr>
        <td>{props.index+1}</td>
        <td>
            <input type='text' name='Title' style={{'width':'100%'}} 
                   className="form-control form-control-sm"
                   value={item.Title} onChange={(e)=>handleChange(e)} />
        </td>
        <td>
            <input type='text' name='Result' style={{'width':'100%'}} 
                    className="form-control form-control-sm"
                    value={item.Result} onChange={(e)=>handleChange(e)} />
        </td>
        <td>
            <DatePicker name="StartDate" className="form-control form-control-sm"
                locale='vi' dateFormat="dd/MM/yyyy" 
                selected={item.StartDate}                       
                onChange={(d)=>{setItem({
                    ...item,
                    StartDate: d})}
                }>
            </DatePicker>       
        </td>
        <td>
            <DatePicker name="EndDate" className="form-control form-control-sm"
                locale='vi' dateFormat="dd/MM/yyyy" 
                selected={item.EndDate}                       
                onChange={(d)=>{setItem({
                    ...item,
                    EndDate: d})}
                }>
            </DatePicker>       
        </td>        
        <td>
            <DatePicker name="CompletedDate" className="form-control form-control-sm"
                locale='vi' dateFormat="dd/MM/yyyy" 
                selected={dayjs(item.CompletedDate).format('MM/DD/YYYY')===dayjs(new Date(9999,11,31)).format('MM/DD/YYYY') ?null: item.CompletedDate}                       
                onChange={(d)=>{setItem({
                    ...item,
                    CompletedDate: d})}
                }>
            </DatePicker>                 
        </td>
        <td>
            <Multiselect 
                options={teacherList} className="form-control form-control-sm"
                selectedValues={getTeacherNamePreSelectedValues(item.AssignedTo)}
                // onSelect={handleAssignedToSelect}
                // onRemove={handleAssignedToRemove}
                onSelect={(selectedList, selectedValue)=> handleSelectTeacher(selectedList, selectedValue,"AssignedTo")}
                onRemove={(selectedList, removedValue)=> handleUnselectTeacher(selectedList, removedValue,"AssignedTo")}
                showCheckbox={true}
                placeholder="Người thực hiện"
                displayValue="Title"/>
        </td>
        <td>
            <Multiselect 
                options={teacherList.filter(t=>t.TeacherId!=="All")} className="form-control form-control-sm"
                selectedValues={getTeacherNamePreSelectedValues(item.Leader)}
                onSelect={(selectedList, selectedValue)=> handleSelectTeacher(selectedList, selectedValue,"Leader")}
                onRemove={(selectedList, removedValue)=> handleUnselectTeacher(selectedList, removedValue,"Leader")}
                showCheckbox={true}
                placeholder="Người phụ trách"
                displayValue="Title"/>
        </td>
        <td>
            <input type='text' name='Note' style={{'width':'100%'}} 
                   className="form-control form-control-sm"
                   value={item.Note} onChange={(e)=>handleChange(e)} />
        </td>
        <td className="text-end" style={{"whiteSpace":"nowrap"}}>
            <button type="button" onClick={handleSave} className="btn btn-primary btn-sm"><i className="fa fa-floppy-o"></i></button>&nbsp;
            <button type="button" onClick={handleCancel} className="btn btn-secondary btn-sm"><i className="fa fa-ban"></i></button>                
        </td>         
    </tr>
    :
    <tr>
        <td>{props.index+1}</td>
        <td>{item.Title}</td>
        <td>{item.Result}</td>
        <td>{dayjs(item.StartDate).format('DD/MM/YYYY')}</td>
        <td>{dayjs(item.EndDate).format('DD/MM/YYYY')}</td>
        <td>{dayjs(item.CompletedDate).format('MM/DD/YYYY')===dayjs(new Date(9999,11,31)).format('MM/DD/YYYY') ? "" : dayjs(item.CompletedDate).format('DD/MM/YYYY')}</td>
        <td>
            { selectedDepartmentId==='All'? getDepartmentName(item.DepartmentId)  : item.AssignedTo.includes("All")?"Cả tổ" : getTeacherNames(item.AssignedTo)}
        </td>
        <td>{getTeacherNames(item.Leader)}</td>
        <td>{item.Note}</td>
        <td className="text-end" style={{"whiteSpace":"nowrap"}}>
            <button type="button" onClick={handleEdit} className="btn btn-warning btn-sm me-1" style={{display:props.allowChangeWorkPlan?'block':'none'}}><i className="fa fa-pencil"></i></button>
            <button type="button" onClick={handleDelete} className="btn btn-danger btn-sm" style={{display:props.allowChangeWorkPlan?'block':'none'}}><i className="fa fa-trash"></i></button>                
        </td>         
    </tr>    
    return <>{rowElement}</>
}



export default WorkPlanItem;