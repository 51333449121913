import React, { useEffect, useState } from 'react';
import ScheduleItem from './ScheduleItem';

const ScheduleList = ({scheduleItems, showForTeacherId, classList, subjects, teachers, firstItemIndex, lastItemIndex, onUpdateScheduleData}) => 
{
    const[schedules, setSchedules] = useState(scheduleItems);
    const[scheduleCellChanged, setScheduleCellChanged] = useState(false);
    
    /* ************************************************************************************
     * Handler nút lệnh Lưu trên dialogbox - handleSave để chuẩn bị dữ liệu lưu vào localStorage
     * ***********************************************************************************
     * @param {*} scheduleCell: Cell dữ liệu chứa thông tin cần lưu lại
     * @param {*} scheduleRowId: Id định danh DÒNG chứa cell
     * @param {*} classId: Định danh của lớp
     * @param {*} partDay: Xác định cell thuộc CỘT sáng hay chiều 
     **************************************************************************************/
    const handleSheduleCellChange = (scheduleCell, scheduleRowId, classId, partDay) => {     
        //Tìm muc dữ liệu có thay đổi (thêm mới/sửa trên giao diện hiển thị TKB)  
        let scheduleItemsNeedToUpdate = schedules;
        const idx1 = scheduleItemsNeedToUpdate.findIndex(item => item.row_id === scheduleRowId);
        const scheduleItemHasChanged = scheduleItemsNeedToUpdate[idx1];
        let classSubjects = scheduleItemHasChanged.class_subjects;

        //Tìm cell có dữ liệu thay đổi
        const idx2 = classSubjects.findIndex(item=>item.class_id===classId && (partDay==="Sáng" ? item.morning.id === scheduleCell.id : item.afternoon.id === scheduleCell.id));
        let neededUpdateCellItem = partDay === 'Sáng' ? classSubjects[idx2].morning : classSubjects[idx2].afternoon;

        neededUpdateCellItem = {
            ...neededUpdateCellItem, 
            subject_id: scheduleCell.subject_id,
            teacher_id: scheduleCell.teacher_id,
            comment: scheduleCell.comment,
            week: scheduleCell.week,
        };

        if(partDay==='Sáng'){
            classSubjects[idx2].morning = neededUpdateCellItem;
        } else{
            classSubjects[idx2].afternoon = neededUpdateCellItem;
        }

        scheduleItemsNeedToUpdate[idx1]={
            ...scheduleItemsNeedToUpdate[idx1],
            class_subjects: classSubjects,
        }       

        //Gọi thủ tục cập nhật dữ liệu lịch để sau này lưu về server
        onUpdateScheduleData(
            classSubjects[idx2].item_id, 
            classId, 
            classSubjects[idx2].class_name, 
            classSubjects[idx2].grade,
            scheduleItemHasChanged.schedule_date, 
            scheduleItemHasChanged.period, 
            partDay, 
            neededUpdateCellItem);

        //Cập nhật thay đổi và dữ liệu trong bộ nhớ để hiển thị
        setSchedules(scheduleItemsNeedToUpdate);
        setScheduleCellChanged(true);
    }

    const getTeacherName = (teacherId) => {
        const idx = teachers.findIndex(t=>t.TeacherId===teacherId)
        if(idx>=0)
            return teachers[idx].Title; //Hiện theo bí danh xếp TKB
        else
            return " ? "
    }

    useEffect(()=>{
        if(scheduleCellChanged) setScheduleCellChanged(!scheduleCellChanged);  //Mục đích là để hiển thị giá trị cập nhật trên Cell của Thời khóa biểu khi có thay đổi

    },[scheduleCellChanged]);

    return (
        <>
            {
                classList.length > 0 &&
                <table className="table table-hover table-bordered table-sm border" style={{"marginBottom":"2rem"}}>                
                    <thead className='table-light' style={{'fontSize':'0.8em'}}>
                        <tr className="align-middle">
                            <th rowSpan="2" className='text-center py-0 px-2' style={{'width':'10px'}}>Thứ</th>
                            <th rowSpan="2" className='text-center py-0 px-2' style={{'width':'10px'}}>Tiết</th>
                            {
                                classList.map((classItem, index) =>
                                    {
                                        if (index>=firstItemIndex*1  && index<=lastItemIndex)
                                            return <th colSpan="2" className='text-center p-1' key={classItem.ClassId}>
                                                {classItem.Title!=='NA' && <><span>{classItem.Title}</span><span className="fw-normal"> ({getTeacherName(classItem.HeadTeacher)})</span> </>}
                                            </th>
                                        return null
                                    }
                                )
                            }
                        </tr>
                        <tr>
                            {
                                classList.map((classItem, index) => 
                                    { if (index>=firstItemIndex && index<=lastItemIndex)
                                        return <>
                                            <th className='text-center p-1' key={classItem.classId+"S"} style={{'width':'10%'}}>Sáng</th>
                                            <th className='text-center p-1' key={classItem.classId+"C"} style={{'width':'10%'}}>Chiều</th>
                                        </>
                                        return null
                                    }                                    
                                )
                            }                    
                        </tr>
                    </thead>
                    <tbody style={{"lineHeight":"1.15"}}>
                        {
                            schedules.map((item) =>  
                                <ScheduleItem 
                                    key={item.row_id}  
                                    scheduleItem = {item}
                                    showForTeacherId = {showForTeacherId} 
                                    subjects= {subjects}
                                    teachers={teachers}
                                    firstItemIndex={firstItemIndex}
                                    lastItemIndex={lastItemIndex}
                                    onSaveCellClick = {handleSheduleCellChange}
                                />                                     
                            )
                        } 
                    </tbody>
                </table>
            }            
        </>
    )
}

export default ScheduleList;
