import React, {useState, useEffect} from 'react';
import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import DOMPutify from 'dompurify';
//import WeekSelection from '../common/WeekSelection';
import WeekSchoolSelection from '../common/WeekSchoolSelection';
//import { scheduleConfig } from '../../appConfig';

const LSListItemLectureDialog = (props) => {
    dayjs.extend(isoWeek);
    const [scheduleItem, setItem] = useState(props.scheduleItem);
    const [applyForOtherPeriods, setApplyForOtherPeriods] = useState(false);
    const {isOwner, subjectPlanDetails, dialogId, schoolYear} = props;
    const [selectedSubjectPlanDetail, setSelectedSubjectPlanDetail] = useState(props.currentSubjectPlanDetailObj);
    const [subjectPlanDetailItems, setSubjectPlanDetailItems] = useState([]);
    const [hasLecture,] = useState(props.scheduleItem.lecture_id?true:false);
    const [hasChanged, setHasChanged] = useState(false);

    //const [beforeEditItem, setBeforeEditItem] = useState({...props.item});

    const handleChange = (e) => {
        const target = e.target;
        let name = target.name;
        let value = target.type==='checkbox'?target.checked:target.value;

        if (name === "lecture_id") {
            const idx = subjectPlanDetails.findIndex(sp=>sp.LectureId === value);  
            const spObj = idx >=0 ? subjectPlanDetails[idx] : {};
            setSelectedSubjectPlanDetail(spObj);
        }
        //Tùy chọn áp dụng bài giảng đã chọn cho tất cả các lớp cùng khối, cùng tuần
        if (name==="applyForOtherPeriods") {
            setApplyForOtherPeriods(value);
        }

        setItem({
            ...scheduleItem,
            [name]: value,
        });
        setHasChanged(true);
    }

    const handleWeekChange = (weekStartDay, selectedSchoolWeek) => { 
        if (selectedSchoolWeek > 0) {
            setSubjectPlanDetailItems(subjectPlanDetails.filter(item=>item.Week === selectedSchoolWeek));   
        }
        else {
            setSubjectPlanDetailItems(subjectPlanDetails);
        }
        setHasChanged(true);
    }

    const createMarkup = (html) => {
        return {
            __html: DOMPutify.sanitize(html)
        }
    }

    useEffect(()=>{
        if(subjectPlanDetails) {
            setSubjectPlanDetailItems(subjectPlanDetails);
        }
    },[subjectPlanDetails])

    const dgElement = props.action==='edit' || props.action==='new'
    ? 
    <div className="modal fade" id={dialogId} data-bs-backdrop="static" data-bs-keyboard="false" 
         tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Chọn bài giảng theo phân phối chương trình</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>                        
                </div>
                <div className="modal-body text-start pt-1">
                    <p className="fw-bold pt-3">
                        {props.title}
                    </p>
                    {
                        subjectPlanDetailItems.length>0 ?
                            <div className='ps-3'>
                                <div className="mb3">
                                    <label htmlFor='week' className='col-form-label'>Tuần học theo PPCT</label>
                                    <div className="row">
                                        <div className="col-md-6">
                                            {/* <WeekSelection
                                                selectedYear = {schoolYear}
                                                onWeekChange = {handleWeekChange}
                                            />  */}
                                            <WeekSchoolSelection
                                                selectedYear = {schoolYear}
                                                //selectedDate = {firstDateOfWeekIsoString}
                                                onWeekChange ={handleWeekChange}                            
                                            />
                                        </div>
                                        <div className="col-md-6 mt-2">
                                            <span id="spSelectedWeebk">
                                                {scheduleItem.lecture_week>0 ? "Tuần PPCT đã báo giảng: "+ scheduleItem.lecture_week :""}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='mb3'>
                                    <label htmlFor='lecture_id' className='col-form-label'>Bài giảng&nbsp;</label>
                                    <select name='lecture_id' 
                                        className='form-select form-select-sm' autoFocus
                                        value={scheduleItem.lecture_id}
                                        onChange={(e)=>handleChange(e)}
                                    >
                                        <option value=""></option>
                                        {
                                            subjectPlanDetailItems.filter(item=>item.IsActive).map((subjectPlan, idx) => 
                                                <option key={idx} value={subjectPlan.LectureId}>Tiết {subjectPlan.Period} {subjectPlan.Category} - {subjectPlan.Title}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                <div className='mb3'>
                                    <label htmlFor='comment' className='col-form-label'>Ghi chú</label>
                                    <textarea rows='3' name='comment' className='form-control form-control-sm'
                                        value = {scheduleItem.comment}
                                        onChange={(e)=>handleChange(e)}
                                    />
                                </div>  
                                <div className='mb3 pt-3'>
                                    <input type="checkbox" name="applyForOtherPeriods" 
                                        value={applyForOtherPeriods?"checked":""} 
                                        onChange={(e)=>handleChange(e)}
                                    />
                                    <label htmlFor='applyForOthers' className='col-form-label ps-2'>Áp dụng bài giảng đã chọn cho các lớp cùng khối</label>
                                </div>                    
                                {/* <div className='mb3'>
                                    <label htmlFor='status' className='col-form-label'>Trạng thái</label>
                                    <select name="status" className='form-select form-select-sm'
                                        value ={scheduleItem.status}
                                        onChange={(e)=>handleChange(e)}
                                    >
                                        <option value=""></option>
                                        <option value={scheduleConfig.ScheduleLectureStatus_DaNhapPPCT}>{scheduleConfig.ScheduleLectureStatus_DaNhapPPCT}</option>
                                        <option value={scheduleConfig.ScheduleLectureStatus_DayXong}>{scheduleConfig.ScheduleLectureStatus_DayXong}</option>                                        
                                        <option value={scheduleConfig.ScheduleLectureStatus_NghiOm}>{scheduleConfig.ScheduleLectureStatus_NghiOm}</option>
                                        <option value={scheduleConfig.ScheduleLectureStatus_NghiLe}>{scheduleConfig.ScheduleLectureStatus_NghiLe}</option>
                                        <option value={scheduleConfig.ScheduleLectureStatus_CoDayBu}>{scheduleConfig.ScheduleLectureStatus_CoDayBu}</option>
                                    </select>
                                </div> */}
                            </div> 
                        :
                            <div className='text-warning fs-5'>
                                Đang nạp bài giảng, vui lòng chờ trong giây lát...
                            </div>
                    } 
                </div>

                <div className="modal-footer">
                    <button type="button" disabled={(scheduleItem.lecture_id || hasLecture) && subjectPlanDetailItems.length>0 && isOwner && hasChanged ? "" : "disabled"}
                        className="btn btn-primary btn-sm" data-bs-dismiss="modal"
                        onClick={()=> props.onSaveCellClick(scheduleItem, selectedSubjectPlanDetail, applyForOtherPeriods)}
                    >Ghi lại</button>&nbsp;&nbsp;

                    <button type="button" 
                        className="btn btn-secondary btn-sm" data-bs-dismiss="modal"
                        onClick={props.handleCancel}
                    >&nbsp;Đóng&nbsp;</button>
                </div>
            </div>
        </div>
    </div>
    : 
     <div className="modal fade" id={dialogId} 
        data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" 
        aria-labelledby="staticBackdropLabel" aria-hidden="true">

        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{props.title}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>                        
                </div>
                <div className="modal-body">
                    <p></p>
                    <div 
                        className='preview' 
                        style={{"margin":"5px 0px 20px 0px"}}
                        dangerouslySetInnerHTML={createMarkup(scheduleItem.comment)}
                    >                        
                    </div>  
                    <div>
                        <p></p>
                    </div>                              
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Đóng lại</button>
                </div>
            </div>
        </div>
    </div> 

    
    return(
        <>
            { dgElement }
        </>
    )
}

export default LSListItemLectureDialog;