import React from 'react';

const CategoryList = ({title, categoryData}) => {
    return (
        <table className="table caption-top table-hover table-bordered border">
            <caption><h5>{title}</h5></caption>
            <thead>
                <tr>
                    <th>Tên gọi</th>
                    <th>Tên tắt</th>
                    <th>Ghi chú</th>
                    <th>Còn SD</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>

            </tbody>
        </table>
    )
}

export default CategoryList;
