import React, {useState, useContext} from 'react';
import { MyAppContext } from '../../context/MyAppContext';

const YearSchoolSelection = (props) => {
    const [appContext, ] = useContext(MyAppContext);    
    const [selectedYear, setSelectedYear] = useState(appContext.SchoolYear);

    return(
        <div>
            { props.children }
            <select name = "slYear" className="form-select form-select-sm" 
                value={props.year!==undefined? props.year : selectedYear}
                onChange={e=>{
                        const value = e.target.value;
                        setSelectedYear(value);
                        props.onYearChange(value);
                    }
                }
            >
            {
                appContext.SchoolYearInfo.map(y=>
                    <option key={y.SettingId} value={y.StartYear}>{y.Title}</option>
                )
            }
            </select>
        </div>
    )
}

export default YearSchoolSelection;