import React, {useState, useContext, useEffect} from "react";
import dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import { MyAppContext } from "../../context/MyAppContext";
import { getSchoolWeek } from "../../globalFuntions";

import WeeklyCalendarViewItem_Mb from "./WeeklyCalendarViewItem_Mb";
import WeekPicker from "../common/WeekPicker";
//import YearPicker from "../common/YearPicker";

import { useWeeklyCalendarDataApi } from "./WeeklyCalendarData";


function WeeklyCalendarView_Mb(props) {
    dayjs.extend(isoWeek);
    const[appContext,] = useContext(MyAppContext);
    const[schoolWeek, setSchoolWeek] = useState(appContext.SchoolWeek);

    const [selectedYear, setSelectedYear] = useState(appContext.SchoolYear);  //useState(new Date().getFullYear());
    const [selectedWeek, setSelectedWeek] = useState(dayjs().isoWeek());
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [{weeklyCalendarItems}, setAction] = useWeeklyCalendarDataApi({
        type:'', 
        value:{
            StartDate: dayjs(dayjs().format('YYYY/MM/DD 00:00:00')).isoWeekday(1).toISOString(),
            EndDate:   dayjs(dayjs().format('YYYY/MM/DD 23:59:59')).add(7,'day').toISOString()
        }
    });


    // const handleYearChange = (date, isoWeek) => {
    //     setSelectedYear(date.getFullYear());
    // }

    const handleWeekChange = (dateString) => {
        setSelectedDate(dayjs(dateString));
        setSelectedWeek(dayjs(dateString).isoWeek());
        setSchoolWeek(getSchoolWeek(appContext.SchoolYearInfo[0], dateString));
        setAction({
            type:'', 
            value:{
                StartDate: dayjs(dateString).toISOString(),
                EndDate:   dayjs(dateString).add(7,'day').toISOString()
            }            
        })
    }

    useEffect(()=>{
        if(appContext.SchoolYearInfo.length>0) {
            setSchoolWeek(getSchoolWeek(appContext.SchoolYearInfo[0], selectedDate.toISOString()));
        }
    },[appContext.SchoolYearInfo.length])
    
    return( 
        <>
            <div className="row">
                <h5>Lịch công tác tuần {selectedWeek}/{selectedDate.year()} {schoolWeek>0 ? '(tuần học ' + schoolWeek + ' năm học ' + selectedYear + '-' + (selectedYear+1) + ')' : ''}</h5>
            </div>
            <div className="row py-2">                
                {/* <div className="col-sm-3">
                    <YearPicker 
                        selectedDate = {new Date()}
                        onDateChange = {handleYearChange}
                    />
                </div> */}
                <div className="col-md-12">
                    <WeekPicker 
                        selectedYear = {selectedYear}
                        onDateChange={handleWeekChange}>                                
                    </WeekPicker>
                </div>
                <div className="col-sm-6"></div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    {
                        weeklyCalendarItems.length>0 &&
                            weeklyCalendarItems.map((item, idx)=> 
                                // eslint-disable-next-line react/jsx-pascal-case
                                <WeeklyCalendarViewItem_Mb 
                                    key={idx}
                                    item={item} 
                                />
                            )
                    }
                </div>
            </div>
        </>
    )
}

export default WeeklyCalendarView_Mb;