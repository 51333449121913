import { contextConfig } from "./appConfig"

export const msalConfig={
    auth: {
        clientId_EDT: '9c795804-245b-484c-b2cf-5e3219682684',
        authority_EDT: 'https://login.microsoftonline.com/9476f87b-bebd-4b2a-894a-65c75b456dfc',
        
        //Nguyen Truong To - Thong tin nay khai bao trên portal azure của NTT
        clientId: '3ae90ae2-bdcf-449e-a2a8-e0df3e28ae37',
        authority: 'https://login.microsoftonline.com/942ec745-807c-47eb-a410-f9c2022cb51b',

        redirectUri: contextConfig.ProductionMode ? 'https://baogiang.ugo.vn' : 'http://localhost:3000', //https://eschoolman.azurewebsites.net
        postLogoutRedirectUri: contextConfig.ProductionMode ? 'https://baogiang.ugo.vn' : "http://localhost:3000",  //https://eschoolman.azurewebsites.net
          
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
}

export const loginRequest = {
    scopes: ['User.Read', 'Sites.Read.All', 'Sites.ReadWrite.All','Sites.Manage.All']
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig_EDT = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
    graphBatchEndPoint: 'https://graph.microsoft.com/v1.0/$batch',    
    graphSPEndPoint: 'https://graph.microsoft.com/v1.0/sites',  
    graphSPRelativeEndPoint: '/sites/',
}

export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
    graphBatchEndPoint: 'https://graph.microsoft.com/v1.0/$batch',
    graphSPEndPoint: 'https://graph.microsoft.com/v1.0/sites/df7ca1ff-f0ed-40c5-bbbd-88e8a28c6802/sites',    //https://c2nguyentruongto.sharepoint.com/sites/eSchoolMan/_api/site/id
    graphSPRelativeEndPoint: '/sites/df7ca1ff-f0ed-40c5-bbbd-88e8a28c6802/sites',
}

export const sharePointConfig_EDT = {
    siteId: '127bd137-409e-4847-8370-8a358e1f4e91',  //https://edtvn.sharepoint.com/sites/MyDevTest/_api/site/id; nếu lấy web is: https://<tenant>.sharepoint.com/<site-url>/_api/web/id
    SchoolYearSettingListId: '76de5a1b-da69-4f03-a120-6ae9a8269814', //List danh sách năm học
    SubjectListId: '6211092e-ed21-4748-a875-9becbb25d2e3', //List môn học
    TeacherListId: '24decc99-1ca8-4838-a1c5-f76f87443511', //List Giáo viên
    ClassesListId: '403be74a-0fa6-41fa-9f67-809e69a38543', // List Lớp học
    SubjectPlanListId: '3de84eda-776e-4a61-99bf-5d6d3e22bdb3', //List Kế hoạc dạy học
    SubjectPlanDetailListId: '1842F49F-4886-46E2-A319-036FEBBECAA5', //List Chi tiết kế hoạch dạy học
    ClassNotebookListId: '8B5189C0-A372-4B3E-B425-1721E2686A9E', //List Sổ đầu bài
    WeeklyCalendarListId: '8643ed59-3a53-4eb4-88ff-762c10132a01', //List Lịch công tác tuần
    NewsBoardListId:'0442690e-8083-4442-a064-89dd490b868d', //List tin tức
    ScheduleListId: '421E3D1E-90B5-42BB-AE83-55406F3C81F2', //Thời khóa biểu
    ScheduleLogListId: '8636d692-1c15-41c9-9f9c-ac62b83a1e13', //Các thay đổi trên một mục TKB như Dự giớ, dạy bù... được lưu ở list này
    ScheduleImportedListId: '03B4E2F9-B015-4686-A04E-E3DCFF6988A0', //List chứa dữ liệu thời khóa biểu import từ file excel
    LectureScheduleListId: 'f0062be7-9f22-43e1-86a7-224279faba4a', //Sổ lịch báo giảng của giáo viên
    DepartmentListId: 'F1D7037E-EFBA-402B-B299-113C51CC635E', //List Tổ chuyên môn
    TitleListId: '3ee67d48-0d52-466b-a1d2-f65fe3cb6c0b', //List Chức danh, chức vụ
    //WorkingPlanListId: 'bcd62b74-154e-4ce5-8237-5ab49305f384' //List ke hoach cong tac cua cac don vi/to
}

export const sharePointConfig = {
    siteId: '7fe1ce6b-52c0-4c4d-9918-28606ffe2476',  //https://c2nguyentruongto.sharepoint.com/sites/eSchoolMan/data/_api/web/id
    SchoolYearSettingListId: '3e190120-b957-42d0-99a8-3b8737fe8c3a', //List danh sách năm học
    SiteDriveId: 'b!_6F83-3wxUC7vYjoooxoAmvO4X_AUk1MmRgoYG_-JHat_zC2omAaSIHPGZ54BgXh',  //Là DriveId của thư viện chứa tài liệu, hồ sơ của nhà trường do các gv, tổ trưởng upload lên
    SiteDriveListId: 'b630ffad-60a2-481a-81cf-199e780605e1', // Là List ID của thư viện chứa tài liệu
    SubjectListId: '8ba7c49e-4d16-4df6-a2e0-59d578fc5895', //List môn học
    TeacherListId: 'd2a130d7-8f62-4c2c-a28d-744a08706212', //List Giáo viên
    ClassesListId: 'ee85c1f2-f9dd-42a7-b9b0-498c8f863828', // List Lớp học
    SubjectPlanListId: '2985ddea-9a0d-4f04-80de-a16d6a0e9bd3', //List Kế hoạc dạy học
    SubjectPlanDetailListId: '0b7b993f-57fd-4c21-a347-4b27ac29dd55', //List Chi tiết kế hoạch dạy học
    ClassNotebookListId: 'c195654f-367d-467c-9854-94862d1b5c43', //List Sổ đầu bài
    WeeklyCalendarListId: '8522df7f-87d2-4c49-a4d3-ca5a75012bae', //List Lịch công tác tuần
    NewsBoardListId:'ec91cc8c-1183-43af-90cd-63b8b63f8a30', //List tin tức
    ScheduleListId: '320ba277-0bb7-4472-a40b-4c0feb42aa48', //Thời khóa biểu
    ScheduleLogListId: '17d7383c-2d03-468d-a344-e0cfcb4ec756',
    ScheduleImportedListId: '5f6ae8bb-be64-4f2a-bd8f-9dd8854c23d6',
    LectureScheduleListId_2022: '990f5f53-312b-42b3-857c-71a4a0f7244e', //Sổ lịch báo giảng của giáo viên năm học 2022-2023 - LectureSchedule
    LectureScheduleListId: '4DB446AB-1DEA-4734-BEB3-5432F672BF36', // Lịch báo giảng của giáo viên năm học 2023-2024 LectuteSchedule2023-2024
    DepartmentListId: 'ee9d82c6-8b07-44cf-85a7-b01ab006543a', //List Tổ chuyên môn
    TitleListId: '1226131c-a113-4103-9215-7b13097c9ce1', //List Chức danh, chức vụ
    WorkingPlanListId: 'bcd62b74-154e-4ce5-8237-5ab49305f384' //List ke hoach cong tac cua cac don vi/to
}

export const silentRequest = {
    scopes: ["openid", "profile"],
    loginHint: "nguyenbt@edt.com.vn"
};