import  {useEffect, useState} from 'react';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { v4 as uuidv4 } from 'uuid';
import { remove, orderBy as _orderBy} from 'lodash';

import { loginRequest, sharePointConfig } from '../../authConfig';
import {
    getSharePointListItems,
    updateSharePointItem,
    addSharePointListItem,
    deleteSharePointItem,
    deleteSharePointItems
} from '../../graph';


import {SubjectPlans} from '../../mock/MockData';
import {SubjectPlanDetail} from '../../mock/MockData';


export const createBlankSubjectPlanItem = () =>{
    return [
        {
            id:0,
            SubjectPlanId: uuidv4(),
            SubjectId: '',
            Title: '',
            Grade: 6,
            StartYear: new Date().getFullYear(),
            EndYear: 0,
            Sem1_TotalWeeks:0,
            Sem1_PeriodsPerWeek:0,
            Sem2_TotalWeeks:0,
            Sem2_PeriodsPerWeek:0,
            RefDocument: '',
            PlanDetail: null,
            IsActive: true
        }
    ]
}

export const createBlankSubjectPlanDetailItem = (numOfBlankItems=1) =>{
    let blankItems = [];
    for(let i=0; i<numOfBlankItems; i++) {
        blankItems.push({
            LectureId: uuidv4(),
            SubjectPlanId:'',
            Title: '',
            Category:'', //Phân môn, ví dụ ở môn toán: ĐS, HH,...
            Semester:'',
            Week: 0,
            Period: 0,
            AdjustContent:'',
            GuideLine:'',  
            StartYear: 0,
            EndYear: 0,
            Grade: 0,
            SubjectId: '',          
            IsActive: true            
        })
    }
    return blankItems;
}

export const useSubjectPlanDataApiMock = () => {
    const [subjectPlanItems, setSubjectPlanItems] = useState(SubjectPlans);
    const [subjectPlanDetailItems, setSubjectPlanDetailItems] = useState(SubjectPlanDetail);
    const [action, setAction] = useState({type:'', value:''});  
 
    useEffect(() => { 
        const fetchData = async () => {
            let resultItems = Array.from(SubjectPlans);           

            const actionValue = action.value;
            if(actionValue.subjectId && actionValue.year && actionValue.grade){
                resultItems = resultItems.filter(p => 
                    p.SubjectId===actionValue.subjectId 
                    && p.StartYear===actionValue.year*1 
                    && p.Grade === actionValue.grade*1);
            } else {
                resultItems=[];
            }
            
            let detailItems = []
            if(resultItems.length>0){
                detailItems =  SubjectPlanDetail.filter(d=>d.SubjectPlanId === resultItems[0].SubjectPlanId);
            }

            setSubjectPlanItems(resultItems); 
            setSubjectPlanDetailItems(detailItems); 
        };

        const addBlankPlanItem = async (item) => {
            setSubjectPlanItems(item.concat(subjectPlanItems));
        };

        const addBlankPlanDetailItem = async (item) => {
            setSubjectPlanDetailItems(item.concat(subjectPlanDetailItems));
        };

        const submitDataPlanItem = async (item) => {
            console.log('Submit Plan data');
            console.log(item);
        };

        const submitDataPlanDetailItem = async (item) => {
            console.log('Submit Plan Detail data');
            console.log(item);
        };

        const deletePlanItem = async (item) => {
            let items = Array.from(subjectPlanItems);
            remove(items, i=>i.SubjectPlanId===item.SubjectPlanId);        
            setSubjectPlanItems(items); 
        }

        const deletePlanDetailItem = async (item) => {
            let items = Array.from(subjectPlanDetailItems);
            remove(items, i=>i.LectureId===item.LectureId);        
            setSubjectPlanDetailItems(items); 
        }    

        const processData =() => {
            switch(action.type){
                case 'add_plan':
                    addBlankPlanItem(action.value);
                    break;
                case 'add_plan_detail':
                    addBlankPlanDetailItem(action.value);
                    break;
                case 'save_plan':
                    submitDataPlanItem(action.value);
                    break;                    
                case 'save_plan_detail':
                    submitDataPlanDetailItem(action.value);
                    break;
                case 'del_plan':
                    deletePlanItem(action.value);
                    break;                    
                case 'del_plan_detail':
                    deletePlanDetailItem(action.value);
                    break;
                default:
                    fetchData();
                    
            }
        }

        processData();

    },[action]);

    return [{subjectPlanItems, subjectPlanDetailItems},  setAction];
}

export const useSubjectPlanDataApi = (initialAction) => {
    const [singleSubjectPlanItem, setSingleSubjectPlanItem] = useState(null);
    const [subjectPlanItems, setSubjectPlanItems] = useState([]);
    const [subjectPlanDetailItems, setSubjectPlanDetailItems] = useState([]);

    const [subjectPlanDetailItems_v2, setSubjectPlanDetailItemV2] = useState([]);  
    //subjectPlanDetailItems_v2 hiện được dùng trong LSPage.js va ClassNotebook.js phục vụ cho lookup....

    const [isLoadingSubjectPlan, setIsLoadingSubjectPlan] = useState(false);
    const [action, setAction] = useState(initialAction===undefined?{type:'', value:''}:initialAction);
    const [subjectPlanApiMessage, setSubjectPlanApiMessage] = useState("");

    const {instance, accounts} = useMsal();

    const fetchSubjectPlan_23 = (accessToken, subjectPlanId) =>{        
        getSharePointListItems(
            accessToken, 
            sharePointConfig.SubjectPlanListId,
            "SubjectPlanId,SubjectId,Title,Grade,StartYear,EndYear,Sem1_TotalWeeks,Sem1_PeriodsPerWeek,Sem2_TotalWeeks,Sem2_PeriodsPerWeek,RefDocument,IsActive,id", 
            `fields/SubjectPlanId eq '${subjectPlanId}'`  
        ).then(response => {  
            if(response.value.length>0){
                let subjectPlanItem = response.value[0].fields;
                subjectPlanItem = {
                    ...subjectPlanItem,
                    SubjectPlanDetails: subjectPlanItem.PlanDetail & subjectPlanItem.PlanDetail!==""? JSON.parse(subjectPlanItem.PlanDetail):[]
                }
                setSingleSubjectPlanItem(subjectPlanItem)

                console.log('Call fetchSubjectPlan for subjectPlanId', subjectPlanId, subjectPlanItem);
            }
            else{
                setSingleSubjectPlanItem(null);
            }
            setIsLoadingSubjectPlan(false);
        });
    }


    //==================================================================================
    // fetchSubjectPlanList
     //==================================================================================
    // Input :  2 tham số không kể accessToken
    // - schoolYear (năm bắt đầu của năm học cần lấy/xem dữ liệu)
    // - grade: khối lớp (6,7,8, 9)
    // Output: 
    // Nếu tồn tại, sẽ trả về danh sách dạng mảng các đối tượng là các items trong 
    // list SubjectPlan. Nếu không là một mảng rỗng
    //==================================================================================
    const fetchSubjectPlanList = (accessToken, schoolYear, grade) =>{
        //Lấy cả trường ID của SharePoint List ItemID phục vụ cho việc cập nhật/xóa
        getSharePointListItems(
            accessToken, 
            sharePointConfig.SubjectPlanListId,
            "SubjectPlanId,SubjectId,Title,Grade,StartYear,EndYear,Sem1_TotalWeeks,Sem1_PeriodsPerWeek,Sem2_TotalWeeks,Sem2_PeriodsPerWeek,RefDocument, PlanDetail, IsActive,id", 
            `((fields/Grade eq ${grade}) and (${schoolYear} eq fields/StartYear))`  
        ).then(response => {  
            let items = [];
             const listItemArray = response.value;
            for(let i=0; i<listItemArray.length; i++){
                const fields = listItemArray[i].fields;
                items.push({
                    ...fields,
                    PlanDetail: fields.PlanDetail && fields.PlanDetail!=="" ? JSON.parse(fields.PlanDetail).filter(element=>element.IsActive===true) : [] //Chi tiết kế hoạch giảng dạy
                });
            }
            setSubjectPlanItems(items);
            setIsLoadingSubjectPlan(false);
            setSubjectPlanApiMessage('subjectplan-fetched');
        })
    }

    //==================================================================================
    //  fetchSubjectPlanDetailDataByYear 
    //==================================================================================
    //  Lấy chi tiết kế hoạch dạy học theo Năm học và môn học
    //  - schoolYear: Năm bắt đầu của một niên khóa, ví dụ niên khóa 2023-2024 thì là 2023
    //  - subjectIdArray: Là một mảng chứa id của các môn học
    //==================================================================================
    const fetchSubjectPlanDetailDataByYear = (accessToken, schoolYear, subjectIdArray) => {
        var queryClause = `(fields/StartYear eq ${schoolYear})`;
        var count = 0;
        subjectIdArray.forEach(subjectId => {
            if(count===0) {
                queryClause = queryClause + ` and ((fields/SubjectId eq '${subjectId}')`;
            }
            else{
                queryClause = queryClause + ` or (fields/SubjectId eq '${subjectId}')`;
            }
            count = count +1;
        });
        if(count>0) queryClause = queryClause + ")";

        getSharePointListItems(
            accessToken, 
            sharePointConfig.SubjectPlanListId,
            "SubjectPlanId,Title,SubjectId,StartYear,EndYear,Grade,PlanDetail,IsActive,id",
            queryClause, undefined, 5000
        ).then(response => {  
            let subjectPlanItems = [];
            let subjectPlanDetailItems = [];
            const listItemArray = response.value;
            let fields;         
            for(let i=0; i<listItemArray.length; i++){
                fields = listItemArray[i].fields;
                subjectPlanItems.push({
                    ...fields,
                    PlanDetail: fields.PlanDetail ? JSON.parse(fields.PlanDetail): []
                });
            }

            subjectPlanItems.forEach((planItem, index)=>{
                const items = planItem.PlanDetail.map(item=>{
                    return {
                        ...item,
                        SubjectId: planItem.SubjectId,
                        StartYear: planItem.StartYear,
                        EndYear: planItem.EndYear,
                        Grade: planItem.Grade,
                        PeriodN: parseInt(item.Period) //Chuyển từ dạng text sang dạng số để sắp xếp theo đúng thứ tự số
                    }
                });
                subjectPlanDetailItems = subjectPlanDetailItems.concat(items);
            })

            subjectPlanDetailItems = _orderBy(subjectPlanDetailItems,['Semester','Week','PeriodN','Category'],['asc','asc','asc','asc']);
            setSubjectPlanDetailItemV2(subjectPlanDetailItems);
            //console.log('subjectPlanDetailItems lấy từ trường PlanDetail', subjectPlanDetailItems);
            setIsLoadingSubjectPlan(false);
        })
    }

    const savePlanItem = (accessToken, item) => {
        const planDetailItems = _orderBy(item.PlanDetail,['Week','Period','Category'],['asc','asc','asc']);
        let fieldValues = {};
        if(item.id>0){ //Nếu đã có thì cập nhật nội dung   
            fieldValues = {
                fields: {
                    SubjectId: item.SubjectId,
                    Title: item.Title, 
                    Grade: item.Grade,
                    StartYear: item.StartYear,
                    EndYear: parseInt(item.StartYear) + 1,
                    Sem1_TotalWeeks: item.Sem1_TotalWeeks,
                    Sem1_PeriodsPerWeek: item.Sem1_PeriodsPerWeek,
                    Sem2_TotalWeeks: item.Sem2_TotalWeeks,
                    Sem2_PeriodsPerWeek: item.Sem2_PeriodsPerWeek, 
                    RefDocument: item.RefDocument,
                    PlanDetail: JSON.stringify(planDetailItems),             
                    IsActive: item.IsActive==='true' || item.IsActive===true?true:false
                }
            };             
            updateSharePointItem(accessToken, sharePointConfig.SubjectPlanListId, item.id, fieldValues)
                .then(response => {                    
                    console.log('Save plan item updating', response);
                    //Nạp lại danh sách Subject Plan
                    setIsLoadingSubjectPlan(true);
                    fetchSubjectPlanList(accessToken, item.StartYear, item.Grade);
                    setSubjectPlanApiMessage('subjectplan-updated');       
            });
        }
        else{ //Nếu chưa có thì thêm vào 
            fieldValues = {
                fields: {
                    SubjectPlanId: item.SubjectPlanId,
                    SubjectId: item.SubjectId,
                    Title: item.Title, 
                    Grade: item.Grade,
                    StartYear: item.StartYear,
                    EndYear: item.StartYear+1,
                    Sem1_TotalWeeks: item.Sem1_TotalWeeks,
                    Sem1_PeriodsPerWeek: item.Sem1_PeriodsPerWeek,
                    Sem2_TotalWeeks: item.Sem2_TotalWeeks,
                    Sem2_PeriodsPerWeek: item.Sem2_PeriodsPerWeek, 
                    RefDocument: item.RefDocument, 
                    PlanDetail: JSON.stringify(planDetailItems),
                    IsActive: item.IsActive==='true' || item.IsActive===true?true:false
                }
            };
            addSharePointListItem(accessToken, sharePointConfig.SubjectPlanListId, fieldValues)
                .then(response=>{                
                if(response.id) {
                    console.log("Save plan item successfully",response);
                    //Nạp lại danh sách Subject Plan
                    setIsLoadingSubjectPlan(true);
                    fetchSubjectPlanList(accessToken, item.StartYear, item.Grade);
                    setSubjectPlanApiMessage('subjectplan-added'); 
                } else {
                    console.log("Save plan item failed",response);
                    setSubjectPlanApiMessage('subjectplan-added-failed'); 
                }                
            });
        }
    };

    const deletePlanItem = (accessToken, item) => {
        //Xóa phía server nếu item này đã tồn tại trên server dựa vào SharePoint List Item ID field
        if(item.id>0){
            deleteSharePointItem(accessToken,sharePointConfig.SubjectPlanListId,item.id).then(response =>{
                fetchSubjectPlanList(accessToken, item.StartYear, item.Grade);
                setSubjectPlanApiMessage('subjectplan-deleted'); 
            });
        }
        //Xóa ở local/client
        //let items = Array.from(subjectPlanItems);
        // remove(items, i=>i.SubjectPlanId===item.SubjectPlanId);
        // setSubjectPlanItems(items);
        // setIsLoadingSubjectPlan(false);
    }

    const savePlanDetailItem_22 = (accessToken, item) => {
        getSharePointListItems(
            accessToken, 
            sharePointConfig.SubjectPlanDetailListId,
            "LectureId", 
            `fields/LectureId eq '${item.LectureId}'`
        ).then(response => {
            let fieldValues = {};
            const listItems = response.value
            if(listItems.length>0){ //Nếu đã có thì cập nhật nội dung 
                fieldValues = {
                    fields: {
                        SubjectPlanId: item.SubjectPlanId,                        
                        Title: item.Title,
                        Category: item.Category,
                        Semester: item.Semester,
                        Week: item.Week,
                        Period: item.Period + "",
                        AdjustContent: item.AdjustContent,
                        GuideLine: item.GuideLine,
                        StartYear: item.StartYear,
                        EndYear: item.EndYear,
                        Grade: item.Grade,
                        SubjectId: item.SubjectId,
                        IsActive: item.IsActive==='true' || item.IsActive===true?true:false
                    }
                };                  
                updateSharePointItem(accessToken, sharePointConfig.SubjectPlanDetailListId, listItems[0].id, fieldValues)
                    .then(response => {
                        console.log('response after updated: ', response);
                        setIsLoadingSubjectPlan(false);
                    });
            }
            else{ //Nếu chưa có thì thêm vào 
                fieldValues = {
                    fields: {
                        LectureId: item.LectureId,                        
                        SubjectPlanId: item.SubjectPlanId,
                        Title: item.Title,
                        Category: item.Category,
                        Semester: item.Semester,
                        Week: item.Week,
                        Period: item.Period + "",
                        AdjustContent: item.AdjustContent,
                        GuideLine: item.GuideLine,
                        StartYear: item.StartYear,
                        EndYear: item.EndYear,
                        Grade: item.Grade,
                        SubjectId: item.SubjectId,                        
                        IsActive: item.IsActive==='true' || item.IsActive===true?true:false
                    }
                };
                addSharePointListItem(accessToken, sharePointConfig.SubjectPlanDetailListId, fieldValues)
                    .then(response=>{
                        console.log(response);
                        setIsLoadingSubjectPlan(false);
                    });
            }
        }) 
    };

    useEffect(() => {     
        const accessTokenRequest = {
            ...loginRequest,
            account: accounts[0]
        }

        const processData = async () => {
            setIsLoadingSubjectPlan(true);
            instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
                const accessToken = accessTokenResponse.accessToken;
                switch(action.type){
                    case 'add_plan_detail':
                        //addBlankPlanDetailItem_22(action.value);
                        break;
                    case 'save_plan':
                        savePlanItem(accessToken, action.value);
                        break;
                    case 'save_plan_detail':
                        savePlanDetailItem_22(accessToken, action.value);
                        break;                        
                    case 'del_plan':
                        deletePlanItem(accessToken, action.value);
                        break;
                    case 'del_plan_detail':
                        //deletePlanDetailItem_22(accessToken, action.value);
                        break;
                    case 'fetch_plan':
                        //fetchSubjectPlan(accessToken, action.value.subjectPlanId);
                        break;
                    case 'fetch_plan_list':
                        fetchSubjectPlanList(accessToken, action.value.year, action.value.grade);
                        break;

                    case 'fetch_plan_detail':                        
                        fetchSubjectPlanDetailDataByYear(accessToken, action.value.schoolYear, action.value.subjectIdArray);
                        break;
                    default:
                        const actionValue = action.value;
                        if(actionValue.subjectId && actionValue.year && actionValue.grade){
                            //fetchData(accessToken);
                        } else {
                            setSubjectPlanItems([]);
                            setSubjectPlanDetailItems([]);
                            setIsLoadingSubjectPlan(false);
                        }
                }
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest);                    
                }
                console.log(error);
                setIsLoadingSubjectPlan(false);
            }) 
        }; 

        processData();  

    },[action]);

    return [{isLoadingSubjectPlan, subjectPlanApiMessage, singleSubjectPlanItem, subjectPlanItems, subjectPlanDetailItems, subjectPlanDetailItems_v2}, setAction, setIsLoadingSubjectPlan];
}
