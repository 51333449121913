import React, {useState} from "react";
import dayjs from 'dayjs';
import vi from 'dayjs/locale/vi'

import DateTimePicker from '../common/DateTimePicter';

function MonthlyCalendarItem(props){ 
    const [item, setItem] = useState(props.item);
    const [beforeEditItem, setBeforeEditItem] = useState({...props.item});

    const [action, setAction] = useState(props.action);

    const handleEdit = () =>{
        setBeforeEditItem({...item});
        setAction('edit');
    }

    const handleSave = () => {
        props.onSaveItemClick(item);
        setAction('');
    }

    const handleCancel = () =>{
        if(action==='new'){
            handleDelete(item.id);
        }else{ //edit action nên hủy các sửa đổi, khôi phục về giá trị trước khi sửa
            setItem(beforeEditItem);
        }
        setAction('');
    }

    const handleDelete = ()=>
    {
        if(item.IsDefault*1===0){
            props.onDeleteItemClick(item); 
        }   
    }

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.type==='checkbox'?target.checked:target.value;
        setItem({
            ...item,
            [name]:value
        });
    }

    const handleStartDateChange = (dateTime) => {
        setItem({
            ...item,
            StartDate: dateTime
        })
    }

    const handleEndDateChange= (dateTime) => {
        setItem({
            ...item,
            EndDate: dateTime
        })
    }

    const firstColElement = item.IsFirstColumn*1===1
        ?<td rowSpan={item.NumOfItems} className="text-center align-middle" style={{"backgroundColor":"#f8f9fa"}}>
            {dayjs(item.StartDate).locale('vi').format('dddd')} {dayjs(item.StartDate).format('DD/MM/YYYY')}
        </td>
        :null


    const rowElement = (action==='edit' || action==='new' || item.id*1===0)
        ? <tr>
            {firstColElement}            
            <td>
                <DateTimePicker name="StartDate" selectedDate={new Date(item.StartDate)} 
                    onDateChange={handleStartDateChange}/>
            </td>
            <td>
                <DateTimePicker name="EndDate" selectedDate={new Date(item.EndDate)} 
                    onDateChange={handleEndDateChange}/>            
            </td>
            <td>
                <input type='text' name='Title' className="form-control form-control-sm"
                    value={item.Title} onChange={handleChange} />
            </td>
            <td>
                <input type='text' name='Location' className="form-control form-control-sm"
                    value={item.Location} onChange={handleChange} />                
            </td>  
            <td>
                <input type='text' name='Responder' className="form-control form-control-sm"
                    value={item.Responder} onChange={handleChange} />
            </td>
            <td>
                <textarea name='Result' className="form-control form-control-sm" rows="2" 
                    value={item.Result} onChange={handleChange}/>  
            </td>
            <td>
                <textarea name='AmComment' className="form-control form-control-sm" rows="2" 
                    value={item.AmComment} onChange={handleChange}/>  
            </td>
            <td>
                <textarea name='PmComment' className="form-control form-control-sm" rows="2" 
                    value={item.PmComment} onChange={handleChange}/>   
            </td>
            <td>
                <textarea name='Note' className="form-control form-control-sm" rows="2" 
                    value={item.Note} onChange={handleChange}/>
            </td>
            <td className="text-end">
                <button type="button" onClick={handleSave} className="btn btn-primary btn-sm"><i className="fa fa-floppy-o" aria-hidden="true"></i></button>&nbsp;
                <button type="button" onClick={handleCancel} className="btn btn-secondary btn-sm"><i className="fa fa-ban" aria-hidden="true"></i></button>                
            </td>            
        </tr>
        :<tr>
            {firstColElement}            
            <td className="text-center">
                {item.Title!==''
                    ? dayjs(item.StartDate).format('HH:mm')
                    :''
                }
            </td>
            <td className="text-center">
                {item.Title!==''
                    ? dayjs(item.EndDate).format('HH:mm')
                    :''
                }
            </td>            
            <td>{item.Title}</td>
            <td>{item.Location}</td>    
            <td>{item.Responder}</td>
            <td>{item.Result}</td>
            <td>{item.AmComment}</td>
            <td>{item.PmComment}</td>
            <td>{item.Note}</td>
            { item.IsDefault*1===0
                ?
                <td className="text-end">                
                    <button type="button" onClick={handleEdit} className="btn btn-warning btn-sm"><i className="fa fa-pencil" aria-hidden="true"></i></button>&nbsp;
                    <button type="button" onClick={handleDelete} className="btn btn-danger btn-sm"><i className="fa fa-trash-o" aria-hidden="true"></i></button>                               
                </td>
                :
                <td className="text-end"> 
                    <button type="button" className="btn btn-primary btn-sm"  
                        onClick ={() => props.onAddItemClick(item.StartDate)}
                    >
                        <i className="fa fa-plus" aria-hidden="true"></i>
                    </button>
                </td>
            }
        </tr>;

    return(
        <>
            {rowElement}
        </>
    )
}

export default MonthlyCalendarItem;