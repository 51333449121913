import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import  Button  from "@mui/material/Button";
import  MenuItem  from "@mui/material/MenuItem";
import  Menu  from "@mui/material/Menu";
import { loginRequest } from "../../authConfig";

export const SignInButton = () => {
    const { instance } = useMsal();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleLogin = (loginType) => {
        setAnchorEl(null);
        if(loginType ==="popup") {
            instance.loginPopup(loginRequest);
        }else if(loginType==='redirect'){
            instance.loginRedirect(loginRequest);
        }
    }
    return(
        <div className="m-0 p-0">
            <Button onClick={()=>handleLogin("redirect")} color='inherit'>
                Đăng nhập
            </Button>

{/*             <Button onClick={(event)=>setAnchorEl(event.currentTarget)} color='inherit'>
                Đăng nhập
            </Button>
            <Menu
                id="menu-appbar" 
                anchorEl={anchorEl} 
                anchorOrigin={{vertical:'top', horizontal:'right'}}
                keepMounted
                transformOrigin={{vertical:'top', horizontal:'right'}}
                open={open}
                onClose={()=>setAnchorEl(null)}
            >
                <MenuItem onClick={() => handleLogin("popup")} key="popupLogin">Đăng nhập dùng Popup</MenuItem>
                <MenuItem onClick={() => handleLogin("redirect")} key="redirectLogin">Đăng nhập dùng Redirect</MenuItem>
            </Menu> */}
        </div>
    )
}