
function SubjectPlanList({subjectPlanItems, onSubjectPlanClick})
{
  return (
    <>
      { subjectPlanItems.length>0 && <h5>Danh sách KHDH</h5>}
      <ul className="list group">
        {
          subjectPlanItems.map((s,i) =>
            <li className="list-group-item pb-3" key={ 'li_' + s.SubjectPlanId}>
              <a  key={`${s.SubjectPlanId}_${s.id}`} id={s.SubjectPlanId}  role='button' 
                  title={s.PlanDetail && s.PlanDetail.length>0?"":"Chưa có Phân phối chương trình"}
                  onClick ={(e) => onSubjectPlanClick(e, s.SubjectPlanId, s)} >
                    {i+1}. {s.Title} {s.PlanDetail && s.PlanDetail.length>0 ?"":<span style={{"color":"red"}}>(*)</span>}
              </a>
            </li>   
        )}

      </ul>
    </>
  );
}

export default SubjectPlanList;