import React, {useState} from "react";

import dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

import MonthlyCalendarItem from './MonthlyCalendarItem';

function MonthlyCalendarList({data, onAddItemClick, onSaveItemClick, onDeleteItemClick}){
    return(       
        <table className="table table-hover table-bordered table-sm">
            <thead className="table-light">
                <tr className="align-middle">
                    <th style={{width:'5%'}} className="text-center" rowSpan="2">Ngày</th>
                    <th style={{width:'6%'}} className="text-center" colSpan="2">Thời gian</th>
                    <th style={{width:'15%'}} className="text-left" rowSpan="2">Nội dung công việc</th>
                    <th style={{width:'10%'}} rowSpan="2">Địa điểm</th>   
                    <th style={{width:'10%'}} rowSpan="2">Người thực hiện</th>
                    <th style={{width:'10%'}} rowSpan="2">Đánh giá KQ</th>    
                    <th style={{width:'11%'}} rowSpan="2">Tình hình sáng</th>    
                    <th style={{width:'11%'}} rowSpan="2">Tình hình chiều</th>
                    <th style={{width:'11%'}} rowSpan="2">Ghi chú</th>                           
                    <th style={{width:'5%'}} className='text-center' rowSpan="2">
                    </th>
                </tr>
                <tr className="align-middle">
                    <th style={{width:'4%'}} className="text-center">BĐ</th>
                    <th style={{width:'4%'}} className="text-center">KT</th>
                </tr>
            </thead>
            <tbody>
                { 
                    data.map((item) => 
                        <MonthlyCalendarItem 
                            key={item.UID}
                            item = {item} 
                            action = {item.NewRecord*1===1?'new':''}
                            onSaveItemClick = {onSaveItemClick}
                            onDeleteItemClick={onDeleteItemClick}   
                            onAddItemClick = {onAddItemClick}                             
                        />
                    )
                }
            </tbody>
        </table>
    )
}

export default MonthlyCalendarList;