import React, {useState} from "react";
import { useMsal } from "@azure/msal-react";
import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

import { loginRequest } from '../../authConfig';
import { 
    callSPGraph,
    getSharePointListItems, 
    updateSharePointItem, 
    addSharePointListItem, 
    deleteSharePointItem, 
    createSPList } from '../../graph';

import NoteList from "./NoteList";
import WeekSelection from "../common/WeekSelection";
import YearSchoolSelection from "../common/YearSchoolSelection";
import ClassSelection from "../common/ClassSelection";
import GradeSelection from "../common/GradeSelection";


import { useNotebookDataApi} from "./NotebookData";
import { useClassDataApi } from "../classes/ClassData";
import { useSubjectDataApi } from "../subject/SubjectData";
import { useTeacherDataApi } from "../teacher/TeacherData";
import { useSubjectPlanDataApi } from '../subject/SubjectPlanData';

import MessageBox from "../common/MessageBox";

import { ScheduleLogList, ScheduleImportedList, ScheduleList, LectureScheduleList, ClassNotebookList,
    TeacherList,SchoolYearSettingList,SubjectList,WeeklyCalendarList,ClassesList,SubjectPlanList,
    SubjectPlanDetailList,NewsBoardList, DepartmentList, TitleList, WorkingPlanList
} from "../../data/SPListDefinition";


///import { ProtectedComponent } from "../../SharePointAccess";

function SubmitClassNotebook(props){
    const {instance, accounts} = useMsal();
    //const name = accounts[0] && accounts[0].name;

    function RequestAccessToken(){
        const request = {
            ...loginRequest,
            account: accounts[0]
        };

        instance.acquireTokenSilent(request).then((response) => {
            console.log("Silent request completed");
            //callSPGraph(response.accessToken).then(response=> console.log(response));
            //updateSharePointItem(response.accessToken, 16).then(response => console.log(response));
            
            // createSPList(response.accessToken, ClassNotebookList);
            // createSPList(response.accessToken, TeacherList);
            // createSPList(response.accessToken, SubjectList);
            // createSPList(response.accessToken, ClassesList);
            // createSPList(response.accessToken, SubjectPlanList);
            // createSPList(response.accessToken, SubjectPlanDetailList);
            // createSPList(response.accessToken, SchoolYearSettingList);
            // createSPList(response.accessToken, WeeklyCalendarList);
            // createSPList(response.accessToken, NewsBoardList);
            // createSPList(response.accessToken, DepartmentList);           
            // createSPList(response.accessToken, ScheduleList);
            // createSPList(response.accessToken, TitleList);
            //createSPList(response.accessToken, ScheduleImportedList);
            //createSPList(response.accessToken, ScheduleLogList);
            createSPList(response.accessToken, LectureScheduleList);
            //createSPList(response.accessToken, WorkingPlanList);
        }).catch((error)=> {
            console.log(error);
            instance.acquireTokenPopup(request).then((response)=> {
                callSPGraph(response.accessToken).then(response=> console.log(response));
                //updateSharePointItem(response.accessToken, 16).then(response => console.log(response));
                //creatSPList(response.accessToken, sharePointConfig.ClassNotebookListId, ClassNotebookList).then(response => console.log(response));
            })
        })
        
    }

    return(
        <div className="pt-3">
            <button type='button' onClick={RequestAccessToken} className='btn btn-primary btn-sm' >{props.children}</button>   
        </div>
    )
}

function ClassNotebook (props){    
    dayjs.extend(isoWeek);   
    const [class_id, setClass_id] = useState('');
    const [grade, setGrade] = useState(9);
    const [week, setWeek] = useState(dayjs().isoWeek());
    const [year, setYear] = useState(dayjs().month()>9 ? dayjs().year()-1 : dayjs().year());


    //Sử dụng custom hook để lấy dữ liệu từ Server về     
    const [{classItems}] = useClassDataApi();
    const [{subjectItems}] = useSubjectDataApi();
    const [{teacherItems}] = useTeacherDataApi(); 
    const [{subjectPlanDetailItems_v2 }, setSubjectPlanDataApiAction] = useSubjectPlanDataApi({type:'fetch_plan_detail',value:{schoolYear:year}});
    const [{notebookRecordItems, isProcessingNotebookData}, setAction] = useNotebookDataApi();
 
    const handleGradeChange = (gradeValue) => {
        setGrade(gradeValue*1);
    }

    const handleClassChange = (classIdValue) =>{
        setClass_id(classIdValue);

        //Gửi yêu cầu lấy số liệu
        setAction({
            type:'fetchData',
            value: {
                classId: classIdValue,
                schoolYear: year*1,
                schoolWeek: week*1
            }
        });
    }

    const handleYearChange = (yearValue) =>{        
        setYear(1*yearValue);
        //Gửi yêu cầu lấy số liệu
        setAction({
            type:'fetchData',
            value: {
                classId: class_id,
                schoolYear: yearValue*1,
                schoolWeek: week*1
            }
        });
        setSubjectPlanDataApiAction({
            type:'fetch_plan_detail',
            value: {schoolYear: yearValue*1}
        })

    }

    const handleWeekChange = (weekStartDay) =>{
        const selectedWeek = dayjs(weekStartDay).isoWeek();
        const selectedYear = dayjs(weekStartDay).year();
        setWeek(1*selectedWeek);

        //Gửi yêu cầu lấy số liệu
        setAction({
            type:'fetchData',
            value: {
                classId: class_id,
                schoolYear: selectedYear*1,
                schoolWeek: selectedWeek*1
            }
        });     
    }

    //Control việc cập nhật một item trong sổ đầu bài (cấp nhật gửi từ ClassNoteBook <- NodeList <- NoteItem )
    const handleSaveItem = (item) => {
        if(class_id==='') return;

        let recs = notebookRecordItems;
        let itemIndex = recs.findIndex(obj => obj.id===item.id);
        recs[itemIndex] = {
            ...recs[itemIndex],             
            subject_id: item.subject_id,
            period_plan: item.period_plan,
            lecture_id: item.lecture_id,
            teacher_id: item.teacher_id,
            absen_student: item.absen_student,            
            week_plan: item.week_plan,
            is_late: item.is_late,
            comment: item.comment,
            note: item.note
        }  
        
        //Gửi số liệu về server
        setAction({
            type:"save",
            value:{
                classId: class_id,
                schoolYear: year,
                schoolWeek: week,
                notebookRecordItem: item,
                notebookRecords: recs
            }
        })
    }
   
    return(            
        <>

            <div className="row pt-3">
                <div className="col-md-5">
                    <h4>SỔ GHI ĐẦU BÀI</h4>
                </div>
                <div className="col-md-7">
                    <div className="row g-3">
                        <div className="col-xs-2 col-md-2">
                            {                       
                                class_id!=='ABC' && 
                                <SubmitClassNotebook 
                                    data={notebookRecordItems} 
                                    classId={class_id} year={year} week={week}>Tạo SP Lists</SubmitClassNotebook>
                            }
                        </div>
                        <div className="col-xs-2 col-md-2">
                            <YearSchoolSelection
                                year = {year}
                                onYearChange = {handleYearChange}
                            >Năm học
                            </YearSchoolSelection>
                        </div>
                        <div className="col-xs-2 col-md-4">
                            <WeekSelection                                
                                selectedYear = {year}
                                onWeekChange ={handleWeekChange}
                            >Tuần học
                            </WeekSelection>
                        </div>                            
                        <div className="col-xs-2 col-md-2">
                            <GradeSelection 
                                grade = {grade}
                                onGradeChange={handleGradeChange}
                            >
                                Khối
                            </GradeSelection>
                        </div>
                        <div className="col-xs-2 col-md-2">
                            <ClassSelection 
                                class_id={class_id}
                                classList = {classItems.filter(c =>  1*c.Grade===1*grade && 1*year >= 1*c.StartYear && 1*year <= c.EndYear)}
                                onClassChange = {handleClassChange}
                            >
                                Lớp học 
                            </ClassSelection>                              
                        </div>
                    </div>
                </div>
            </div>
            <div className="row pt-2"> 
                {                      
                    isProcessingNotebookData?
                    <MessageBox>Đang lấy dữ liệu....</MessageBox>
                    :
                    notebookRecordItems.length>0?
                    <NoteList                         
                        data = {notebookRecordItems}                                    
                        subjects = {subjectItems} 
                        teachers = {teacherItems} 
                        subjectPlanDetails = {subjectPlanDetailItems_v2}
                        onSaveItemClick = {handleSaveItem}
                    />
                    :
                    <MessageBox>Hãy chọn Khối, lớp, tuần, năm để xem sổ đầu bài</MessageBox>
                }
            </div>
        </>
    );

}

export default ClassNotebook;