import { useContext } from 'react';
import {
    HashRouter,
    Routes,
    Route,
  } from 'react-router-dom'

import { MyAppContext } from '../../context/MyAppContext';

  //app pages component
import ClassNotebook  from '../../components/notebook/ClassNotebook'
import SchedulePage from '../../components/schedule/SchedulePage';
import SubjectPlanPage from '../../components/subject/SubjectPlanPage';
import TeacherPage from '../../components/teacher/TeacherPage';
import ClassPage from '../../components/classes/ClassPage';
import SubjectPage from '../../components/subject/SubjectPage';
import LectureScheduleRptPage from '../../components/report/LectureScheduleRptPage';
import SchoolYearPage from '../../components/setting/SchoolYearPage';
import CategoryPage from '../../components/category/CategoryPage';

import {ProtectedComponent} from '../../SharePointAccess';
import DashboardPage from '../../components/dashboard/DashboardPage';
import WeeklyCalendarPage from '../../components/calendar/WeeklyCalendarPage';
import MonthlyCalendarPage from '../../components/calendar/MonthlyCalendarPage';

import NewsBoardPage from '../../components/newsboard/NewsboardPage';
import LSPage from '../../components/schedule/LSPage';
import WorkPlanPage from '../../components/workplan/WorkPlanPage';
import MyPage from '../../components/myinfo/MyPage';
import ImportSchedulePage from '../../components/schedule/ImportSchedulePage';
import WorkOffDay from '../../components/calendar/WorkOffDay';
import FilePage from '../fileman/FilePage';


const  AppRoutes = () => {
    const [appContext,] = useContext(MyAppContext);
    const userRoles  = appContext.CurrentUser.Roles;
    return (
        <Routes>
            <Route exact path='/' element ={<DashboardPage />}></Route>
            <Route exact path='/tkb' element ={<SchedulePage />}></Route>
            <Route exact path='/bg' element ={<LSPage />}></Route>
            <Route exact path='/khct' element ={<WorkPlanPage />}></Route>
            <Route exact path='/notebook' element ={<ClassNotebook />}></Route>
            <Route exact path='/mypage' element ={<MyPage />}></Route>
            {
                (userRoles.includes('admin') || userRoles.includes('leader') || userRoles.includes('manager') ) &&
                <>
                    <Route exact path='/bc-bao-giang' element ={<LectureScheduleRptPage />}></Route>
                    {/* <Route exact path='/bc-chua-bao-giang' element ={<ChuaBaoGiangRptPage />}></Route> */}
                </>
               
            }
            {   
                userRoles.includes('admin') &&
                <>
                    <Route exact path='/khdh' element ={<SubjectPlanPage />}></Route>
                    <Route exact path='/giao-vien' element ={<TeacherPage />}></Route>
                    <Route exact path='/nam-hoc' element ={<SchoolYearPage />}></Route>
                    <Route exact path='/lop' element ={<ClassPage />}></Route>
                    <Route exact path='/mon-hoc' element ={<SubjectPage />}></Route>
                    <Route exact path='/lich-tuan' element ={<WeeklyCalendarPage />}></Route>
                    <Route exact path='/lich-thang' element ={<MonthlyCalendarPage />}></Route>
                    <Route exact path='/tin-tuc' element ={<NewsBoardPage />}></Route>
                    <Route exact path='/importSchedule' element ={<ImportSchedulePage />}></Route>
                    <Route exact path='/workoffday' element = {<WorkOffDay/>}></Route>
                    <Route exact path='/danh-muc' element ={<CategoryPage />}></Route>
                    <Route exact path='/tai-lieu' element={<FilePage />}></Route>   
                    <Route exact path='/sp' element={<ProtectedComponent />}></Route> 
                </>
            }                  
        </Routes>
    )
}

export default AppRoutes;
