import  {useEffect, useState} from 'react';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { v4 as uuidv4 } from 'uuid';
import {orderBy as _orderBy, remove} from 'lodash';

import { loginRequest, sharePointConfig } from '../../authConfig';
import {
    getSharePointListItems,
    GetSharePointSiteDrive,
    GetSharePointSiteDriveChildren,
    UploadFile,
    UpdateFileMeta,
} from '../../graph';
import {getSchoolYear} from '../../globalFuntions';

export const useFileDataApi = (defaultAction = {type:'fetch', 
                                                value: {schoolYear: getSchoolYear(), teacherId:''}
    }) => 
{
    const [driveItems, setDriveItems] = useState([]);
    const [driveChildrenItems, setDriveChildrenItems] = useState([]);
    const [fileListItems, setFileListItems] = useState([]);

    const [action, setAction] = useState(defaultAction);
    const [flagReloadAfterAddNew, setFlagReloadAfterAddNew] = useState(false);
    const [fileApiMessage, setFileApiMessage] = useState("");

    const {instance, accounts} = useMsal();

    const fetchSiteDrive = (accessToken, schoolYear) =>{       
        GetSharePointSiteDrive(
            accessToken 
        ).then(response => {  
            console.log('response', response);
            let items = [];
            setDriveItems(items);            
        })
    }

    //  =======================================================================
    //  fetchSiteDriveChildren
    //  Lấy các DriveItem theo thư mục trong Drive mặc định của Site dữ liệu
    //  - schoolYear: Năm học bắt đầu của một khóa học
    //  =======================================================================
    const fetchSiteDriveChildren = (accessToken, schoolYear) =>{
        const sourceFolder = `${schoolYear}-${schoolYear+1}`;
        GetSharePointSiteDriveChildren(
            accessToken, sourceFolder 
        ).then(response => {  
            console.log('response of fetchSiteDriveChildren', response);
            let items = [];
            const listItemArray = response.value;
            for(let i=0; i<listItemArray.length; i++){
                items.push(listItemArray[i]);
            }
            setDriveChildrenItems(items);
        })
    }

    //  =======================================================================
    //  fetchSiteDriveAsList
    //  Lấy các các mục trong Drive mặc định của Site dữ liệu dưới dạng ListItem
    //  - schoolYear: Năm cần lấy
    //  =======================================================================
    const fetchSiteDriveAsList = (accessToken, schoolYear, teacherId) => {
        let resultItems = [];
        getSharePointListItems(
           accessToken, 
           sharePointConfig.SiteDriveListId,
           "Name,Title,SchoolYear,DocTypeId,TeacherId,DepartmentId,SharingScope,Comment,id", 
           `(fields/SchoolYear eq ${schoolYear}) and (fields/TeacherId eq '${teacherId}')`
       ).then(response => {  
            const listItemArray = response.value;
            for(let i=0; i<listItemArray.length; i++) {
                const listItem = listItemArray[i];                
                resultItems.push({...listItem.fields, 
                    CreatedDateTime: new Date(listItem.createdDateTime), 
                    LastModifiedDateTime: new Date(listItem.lastModifiedDateTime),
                    CreatedBy: listItem.createdBy,
                    ModifiedBy: listItem.lastModifiedBy,
                    WebUrl: listItem.webUrl,
                })
            }
            resultItems =_orderBy(resultItems,['CreatedDateTime','Title'], ['desc','asc']);
            console.log('resultItems (fileListItems)', resultItems);
            setFileListItems(resultItems);
           
       })
   }

    const UploadFilesToServer = (accessToken, filesToUpload, schoolYear, fieldValues) => {
        const destFolder = `${schoolYear}-${schoolYear+1}`;
        for(let i=0; i<filesToUpload.length; i++){
            const singleFile =  filesToUpload[i];
            UploadFile(accessToken, singleFile, destFolder).then(response=>{
                if(response.id){
                    UpdateFileMeta(accessToken, response.id, {fields:{...fieldValues, Title: singleFile.name}})
                    .then(updateResponse=>{
                        console.log(`Cập nhật meta cho file ${singleFile.name} response`, updateResponse);
                    });
                }else{  //Lỗi khi upload file
                    
                }
                console.log(`File ${singleFile.name} upload response`, response);
            })
        }
    }

    useEffect(() => {     
        const accessTokenRequest = {
            ...loginRequest,
            account: accounts[0]
        }

        const processData = async () => {  
            instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
                const accessToken = accessTokenResponse.accessToken;
                switch(action.type){
                    case 'upload':
                        UploadFilesToServer(accessToken, action.value.file, action.value.schoolYear, action.value.fieldValues);
                        break;
                    case 'del':
                        break;
                    case 'fetch-drivechilden':
                        fetchSiteDriveChildren(accessToken, action.value);
                        break;
                    default:          
                        fetchSiteDriveAsList(accessToken, action.value.schoolYear, action.value.teacherId);
                        //fetchSiteDrive(accessToken, action.value);
                }
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest);                    
                }
                console.log(error);
            }) 
        }; 

        processData();  

    },[accounts, action, instance]);

    return [{driveItems, driveChildrenItems, fileListItems},  setAction];
}
