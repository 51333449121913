import {useNewsBoardDataApi} from './NewsboardData';
import NewsboardItemViewer from './NewsboardItemViewer';

function NewsboardViewer(){
    const [{newsItems}, setAction] = useNewsBoardDataApi();

    return (
        <>
            <div className="row">
                <h5 className='ps-0'>Bản tin - Thông báo</h5>
            </div>
            <div className="row pt-2">
                {
                    newsItems.length>0&& newsItems.map(item=>
                        <NewsboardItemViewer key={item.NewsId} item={item}/>    
                    )
                }
            </div>
        </>


    )
}

export default NewsboardViewer;