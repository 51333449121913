import React, {useEffect} from "react";
import SchoolYearList  from "./SchoolYearList";
import {createBlankSchoolYearItem, useSchoolYearDataApi} from './SchoolYearData';

function SchoolYearPage() { 
    const[{schoolYearItems, flagReloadAfterAddNew}, setAction] = useSchoolYearDataApi({type:'get',value:''});

    const handleAdd = () => {
        let blankItem = createBlankSchoolYearItem();
        setAction({
            type:'add',
            value: blankItem
        });
    }

    const handleSave = (item) => {
        setAction({
            type:'save',
            value: item
        });
    }

    const handleDelete = (item) =>{
        setAction({
            type:'del',
            value: item
        });
    }

    useEffect(()=>{
        if(flagReloadAfterAddNew){
            setAction({
                type:'get',
                value: ''                
            });
            //window.location.reload(false);
        }
    },[flagReloadAfterAddNew])

    return (
        <>
            <div className="row">
                <h4 className="pt-3">Thông tin về  năm học</h4>
            </div>
            <div className="row">
                {
                    <SchoolYearList 
                        data={schoolYearItems}
                        onAddItemClick = {handleAdd}
                        onSaveItemClick = {handleSave}
                        onDeleteItemClick = {handleDelete}
                    />
                }
            </div>
        </>
    )
}

export default SchoolYearPage;