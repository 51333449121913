/** Cấu hình phục vụ khởi tạo dữ liệu và hiển thị thời khóa biểu */
export const scheduleConfig = {
    NumOfClassColsPerTable: 5,  //Số lớp hiển thị thành cột trong bảng thời khóa biểu
    SCHEDULE_LS_KEY: "eSchool_Schedule_LSKey",
    SCHEDULE_LECTURE_LATE_WEEKS: 1, //Số tuần chênh lệch giữa tuần học thực tế và tuần theo PPCT

    SchedulePartDay_AM: "Sáng",
    SchedulePartDay_PM: "Chiều",

    ScheduleLogType_Normal: "",
    ScheduleLogType_DuGio: "Dự giờ",
    ScheduleLogType_NghiLe: "Nghỉ lễ",
    ScheduleLogType_KhoaSua: "Khóa sửa",
    ScheduleLogType_DayBu: "Dạy bù",

    LectureScheduleType_Normal: "",
    LectureScheduleType_DayBu: "Dạy bù",    
    ScheduleLectureStatus_DaNhapPPCT: "Đã nhập PPCT",
    ScheduleLectureStatus_DayXong: "Dạy xong",
    ScheduleLectureStatus_NghiOm: "Nghỉ ốm",
    ScheduleLectureStatus_NghiLe: "Nghỉ lễ",
    ScheduleLectureStatus_CoDayBu: "Có dạy bù", 
}

export const schoolConfig = {
    Name: 'Trường THCS Nguyễn Trường Tộ',
    Address: 'Đống Đa, Hà Nội',
    ContactPerson:'',
    ContactPhone: '',
    ContactEmail: '',
    WebsiteUrl: '',
    Grades: [6,7,8,9],
    StartMonth: 8, //Tháng bắt đầu của năm học mới  
    DocumentType: [
        {
            TypeId:'ae6face1-454c-4643-b9ba-5f9a9ba0abdd',
            Name: 'Giáo án'
        },     
        {
            TypeId:'ddf540cb-95b0-4f25-84eb-32af569adf41',
            Name: 'Kế hoạch công tác của cá nhân' //Chỉ cá nhân xem
        }, 
        {
            TypeId:'c52f5153-91ac-4f90-81e0-9739614e60bc',
            Name: 'Kế hoạch công tác của tổ'  //BGH, Tổ trợ lý
        },   
        {
            TypeId:'1d8f3d7f-1a78-48dc-a87a-01d60f924059',
            Name: 'Kế hoạch dạy học bộ môn'
        }, 
        {
            TypeId:'2d8b9982-8a59-4c9f-afda-759a71dfc495',
            Name: 'Chuẩn kiến thức của bộ môn'
        },       
        {
            TypeId:'9c78dedd-de37-4226-9bbf-6d24bc20c40c',
            Name: 'Tài liệu khác...'
        },
    ],
    UserRoles: [
        {
            Name: 'Leader',
            DisplayName: 'Lãnh đạo'
        },
        {
            Name: 'Manager',
            DisplayName: 'Quản lý'
        },
        {
            Name: 'TeamLead',
            DisplayName: 'Tổ trưởng'
        },
        {
            Name: 'ViceTeamLead',
            DisplayName: 'Tổ phó'
        },        
        {
            Name: 'Admin',
            DisplayName: 'Quản trị viên'
        },    
    ]
}

export const contextConfig = {
    ProductionMode: true,      //False - chạy local, true là chạy trên Azure/host
}

export const requestConfig = {
    MAX_REQUEST_PER_BATCH: 20,  //Dùng khi import và lưu Thời khóa biểu
}

