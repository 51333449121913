
import React, {useState, useContext} from 'react';
import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import * as isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear'; 
import * as isLeapYear from 'dayjs/plugin/isLeapYear';

import { MyAppContext } from '../../context/MyAppContext';

const WeekSelection = (props) => {
    dayjs.extend(isoWeek);
    dayjs.extend(isoWeeksInYear);    
    dayjs.extend(isLeapYear);  
    
    const [appContext,] = useContext(MyAppContext);
    const schoolYearItems = appContext.SchoolYearInfo;    

    const {selectedYear, selectedDate, onWeekChange, children} = props; 

    const [selectedIsoWeekStartDay, setSelectedIsoWeekStartDay] = useState(
        selectedDate===undefined || selectedDate===null ? null : dayjs(selectedDate).isoWeekday(1).format('MM/DD/YYYY'));   
    
    const weeks = (start,end) => Array(end - start).fill().map((_,idx)=>start+idx); 
    const schoolYearObj = schoolYearItems.find(y=>y.StartYear<=selectedYear && selectedYear<=y.EndYear);

    if(schoolYearItems.length<=0) {
        return <></>
    } 
    else{   
        return(
            <div>
                {children}
                {schoolYearObj!==undefined &&
                    <select name='selectedWeek' id='đllSelectedWeek' className="form-select form-select-sm" 
                        value={selectedIsoWeekStartDay!==null ? selectedIsoWeekStartDay : ""} 
                        onChange = {e=> {
                            const value = e.target.value;
                            setSelectedIsoWeekStartDay(value);
                            onWeekChange(value, e.target.selectedIndex);
                        }}               
                    >
                        {
                            <option value=""></option>
                        }                        
                        <optgroup label='Học kỳ 1'>
                        {
                            weeks(1,1+schoolYearObj.Sem1_TotalWeeks*1).map((w,index) => {  
                                const startDateOfWeekSem1 = dayjs(schoolYearObj.Sem1_StartDay).add(index*7,'day'); 
                                return(
                                    <option key={w} value={startDateOfWeekSem1.format('MM/DD/YYYY')}>
                                        {w} ({startDateOfWeekSem1.format('DD/MM/YYYY')})
                                    </option> 
                                )                    
                            })
                        }
                        </optgroup>
                        <optgroup label='Học kỳ 2'>
                        {
                            weeks(1 + schoolYearObj.Sem1_TotalWeeks*1, 1 + schoolYearObj.Sem1_TotalWeeks*1 + schoolYearObj.Sem2_TotalWeeks*1).map((w,index) => {
                                const startDateOfWeekSem2 = dayjs(schoolYearObj.Sem2_StartDay).add(index*7,'day');
                                return  (                                
                                    <option key={w} value={startDateOfWeekSem2.format('MM/DD/YYYY')}>
                                        {w} ({startDateOfWeekSem2.format('DD/MM/YYYY')})
                                    </option>
                                ) 
                            })                            
                        }
                        </optgroup>                
                    </select>
                }
            </div>
        );
    }
}

export default WeekSelection;