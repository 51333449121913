import React, { useState, useContext, useEffect } from 'react';
import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

import { MyAppContext } from '../../context/MyAppContext';
import { scheduleConfig } from '../../appConfig';
import { useSubjectPlanDataApi } from '../subject/SubjectPlanData';
import { useLectureScheduleDataApi } from './LSData';
import MessageBox from '../common/MessageBox';

import YearSchoolSelection from '../common/YearSchoolSelection';
import WeekSchoolSelection from '../common/WeekSchoolSelection';
import LSList from './LSList';


const LSPage = (props) => {
    dayjs.extend(isoWeek);   

    const [appContext,] =  useContext(MyAppContext);
    const CurrentUser = appContext.CurrentUser;   
    const teacherItems = appContext.Teachers;
    const subjectItems = appContext.Subjects; 
    const [teacherSubjectItems, setTeacherSubjectItems] = useState([]); //Các môn học dạy bởi giáo viên đang được chọn/xét

    const [firstDateOfWeekIsoString, setFirstDateOfWeekIsoString] = useState(appContext.CurrentDateInfo.FirstDateOfWeekIsoString);
    const [schoolYear, setSchoolYear] = useState(appContext.SchoolYear);    
    const [schoolWeek, setSchoolWeek] = useState(appContext.SchoolWeek);
    
    const [selectedTeacherId, setSelectedTeacherId] = useState(appContext.CurrentUser.TeacherId); 
    const [selectedTeacherObj, setSelectedTeacherObj] = useState (teacherItems.find(t=>t.TeacherId === appContext.CurrentUser.TeacherId));
    const [{subjectPlanDetailItems_v2}, setSubjectPlanDetailAction] = useSubjectPlanDataApi({type:'fetch_plan_detail',value:{schoolYear:schoolYear, subjectIdArray: selectedTeacherObj ? selectedTeacherObj.SubjectId:[]}});

    const [{lsScheduleItem, isProcessingScheduleData, flagReloadAfterAddNew}, setLectureScheduleAction] = useLectureScheduleDataApi();
    const [isOwner, setIsOwner] = useState(selectedTeacherObj ? true:false);

    const getSubjectItemsTeachedByTeacher = (subjectIdArray) => {
        let subjects = [];
        subjectIdArray.forEach(sId=> {
            const subjectItem = subjectItems.find(s=>s.SubjectId===sId);
            if(subjectItem) subjects.push(subjectItem);
        })
        return subjects;
    }
 
    const handleSchoolYearChange = (yearValue) => {   
        setSchoolYear(1*yearValue);       
    }
    
    const handleWeekChange = (weekStartDay, selectedSchoolWeek) => { 
        if (selectedSchoolWeek<1) {
            alert("Chưa chọn tuần");
            return;
        }
        setFirstDateOfWeekIsoString(dayjs(weekStartDay).toISOString());
        setSchoolWeek(selectedSchoolWeek);

        //Gửi yêu cầu lấy số liệu
        setLectureScheduleAction({
            type:'fetchData',
            value: {   
                firstDateOfWeekIsoString: dayjs(weekStartDay).toISOString(),             
                schoolYear: schoolYear,
                schoolWeek: selectedSchoolWeek,
                teacherId: selectedTeacherId,                
            }
        });   
    }
    
    const handleTeacherSelectionChange = (teacherId) => {
        setSelectedTeacherId(teacherId); 
        const teacherObj = teacherItems.find(t=>t.TeacherId === teacherId);
        setSelectedTeacherObj (teacherObj);
        
        //console.log('teacherObj.SubjectId', teacherObj.SubjectId);
        //console.log('getSubjectItemsTeachedByTeacher(teacherObj.SubjectId)', getSubjectItemsTeachedByTeacher(teacherObj.SubjectId));

        if (teacherObj) {
            setSubjectPlanDetailAction({
                type:'fetch_plan_detail',
                value:{
                    schoolYear:schoolYear, 
                    subjectIdArray:teacherObj.SubjectId
                }
            })
            setTeacherSubjectItems(getSubjectItemsTeachedByTeacher(teacherObj.SubjectId));
        }    

        //Xem người dùng hiện tại với giáo viên được chọn có phải là một không
        setIsOwner(teacherObj ? (appContext.CurrentUser.TeacherId===teacherObj.TeacherId || appContext.CurrentUser.UserName.toLowerCase().includes('nguyenbui')): false); 
        console.log('isOwner', teacherObj ?  (appContext.CurrentUser.TeacherId===teacherObj.TeacherId || appContext.CurrentUser.UserName.toLowerCase().includes('nguyenbui')) : false);

        //Gửi yêu cầu lấy số liệu
        setLectureScheduleAction({
            type:'fetchData',
            value: {     
                firstDateOfWeekIsoString: firstDateOfWeekIsoString,            
                schoolYear: schoolYear,
                schoolWeek: schoolWeek,
                teacherId: teacherId,                
            }
        });         
    }

    const handleRefreshData = () => {
        //Gửi yêu cầu lấy số liệu
        setLectureScheduleAction({
            type:'fetchData',
            value: {     
                firstDateOfWeekIsoString: firstDateOfWeekIsoString,            
                schoolYear: schoolYear,
                schoolWeek: schoolWeek,
                teacherId: selectedTeacherId,                
            }
        });        
    }

    const handleLectureScheduleCellChange = (lsItem) => {
        const idx = lsScheduleItem.records.findIndex(item=>item.id===lsItem.id);
        if(idx >=0) {
            lsScheduleItem.item_id = lsItem.item_id;
            lsScheduleItem.school_week = schoolWeek;
            lsScheduleItem.start_date_week_isostring = firstDateOfWeekIsoString;
            lsScheduleItem.records[idx] = lsItem;
            lsScheduleItem.flagDataChanged =true;
        }

        handleSaveLectureSchedule();
    }

    const handleSaveLectureSchedule = () => {
        if(lsScheduleItem.flagDataChanged === false || schoolWeek===0) {
            alert(`Không có thay đổi hoặc tuần học chưa đúng (${schoolWeek})`);
            return;
        }
        //console.log('lsScheduleItem gửi về server', lsScheduleItem);
                
        lsScheduleItem.teacher_name = selectedTeacherObj ? selectedTeacherObj.FullName: selectedTeacherId;
        //lsScheduleItem.flagDataChanged = !lsScheduleItem.flagDataChanged;
        setLectureScheduleAction({
            type:'save',
            value: lsScheduleItem,
        });
    }

    useEffect(()=>{
        if(CurrentUser && teacherItems.length>0) {   
            const currentLoginTeacher = teacherItems.find(t=>t.UserName && t.UserName.toLowerCase()===CurrentUser.UserName);
            if(currentLoginTeacher) {
                setSelectedTeacherId(currentLoginTeacher.TeacherId);
                setSelectedTeacherObj(currentLoginTeacher);                  
                setTeacherSubjectItems(getSubjectItemsTeachedByTeacher(currentLoginTeacher.SubjectId)); 
            } else {
                console.error("Khong tim thay giao vien " + CurrentUser.UserName);
            }

            setLectureScheduleAction({
                type:'fetchData',
                value: {   
                    firstDateOfWeekIsoString: firstDateOfWeekIsoString,              
                    schoolYear: schoolYear,
                    schoolWeek: schoolWeek,
                    teacherId: currentLoginTeacher.TeacherId,                
                }
            });
            
        }
    },[CurrentUser,teacherItems.length]);


    useEffect(()=> {
        if(flagReloadAfterAddNew){
            setLectureScheduleAction({
                type:'fetchData',
                value: {     
                    firstDateOfWeekIsoString: firstDateOfWeekIsoString,            
                    schoolYear: schoolYear,
                    schoolWeek: schoolWeek,
                    teacherId: selectedTeacherId,                
                }
            });
        }
    },[flagReloadAfterAddNew]);

    let totalPeriods=0;
    let latePeriods=0;
    let makeupLessons = 0;
    let notSelectLecure=0;
    if (lsScheduleItem && lsScheduleItem.records) {
        totalPeriods = lsScheduleItem.records.filter(item=>item.teacher_id!=="" && item.type!==scheduleConfig.LectureScheduleType_DayBu).length;
        latePeriods = lsScheduleItem.records.filter(item=>item.is_late===true && item.type!==scheduleConfig.LectureScheduleType_DayBu).length;
        makeupLessons = lsScheduleItem.records.filter(item=>item.type===scheduleConfig.LectureScheduleType_DayBu).length;
        notSelectLecure = lsScheduleItem.records.filter(item=>item.teacher_id!=="" && (item.lecture_id==="" ||item.lecture_id===undefined )).length;
    }

    //console.log('user info', appContext);
    return(
        <div className='ms-3 px-2'>
            <div className="row pt-3">
                <div className="col-md-6">
                    <h4>LỊCH BÁO GIẢNG</h4>
                    <p>
                        Tuần {schoolWeek} năm học {schoolYear}-{schoolYear+1} - Thực hiện từ: {dayjs(firstDateOfWeekIsoString).isoWeekday(1).format('DD/MM/YYYY')} đến {dayjs(firstDateOfWeekIsoString).isoWeekday(6).format('DD/MM/YYYY')}
                        <br/>Giáo viên: {selectedTeacherObj?selectedTeacherObj.FullName:CurrentUser.UserName}  {CurrentUser.DisplayName ?"":""}
                        - <b>{totalPeriods}</b> tiết (Chưa báo bài giảng: <b>{notSelectLecure}</b>; Dạy chậm: <b>{latePeriods}</b>) + Dạy bù: <b>{makeupLessons}</b>
                    </p>       
                </div>
                <div className="col-md-6 pt-4 d-print-none">
                    <div className="row">
                        <div className="col-xs-3 col-md-3 px-1" style={{visibility:'hidden'}}>
                            <YearSchoolSelection 
                                year={schoolYear}
                                onYearChange = {handleSchoolYearChange}
                            >
                                Năm học
                            </YearSchoolSelection>
                        </div> 
                        <div className="col-xs-3 col-md-3 px-2">
                            {/* <WeekSelection
                                selectedYear = {schoolYear}
                                selectedDate = {firstDateOfWeekIsoString}
                                onWeekChange ={handleWeekChange}
                            >
                                Tuần học
                            </WeekSelection> */}

                            <WeekSchoolSelection
                                selectedYear = {schoolYear}
                                selectedDate = {firstDateOfWeekIsoString}
                                onWeekChange ={handleWeekChange}                            
                            >
                                Tuần học
                            </WeekSchoolSelection>
                        </div>
                        <div className='col-xs-4 col-md-4 px-2'>
                            Giáo viên
                            <select name="sl-teacher" disabled= {appContext.CurrentUser.Roles.includes('admin') ? '' : 'disabled'}                              
                                className="form-select form-select-sm"
                                value = {selectedTeacherObj?selectedTeacherObj.TeacherId:""}
                                onChange = {(e) => handleTeacherSelectionChange (e.target.value)}
                            >
                                <option value=""></option>
                                {
                                    teacherItems.map((t,idx) =>
                                        <option key={idx} value={t.TeacherId}>{t.FullName}</option>
                                    )
                                }
                            </select>
                        </div>
                        <div className="col-xs-2 col-md-2 ps-1 pt-3 mt-1 text-end">
                            <button className='btn btn-info btn-sm' 
                                onClick={handleRefreshData}
                            >
                                <i className="fa fa-refresh" aria-hidden="true"></i>
                            </button>
                            {/* &nbsp;&nbsp;&nbsp;
                            <button className='btn btn-primary btn-sm' 
                                disabled={(!isProcessingScheduleData && !flagReloadAfterAddNew)?"":"disabled"}
                                onClick = {handleSaveLectureSchedule}>
                                Lưu SBG
                            </button> */}
                        </div> 
                    </div>
                </div>              
            </div>
            <div className="row pt-2">
                <div className="col-md-12">
                    {
                        isProcessingScheduleData ?
                        <MessageBox>Đang lấy dữ liệu...</MessageBox>
                        :
                        (lsScheduleItem && selectedTeacherObj) 
                        ? <LSList
                            schedules = {lsScheduleItem.records}
                            subjects = {subjectItems}
                            subjectPlanDetails = {subjectPlanDetailItems_v2}
                            teacherSubjects = {teacherSubjectItems}
                            teacherId = {selectedTeacherId}
                            schoolYear = {schoolYear}
                            schoolWeek = {schoolWeek}
                            firstDayOfWeekIsoString = {firstDateOfWeekIsoString}
                            onUpdateCellChange = {handleLectureScheduleCellChange}
                            isOwner = {isOwner}
                        />
                        : <MessageBox>Không có dữ liệu!</MessageBox>
                    }                    
                </div>
            </div>            
        </div>
    )
}

export default LSPage;
