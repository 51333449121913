import React, {useState} from "react";
import { createBlankNewsItem } from "./NewsboardData";
import NewsItem from './NewsboardItem';
import NewsBoardItemDialog from "./NewsboardItemDialog";

function NewsBoardList({data, onSaveItemClick, onDeleteItemClick}){
    const [item, setItem] = useState(createBlankNewsItem()[0]);
    return(        
        <div className="form-inline" name="frmNewsList">                   
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th style={{width:'20%'}} className='text-left'>Tiêu đề</th>
                        <th style={{width:'30%'}} className='text-left'>Tóm tắt</th>
                        <th style={{width:'8%'}} className="text-left">Phân loại</th>                        
                        <th style={{width:'10%'}} className="text-left">Người đăng</th>
                        <th style={{width:'6%'}} className="text-left">Ngày ngừng</th>
                        <th style={{width:'8%'}} className='text-end'>
                            <button type="button" 
                                className="btn btn-primary btn-sm" data-bs-toggle="modal" 
                                data-bs-target={"#dlg" + item.NewsId}
                            >Thêm tin mới</button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    { 
                        data.map((item, index) => 
                            <NewsItem 
                                key={item.NewsId}
                                item = {item} 
                                action = {item.Title === ''? 'new' : ''} 
                                onSaveItemClick = {onSaveItemClick}
                                onDeleteItemClick={onDeleteItemClick}                                
                            />
                        )
                    }
                </tbody>
            </table>
            <NewsBoardItemDialog 
                item={item} 
                action={'new'} 
                onSaveItemClick = {onSaveItemClick}
            />
        </div>
    )
}

export default NewsBoardList;