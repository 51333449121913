import React, {useState} from "react";
import * as dayjs from 'dayjs';
import DateSelection from "../common/DateSelection";
import NewsBoardItemDialog from "./NewsboardItemDialog";

function NewsItem(props){ 
    const [item, setItem] = useState(props.item);
    const [beforeEditItem, setBeforeEditItem] = useState({...props.item});

    const [action, setAction] = useState(props.action);

    const handleEdit = () =>{
        setBeforeEditItem({...item});
        setAction('edit');
    }

    const handleSave = () => {
        props.onSaveItemClick(item);
        setAction('');
    }

    const handleCancel = () =>{
        console.log('handle cancel');
        if(action==='new'){
            handleDelete(item.NewsId);
        }else{ //edit action nên hủy các sửa đổi, khôi phục về giá trị trước khi sửa
            setItem(beforeEditItem);
        }
        setAction('');
    }

    const handleDelete = ()=>
    {
        if (action!=='edit' && action !=='new') {  
            if ( window.confirm(`Bạn chắc chắn xóa bản tin ${item.Title} không?`) ) {
                props.onDeleteItemClick(item); 
            }            
        } else {
            props.onDeleteItemClick(item); 
        }   
    }

    return(
        <>
            <tr>
                <td>
                    <a  className="text-decoration-none" 
                        href={"#dlg" + item.NewsId} data-bs-toggle="modal" 
                        data-bs-target={"#dlg" + item.NewsId}
                        onClick={()=>setAction('')}
                    >{item.Title}</a>
                </td>
                <td>{item.Summary}</td>
                <td>{item.Category}</td>
                <td>
                    {item.CreatedBy.displayName}
                </td>
                <td>
                    {dayjs(item.ExpiredDate).format('DD/MM/YYYY')}
                    <NewsBoardItemDialog 
                        item={item} 
                        action={action} 
                        onSaveItemClick = {props.onSaveItemClick}/>
                </td>            
                <td className="text-end">  
                    <button type="button" 
                        className="btn btn-primary btn-sm" data-bs-toggle="modal" 
                        data-bs-target={"#dlg" + item.NewsId}
                        onClick={()=>setAction('edit')}
                    >&nbsp;Sửa&nbsp;</button>&nbsp;
                    <button type="button" onClick={handleDelete} className="btn btn-danger btn-sm">&nbsp;Xóa&nbsp;</button>                
                </td>
            </tr>
        </>
    )
}

export default NewsItem;