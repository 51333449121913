
import React, {useState} from 'react';
import NewsBoardItemDialog from './NewsboardItemDialog';

function NewsboardItemViewer(props){
    const [item, setItem] = useState(props.item);
    return (
        <div className="card px-0 ms-0 me-2">
            <div className="card-body">
                <h6 className="card-title">
                    <a className="text-decoration-none" 
                        href={"#dlg" + item.NewsId} 
                        data-bs-toggle="modal" 
                        data-bs-target={"#dlg" + item.NewsId}>{item.Title}</a>
                </h6>
                <p className="card-text">{item.Summary}</p>
                {/* <a href={'news/'+item.NewsId} className="btn btn-primary">Xem chi tiết</a> */}
                {/* <a className="text-decoration-none" 
                    href={"#dlg" + item.NewsId} 
                    data-bs-toggle="modal" 
                    data-bs-target={"#dlg" + item.NewsId}>Xem chi tiết</a> */}
            </div>
            <div>
                <NewsBoardItemDialog item={item} action={''}/>
            </div>
        </div>
    )
}

export default NewsboardItemViewer;