import DatePicker from "react-multi-date-picker";
const weekDays = ["CN","Hai","Ba","Tư","Năm", "Sáu","Bẩy"];
export default function WorkOffDay() {    
    return (
        <DatePicker weekDays={weekDays} multiple render={<CustomMultipleInput/>}  />
    )
}

const CustomMultipleInput = ({openCalendar, value}) => {
    return (
        <div className="ps-3">
        <h5 className="pt-3">Lịch nghỉ học</h5>        
        <table>
            <thead>
                <tr>
                    <th width="10%">
                        <a onClick={openCalendar} href="#">Ngày nghi</a>
                    </th>
                    <th width="15%">Tiết sáng</th>
                    <th width="15%">Tiết chiều</th>
                    <th width="75%">Lớp</th>
                </tr>
            </thead>
            <tbody>
                {
                    value.map((v,idx) => 
                        <tr key={idx}>
                            <td>{v}</td>
                            <td>
                                {
                                    [1,2,3,4,5].map((period,idx) =>
                                        <>
                                            <input type="checkbox" name="am_period" key={"am_"+idx} value={period} className="me-1"/>
                                            <label className="me-2">{period}</label>
                                        </>                                        
                                    )
                                }
                            </td>
                            <td>
                            `{
                                    [1,2,3,4,5].map((period,idx) =>
                                        <>
                                            <input type="checkbox" name="pm_period" key={"pm_"+idx} value={period} className="me-1"/>
                                            <label className="me-2">{period}</label>
                                        </>                                        
                                    )
                                }                                
                            </td>
                            <td>Toàn trường</td>
                            <td></td>
                        </tr>
                    )
                }
            </tbody>
        </table>
        </div>
    )
}