import React, {useState} from "react"
import {createBlankSubjectPlanItem} from './SubjectPlanData';
import SubjectPlanImportDlg from "./SubjectPlanImportDlg";

function SubjectPlanItem(props) {
    const [item, setItem] = useState(props.item);
    const [beforeEditItem, setBeforeEditItem] = useState({...props.item});
    const [action, setAction] = useState(props.action);

    const handleAdd = () => {
        props.onResetSelectionToAdd();
        let blankItem = createBlankSubjectPlanItem()[0];
        blankItem = {
            ...blankItem,
            Grade: props.grade*1,
            StartYear: props.startYear,
            EndYear: props.startYear*1 +1
        }
        setItem(blankItem);
        setAction('add');        
    }

    const handleEdit = () => {
        setBeforeEditItem({...item});
        setAction('edit');
    }

    const handleCancel = () => {        
        if(action==='add'){
            //handleDelete();
            setAction('new');
        } else { //edit action nên hủy các sửa đổi, khôi phục về giá trị trước khi sửa
            setItem(beforeEditItem);
            setAction('');
        }        
    }

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.type==='checkbox' ? target.checked: target.value;
        if(name==='StartYear'){
            setItem({
                ...item,
                [name]: value,
                EndYear: value*1 + 1
            });
        }
        else{
            setItem({
                ...item,
                [name]: value
            });
        }
    }

    const handleDelete = () => {
        if(window.confirm('Bạn có chắc chắn muốn xóa kế hoạch này không?\nNếu muốn xóa, bấm OK')){
            props.onDeleteItemClick(item);
        }        
    }

    const handleSave = () => {        
        if(item.Grade<=0 ||item.SubjectId==="" || item.StartYear*1 < 2022 || item.Sem1_PeriodsPerWeek*1 <=0 || item.Sem2_PeriodsPerWeek*1 <=0 || item.Sem1_TotalWeeks*1 <=0 || item.Sem2_TotalWeeks*1 <=0) {
            alert(`Bạn phải nhập đủ giá trị cho các mục: Năm học, khối lớp, môn học, tổng số tuần, số tiết một tuần. Hãy kiểm tra và nhập mục còn thiếu.` );
        } else {
            const newItem = {...item, EndYear: item.StartYear+1};
            setItem(newItem);
            props.onSaveItemClick(newItem);
            setAction('save');
        }
    }

    let itemElement;
    switch(action){
        case 'edit':
        case 'add':
            itemElement = 
                <table className="table table-borderless table-sm">
                    <tbody>
                        <tr>
                            <td colSpan={4}>
                                <h5>{action==="edit"?"Sửa":"Thêm"} khai báo kế hoạch dạy học khối lớp {props.grade===0?"???":props.grade} năm học {props.startYear} - {props.startYear+1} </h5>
                                {item.RefDocument!=='' && <p>Tài liệu liên quan: {item.RefDocument}</p>}
                            </td>
                            <td colSpan={2} className='text-end'>
                                <button type="button" className="btn btn-info btn-sm" onClick={handleSave}>Lưu</button> &nbsp;
                                <button type="button" className="btn btn-warning btn-sm" onClick={handleCancel}>Thôi</button>&nbsp;
                            </td>
                        </tr>
                        <tr>
                            <td>Môn học</td>
                            {
                                (action==="add" || (action==="edit" && item.PlanDetail?.length<0)) //Chỉ cho chọn lại môn học nếu là thêm mới hoặc sửa mục chưa có PPCT
                                ?<td>                            
                                    <select name='SubjectId' className="form-select form-select-sm"
                                        value={item.SubjectId}  onChange={handleChange}
                                    >
                                        <option value=''></option>
                                        {
                                            props.subjectItems.map(s=>
                                                <option key={s.SubjectId} value={s.SubjectId}>{s.Title}</option>
                                            )
                                        }
                                    </select>                            
                                </td>
                                :<td><b>{item.Title}</b></td> //Mục đã có PPCT thì không được thay đổi môn học
                            }                            
                        </tr>                        
                        {/* <tr>
                            <td>Khối lớp</td>
                            <td>
                                <input type='number' min='6' max='9' name="Grade" className="form-control form-control-sm"
                                        value={item.Grade} onChange={handleChange} />
                            </td>                                                    
                            <td>Năm học</td>
                            <td>
                                <input type='number' name="StartYear" className="form-control form-control-sm"
                                    value={item.StartYear} onChange={handleChange} /> 
                            </td>                        
                        </tr>                         */}
                        {/* <tr>
                            <td>Khối lớp</td>
                            <td>
                                {props.grade}
                            </td>                                                    
                            <td>Năm học</td>
                            <td>
                                {props.startYear}
                            </td>                        
                        </tr>                           */}
                        <tr>
                            <td>TS tuần HK1</td>
                            <td>
                                <input type='number' name="Sem1_TotalWeeks" className="form-control form-control-sm"
                                    value={item.Sem1_TotalWeeks} onChange={handleChange} /> 
                            </td>
                            <td>S.tiết/tuần HK1</td>
                            <td>
                                <input type='number' name="Sem1_PeriodsPerWeek" className="form-control form-control-sm"
                                    value={item.Sem1_PeriodsPerWeek} onChange={handleChange} />
                            </td>
                            {/* <td>TS tiết HK1</td>
                            <td>{item.Sem1_PeriodsPerWeek*item.Sem1_TotalWeeks}</td>                             */}
                        </tr>  
                        <tr>
                            <td>TS tuần HK2</td>
                            <td>
                                <input type='number' name="Sem2_TotalWeeks" className="form-control form-control-sm"
                                    value={item.Sem2_TotalWeeks} onChange={handleChange} /> 
                            </td>
                            <td>S.tiết/tuần HK2</td>
                            <td>
                                <input type='number' name="Sem2_PeriodsPerWeek" className="form-control form-control-sm"
                                    value={item.Sem2_PeriodsPerWeek} onChange={handleChange} />
                            </td>
                            {/* <td>TS tiết HK2</td>
                            <td>{item.Sem2_PeriodsPerWeek*item.Sem2_TotalWeeks}</td>                                 */}
                        </tr> 
                        <tr>
                            <td>Tài liệu liên quan</td>
                            <td colSpan={3}>
                                <input type='text' name="RefDocument" className="form-control form-control-sm"
                                    value={item.RefDocument} onChange={e=>handleChange(e)} />
                            </td>
                        </tr>  
                        {/* <tr>
                            <td>Còn dùng</td>
                            <td>
                                <input type='checkbox' name="IsActive" checked={item.IsActive} onChange={handleChange} />
                            </td>
                        </tr>                                                                                                  */}
                    </tbody>
                </table>;          
            break;
        case 'new':
            itemElement = 
                <div style={{'padding':'5px 20px 5px 20px'}}>
                    <p>
                        Hãy chọn để tạo khai báo KHDG cho các môn học nếu chưa có. Chỉ sau khi có khai báo KHGD mới có thể nhập PPCT bằng tay hoặc nhập từ file Excel.
                        <br/> - <b>Tự tạo khai báo</b>: Khi bấm nút này, bạn sẽ phải nhập môn học, khối lớp, năm học... để tạo
                        <br/> - <b>Khai báo tự động</b>: Bạn chọn năm học ở bên trái, rồi bấm nút này, phần mềm sẽ tự động tạo khai báo KHGD cho các môn học theo từng khối lớp...
                    </p>
                    <button type='button' className="btn btn-primary btn-sm"  title="Thêm khai báo kế PPCT của một môn học"  
                        onClick={handleAdd}
                        disabled={props.grade*1>0?'':'disabled'}
                    >Tự tạo khai báo</button>
                    <button type='button' className="btn btn-primary btn-sm ms-2"  title="Tự động tạo khai báo PPTC cho các môn học" 
                        onClick={()=>alert("Xin lỗi, chức năng này chưa có ở phiên bản phần mềm hiện tại.")}
                        disabled={props.grade*1>0?'':'disabled'}
                    >Khai báo tự động</button>
                    <SubjectPlanImportDlg 
                        subjectPlanItem={props.item}
                        subjectItems = {props.subjectItems}
                        startYear = {props.startYear} 
                        onSaveImportedPlanDetailItems = {props.onSaveImportedPlanDetailItems} 
                    />
                    {/* <button type="button" className="btn btn-primary btn-sm ms-2" title="Nhập chi tiết kế hoạch dạy học của môn học từ file excel..."
                        data-bs-toggle="modal" data-bs-target="#dlgImportPPCT">Import&nbsp;<i className="fa fa-download"></i>
                    </button> */}
                </div>;
            break;
        case 'blank':
            itemElement=<></>;
            break;
        default:
            itemElement = 
                <table className="table table-hover table-sm">
                    <tbody>
                        <tr>
                            <td colSpan={4}>
                                <h5>Phân phối Chương trình môn {item?.Title}</h5>
                                {item?.RefDocument!=='' && <p>Tài liệu liên quan: {item?.RefDocument}</p>}
                                <SubjectPlanImportDlg 
                                    subjectPlanItem={props.item}
                                    subjectItems = {props.subjectItems}
                                    startYear = {props.startYear}
                                    onSaveImportedPlanDetailItems = {props.onSaveImportedPlanDetailItems}
                                />
                            </td>
                            <td colSpan={2} className='text-end'>                               
                                <button type='button' className="btn btn-primary btn-sm" onClick={handleAdd} title="Thêm khai báo kế hoạch giảng dạy của một môn học">Thêm</button>&nbsp;
                                <button type="button" className="btn btn-warning btn-sm" onClick={handleEdit} title="Sửa khai báo kế hoạch giảng dạy của một môn học này">Sửa</button>&nbsp;
                                <button type="button" className="btn btn-danger btn-sm" onClick={handleDelete} title="Xóa khai báo kế hoạch giảng dạy của môn học này cùng toàn bộ thông tin chi tiết">Xóa</button>&nbsp;&nbsp;&nbsp;&nbsp;
                                <button type="button" className="btn btn-info btn-sm me-2" title="Nhập PPCT của môn học từ file excel..."
                                    data-bs-toggle="modal" data-bs-target="#dlgImportPPCT">Import&nbsp;<i className="fa fa-download"></i>
                                </button>                               
                            </td>
                        </tr>
                        <tr>
                            <td>Môn</td>
                            <td className="fw-bold">    
                                {item.SubjectId ? props.subjectItems.find(subject=>subject.SubjectId===item.SubjectId)?.Title:""}
                            </td>
                            <td>Năm học</td>
                            <td className="fw-bold">{item.StartYear} - {item.StartYear+1}</td>
                            <td colSpan={2}></td>
                        </tr>
                        <tr>
                            <td>TS tuần HK1</td>
                            <td className="fw-bold">{item.Sem1_TotalWeeks}</td>
                            <td>S.tiết/tuần HK1</td>
                            <td className="fw-bold">{item.Sem1_PeriodsPerWeek}</td>
                            <td>TS tiết HK1</td>
                            <td className="fw-bold">{item.Sem1_PeriodsPerWeek*item.Sem1_TotalWeeks}</td>                            
                        </tr>  
                        <tr>
                            <td>TS tuần HK2</td>
                            <td className="fw-bold">{item.Sem2_TotalWeeks}</td>
                            <td>S.tiết/tuần HK2</td>
                            <td className="fw-bold">{item.Sem2_PeriodsPerWeek}</td>
                            <td>TS tiết HK2</td>
                            <td className="fw-bold">{item.Sem2_PeriodsPerWeek*item.Sem2_TotalWeeks}</td>                                
                        </tr>  
                    </tbody>
                </table>; 
    }

    return( 
        <>
            {itemElement}
        </>
    )
}

export default SubjectPlanItem;
