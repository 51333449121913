
import React, {useState, useContext} from 'react';
import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import * as isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear'; 
import * as isLeapYear from 'dayjs/plugin/isLeapYear';

import { MyAppContext } from '../../context/MyAppContext';

const WeekSchoolSelection = (props) => {
    dayjs.extend(isoWeek);
    dayjs.extend(isoWeeksInYear);    
    dayjs.extend(isLeapYear);  
    
    const [appContext,] = useContext(MyAppContext);
    const schoolYearItems = appContext.SchoolYearInfo;    

    const {selectedYear, selectedDate, onWeekChange, children} = props; 

    const [selectedIsoWeekStartDay, setSelectedIsoWeekStartDay] = useState(
        selectedDate===undefined || selectedDate===null ? null : dayjs(selectedDate).isoWeekday(1).format('MM/DD/YYYY'));   
    
     const schoolYearObj = schoolYearItems.find(y=>y.StartYear<=selectedYear && selectedYear<=y.EndYear);

    if(schoolYearItems.length<=0) {
        return <></>
    } 
    else{   
        return(
            <div>
                {children}
                {schoolYearObj!==undefined &&
                    <select name='selectedWeek' id='đllSelectedWeek' className="form-select form-select-sm" 
                        value={selectedIsoWeekStartDay!==null ? selectedIsoWeekStartDay : ""} 
                        onChange = {e=> {
                            const value = e.target.value;
                            setSelectedIsoWeekStartDay(value);
                            let selectedText = e.target.options[e.target.selectedIndex].text;
                            let selectedWeek = selectedText.split('(')[0].trim();
                            onWeekChange(value, parseInt(selectedWeek)); //e.target.selectedIndex
                        }}               
                    >
                        {
                            <option value=""></option>
                        }                        
                        <optgroup label='Học kỳ 1'>
                        {
                            schoolYearObj.Note.sem1.map((item, idx) => { 
                                const currentStartDateOfWeek = dayjs(item.start);
                                return(
                                    <option key={item.week+"-" + idx} value={currentStartDateOfWeek.format('MM/DD/YYYY')}>
                                        {item.week} ({currentStartDateOfWeek.format('DD/MM/YYYY')})
                                    </option> 
                                )                    
                            })
                        }
                        </optgroup>
                        <optgroup label='Học kỳ 2'>
                        {
                            schoolYearObj.Note.sem2.map((item, idx) => { 
                                const currentStartDateOfWeek = dayjs(item.start);
                                return(
                                    <option key={item.week+"-"+idx} value={currentStartDateOfWeek.format('MM/DD/YYYY')}>
                                        {item.week} ({currentStartDateOfWeek.format('DD/MM/YYYY')})
                                    </option> 
                                )                    
                            })                           
                        }
                        </optgroup>                
                    </select>
                }
            </div>
        );
    }
}

export default WeekSchoolSelection;