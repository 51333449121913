import React, {useState, useContext, useEffect} from 'react';
import { read, utils } from 'xlsx';

import * as dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { orderBy as _orderBy } from 'lodash';
import { MyAppContext } from '../../context/MyAppContext';
import { schoolConfig } from '../../appConfig';
import { useTeacherDataApi } from "../teacher/TeacherData";
import { useSubjectDataApi } from "../subject/SubjectData";
import { useClassDataApi } from "../classes/ClassData";
import { useScheduleDataApi, EmptySchedule, UpdateDateForScheduleItems } from './ScheduleData';

//import WeekSelection from '../common/WeekSelection';
import WeekSchoolSelection from '../common/WeekSchoolSelection';

import MessageBox from '../common/MessageBox';
import ImportScheduleList from './ImportScheduleList';

const SHEET_DEFAULT_CELL_VALUE = '???';
const SHEET_AM_SCHEDULE_NAME = 'TKB_LOP_S';
const SHEET_PM_SCHEDULE_NAME = 'TKB_LOP_C';
const SHEET_CELL_SEPARATOR = '- ';
const SUBJECT_CATEGORY = 'Chủ nhiệm'.toLowerCase();

const ImportSchedulePage = () => {
    dayjs.extend(isoWeek);
    const [appContext,] =  useContext(MyAppContext);
    const [{teacherItems}] = useTeacherDataApi();       // Danh sách giáo viên có trong CSDL của App
    const [{subjectItems}] = useSubjectDataApi();       // Danh sách môn học có trong CSDL của App
    const [{classItems}]   = useClassDataApi();           // Danh sách các lớp chưa có trong CSDL của App

    const [{isProcessingScheduleData, flagAutoSaveSchedule, statusMessage, errorMessage}, setScheduleAction] = useScheduleDataApi();
    const [schoolYear,] = useState(appContext.SchoolYear); 
    const [schoolWeek, setSchoolWeek] = useState(appContext.SchoolWeek+1);
    const [applyDateObj, setApplyDateObj] = useState(new Date(appContext.CurrentDateInfo.FirstDateOfNextWeekIsoString)); //useState(new Date(dayjs().isoWeek(dayjs().isoWeek()+1).isoWeekday(1).toISOString()));
    const [isValidFile, setIsValidFile] = useState(true);

    const [autoSaveGradeIndex, setAutoSaveGradeIndex] = useState(0);
    const [autoSaveSchoolWeek, setAutoSaveSchoolWeek] = useState(0);
    const [autoSaveStartDateOfWeekObj, setAutoSaveStartDateOfWeekObj] = useState(applyDateObj);
    const [autoSaveTurn, setAutoSaveTurn] = useState(2);  //Xuất phát từ 2 không trùng với việc sử dụng thông thường
    const [maxAutoTurn, setMaxAutorTurn] = useState(1);



    const [importedData, setImportedData] = useState({
        AmScheduleRows: [],
        PmScheduleRows: [],
        ClassNamesAm: [],
        ClassNamesPm: [],
        SubjectNamesAm: [],
        TeacherNamesAm: [],
        SubjectNamesPm: [],
        TeacherNamesPm: [],
        SubjectsNotInDbAm: [],
        TeachersNotInDbAm: [],
        SubjectsNotInDbPm: [],
        TeachersNotInDbPm: [],
        ClassesNotInDb: [],
    });

    const [importedScheduleItems, setImportedScheduleItems] = useState([]);

    /**
     * 
     * @param {*} inputString : Là một chuỗi gồm tên môn học và tên giáo viên dạy, ngăn cách nhau bởi " - " 
     * @returns : Trả về một mảng gồm hai phần tử, phần tử đầu là tên môn học, phần tử thứ 2 là tên giáo viên
     */
    const extractSubjectAndTeacher = (inputString) => {    
        let subjectName = "";
        let teacherName = "";

        if(!inputString || inputString.length<=0) return [inputString, teacherName];
        inputString = inputString +'';
        // console.log('inputString', inputString);
        const idx = inputString?.lastIndexOf(SHEET_CELL_SEPARATOR);
        if (idx >0) {
            subjectName = inputString.substring(0,idx).trim();
            teacherName = inputString.substring(idx+2).trim();
        }else if (idx===0) {
            teacherName = inputString;
        }else {
          subjectName = inputString;
        }

        return [subjectName, teacherName];
    }

    const getClassId = (className) => {
        const idx = classItems.findIndex(c=> c.Title === className);
        return classItems[idx].ClassId;
    }

    /**===================================================
     * Lấy ID của giáo viên dựa vào tên bí danh của giáo viên
     * @param {*} teacherAlias - Tên bí danh của gv
     * @returns ID của giáo viên
     **===================================================*/
    const getTeacherId = (teacherAlias) => {
        const lcTeacherAlias  = teacherAlias.toLowerCase();
        const idx = teacherItems.findIndex(t=>t.Title.toLowerCase()===lcTeacherAlias);
        return idx >- 1 ? teacherItems[idx].TeacherId : teacherAlias;        
    }

    /**================================================
     * Lấy ID của giáo viên theo bí danh của giáo viên
     * (bí danh là tên dùng để sắp thời khóa biểu)
     * @param {*} teacherId - ID của giáo viên
     * @returns Tên bí danh của môn học
     **=================================================*/
    const getTeacherAlias = (teacherId) => {        
        const idx = teacherItems.findIndex(t=>t.TeacherId===teacherId);
        return idx >- 1 ? teacherItems[idx].Title : teacherId;        
    }

    /**===============================================
     * Lấy ID của giáo viên chủ nhiệm một lớp học
     * ===============================================
     * @param {} className - Tên lớp
     * @returns - ID của giáo viên chủ nhiệm
     * ===============================================*/
    const getHeadTeacherId = (className) => {
        const classNameLowerCase = className.toLowerCase();
        const idx = classItems.findIndex(c=>c.Title.toLowerCase()===classNameLowerCase);
        return idx >-1 ? classItems[idx].HeadTeacher : "";
    }

    /**===================================================
     * Lấy ID của môn học theo bí danh môn học dùng để sắp
     * thời khoa biểu
     * @param {*} subjectAlias - Bí danh môn học
     * @returns ID của môn học
     * ===================================================*/
    const getSubjectId = (subjectAlias) => {
        const idx = subjectItems.findIndex(s=>s.AliasName === subjectAlias);
        return idx>-1 ? subjectItems[idx].SubjectId : subjectAlias;
    }

    /**
     * ConvertScheduleRowsToSave
     * @param {*} classesNameAm : Danh sách các lớp học của buổi sáng, mỗi phần tử tương ứng với một cột dữ liệu trong amScheduleRows
     * @param {*} amScheduleRows: Danh sách dữ liệu TKB sáng, Mỗi row là tương ứng 1 tiết. Cột 0 là thứ, cột 1 là tiết, cột 2 là một array chứa dữ liệu từng lớp
     * @param {*} classesNamePm : Danh sách lớp chiều, tương tự như cách tổ chức lớp sáng
     * @param {*} pmScheduleRows: Danh sách dữ liệu TKB sáng
     * @returns (*): Trả về cấu trức dữ liệu TKB dùng để ghi vào CSDL
     */
    const ConvertScheduleRowsToSave = (classesNameAm, amScheduleRows, classesNamePm, pmScheduleRows) => {
        //TKB của mỗi lớp một tuần là một bản ghi, bản ghi có một trường dạng JSON để ghi chi tiết theo ngày, tiết...
        //Nhu vậy 1 tuần sẽ có Số bản ghi = Số lớp (NTT hiện có 55 lớp). Tổng số 35 tuần học sẽ có 55x35 = 1925 bản ghi
        let scheduleItems = [];

        //Dùng ngày, tháng năm học hiện tại để làm mặc định
        const schoolYear = dayjs().month() >= 8 ? dayjs().year() : dayjs().year() -1; //Lưu ý, month chạy từ 0 - 11
        const schoolWeek = 0;
        const startDateOfWeek = dayjs('9/5/' + schoolYear).isoWeekday(1).toISOString();
        classesNameAm.forEach(classItem => {
            const classId = getClassId(classItem.className);
            let schedule = EmptySchedule(classId, classItem.className,parseInt(classItem.className.substring(0,1)),schoolYear,schoolWeek,startDateOfWeek);
            scheduleItems.push(schedule);            
        });

        classesNamePm.forEach(classItem=>{
            if (classesNameAm.findIndex(cAm => cAm.className === classItem.className) <0){ //Chỉ thao tác với lớp chưa có trong danh sách lớp sáng
                const classId = getClassId(classItem.className);
                let schedule = EmptySchedule(classId, classItem.className, 
                    parseInt(classItem.className.substring(0,1)),  //Grade
                    schoolYear,
                    schoolWeek,
                    startDateOfWeek);
                scheduleItems.push(schedule); 
            }
        })

        for (let i=0; i<scheduleItems.length; i++){
            //Cập nhật dữ liệu thời khóa biểu cho schedule này
            let records = scheduleItems[i].records;
            for (let j=0; j<records.length; j++){
                const r = records[j];
                let dataItem=null;
                if(r.part_day==="Sáng") {
                    const idx1 = amScheduleRows.findIndex(s=> 
                        s.schedule_date === (parseInt(dayjs(r.schedule_date).format('d'))+1) 
                        && s.period === r.period); //+1 vì ngày tính từ 0-6
                                      
                    if (idx1>=0) {
                        const idx2 = amScheduleRows[idx1].class_subjects.findIndex(s=>s.class_name === scheduleItems[i].class_name);
                        if (idx2>=0) dataItem = amScheduleRows[idx1].class_subjects[idx2];
                    }
                } else {
                    const idx1 = pmScheduleRows.findIndex(s=> 
                        s.schedule_date === parseInt(dayjs(r.schedule_date).format('d'))+1
                        && s.period === r.period);                                         
                    if (idx1>=0) {
                        const idx2 = pmScheduleRows[idx1].class_subjects.findIndex(s=>s.class_name === scheduleItems[i].class_name);
                        if (idx2>=0) dataItem = pmScheduleRows[idx1].class_subjects[idx2];
                    }
                }
                if (dataItem!==null) {
                    r.subject_id = getSubjectId(dataItem.subject_name);
                    r.teacher_id = getTeacherId(dataItem.teacher_name);
                    scheduleItems[i].records[j] = r;
                }
            }            
        }
        return scheduleItems;
    } 

    const handleImport = ($event) => {
        const files = $event.target.files;  
        let fileName = $event.target.value;
        fileName = fileName.substring(fileName.lastIndexOf('\\')+1);
        
        document.getElementById('lblFile').innerHTML = fileName;

        if(files.length) {
            const file = files[0];
            const reader = new FileReader();
            
            reader.onload = (event) => {
                let wb;
                try {
                    wb = read(event.target.result);
                }
                catch {
                    setIsValidFile(false);
                    return;
                }

                const sheets = wb.SheetNames;

                if (sheets.length) {
                    //Đọc sheet TKB sáng
                    const sheet_am_idx = sheets.findIndex(item=>item===SHEET_AM_SCHEDULE_NAME);
                    const sheet_pm_idx = sheets.findIndex(item=>item===SHEET_PM_SCHEDULE_NAME);
                    if (sheet_am_idx <0 && sheet_pm_idx < 0) { //file dữ liệu không hợp lệ 
                        setIsValidFile(false);
                        return;
                    } else {
                        setIsValidFile(true);
                    }

                    //Đọc dữ liệu trong sheet sáng, chiều từ dòng thứ 5 trở đi, dòng đầu tiên trở đi là dòng header
                    //Giá trị empty được điền bằng giá trị '???'
                    let am_rows = utils.sheet_to_json(wb.Sheets[sheets[sheet_am_idx]], {number:5, header:1, defval:SHEET_DEFAULT_CELL_VALUE}).slice(4);
                    let pm_rows = utils.sheet_to_json(wb.Sheets[sheets[sheet_pm_idx]], {number:5, header:1, defval:SHEET_DEFAULT_CELL_VALUE}).slice(4);

                    //Xử lý dòng header để tách lấy tên các lớp trong TKB sáng, 2 cột đầu là THỨ và TIẾT, các cột tiếp theo là 
                    //tên lớp gắn cùng với tên giáo viên chủ nhiêm - ngăn cách giữa tên lớp và gv chủ nhiện là dấu xuống dòng \n
                    let colNames = [];
                    Object.keys(am_rows[0]).forEach(k=> colNames.push(am_rows[0][k]));

                    //Lấy tên lớp trong TKB sáng
                    let classNameArrayAm = [];
                    colNames.slice(2).forEach(colName=>{
                        const class_teacher = colName.split('\n');
                        classNameArrayAm.push({className:class_teacher[0], teacherName:class_teacher[1]});
                    });

                    //Lấy tên lớp TKB chiều
                    colNames = [];
                    Object.keys(pm_rows[0]).forEach(k=> colNames.push(pm_rows[0][k]));
                    let classNameArrayPm = [];
                    colNames.slice(2).forEach(colName=>{
                        const class_teacher = colName.split('\n');
                        classNameArrayPm.push({className:class_teacher[0], teacherName:class_teacher[1]});
                    });                   

                    // Xử lý các dòng dữ liệu sáng để đưa lưu vào một mảng mà mỗi phần tử là một mảng giá trị 
                    // (tên môn học, tên giáo viên) các cột
                    let amRowData = [];     
                    let subjectsAm = []; //importedData.subjectNames;     
                    let teachersAm = []; //importedData.teacherNames;   
                    let scheduleDay= 2;
                    am_rows = am_rows.slice(1); //Bỏ qua dòng header đầu tiên
                    am_rows.forEach((row, i)=>{                    
                        let ColValues = [];
                        let extractedNames = [];
                        let period=0;   
                        const keys = Object.keys(row);
                        // for (let k=1; k < keys.length; k++){  //bỏ qua key=0, vì đó là cột THỨ
                        keys.forEach(key=>{
                            switch (key) {
                                case 0:
                                case "0":
                                    break; //bỏ qua key=0, vì đó là cột THỨ
                                case 1:
                                case "1":
                                    period=row[key];
                                    break;
                                default:
                                    extractedNames = extractSubjectAndTeacher(row[key]);
                                    const subjectName = extractedNames[0]===SHEET_DEFAULT_CELL_VALUE?"":extractedNames[0];
                                    let teacherName = extractedNames[1];

                                    //Nếu không có tên giáo viên và là môn học do giáo viên chủ nhiệm dạy thì lấy tên của giáo viên chủ nhiệm
                                    if (teacherName==="" && subjectName!=="") {
                                        const sIdx = subjectItems.findIndex(s=>s.AliasName===subjectName);
                                        if( sIdx > -1 && subjectItems[sIdx].Category && subjectItems[sIdx].Category.toLowerCase()===SUBJECT_CATEGORY){
                                            teacherName = getTeacherAlias(getHeadTeacherId(classNameArrayAm[key-2].className));
                                        }
                                    }
                                    ColValues.push({
                                        class_name: classNameArrayAm[key-2]?.className,  //k-2 vì 2 cột đầu là Thứ, tiết
                                        subject_name: subjectName,
                                        teacher_name: teacherName,
                                    });
                                    if (subjectName!=="" && !subjectsAm.includes(subjectName)) {
                                        subjectsAm.push(subjectName);                                        
                                    }  
                                    if (teacherName!=="" && !teachersAm.includes(teacherName)){
                                        teachersAm.push(teacherName);
                                    }                   
                            } 
                        });

                        amRowData.push({
                            schedule_date: scheduleDay,
                            period: period,
                            class_subjects: ColValues,  //Mảng giá trị môn-gv cho từng lớp
                        });
                        
                        if ((i+1)%5===0) { scheduleDay = scheduleDay+1; }  //Sau 5 tiết thì tăng ngày
                    });

                    // Xử lý các dòng dữ liệu Chiều để đưa lưu vào một mảng mà mỗi phần tử là một mảng giá trị 
                    // (tên môn học, tên giáo viên) các cột
                    let pmRowData = [];     
                    let subjectsPm = []; //importedData.subjectNames;     
                    let teachersPm = []; //importedData.teacherNames;                      
                    scheduleDay = 2;
                    pm_rows = pm_rows.slice(1); //Bỏ qua dòng header đầu tiên 
                    pm_rows.forEach((row, i)=>{
                        let ColValues = [];
                        let extractedNames = [];
                        let period=0;      
                        const keys = Object.keys(row);   
                        keys.forEach(key=>{   
                            switch (key) {
                                case 0:
                                case "0":
                                    break; //Bỏ qua key 0, vì đó là cột thứ   
                                case 1:
                                case "1":
                                    period = row[key];
                                    break;
                                default:
                                    extractedNames = extractSubjectAndTeacher(row[key]);
                                    const subjectName = extractedNames[0]===SHEET_DEFAULT_CELL_VALUE?"":extractedNames[0];
                                    let teacherName = extractedNames[1];

                                    //Nếu không có tên giáo viên và là môn học do giáo viên chủ nhiệm dạy thì lấy tên của giáo viên chủ nhiệm
                                    if (teacherName==="" && subjectName!=="") {
                                        const sIdx = subjectItems.findIndex(s=>s.AliasName===subjectName);
                                        if( sIdx > -1 && subjectItems[sIdx].Category && subjectItems[sIdx].Category.toLowerCase()===SUBJECT_CATEGORY){
                                            teacherName = getTeacherAlias(getHeadTeacherId(classNameArrayPm[key-2].className));
                                        }
                                    }

                                    ColValues.push({
                                        class_name: classNameArrayPm[key-2]?.className,
                                        subject_name: subjectName,
                                        teacher_name: teacherName,
                                    });
                                    if (subjectName!=="" && !subjectsPm.includes(subjectName)) {
                                        subjectsPm.push(subjectName);                                        
                                    }  
                                    if (teacherName!=="" && !teachersPm.includes(teacherName)){
                                        teachersPm.push(teacherName);
                                    }                   
                            }                         
                        });
                        pmRowData.push({
                            schedule_date: scheduleDay,
                            period: period,
                            class_subjects: ColValues,
                        });

                        if ((i+1)%5===0) { scheduleDay = scheduleDay + 1; } //Mỗi buổi chỉ có 5 tiết nên ngày tăng sau mỗi 5 tiết                    
                    });


                    //Kiểm tra các môn học chưa có trong CSDL của App
                    let subjectsNotAvailableAm=[];
                    subjectsAm.forEach(s=>{
                        const idx = subjectItems.findIndex(item=>item.AliasName === s);
                        if (idx<0){
                            subjectsNotAvailableAm.push(s);
                        }
                    });

                    let subjectsNotAvailablePm=[];
                    subjectsPm.forEach(s=>{
                        const idx = subjectItems.findIndex(item=>item.AliasName === s);
                        if (idx<0){
                            subjectsNotAvailablePm.push(s);
                        }
                    });

                    //Kiểm tra giáo viên chưa có trong CSDL của App
                    let teachersNotAvailableAm=[];
                    //console.log('teachers', teachersAm);
                    //console.log('teacherItems', teacherItems);
                    teachersAm.forEach(t=>{
                        const idx = teacherItems.findIndex(item=>item.Title === t);
                        if (idx<0){
                            teachersNotAvailableAm.push(t);
                        }
                    });    
                    
                    let teachersNotAvailablePm=[];
                    //console.log('teachers', teachersPm);
                    //console.log('teacherItems', teacherItems);
                    teachersPm.forEach(t=>{
                        const idx = teacherItems.findIndex(item=>item.Title === t);
                        if (idx<0){
                            teachersNotAvailablePm.push(t);
                        }
                    }); 

                    //Kiểm tra lớp học chưa có trong CSDL của app
                    let classesNotAvaialabe = [];
                    let classesArray = classNameArrayAm.concat(classNameArrayPm);
                    classesArray.forEach(c=>{
                        const idx = classItems.findIndex(item=>item.Title === c.className);
                        if (idx<0){
                            classesNotAvaialabe.push(c.className);
                        }
                    });

                    const importedInfo = {
                        AmScheduleRows: amRowData,
                        PmScheduleRows: pmRowData,
                        ClassNamesAm: classNameArrayAm,
                        ClassNamesPm: classNameArrayPm,
                        SubjectNamesAm: subjectsAm,
                        TeacherNamesAm: teachersAm,
                        SubjectNamesPm: subjectsPm,
                        TeacherNamesPm: teachersPm,
                        SubjectsNotInDbAm: subjectsNotAvailableAm,
                        TeachersNotInDbAm: teachersNotAvailableAm,
                        SubjectsNotInDbPm: subjectsNotAvailablePm,
                        TeachersNotInDbPm: teachersNotAvailablePm,  
                        ClassesNotInDb: classesNotAvaialabe,                                      
                    };
                    setImportedData(importedInfo);
                    console.log('Dữ liệu thời khóa biểu đọc được từ file TKB', importedInfo);
                }
            }

            reader.readAsArrayBuffer(file);            
        }
    }

    const handleUpdateData = () => {
        //Dữ liệu thời khóa biểu đã được đọc và xử lý từ file excel theo khuôn dạng lưu trữ để áp dụng cho App
        let importedSchedule = ConvertScheduleRowsToSave(importedData.ClassNamesAm, importedData.AmScheduleRows, 
                                                   importedData.ClassNamesPm, importedData.PmScheduleRows);
        const startDateOfWeek = dayjs(applyDateObj).isoWeekday(1);       
        //Duyệt từng lớp để cập nhật lại ngày tháng, tuần, năm
        for (let i=0; i<importedSchedule.length; i++) {
            let scheduleItem = importedSchedule[i];
            scheduleItem.school_year = schoolYear;
            scheduleItem.school_week = schoolWeek;
            scheduleItem.start_date_week_isostring = startDateOfWeek.toISOString();
            
            //Cập nhật ngày cho lịch dạy các môn. Một lớp có được khai báo sẵn 10 tiết/ngày
            //Nên ta sẽ đếm số records, đủ 10 thì tăng ngày kể từ ngày thứ 2 đầu tuần
            let records = scheduleItem.records;
            let d = 1;
            for(let j = 0; j<records.length; j++){                
                records[j].schedule_date = startDateOfWeek.isoWeekday(d).toISOString();
                if ((j+1)%10===0) d=d+1;
            }
            scheduleItem.records = records;
            importedSchedule[i] = scheduleItem;
        }

        importedSchedule = _orderBy(importedSchedule, ['class_name'],['asc']);
     
        //Chia nhỏ tập bản ghi dữ liệu ra thành 4 nhóm để khắc phục giới hạn lưu trữ hiện tại trường text của SharePoint List
        const numOfPart = 5;
        const maxRecordPerPart = 20;
        let dataRecordParts = [[],[],[],[],[]];
        
        if (importedSchedule.length<=maxRecordPerPart) {
            dataRecordParts[0] = importedSchedule;
        } else {
            const numOfRecPerPart = Math.ceil(importedSchedule.length / numOfPart);
            let startIndex;
            for (let i=1; i<= numOfPart; i++) {
                startIndex = (i-1)*numOfRecPerPart;
                if (i===numOfPart) { //phần tử cuối thì lấy hết các mục còn lại trong importedSchedules
                    dataRecordParts[i-1] = importedSchedule.slice(startIndex);   
                } else {
                    dataRecordParts[i-1] = importedSchedule.slice(startIndex, startIndex + numOfRecPerPart);  
                }  
            }
        }   

        //Ghi về phía server
         setScheduleAction({
            type:'import',
            value: {
                title: 'TKB_' + schoolWeek,
                school_year: schoolYear,
                apply_school_week: schoolWeek,
                start_date_week_isostring: startDateOfWeek.toISOString(),
                schedule_1: dataRecordParts[0],
                schedule_2: dataRecordParts[1],
                schedule_3: dataRecordParts[2],
                schedule_4: dataRecordParts[3],
                schedule_5: dataRecordParts[4],                
            }
        });

        setImportedScheduleItems(importedSchedule)
    }

    const handleWeekChange = (weekStartDay, schoolWeek) => { 
        const selectedDateObj = dayjs(weekStartDay);
        const currentSchoolYearInfo = appContext.SchoolYearInfo[0];
    
        setApplyDateObj(selectedDateObj);
        setSchoolWeek(schoolWeek);
        setAutoSaveTurn(2); //Reset lại mốc mặc định là 2
        setAutoSaveSchoolWeek(schoolWeek);
        setAutoSaveStartDateOfWeekObj(selectedDateObj);

        if(schoolWeek <= currentSchoolYearInfo.Sem1_TotalWeeks) {
            setMaxAutorTurn((1 + currentSchoolYearInfo.Sem1_TotalWeeks - schoolWeek)*schoolConfig.Grades.length);
        } else {
            setMaxAutorTurn((1 + currentSchoolYearInfo.Sem1_TotalWeeks  + currentSchoolYearInfo.Sem2_TotalWeeks - schoolWeek) *schoolConfig.Grades.length);
        }
    }

    useEffect(()=>{
        //console.warn("Enter useEffect : ", flagAutoSaveSchedule, schoolWeek);
        if (flagAutoSaveSchedule > 0 && autoSaveTurn <= maxAutoTurn + 1 ) { 
            console.log('auto save to server turn flagAutoSaveSchedule: ', flagAutoSaveSchedule);

            let savingItems = importedScheduleItems.filter(item=>parseInt(item.grade)===schoolConfig.Grade[autoSaveGradeIndex]);
            savingItems = UpdateDateForScheduleItems(savingItems, 
                schoolYear, autoSaveSchoolWeek, schoolWeek, 
                autoSaveStartDateOfWeekObj.toISOString(), true, true);

            setScheduleAction({
                type:'saveafterimport',
                value: {
                    autoTurn: autoSaveTurn + 1,  //Tăng
                    scheduleItems: savingItems, 
                }
            });

            if (autoSaveGradeIndex<3) {
                setAutoSaveGradeIndex(autoSaveGradeIndex+1);
            } else {
                setAutoSaveGradeIndex(0);
                setAutoSaveSchoolWeek(autoSaveSchoolWeek+1);
                setAutoSaveStartDateOfWeekObj(autoSaveStartDateOfWeekObj.add(7,'d'));
            }            
            setAutoSaveTurn(autoSaveTurn+1);
        }
    }, [flagAutoSaveSchedule])

    return (
        <div className='pt-3'>
            <div className='row'>
                <h4>Import Dữ liệu Thời khóa biểu từ file Excel</h4>
            </div>
            <div className="row pt-3">
                <div className="col-md-3">
                    <div className="input-group">
                        <div className="custom-file">
                            <label className="custom-file-label" htmlFor="inputGroupFile" id="lblFile">Bấm để chọn tệp TKB ...</label>
                            <input type="file" className="custom-file-input"  name="file" id="inputGroupFile" required onChange={handleImport}
                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>                                    
                        </div>
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className="row mb-3">
                        <label htmlFor="applyDate" className="col-sm-5 col-form-label">Áp dụng từ tuần</label>
                        <div className="col-sm-7 ps-0">
                            <WeekSchoolSelection 
                                selectedYear = {schoolYear}
                                selectedDate = {dayjs(applyDateObj)}
                                onWeekChange = {handleWeekChange}                            
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-6 text-end">
                    <button className="btn btn-primary btn-sm"                        
                        disabled={
                            (importedData 
                                && (importedData.SubjectsNotInDbAm.length>0 || importedData.TeachersNotInDbAm.length>0 
                                    || importedData.SubjectsNotInDbPm.length>0 ||importedData.TeachersNotInDbPm.length>0)
                            ) 
                            || importedData.AmScheduleRows.length===0 || importedData.PmScheduleRows.length===0 
                            || isProcessingScheduleData  ? "disabled" : ""
                        }
                        onClick={handleUpdateData} 
                    >
                        Import TKB từ tuần {schoolWeek} <i className="fa fa-download"></i>
                    </button>
                </div>
            </div>
            {
                isProcessingScheduleData ?
                <>
                    <MessageBox>Đang import dữ liệu thời khóa biểu</MessageBox>
                    {
                        flagAutoSaveSchedule>=2 && statusMessage && 
                        <MessageBox format="text-center fw-bold pt-0">                            
                            <progress value={parseInt(statusMessage)} max={maxAutoTurn/schoolConfig.Grade.length}></progress>
                        </MessageBox>
                    }
                </>                
                :
                isValidFile ?
                <>
                    {errorMessage!=="" && <MessageBox format="text-center text-danger fw-bold pt-3">{errorMessage}</MessageBox>}                    
                    <div className='row pt-3'>
                        <div className='col-md-12'>
                            {
                                importedData.ClassNamesAm.length > 0 &&
                                <ImportScheduleList 
                                    scheduleRows = {importedData.AmScheduleRows}
                                    classNames = {importedData.ClassNamesAm}
                                    teacherNames = {importedData.TeacherNamesAm}
                                    subjectNames = {importedData.SubjectNamesAm} 
                                    subjectsNotInDb = {importedData.SubjectsNotInDbAm}
                                    teachersNotInDb = {importedData.TeachersNotInDbAm}
                                    classesNotInDb = {importedData.ClassesNotInDb}
                                    defaultCellValue = {SHEET_DEFAULT_CELL_VALUE}
                                    partDay = "Sáng"
                                />
                            }

                        </div>
                    </div>
                    <div className='row pt-3'>
                        <div className='col-md-12'>
                            {
                                importedData.ClassNamesPm.length > 0 &&
                                <ImportScheduleList 
                                    scheduleRows = {importedData.PmScheduleRows}
                                    classNames = {importedData.ClassNamesPm}
                                    teacherNames = {importedData.TeacherNamesPm}
                                    subjectNames = {importedData.SubjectNamesPm} 
                                    subjectsNotInDb = {importedData.SubjectsNotInDbPm}
                                    teachersNotInDb = {importedData.TeachersNotInDbPm}
                                    classesNotInDb = {importedData.ClassesNotInDb}
                                    defaultCellValue = {SHEET_DEFAULT_CELL_VALUE}
                                    partDay = "Chiều"
                                />
                            }
                        </div>
                    </div>
                </>
                :<MessageBox format="text-center text-danger fw-bold pt-3">File dữ liệu không hợp lệ, kiểm tra và chọn lại</MessageBox> 
            }
        </div>
    )

}

export default ImportSchedulePage;