import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter as Router } from 'react-router-dom'; //BrowserRouter as Router
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './styles/theme';
import App from './App';

// MSAL imports
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
//import dayjs from 'dayjs';

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if(!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length>0){
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event)=>{
  if(event.eventType===EventType.LOGIN_SUCCESS && event.payload.account){
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
  } else if(event.eventType===EventType.LOGOUT_SUCCESS) {
      //  sessionStorage.removeItem(contextConfig.ContextLsKey);
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router basename='/'>
      <ThemeProvider theme={theme}>
        <App msalInstance = {msalInstance} />
      </ThemeProvider> 
    </Router>
  </React.StrictMode>
);

