import  {useEffect, useState} from 'react';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { v4 as uuidv4 } from 'uuid';
import {orderBy as _orderBy, remove} from 'lodash';

import { loginRequest, sharePointConfig } from '../../authConfig';
import {
    getSharePointListItems,
    updateSharePointItem,
    addSharePointListItem,
    deleteSharePointItem
} from '../../graph';

import { SchoolYearSettings } from "../../mock/MockData";

export const createBlankSchoolYearItem = () =>{
    return [
        {
            id:0,
            SettingId: uuidv4(),
            Title:'',
            StartYear: new Date().getFullYear(),
            EndYear: new Date().getFullYear()*1 + 1,
            Sem1_StartDay: new Date(),
            Sem1_TotalWeeks: 18,
            Sem1_StartIsoWeek:0,
            Sem2_StartDay: new Date(),
            Sem2_TotalWeeks: 17,
            Sem2_StartIsoWeek:0,            
            Note: ''
            
        }
    ]
}

export const useSchoolYearDataApiMock = () => {
    const [schoolYearItems, setSchoolYearItems] = useState(SchoolYearSettings);
    const [action, setAction] = useState({type:'', value:''});

    useEffect(() => {     
        const fetchData = async () => {  
            setSchoolYearItems(SchoolYearSettings);
        };

        const addBlankItem = async (item) => {
            setSchoolYearItems(item.concat(schoolYearItems));
        };

        const submitData = async (item) => {
            console.log('Submit data');
        };

        const deleteItem = async (item) => {
            let items = Array.from(schoolYearItems);
            remove(items, i=>i.SettingId===item.SettingId);        
            setSchoolYearItems(items); 
        }

        const processData = ()=> {
            switch(action.type){
                case 'add':
                    addBlankItem(action.value);
                    break;
                case 'save':
                    submitData(action.value);
                    break;
                case 'del':
                    deleteItem(action.value);
                    break;
                default:
                    fetchData();
            }
        }

        processData();
        
    },[action]);

    return [{schoolYearItems},  setAction];
}

export const useSchoolYearDataApi = (defaultAction={type:'', value:''}) => {
    const [schoolYearItems, setSchoolYearItems] = useState([]);
    const [action, setAction] = useState(defaultAction);
    const [flagReloadAfterAddNew, setFlagReloadAfterAddNew] = useState(false);

    const {instance, accounts} = useMsal();

    const fetchData = async (accessToken) =>{
        const response = await getSharePointListItems(
            accessToken, 
            sharePointConfig.SchoolYearSettingListId,
            "id,SettingId,Title,StartYear,EndYear,Sem1_StartDay,Sem1_TotalWeeks,Sem1_StartIsoWeek,Sem2_StartDay,Sem2_TotalWeeks,Sem2_StartIsoWeek,Note", 
            ""  
        );        

        let items = [];
        const listItemArray = await response.value;

        for(let i=0; i<listItemArray.length; i++){
            items.push({
                ...listItemArray[i].fields,
                Sem1_StartDay: new Date(listItemArray[i].fields.Sem1_StartDay),
                Sem2_StartDay: new Date(listItemArray[i].fields.Sem2_StartDay),
                Note: listItemArray[i].fields.Note && listItemArray[i].fields.Note!=="" ? JSON.parse(listItemArray[i].fields.Note): null
            });
        }
        items = _orderBy(items,['StartYear'],['desc']);
        setSchoolYearItems(items);
    }

    const addBlankItem = (item) => {
        setSchoolYearItems(item.concat(schoolYearItems));
    };

    const saveItem_old = (accessToken, item) => {
        setFlagReloadAfterAddNew(false);
        getSharePointListItems(
            accessToken, 
            sharePointConfig.SchoolYearSettingListId,
            "SettingId,Title", 
            `(fields/SettingId eq '${item.SettingId}')`
        ).then(response => {
            let fieldValues = {};
            const listItems = response.value;
            if(listItems.length>0){ //Nếu đã có thì cập nhật nội dung của mục trong tuần đã có  
                fieldValues = {
                    fields: {
                        Title: item.Title,
                        StartYear: item.StartYear,
                        EndYear: item.EndYear,
                        Sem1_StartDay: new Date(item.Sem1_StartDay).toISOString(),
                        Sem1_TotalWeeks: item.Sem1_TotalWeeks,
                        Sem1_StartIsoWeek: item.Sem1_StartIsoWeek,

                        Sem2_StartDay: new Date(item.Sem2_StartDay).toISOString(),
                        Sem2_TotalWeeks: item.Sem2_TotalWeeks,
                        Sem2_StartIsoWeek: item.Sem2_StartIsoWeek,
                        Note: JSON.stringify(item.Note)
                    }
                };  
                updateSharePointItem(accessToken, sharePointConfig.SchoolYearSettingListId, listItems[0].id, fieldValues)
                    .then(response => {
                        console.log(response);                        
                    });
            }
            else{ //Nếu chưa có thì thêm vào theo tuần
                fieldValues = {
                    fields: {
                        SettingId: item.SettingId,
                        Title: item.Title,
                        StartYear: item.StartYear,
                        EndYear: item.EndYear,
                        Sem1_StartDay: item.Sem1_StartDay,
                        Sem1_TotalWeeks: item.Sem1_TotalWeeks,
                        Sem1_StartIsoWeek: item.Sem1_StartIsoWeek,
                        Sem2_StartDay: item.Sem2_StartDay,
                        Sem2_TotalWeeks: item.Sem2_TotalWeeks,
                        Sem2_StartIsoWeek: item.Sem2_StartIsoWeek,
                        Note: JSON.stringify(item.Note)
                    }
                };
                addSharePointListItem(accessToken, sharePointConfig.SchoolYearSettingListId, fieldValues)
                    .then(response=>{
                        console.log(response);
                        setFlagReloadAfterAddNew(true);
                    });
            }
        }) 
    };

    const saveItem = (accessToken, item) => {
        setFlagReloadAfterAddNew(false); 
        let fieldValues = {};
        if(item.id>0){ //Nếu đã có thì cập nhật nội dung của mục trong tuần đã có  
            fieldValues = {
                fields: {
                    Title: item.Title,
                    StartYear: item.StartYear,
                    EndYear: item.EndYear,
                    Sem1_StartDay: new Date(item.Sem1_StartDay).toISOString(),
                    Sem1_TotalWeeks: item.Sem1_TotalWeeks,
                    Sem1_StartIsoWeek: item.Sem1_StartIsoWeek,

                    Sem2_StartDay: new Date(item.Sem2_StartDay).toISOString(),
                    Sem2_TotalWeeks: item.Sem2_TotalWeeks,
                    Sem2_StartIsoWeek: item.Sem2_StartIsoWeek,
                    Note: JSON.stringify(item.Note)
                }
            };  
            updateSharePointItem(accessToken, sharePointConfig.SchoolYearSettingListId, item.id, fieldValues)
                .then(response => {
                    console.log(response);      
                    setFlagReloadAfterAddNew(true);                  
                });
        }
        else{ //Nếu chưa có thì thêm vào theo tuần
            fieldValues = {
                fields: {
                    SettingId: item.SettingId,
                    Title: item.Title,
                    StartYear: item.StartYear,
                    EndYear: item.EndYear,
                    Sem1_StartDay: item.Sem1_StartDay,
                    Sem1_TotalWeeks: item.Sem1_TotalWeeks,
                    Sem1_StartIsoWeek: item.Sem1_StartIsoWeek,
                    Sem2_StartDay: item.Sem2_StartDay,
                    Sem2_TotalWeeks: item.Sem2_TotalWeeks,
                    Sem2_StartIsoWeek: item.Sem2_StartIsoWeek,
                    Note: JSON.stringify(item.Note)
                }
            };
            addSharePointListItem(accessToken, sharePointConfig.SchoolYearSettingListId, fieldValues)
                .then(response=>{                    
                    if(response.id){
                        setFlagReloadAfterAddNew(true);
                    } else {
                        console.log('Lỗi khi thêm mới (response)', response);
                    }
                });
        }
    };


    const deleteItem = (accessToken, item) => {
        let items = Array.from(schoolYearItems);
        remove(items, i=>i.SettingId===item.SettingId);

        //Xóa phía server nếu item này đã tồn tại trên server
        if(item.id){
            deleteSharePointItem(accessToken,sharePointConfig.SchoolYearSettingListId,item.id).then(response =>{
                console.log(response);
            });
        }
        setSchoolYearItems(items);
    }

    useEffect(() => {     
        const accessTokenRequest = {
            ...loginRequest,
            account: accounts[0]
        }        

        const processData = async () => {  
            instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
                const accessToken = accessTokenResponse.accessToken;
                switch(action.type){
                    case 'add':
                        addBlankItem(action.value);
                        break;
                    case 'save':
                        saveItem(accessToken, action.value);
                        break;
                    case 'del':
                        deleteItem(accessToken, action.value);
                        break;
                    case 'get':
                        fetchData(accessToken);
                        break;
                    default:
                        //fetchData(accessToken);
                }
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest);                    
                }
                console.log(error);
            }) 
        }; 

        processData();       
    },[action]);

    return [{schoolYearItems, flagReloadAfterAddNew },  setAction];
}




