import React, { useState, useEffect, useContext} from 'react';
import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

import ScheduleList from './ScheduleList';
import YearSchoolSelection from '../common/YearSchoolSelection';
import WeekSelection from '../common/WeekSelection';
import GradeSelection from '../common/GradeSelection';

import { scheduleConfig } from '../../appConfig';
import { useScheduleDataApi, EmptySchedule } from './ScheduleData';
import { useClassDataApi } from "../classes/ClassData";

import MessageBox from '../common/MessageBox';
//import { contextConfig } from '../../appConfig';
import { MyAppContext } from '../../context/MyAppContext';
import WeekSchoolSelection from '../common/WeekSchoolSelection';

const SchedulePage = (props) => {
    dayjs.extend(isoWeek); 

    const [appContext,] = useContext(MyAppContext);
    const subjectItems = appContext.Subjects;
    const teacherItems = appContext.Teachers;

    const [firstDateOfWeekIsoString, setFirstDateOfWeekIsoString] = useState(appContext.CurrentDateInfo.FirstDateOfWeekIsoString); //useState(dayjs().toISOString());    
    const [schoolYear, setSchoolYear] = useState(appContext.SchoolYear);   
    //schoolWeek, là tuần học (1-35), tuần 1 bắt đầu từ tuần có ngày khai giảng cho đến tuần kết thúc. 
    //Kỳ 1 có 18 tuần, kỳ 2 có 17 tuần
    const [schoolWeek, setSchoolWeek] = useState(appContext.SchoolWeek);  
    const [grade, setGrade] = useState(0); 
    const [{classItems}, setClassAction] = useClassDataApi(schoolYear);
    const [{schedules, isProcessingScheduleData, flagAutoSaveSchedule}, setScheduleAction] = useScheduleDataApi();
    const [classesInfo, setClassesInfo] = useState([]);    
   
    const numOfSegmen = schedules && schedules.ClassList ? schedules.ClassList.length / scheduleConfig.NumOfClassColsPerTable : 0
    const listArray = numOfSegmen > 0 ? Array(numOfSegmen).fill() : [];

    const [showOnlyForMe, setShowOnlyForMe] = useState(false);

    const handleSchoolYearChange = (yearValue) =>{     
        setSchoolYear(1*yearValue);

        //Cập nhật lại danh sách lớp học theo năm
        setClassAction({type:'fetchData', value: yearValue*1});        
        handleGradeChange(grade);
    }

    const handleWeekChange = (weekStartDay, selectedSchoolWeek) => { 
        if (selectedSchoolWeek<1) {
            alert("Chưa chọn tuần...");
            return;
        }
        const firstDOWisoString =  dayjs(weekStartDay).toISOString();
        setFirstDateOfWeekIsoString(firstDOWisoString);
        setSchoolWeek(selectedSchoolWeek);  
        
        //Gửi yêu cầu lấy số liệu
        if (grade<=0) return;

        setScheduleAction({
            type:'fetchData',
            value: {
                classItems: classesInfo,
                grade: grade,
                firstDateOfWeekIsoString: firstDOWisoString,
                schoolYear: schoolYear,
                schoolWeek: selectedSchoolWeek,                
            }
        });   
        //console.log("selectedSchoolWeek - firstDOWisoString", selectedSchoolWeek, firstDOWisoString);
    }

    const handleGradeChange = (gradeValue) => {
        setGrade(gradeValue*1);       
        if (classItems.length === 0) return; 
        const classes = classItems.filter(c =>  c.Grade*1===gradeValue*1);
        setClassesInfo(classes); 

        if(classes.length<=0) {

            return;            
        }
        //Gửi yêu cầu lấy số liệu
        setScheduleAction({
            type:'fetchData',
            value: {
                classItems: classes,
                grade: gradeValue,
                firstDateOfWeekIsoString: firstDateOfWeekIsoString,
                schoolYear: schoolYear*1,
                schoolWeek: schoolWeek,
            }
        });
    }

    /*************************************************************************************
     * updateStorageData Lưu dữ liệu thay đổi tạm xuống local db để sau này lưu về server
     * ***********************************************************************************
     * Dữ liệu sẽ được lưu thành một mảng dữ liệu có các phần tử có cấu trúc EmptySchedule 
     * định nghĩa trong ScheduleData code file
     **************************************************************************************/
     const handleUpdateStorageData = (item_id, classId, className, grade, schedule_date, period, part_day, scheduleCellData) => {
        const scheduleDateObj = dayjs(schedule_date);
        const school_year = scheduleDateObj.month() > 9 ? scheduleDateObj.year(): scheduleDateObj.year()-1;
        const week = scheduleDateObj.isoWeek();

        //1. Xác định Item cần cập nhật dữ liệu thời khóa biểu
        let scheduleItems = schedules.StorageData;
        let updatingDataItem = null;
        let idx1 = -1;
        idx1 = scheduleItems.findIndex(item => item.class_id === classId && item.school_year === school_year && dayjs(item.start_date_week_isostring).isoWeek() === week);
        if(idx1 >= 0) {
            updatingDataItem = scheduleItems[idx1];
        } else {
            updatingDataItem = EmptySchedule(classId, className, grade, schoolYear, schoolWeek, firstDateOfWeekIsoString);
        }       

        //3. Tìm và cập nhật tiết cần cập nhật dữ liệu vào item đã tìm được ở bước 2 dựa vào ngày, tiết, buổi
        let periodRecords = updatingDataItem.records;
        const idx2 = periodRecords.findIndex(r=>
            dayjs(r.schedule_date).format('dddd') === scheduleDateObj.format('dddd') 
            && r.period === period 
            && r.part_day === part_day);

        periodRecords[idx2] = {
            ...periodRecords[idx2],
            id: scheduleCellData.id,
            period: period,
            part_day: part_day,            
            subject_id: scheduleCellData.subject_id,
            teacher_id: scheduleCellData.teacher_id,
            comment: scheduleCellData.comment,

        }
        updatingDataItem.records[idx2] = periodRecords[idx2];
      
        //4. Cập nhật và ghi dữ liệu thay đổi vào localStorage        
        updatingDataItem.flagDataChanged = true; //Dựng cờ báo hiệu mục này có thay đổi dữ liệu
        if(idx1>=0) {
            schedules.StorageData[idx1] = updatingDataItem;
        } else {
            updatingDataItem.item_id = item_id;
            schedules.StorageData.push(updatingDataItem);
        }

        console.log('updatingDataItem', updatingDataItem);
        localStorage.setItem(scheduleConfig.SCHEDULE_LS_KEY, JSON.stringify(scheduleItems));       
  
    }    

    const handleSaveSchedule = () => {
        //Lọc lấy dữ liệu có thay đổi và không phải là dữ liệu của các lớp "điền làm đẹp khi hiện TKB" 
        const changeDataItems = schedules.StorageData.filter(item => item.flagDataChanged===true && item.class_name!=='NA');
        if (changeDataItems.length > 0) {
            setScheduleAction({
                type:'save',
                value: changeDataItems, 
            });
            console.log('Lưu dữ liệu về server: ', changeDataItems);
        } else {
            console.log('Không có dữ liệu thay đổi');
        }       
    }

    useEffect(()=>{
        if (flagAutoSaveSchedule>0) {
            handleSaveSchedule();
        }        
    },[flagAutoSaveSchedule])

    return(
        <div className='ms-3 px-2'>
            <div className="row pt-3">
                <div className="col-md-6">
                    <h5>THỜI KHÓA BIỂU NĂM HỌC {schoolYear}-{schoolYear+1} </h5>
                    {schoolWeek!=="" &&  
                        <p>
                            Tuần {schoolWeek} ({dayjs(firstDateOfWeekIsoString).isoWeekday(1).format('DD/MM/YYYY')}-{dayjs(firstDateOfWeekIsoString).isoWeekday(6).format('DD/MM/YYYY')})
                        </p>
                    }
                </div>
                <div className="col-md-6 d-print-none">
                    <div className="row">
                        <div className="col-md-3" style={{"visibility":"hidden"}}>
                            <YearSchoolSelection 
                                year={schoolYear}
                                onYearChange = {handleSchoolYearChange}
                            >Năm học</YearSchoolSelection>
                        </div> 
                        <div className="col-md-3">
                            <WeekSchoolSelection
                                selectedYear = {schoolYear}
                                selectedDate = {firstDateOfWeekIsoString}
                                onWeekChange ={handleWeekChange}                            
                            >
                                Tuần học
                            </WeekSchoolSelection>
                        </div>               
                        <div className="col-md-2">
                            <GradeSelection
                                grade={grade}
                                onGradeChange={handleGradeChange}
                            >Khối</GradeSelection>
                        </div>
                        <div className="col-md-4">
                            <input type="checkbox" id="showForMyOnly" className='form-check-input me-1' style={{'marginTop':'30px'}}
                                onChange={e=> setShowOnlyForMe(e.target.checked)}
                            />
                            <label className="form-check-label" htmlFor='showForMeOnly' style={{'marginTop':'25px'}}>Chỉ hiện TKB của tôi</label>
                        </div>
                        {/* <div className="col-md-2">
                            <button className='btn btn-primary btn-sm' disabled= {contextConfig.ProductionMode?"disabled":""}  
                                style={{'marginTop':'20px'}}
                                onClick = {handleSaveSchedule}>
                                Lưu TKB
                            </button>
                        </div> */}
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col-md-12">
                    {
                        isProcessingScheduleData ?
                        <MessageBox format="fw-bold text-center p-5">Đang lấy dữ liệu...</MessageBox>
                        :
                        (schedules && schedules.ClassList.length>0 && grade>0) ?
                        <>{
                            listArray.map((item, index) => {
                                return (
                                    <ScheduleList 
                                        key={index}
                                        scheduleItems={schedules.DisplayData}
                                        showForTeacherId = {showOnlyForMe ? appContext.CurrentUser.TeacherId: ""}
                                        classList = {schedules.ClassList} 
                                        subjects={subjectItems}
                                        teachers={teacherItems}
                                        firstItemIndex={index*scheduleConfig.NumOfClassColsPerTable}
                                        lastItemIndex={(index+1)*scheduleConfig.NumOfClassColsPerTable -1}
                                        onUpdateScheduleData = {handleUpdateStorageData}
                                        listKey={"LK"+ index}
                                    />
                                )
                            })
                          }
                        </>
                        : <MessageBox>Không có dữ liệu hoặc chưa chọn khối lớp cần xem!</MessageBox>
                    }
                    
                </div>
            </div>            
        </div>
    )
}
export default SchedulePage;
