const GradeSelection = (props) => {
    const {grade, onGradeChange} = props; 
    return(
        <div>
            { props.children }
            <select name="selectedClass" className="form-select form-select-sm" 
                value={grade}
                onChange={(e) => onGradeChange(e.target.value)}
            >
                <option value={0}></option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
            </select>
        </div>
    )
}
export default GradeSelection;