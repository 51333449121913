import React, {useState} from 'react';
import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

// import Multiselect from "multiselect-react-dropdown";
import DOMPutify from 'dompurify';
import { scheduleConfig } from '../../appConfig';

const LSListItemMakeupLessonDialog = (props) => {
    dayjs.extend(isoWeek);    
    const {isOwner, subjectPlanDetails, classItems, teacherSubjects, dialogId} = props;
    const [scheduleItem, setItem] = useState(props.scheduleItem);
    const [selectedSubjectPlanDetail, setSelectedSubjectPlanDetail] = useState(props.currentSubjectPlanDetailObj);
    const [selectedClassObj, setSelectedClassObj] = useState(classItems.find(item=>item.ClassId===scheduleItem.class_id));
    const [subjectPlanDetailItems, setSubjectPlanDetailItems] = useState(subjectPlanDetails.filter(item=>item.SubjectId===scheduleItem.subject_id && item.Grade===selectedClassObj.Grade));
    

    //const [beforeEditItem, setBeforeEditItem] = useState({...props.item});

    const handleChange = (e) => {
        const target = e.target;
        let name = target.name;
        let value = target.type==='checkbox'?target.checked:target.value;
        let classObj= null;

        if (name === "class_id") {
            if(value!=="") {
                classObj = classItems.find(item=>item.ClassId===value);
                //const ddlSubject = document.getElementById('subject_id'+dialogId);
                // selectedSubjectId = ddlSubject.value;
                setSelectedClassObj(classObj);

                if(scheduleItem.subject_id) {
                    setSubjectPlanDetailItems(subjectPlanDetails.filter(item=>item.SubjectId===scheduleItem.subject_id && item.Grade===classObj.Grade));
                }
                else {
                    setSubjectPlanDetailItems([]);
                }
            } else{
                setSubjectPlanDetailItems([]);
            }
        }
        if (name ==="subject_id") {
            if (value!=="" && selectedClassObj){                
                setSubjectPlanDetailItems(subjectPlanDetails.filter(item=>item.SubjectId===value && item.Grade===selectedClassObj.Grade));
            }
            else {
                setSubjectPlanDetailItems([]);
            }
        }

        if (name === "lecture_id") {
            const idx = subjectPlanDetails.findIndex(sp=>sp.LectureId === value);  
            const spObj = idx >=0 ? subjectPlanDetails[idx] : {};
            setSelectedSubjectPlanDetail(spObj);
        }
        setItem({
            ...scheduleItem,
            [name]: value,
            class_name: name==='class_id' && classObj ? classObj.Title: scheduleItem.class_name,
        });  
    }

    const handleClearMakupLesson = () => {
        setItem({
            ...scheduleItem, class_id:"", class_name:"", subject_id:"", status:"", is_free:true,
        });
        setSubjectPlanDetailItems([]);
        props.onSaveCellClick({...scheduleItem, class_id:"", class_name:"", subject_id:"", status:"", is_free:true}, []);
    }

    const createMarkup = (html) => {
        return {
            __html: DOMPutify.sanitize(html)
        }
    }
    
    const dgElement = props.action==='edit' || props.action==='new'
    ? 
    <div className="modal fade" id={dialogId} data-bs-backdrop="static" data-bs-keyboard="false" 
         tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">TIẾT DẠY BÙ</h5> 
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>                        
                </div>
                <div className="modal-body text-start pt-1">
                    <p className="fw-bold pt-3">
                        {props.title}
                    </p>
                    <div className="ps-3">
                        <div className='mb3'>
                             <label htmlFor='class_id' className='col-form-label'>Lớp học</label>
                            <select name="class_id" id={"class_id"+dialogId} className='form-select form-select-sm'
                                value= {scheduleItem.class_id} autoFocus
                                onChange={(e)=>handleChange(e)} 
                            >
                                <option value=""></option>
                                {
                                    classItems.map((clsItem,idx)=>
                                        <option key={idx} value={clsItem.ClassId}>{clsItem.Title}</option>
                                    )
                                }
                            </select>
                        </div>

                        <div className='mb3'>
                            <label htmlFor='subject_id' className='col-form-label'>Môn học</label>
                            <select name="subject_id" id={"subject_id"+dialogId} className='form-select form-select-sm'
                                value= {scheduleItem.subject_id}
                                onChange={(e)=>handleChange(e)} 
                            >
                                <option value=""></option>
                                {
                                    teacherSubjects.map((ts,idx)=>
                                        <option key={idx} value={ts.SubjectId}>{ts.Title}</option>
                                    )
                                }
                            </select>
                        </div>
                        <div className='mb3'>
                            <label htmlFor='lecture_id' className='col-form-label'>Bài giảng</label>
                            <select name='lecture_id' 
                                className='form-select form-select-sm'
                                value={scheduleItem.lecture_id}
                                onChange={(e)=>handleChange(e)}
                            >
                                <option value=""></option>
                                {
                                    subjectPlanDetailItems.filter(item=>item.IsActive).map((subjectPlan, idx) => 
                                        <option key={idx} value={subjectPlan.LectureId}>Tiết {subjectPlan.Period} {subjectPlan.Category} - {subjectPlan.Title}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>                         
                </div>
                <div className="modal-footer">
                    <button type="button" disabled={scheduleItem.lecture_id && subjectPlanDetailItems.length>0 && isOwner ? "" : "disabled" }
                        className="btn btn-primary btn-sm" data-bs-dismiss="modal"
                        onClick={()=> props.onSaveCellClick(scheduleItem, selectedSubjectPlanDetail)}
                    >Ghi lại</button> &nbsp;&nbsp;

                      {/* scheduleItem.subject_id!=="" && scheduleItem.lecture_id!=="" && scheduleItem.item_id>0 */}
                    { scheduleItem.subject_id!=="" && scheduleItem.lecture_id!=="" && scheduleItem.type === scheduleConfig.LectureScheduleType_DayBu &&
                        <button type="button"
                            className="btn btn-warning btn-sm" data-bs-dismiss="modal"
                            onClick={()=> handleClearMakupLesson()}
                        >Bỏ dạy bù</button>
                    }
                    &nbsp;&nbsp;<button type="button" 
                        className="btn btn-secondary btn-sm" data-bs-dismiss="modal"
                        onClick={props.handleCancel}
                    >&nbsp;Đóng&nbsp;</button>
                </div>
            </div>
        </div>
    </div>
    : 
     <div className="modal fade" id={dialogId} 
        data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" 
        aria-labelledby="staticBackdropLabel" aria-hidden="true">

        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{props.title}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>                        
                </div>
                <div className="modal-body">
                    <p></p>
                    <div 
                        className='preview' 
                        style={{"margin":"5px 0px 20px 0px"}}
                        dangerouslySetInnerHTML={createMarkup(scheduleItem.comment)}
                    >                        
                    </div>  
                    <div>
                        <p></p>
                    </div>                              
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Đóng lại</button>
                </div>
            </div>
        </div>
    </div> 

    return(
        <>
            { dgElement }
        </>
    )
}

export default LSListItemMakeupLessonDialog;