import React from 'react';
import "./App.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { 
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';

import {PageLayout} from './components/ui/PageLayout';
import AppRoutes from './components/ui/AppRoutes';

import SignInSignOutButton from './components/ui/SignInSignOutButton';
import NavBar from './components/ui/NavBar';
import { MyAppProvider } from './context/MyAppContext';


function App({msalInstance}) {
  return (
    <div className="container-fluid" style={{'fontSize':'0.85em'}} >
      <MsalProvider instance={msalInstance}>    
        <PageLayout>
          <AuthenticatedTemplate>
              <MyAppProvider>
                <NavBar />              
                <AppRoutes />
              </MyAppProvider>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>          
              <div className="card mb-3 m-3 border-0 text-center">
                <div className="card-body">
                  <h5 className="card-title">Phần mềm quản lý nội bộ</h5>
                  <p className="card-text">THCS Nguyễn Trường Tộ</p>
                  <hr/>
                  <p className='card-text'>Hỗ trợ BGH và giáo viên quản lý, theo dõi thời khóa biểu, lịch báo giảng, kế hoạch dạy học, kế hoạch công tác, chậm tiết... một cách tập trung, thuận tiện và hiệu quả hơn.</p>
                  <p className="card-text">Thầy cô vui lòng đăng nhập để sử dụng phần mềm!</p>
                  <SignInSignOutButton />
                </div>
              </div>
          </UnauthenticatedTemplate>
        </PageLayout>        
      </MsalProvider>    
    </div>
  );
}

export default App;
