import React, {useState} from "react";

function SubjectItem(props){ 
    const [item, setItem] = useState(props.item);
    const [beforeEditItem, setBeforeEditItem] = useState({...props.item});

    const [action, setAction] = useState(props.action);

    const handleEdit = () =>{
        setBeforeEditItem({...item});
        setAction('edit');
    }

    const handleSave = () => {
        props.onSaveItemClick(item);
        setAction('');
    }

    const handleCancel = () =>{
        if(action==='new'){
            handleDelete();
        }else{ //edit action nên hủy các sửa đổi, khôi phục về giá trị trước khi sửa
            setItem(beforeEditItem);
        }
        setAction('');
    }

    const handleDelete = () =>
    {
        if (action!=='edit' && action !=='new') {  
            if ( window.confirm(`Bạn có chắc chắn xóa môn học ${item.Title} này không?`)) {
                props.onDeleteItemClick(item); 
            }            
        } else {
            props.onDeleteItemClick(item); 
        }
    }

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.type==='checkbox'?target.checked:target.value;

        //Kiểm tra để không cho nhập trùng tên môn học
        if ((action==="new" || action==="edit") && name==="Title") {
            const idx = props.subjectItems.findIndex(s => s.Title === value && s.SubjectId !== item.SubjectId);
            if (idx>=0) {
                alert(`Nhận trùng tên môn học ${value}. Kiểm tra và nhập lại`);                
                return;
            }
        }

        //Kiểm tra để không cho nhập trùng tên môn học dùng để xếp và hiện TKB
        if ((action==="new" || action==="edit") && name==="AliasName") {
            const idx = props.subjectItems.findIndex(s => s.AliasName === value && s.SubjectId !== item.SubjectId);
            if (idx>=0) {
                alert(`Nhận trùng tên môn học dùng xếp TKB ${value}. Kiểm tra và nhập lại`);                
                return;
            }
        }

        setItem({
            ...item,
            [name]:value
        });
    }

    const handleKeyUp = (event, nextElementId) => {
        if (event.keyCode===13){
            const nextElm = document.getElementById(nextElementId);
            if (nextElm) nextElm.focus();
        }
    }

    const rowElement = (action==='edit' || action==='new')
        ? <tr>
            <td>
                <input type='text' name='Title' style={{'width':'100%'}}                     
                    className="form-control form-control-sm" autoFocus
                    value={item.Title}
                    onKeyUp = {(event)=> handleKeyUp(event, "AliasName")}
                    onChange={(e)=>handleChange(e)} />
            </td>
            <td>
                <input type='text' name='AliasName' id='AliasName' style={{'width':'100%'}}                     
                    className="form-control form-control-sm"
                    value={item.AliasName}
                    onKeyUp = {(event)=> handleKeyUp(event, "Category")}
                    onChange={(e)=>handleChange(e)} />
            </td>
            <td>
                <input type='text' name='Category' id="Category" style={{'width':'100%'}} className="form-control form-control-sm"
                    value={item.Category} 
                    onKeyUp = {(event)=> handleKeyUp(event, "IsActive")}
                    onChange={(e)=>handleChange(e)} />
            </td>  
            <td>
                <select name="IsActive" id="IsActive" className="form-select form-select-sm"
                    value={item.IsActive} onChange={ e=> handleChange(e)}
                    onKeyUp = {(event)=> handleKeyUp(event, "btnSaveItem")}
                >
                    <option value={true}>Có</option>
                    <option value={false}>Không</option>
                </select>
            </td>                       
            <td className="text-end">
                <button type="button" onClick={handleSave} id="btnSaveItem" className="btn btn-primary btn-sm"><i className="fa fa-floppy-o"></i></button>&nbsp;
                <button type="button" onClick={handleCancel} className="btn btn-secondary btn-sm"><i className="fa fa-ban"></i></button>                
            </td>            
        </tr>
        :<tr>
            <td>{item.Title}</td>
            <td>{item.AliasName}</td>
            <td>{item.Category}</td>
            <td>{item.IsActive==='true' || item.IsActive===true?'Có':'Không'}</td>
            <td className="text-end">
                <button type="button" onClick={handleEdit} className="btn btn-warning btn-sm"><i className="fa fa-pencil"></i></button>&nbsp;
                <button type="button" onClick={handleDelete} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i></button>                
            </td>
        </tr>;

    return(
        <>
            {rowElement}
        </>
    )
}

export default SubjectItem;