import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import vi from 'date-fns/locale/vi';

function DateSelection({selectedDate, onDateChange}){
    registerLocale('vi', vi);
    return (
        <DatePicker className="form-control form-control-sm"
            selected={selectedDate}
            locale='vi' 
            dateFormat= "dd/MM/yyyy"
            onChange={(date)=> {
                onDateChange(date);
            }}>            
        </DatePicker>
    )
}
export default DateSelection;