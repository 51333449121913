import React, {useState} from 'react';
import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

import MySchedule from './MySchedule';
import YearSchoolSelection from '../common/YearSchoolSelection';
import WeekSelection from '../common/WeekSelection';

import { useScheduleDataApi } from '../schedule/ScheduleData';
import { useSubjectDataApi } from "../subject/SubjectData";
import { useTeacherDataApi } from "../teacher/TeacherData";
import { useClassDataApi } from "../classes/ClassData";

//import MessageBox from '../common/MessageBox';

const MyPage = () => {
    dayjs.extend(isoWeek);   

    const currentSchoolYear = dayjs().month()>9 ? dayjs().year()-1 : dayjs().year();    
    const [year, setYear] = useState(currentSchoolYear);
    const [week, setWeek] = useState(dayjs().isoWeek());   

    const [{classItems}, setClassAction] = useClassDataApi(year);
    const [{subjectItems}] = useSubjectDataApi();
    const [{teacherItems}] = useTeacherDataApi(); 
    const [{schedules, isProcessingScheduleData}, setScheduleAction] = useScheduleDataApi();


    const handleYearChange = (yearValue) =>{        
        setYear(1*yearValue);
        setClassAction(
            {
                type:'fetchData',
                value: yearValue*1,
            }
        );
    }

    const handleWeekChange = (weekStartDay) =>{
        const selectedWeek = dayjs(weekStartDay).isoWeek();
        const selectedYear = dayjs(weekStartDay).year();
        setWeek(1*selectedWeek);

        //Gửi yêu cầu lấy số liệu
        setScheduleAction({
            type:'fetchData',
            value: {
                classItems: [],
                grade: '',
                schoolYear: selectedYear*1,
                schoolWeek: selectedWeek*1
            }
        });     
    }

    return(
        <div className='px-2'>
            <div className="row">
                <div className="col-md-12">
                    <h4>Thông tin và công việc của tôi</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-3 col-md-2">
                    <WeekSelection
                        selectedYear = {year}
                        onWeekChange ={handleWeekChange}
                    >
                        Tuần học
                    </WeekSelection>
                </div>
                <div className="col-xs-3 col-md-2">
                    <YearSchoolSelection 
                        year={year}
                        onYearChange = {handleYearChange}
                    >
                        Năm học
                    </YearSchoolSelection>
                </div>

                <div className="col-xs-6 col-md-8">

                </div>                
            </div>
            <div className="row" style={{'marginTop':'10px'}}>
                <div className="col-md-9">
                    <MySchedule />
                </div>
                <div className="col-md-3">

                </div>
            </div>
        </div>
    )
}

export default MyPage;
