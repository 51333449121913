import React, {useState} from "react";
import NoteItem from "./NoteItem";

function NoteList(props) {
    const[selectedPartDay, setSelectedPartDay] = useState('Sáng');
    return(
        <form className="form-inline">       
            <table className="table table-bordered table-sm table-hover border">
                <thead style={{'fontSize':'0.8em'}}>
                    <tr className="align-middle">
                        <th style={{width:'6%'}} className='text-center'>Ngày</th>
                        <th style={{width:'3%'}} className='text-center'>Buổi</th>
                        <th style={{width:'4%'}} className="text-center">Tiết</th>
                        <th style={{width:'6%'}}>Môn</th>
                        <th style={{width:'5%'}} className="text-center">Tiết<br/>PBCT</th>
                        <th style={{width:'15%'}}>Bài học, nội dung</th>                                
                        <th style={{width:'5%'}} className='text-center'>HS vắng</th>
                        <th style={{width:'15%'}}>Nhận xét</th>                        
                        <th style={{width:'12%'}}>Giáo viên</th>
                        <th style={{width:'12%'}}>Ghi chú khác</th>
                        <th style={{width:'9%'}} className='text-end'>
                            <select name='slPartDay' className="form-select form-select-sm"
                                value={selectedPartDay}
                                onChange={e=> setSelectedPartDay(e.target.value)}                                
                            >                                
                                <option value='all'>Cả ngày</option>
                                <option value='Sáng'>Sáng</option>
                                <option value='Chiều'>Chiều</option>
                            </select>
                        </th>
                    </tr>
                </thead>
                <tbody style={{'lineHeight':'12px'}} >
                    { 
                        props.data.length>0 &&
                        props.data.filter(i=>selectedPartDay==='all'?i.part_day!=='all' : i.part_day===selectedPartDay).map((item)=> 
                            <NoteItem 
                                key={item.id} 
                                item={item} 
                                view={selectedPartDay}
                                subjects={props.subjects} 
                                teachers={props.teachers}                                    
                                subjectPlanDetails = {props.subjectPlanDetails}
                                onSaveItemClick = {props.onSaveItemClick}
                            />
                        )
                    }
                </tbody>
            </table>
        </form>
    )
}

export default NoteList;