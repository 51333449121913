import React, { useState, useEffect }  from 'react';
import {orderBy as _orderBy} from 'lodash';

import { useScheduleDataApi } from './ScheduleData';

import LSListItem from './LSListItem';
//import dayjs from 'dayjs';
import { scheduleConfig } from '../../appConfig';

const LSList = (props) => {
    const {teacherId, isOwner, subjectPlanDetails, subjects, teacherSubjects, schoolYear, schoolWeek, onUpdateCellChange, firstDayOfWeekIsoString=false} = props;
    const [schedules, setSchedules] = useState(props.schedules);
    const [lsCellChanged, setLSCellChanged] = useState(false);
    const [{freePeriodItems}, setScheduleAction] = useScheduleDataApi();

    const handleLSCellChange = (lsItem, selectedSubjectPlainDetailItem, applyForOtherPeriods) => { 
        //console.log('lsItem', lsItem); 
        //console.log('applyForOtherPeriods',applyForOtherPeriods); 

        let lsItemsNeedChange = schedules;
        const idx = lsItemsNeedChange.findIndex(item=> item.id === lsItem.id);
       
        if (idx >= 0) {
            //console.log('selectedSubjectPlainDetailItem',selectedSubjectPlainDetailItem);
            lsItemsNeedChange[idx].lecture_id = selectedSubjectPlainDetailItem.LectureId;
            lsItemsNeedChange[idx].lecture_week = selectedSubjectPlainDetailItem.Week;
            lsItemsNeedChange[idx].lecture_period = selectedSubjectPlainDetailItem.Period;
            lsItemsNeedChange[idx].category = selectedSubjectPlainDetailItem.Category;
            lsItemsNeedChange[idx].comment = lsItem.comment?lsItem.comment:"";
            lsItemsNeedChange[idx].status = lsItem.status; 
            if (lsItem.is_free) { //Tiết trống, trắng cho trường hợp nhập tiết dạy bù
                lsItemsNeedChange[idx].class_id = lsItem.class_id;
                lsItemsNeedChange[idx].class_name = lsItem.class_name;
                lsItemsNeedChange[idx].subject_id = lsItem.subject_id;
                if (lsItem.subject_id==="" & lsItem.class_id===""){  //Trường hợp không nhập đủ thông tin dạy bù
                    lsItemsNeedChange[idx].class_name = ""; 
                    lsItemsNeedChange[idx].teacher_id = ""; 
                    lsItemsNeedChange[idx].category = "";
                    lsItemsNeedChange[idx].status = "";
                    lsItemsNeedChange[idx].type = "";

                    lsItemsNeedChange[idx].lecture_id="";
                    lsItemsNeedChange[idx].lecture_week=0;
                    lsItemsNeedChange[idx].lecture_period = 0;
                }
                else {
                    lsItemsNeedChange[idx].teacher_id = teacherId; 
                    lsItemsNeedChange[idx].status = scheduleConfig.LectureScheduleType_DayBu;
                    lsItemsNeedChange[idx].type =  scheduleConfig.LectureScheduleType_DayBu;
                }

            }            
            lsItemsNeedChange[idx].is_late = lsItemsNeedChange[idx].school_week - selectedSubjectPlainDetailItem.Week >= scheduleConfig.SCHEDULE_LECTURE_LATE_WEEKS ? true: false;

            //Áp dụng bài giảng đã chọn cho các lớp khác cùng khối
            if (applyForOtherPeriods) { 
                //Lọc lấy tất cả các lớp cùng khối với lớp đã vừa chọn được bài giảng
                const grade = lsItem.class_name.substring(0,1);
                const selectedItems = lsItemsNeedChange.slice(idx+1).filter(item=>item.class_name  && item.type!==scheduleConfig.LectureScheduleType_DayBu
                    && item.class_name!==lsItem.class_name 
                    //&& item.lecture_id===""
                    && item.class_name.substring(0,1)===grade);
                //Cập nhật bài giảng cho các mục tương ứng với các lớp cùng khối
                const hasDoneItems = [];
                selectedItems.forEach(selectedItem =>{
                    if (hasDoneItems.length<1 || !hasDoneItems.includes(selectedItem.class_name)) {   
                        hasDoneItems.push(selectedItem.class_name);                                 
                        const idx = lsItemsNeedChange.findIndex(item=> item.id === selectedItem.id);                        
                        lsItemsNeedChange[idx].lecture_id = selectedSubjectPlainDetailItem.LectureId;
                        lsItemsNeedChange[idx].lecture_week = selectedSubjectPlainDetailItem.Week;
                        lsItemsNeedChange[idx].lecture_period = selectedSubjectPlainDetailItem.Period;
                        lsItemsNeedChange[idx].category = selectedSubjectPlainDetailItem.Category;
                        lsItemsNeedChange[idx].comment = lsItem.comment?lsItem.comment:"";
                        lsItemsNeedChange[idx].status = lsItem.status; 
                        lsItemsNeedChange[idx].is_late = lsItemsNeedChange[idx].school_week - selectedSubjectPlainDetailItem.Week >= scheduleConfig.SCHEDULE_LECTURE_LATE_WEEKS ? true: false;
                    }
                });
            }
           
            // Gửi lên dữ liệu lên cấp cha để lưu về máy chủ DL
            onUpdateCellChange(lsItemsNeedChange[idx]);
            setSchedules(lsItemsNeedChange);
            setLSCellChanged(true);
        }

    }

    useEffect(()=>{
        if(lsCellChanged) setLSCellChanged(!lsCellChanged);
    },[lsCellChanged])

    useEffect(()=>{
        let classIds = [];
        const items = schedules.filter(item=>item.class_id!=="");
        items.forEach(item=>{
            if (!classIds.includes(item.class_id)){
                classIds.push(item.class_id);
            }
        });
        if (classIds.length<=0) return;
        
        setScheduleAction(
          {
              type:'fetchfreeperiods',
              value: {
                  classIds: classIds,
                  fromDateIsoString: firstDayOfWeekIsoString,
                  numOfWeeks: 2,
              }
          }
        )
    },[firstDayOfWeekIsoString, schedules, setScheduleAction]) 

    let grades = [];
    let classItems=[];
    let classNames=[];
    const classList = schedules.filter(s=>s.class_name!=="");                                
    classList.forEach(element=>{ 
        const grade = element.class_name.substring(0,1)*1;                           
        if(!classNames.includes(element.class_name)){ 
            classNames.push(element.class_name); 
            classItems.push({ClassId: element.class_id, Title: element.class_name, Grade: grade});
            if (!grades.includes(grade)) grades.push(grade);
        }                                    
    });

    classItems = _orderBy(classItems,['Title'],['asc']);
    return (
        <>
            <table className="table table-hover table-bordered table-sm border"> 
                <thead className="table-light" style={{'fontSize':'0.8em'}}>
                    <tr className="align-middle">
                        <th rowSpan="2" className='text-center' style={{'width':'10px'}}>Thứ</th>
                        <th rowSpan="2" className='text-center' style={{'width':'10px'}}>Buổi</th>
                        <th rowSpan="2" className='text-center' style={{'width':'12px'}}>Tiết</th>
                        <th rowSpan="2" className='text-center' style={{'width':'12px'}}>Lớp</th>
                        <th rowSpan="2" className='text-center'  style={{'width':'30px'}}>Môn</th>
                        <th colSpan="4" className='text-center'  style={{'width':'45%'}}>Phân phối chương trình</th>
                        <th rowSpan="2" style={{'width':'25%'}}>Ghi chú</th>
                        <th rowSpan="2" className='text-center' style={{'width':'25px'}}>Trạng thái</th>
                        <th rowSpan="2" className='text-center' style={{'width':'25px'}}> </th>
                    </tr>
                    <tr>
                        <th className='text-center'  style={{'width':'25px'}}>PM</th>
                        <th className='text-center' style={{'width':'12px'}}>Tuần</th>
                        <th className='text-center' style={{'width':'12px'}}>Tiết</th>                        
                        <th style={{'width':'35%'}}>Bài giảng</th>
                    </tr>
                </thead>
                <tbody style={{"lineHeight":"1.0"}}>
                    {
                        schedules.map((item, idx) => {
                            let subjectItems = [];
                            //let classItems  = [];
                            let subjectPlanDetailItems = [];
                            const itemGrade = parseInt(item.class_name.substring(0,1));

                            if (item.subject_id !== "" && !item.is_free) { //Trường hợp tiết học có môn
                                subjectItems =  subjects.filter(s=>s.SubjectId === item.subject_id);
                                subjectPlanDetailItems = subjectPlanDetails.filter(sp=>sp.SubjectId === item.subject_id 
                                    && sp.Grade === itemGrade);
                            } else { //Tiết học không có môn nên sẽ lấy bài giảng theo các môn mà cô sẽ dạy
                                //Xác định các lớp mà cô dạy từ danh sách các tiết dạy hiện tại
                                let subjectIds = [];
                                teacherSubjects.forEach(ts=>{
                                    subjectIds.push(ts.SubjectId);
                                });

                                subjectItems =  teacherSubjects; //subjects.filter(s=>subjectIds.includes(s.SubjectId));
                                subjectPlanDetailItems = subjectPlanDetails.filter(sp => subjectIds.includes(sp.SubjectId) 
                                    && grades.includes(sp.Grade));
                            }

                            // console.log('subjectPlanDetails gốc dùng để lọc để gửi vào item', subjectPlanDetails);
                            
                            return (
                                <LSListItem key={idx}
                                    scheduleItem = {item}
                                    subjects = {subjectItems}
                                    teacherSubjects = {teacherSubjects}
                                    subjectPlanDetails = {subjectPlanDetailItems}
                                    classItems = {classItems}
                                    schoolYear = {schoolYear}
                                    schoolWeek = {schoolWeek}
                                    freePeriodItems = {freePeriodItems}
                                    onSaveCellClick = {handleLSCellChange}
                                    isOwner = {isOwner}
                                />
                            )
                        })
                    }
                </tbody>
            </table>
        </>
    )
}

export default LSList;
