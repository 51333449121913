import  {useEffect, useState} from 'react';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { v4 as uuidv4 } from 'uuid';
import { remove, orderBy as _orderBy} from 'lodash';

import { loginRequest, sharePointConfig } from '../../authConfig';
import {
    getSharePointListItems,
    updateSharePointItem,
    addSharePointListItem,
    deleteSharePointItem
} from '../../graph';

import { Subjects } from '../../mock/MockData';

export const createBlankSubjectItem = () =>{
    return [
        {
            id: 0,
            SubjectId: uuidv4(),
            Title: '',
            AliasName: '',
            Category:'',
            IsActive: true
        }
    ]
}

export const useSubjectDataApiMock = () => {
    const [subjectItems, setSubjectItems] = useState(Subjects);
    const [action, setAction] = useState({type:'', value:''});
 
    useEffect(() => { 
        const fetchData = async () => {              
            setSubjectItems(Subjects);           
        };

        const addBlankItem = async (item) => {
            setSubjectItems(item.concat(subjectItems));
        };

        const submitData = async (item) => {
            console.log('Submit data');
        };

        const deleteItem = async (item) => {
            let items = Array.from(subjectItems);
            remove(items, i=>i.SubjectId===item.SubjectId);        
            setSubjectItems(items); 
        }    

        const processData =() => {
            switch(action.type){
                case 'add':
                    addBlankItem(action.value);
                    break;
                case 'save':
                    submitData(action.value);
                    break;
                case 'del':
                    deleteItem(action.value);
                    break;
                default:
                    fetchData();
            }
        }

        processData();

    },[action]);

    return [{subjectItems},  setAction];
}

export const useSubjectDataApi = () => {
    const [subjectItems, setSubjectItems] = useState([]);
    const [action, setAction] = useState({type:'', value:''});
    const [flagReloadAfterAddNew, setFlagReloadAfterAddNew] = useState(false);

    const {instance, accounts} = useMsal();

    const fetchData = (accessToken) =>{
        //Lấy cả trường ID của SharePoint List ItemID phục vụ cho việc cập nhật/xóa
        getSharePointListItems(
            accessToken, 
            sharePointConfig.SubjectListId,
            "SubjectId,Title,AliasName,Category,IsActive,id", 
            ""  
        ).then(response => {  
            let items = [];
            const listItemArray = response.value;
            for(let i=0; i<listItemArray.length; i++){
                items.push(listItemArray[i].fields);
            }

            items = _orderBy(items, ['Title'],['asc']);
            setSubjectItems(items);
        })
    }

    const addBlankItem = (item) => {
        setSubjectItems(item.concat(subjectItems));
    };

    const saveItem = (accessToken, item) => {
        setFlagReloadAfterAddNew(false);

        let fieldValues = {};
        if(item.id>0){ //Nếu đã có thì cập nhật nội dung 
            fieldValues = {
                fields: {
                    Title: item.Title,
                    AliasName: item.AliasName,
                    Category: item.Category,
                    IsActive: item.IsActive==='true' || item.IsActive=== true ? true:false
                }
            };  
            updateSharePointItem(accessToken, sharePointConfig.SubjectListId, item.id, fieldValues)
                .then(response => {
                    //console.log(response)
                });
        }
        else{ //Nếu chưa có thì thêm vào 
            fieldValues = {
                fields: {
                    SubjectId: item.SubjectId,
                    Title: item.Title,
                    AliasName: item.AliasName,
                    Category: item.Category,
                    IsActive: item.IsActive==='true' || item.IsActive===true?true:false
                }
            };
            addSharePointListItem(accessToken, sharePointConfig.SubjectListId, fieldValues)
                .then(response=>{
                    if(response.id){
                        setFlagReloadAfterAddNew(true);
                    } else {
                        console.error('Có lỗi khi thêm mới môn học (response)', response)
                    }
                });
        }

    };

    const deleteItem = (accessToken, item) => {
        let items = Array.from(subjectItems);
        remove(items, i=>i.SubjectId===item.SubjectId);

        //Xóa phía server nếu item này đã tồn tại trên server dựa vào SharePoint List Item ID field
        if(item.id){
            deleteSharePointItem(accessToken,sharePointConfig.SubjectListId,item.id).then(response =>{
                console.log('Completed delete item ' + item.SubjectId);
                console.log(response);
            });
        }
        setSubjectItems(items);
    }

    
    useEffect(() => {     
        const accessTokenRequest = {
            ...loginRequest,
            account: accounts[0]
        }

        const processData = async () => {  
            instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
                const accessToken = accessTokenResponse.accessToken;
                switch(action.type){
                    case 'add':
                        addBlankItem(action.value);
                        break;
                    case 'save':
                        saveItem(accessToken, action.value);
                        break;
                    case 'del':
                        deleteItem(accessToken, action.value);
                        break;
                    default:
                        fetchData(accessToken);
                }
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest);                    
                }
                console.log(error);
            }) 
        }; 

        processData();  

    },[action]);

    return [{subjectItems, flagReloadAfterAddNew},  setAction];
}
