import React , {useState } from "react";
import WorkPlanItem from "./WorkPlanItem";

const WorkPlanList = ({data, teacherList, selectedDepartmentId, departmentItems,allowChangeWorkPlan, onAddItemClick, onSaveItemClick, onDeleteItemClick, onCreatWorkPlanClick}) => {
    //Thêm một mục All vào đầu danh sách giáo viên
    //const [teacherListWithAll,] = useState([{...teacherList[0], TeacherId:'All',Title:'Cả tổ'} , ...teacherList]);
    const teacherListWithAll = [{...teacherList[0], TeacherId:'All',Title:'Cả tổ'} , ...teacherList];
    return(
        <form className="form-inline" name="frmWorkPlanList">
            <table className="table table-hover table-sm caption-top">
                <caption>
                    <div className="row">
                        <div className="col-md-7">

                        </div>
                        <div className="col-md-5 text-end">
                            {   data.length<=0 && selectedDepartmentId && allowChangeWorkPlan && 1==0 &&
                                <button type="button" title="Tạo kế hoạch tháng (sẽ copy các hạng mục công việc từ tháng trước để thầy cô chỉnh sửa, bổ sung...)"
                                    onClick={onCreatWorkPlanClick} className="btn btn-primary btn-sm me-2"
                                >Tạo kế hoạch tháng</button>
                            }
                            {   allowChangeWorkPlan &&
                                <button type="button" title="Thêm mới một hạng mục công việc"
                                    onClick={onAddItemClick} className="btn btn-primary btn-sm" disabled = {selectedDepartmentId?"":"disabled"}
                                >Thêm mục</button>   
                            }                         
                        </div>
                    </div>
                </caption>
                <thead>
                    <tr>
                        <th style={{"width":"15px"}}>TT</th>
                        <th style={{"width":"25%"}}>Nội dung công việc</th>
                        <th style={{"width":"15%"}}>Sản phẩm/kết quả</th>
                        <th style={{"width":"100px","whiteSpace":"nowrap"}}>Từ ngày</th>
                        <th style={{"width":"100px","whiteSpace":"nowrap"}}>Đến ngày</th>
                        <th style={{"width":"100px","whiteSpace":"nowrap"}}>Ngày HT</th>
                        <th style={{"width":"20%"}}>{selectedDepartmentId==='All'?'Tổ thực hiện': 'Người thực hiện'}</th>
                        <th style={{"width":"120px","whiteSpace":"nowrap"}}>Phụ trách</th>
                        <th style={{"width":"10%"}}>Ghi chú</th>
                        <th style={{"whiteSpace":"nowrap"}}></th>
                    </tr>
                </thead>
                <tbody className="algin-middle">
                    {
                        
                        data.map((item, index)=> {                           
                            if(item.AssignedTo.includes('All')) {
                                item.AssignedTo=[];
                                for(let i=0; i<teacherListWithAll.length; i++){
                                    item.AssignedTo.push(teacherListWithAll[i].TeacherId);
                                }
                            }
                            return <WorkPlanItem 
                                key={item.WorkingPlanId}
                                index= {index}
                                item={item}
                                selectedDepartmentId = {selectedDepartmentId}
                                departmentItems = {selectedDepartmentId==='All'?departmentItems:[]}
                                teacherList = {teacherListWithAll}
                                allowChangeWorkPlan = {allowChangeWorkPlan}
                                mode={item.Title===""?"new":""}
                                onSaveItemClick = {onSaveItemClick}
                                onDeleteItemClick = {onDeleteItemClick}
                            />
                         } )
                    }
                </tbody>
            </table>
        </form>
    )
}

export default WorkPlanList;