import React, {createContext, useState, useEffect} from 'react';
import { useMsal } from '@azure/msal-react';
import { useSchoolYearDataApi} from '../components/setting/SchoolYearData';
import { useTeacherDataApi } from '../components/teacher/TeacherData';
import { useSubjectDataApi } from '../components/subject/SubjectData';

import { getSchoolWeek, getSchoolYear, getCurrentDateInfo } from '../globalFuntions';

export const MyAppContext = createContext();

export function MyAppProvider({children}) {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    const [{schoolYearItems}, setSchoolYearAction] = useSchoolYearDataApi();
    const [{teacherItems}] = useTeacherDataApi();
    const [{subjectItems}] = useSubjectDataApi();

    const value = useState({
        CurrentUser: {
            UserName:'',
            DisplayName:'',
            TeacherId:'',
            DepartmentId:'',
            Roles:[],
        },
        SchoolYearInfo: [], //Thông tin về năm học
        SchoolYear: getSchoolYear(), //Năm bắt đầu của khóa học, vd khóa 2023-2024 thì sẽ trả về 2023
        SchoolWeek: 1,  //Tuần học hiện tại
        CurrentDateInfo: getCurrentDateInfo(),
        Teachers: teacherItems,
        Subjects: subjectItems,
    });


    useEffect(()=>{
        const[appContext, setAppContext] = value;
        const userName = activeAccount.username.toLowerCase();        
        const currentUser = appContext.CurrentUser;
        
        if (currentUser.UserName !== userName) {
            setAppContext({
                ...appContext,
                CurrentUser: {
                    ...appContext.CurrentUser,
                    DisplayName: activeAccount.name, 
                    UserName: userName,
                },
            });
        }
    },[value[0].CurrentUser.UserName])  

    useEffect(()=>{
        if(schoolYearItems.length<=0){
            setSchoolYearAction({
                type:'get',
                value: ''                
            })
        }
    },[schoolYearItems.length])

    useEffect(()=>{
        if(schoolYearItems.length>0){
            const[appContext, setAppContext] = value;
            setAppContext(
                {
                    ...appContext,
                    SchoolYearInfo: schoolYearItems,
                    SchoolWeek: getSchoolWeek(schoolYearItems[0]),
                }                
            )
        }
    },[schoolYearItems.length])

    useEffect(()=>{
        if(teacherItems.length>0){
            const[appContext, setAppContext] = value;
            const currentUserName = appContext.CurrentUser.UserName;
            const teacherObj = teacherItems.find(t=>t.UserName && t.UserName.toLowerCase() === currentUserName);
            let userRoles = teacherObj && teacherObj.Roles ? teacherObj.Roles:[]; //teacherObj ? teacherObj.Roles ? teacherObj.Roles.replace(/,/g,';').split(';'): [] : [] ;
            userRoles = userRoles.map(ur=> ur.toLowerCase());
            setAppContext(
                {
                    ...appContext,
                    CurrentUser: {
                        ...appContext.CurrentUser,
                        TeacherId: teacherObj ? teacherObj.TeacherId :'',
                        DepartmentId: teacherObj?teacherObj.DepartmentId: '',
                        Roles: userRoles
                    },
                    Teachers: teacherItems,
                }                
            )
        }
    },[teacherItems.length])

    useEffect(()=>{
        if(subjectItems.length>0){
            const[appContext, setAppContext] = value;
            setAppContext(
                {
                    ...appContext,
                    Subjects: subjectItems,
                }                
            )
        }
    },[subjectItems.length])

    return(
        <MyAppContext.Provider value={value}>
            {children}
        </MyAppContext.Provider>
    )
}