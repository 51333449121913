import React, {useState} from "react";
import dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import vi from 'date-fns/locale/vi';

import MonthlyCalendarList  from "./MonthlyCalendarList";
import {createBlankWeeklyCalendarItem, reOrderCaledarItems, useWeeklyCalendarDataApi} from './WeeklyCalendarData';
import { useEffect } from "react";


function MonthlyCalendarPage() { 
    registerLocale('vi', vi);
    dayjs.extend(isoWeek);

    const [startDate, setStartDate] = useState(new Date(dayjs().startOf("M")));
    const [endDate, setEndDate] = useState(new Date(dayjs().endOf("M")));

    const [{monthlyCalendarItems}, setAction] = useWeeklyCalendarDataApi({
        type:'fetchMonthlyCalendar', 
        value:{
            StartDate: startDate.toDateString(), 
            EndDate:   endDate.toDateString(), 
            ViewMode: 'edit'
        }
    });

    const [calendarItems, setCalendarItems] = useState(monthlyCalendarItems);


    const handleAdd = (startDate) => {       
        const d = new Date(startDate);
        const dateString = d.getFullYear() + '-' + (d.getMonth() +1) + '-' + d.getDate() + ' ' + dayjs().hour() + ':' + dayjs().minute();

        let blankItem = createBlankWeeklyCalendarItem();
        blankItem[0] = {
            ...blankItem[0],
            StartDate: dayjs(dateString).toISOString(),
            EndDate: dayjs(dateString).add(2,'hour').toISOString(),
            NewRecord: 1
        }

        setAction({
            type:'add',
            value: blankItem
        });

        let updatedCaledarItems = reOrderCaledarItems(blankItem.concat(calendarItems), dayjs(startDate).toISOString());

        setCalendarItems(updatedCaledarItems);
    }

    const handleSave = (item) => {
        setAction({
            type:'save',
            value: item
        });
    }

    const handleDelete = (item) =>{
        setAction({
            type:'del',
            value: item
        });
    }

    const handleStartDateChange = (date) => {
        setStartDate(date);
        if (dayjs(date).isAfter(dayjs(endDate))) {
            setCalendarItems([])
            return;
        }

        setAction({
            type:'fetchMonthlyCalendar', 
            value:{
                StartDate: date.toISOString(),
                EndDate:   endDate.toISOString(),
                ViewMode: 'edit'
            }            
        })
    }

    const handleEndDateChange = (date) => {
        setEndDate(date); 
        if (dayjs(date).isBefore(dayjs(endDate))) {
            setCalendarItems([])
            return;
        }

        setAction({
            type:'fetchMonthlyCalendar', 
            value:{
                StartDate: startDate.toISOString(),
                EndDate:   date.toISOString(),
                ViewMode: 'edit'
            }            
        })   
    }

    useEffect(()=> {
        if (monthlyCalendarItems) {
            setCalendarItems(monthlyCalendarItems);
        }
    },[monthlyCalendarItems])

    return (
        <div className="px-2">
            <div className="row pb-2">
                <h4 className="pt-3">Cập nhật lịch công tác toàn trường</h4>
            </div>
            <div className="row">
                <div className="col-sm-2">
                    Từ ngày 
                    <DatePicker className="form-control form-control-sm"
                        selected={startDate} 
                        locale='vi' dateFormat="dd/MM/yyyy"                        
                        onChange={(date)=>handleStartDateChange(date)}></DatePicker>
                </div>
                <div className="col-sm-2">
                    Đến ngày 
                    <DatePicker className="form-control form-control-sm"
                        selected={endDate} 
                        locale='vi' dateFormat="dd/MM/yyyy"                        
                        onChange={(date)=>handleEndDateChange(date)}></DatePicker>
                </div>
                <div className="col-sm-8 text-end pt-3">
                    <button className="btn btn-info btn-sm">
                        <i className="fa fa-download" aria-hidden="true"></i>
                    </button>
                </div>                                
            </div>
            <div className="row pt-3">
                <MonthlyCalendarList 
                    data={calendarItems}
                    onAddItemClick = {handleAdd}
                    onSaveItemClick = {handleSave}
                    onDeleteItemClick = {handleDelete}
                />
            </div>
        </div>
    )
}

export default MonthlyCalendarPage;