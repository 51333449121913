import { graphConfig, sharePointConfig } from "./authConfig";

export async function callSPGraph(accessToken){
    const headers = new Headers();
    const bear = `Bearer ${accessToken}`;
    headers.append("Authorization", bear);

    const options={
        method: "GET",
        headers: headers
    };

    const api_url = `${graphConfig.graphSPEndPoint}/${sharePointConfig.siteId}/lists/${sharePointConfig.ClassNotebookListId}/items?expand=fields(select=Id,Title)`
    return fetch(api_url, options)
        .then(response=>response.json())
        .catch(error=>console.log(error));
}

//***************************************************************** */
// SharePoint List & ListItem operation
//***************************************************************** */

export async function createSPList(accessToken, listInfo){
    const headers = new Headers();
    const bear = `Bearer ${accessToken}`;
    headers.append("Authorization",bear);
    headers.append("Content-Type","application/json");

    const options={
        method: "POST",
        headers: headers,
        body: JSON.stringify(listInfo)
    };

    const api_url = `${graphConfig.graphSPEndPoint}/${sharePointConfig.siteId}/lists`
    return fetch(api_url,options)
        .then((response) => response.json())
        .catch((error) => console.log(error));
}

export async function getSharePointListItems(accessToken, listId, selectFields, queryClause, orderClause, topValue){
    const headers = new Headers();
    const bear = `Bearer ${accessToken}`;
    headers.append("Authorization", bear);

    const options={
        method: "GET",
        headers: headers
    };

    const api_url = `${graphConfig.graphSPEndPoint}/${sharePointConfig.siteId}/lists/${listId}/items?expand=fields(select=${selectFields})&filter=${queryClause===undefined?"":queryClause}&orderBy=${orderClause===undefined?"": orderClause}&top=${topValue===undefined?10000:topValue}`
    return fetch(api_url, options)
        .then(response=>response.json())
        .catch(error=>console.log(error));
}

export async function getSharePointListItemsBatch(accessToken, requestIdArray, listIdArray, selectFieldsArray, queryClauseArray, orderClauseArray,topValueArray){
    let batchRequest = {
        requests:[]
    };
    for(let i=0; i<selectFieldsArray.length; i++) {
        const queryClause = queryClauseArray ? queryClauseArray[i]?queryClauseArray[i]:"": "";
        const orderClause = orderClauseArray ? orderClauseArray[i]?orderClauseArray[i]:"": "";
        const topValue = topValueArray ? topValueArray[i]?topValueArray[i]:10000: 10000;
        
        const singleRequest = {
            id: requestIdArray[i],
            method: "GET",
            headers: {
                "Content-Type":"application/json"
            },
            url: `${graphConfig.graphSPRelativeEndPoint}/${sharePointConfig.siteId}/lists/${listIdArray[i]}/items?expand=fields(select=${selectFieldsArray[i]})&filter=${queryClause}&orderby=${orderClause}&top=${topValue}`,
        }
        batchRequest.requests.push(singleRequest);            
    }    

    const options= {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type":"application/json"
        },
        body: JSON.stringify(batchRequest),
    };

    return fetch(`${graphConfig.graphBatchEndPoint}`, options)
        .then((response) => response.json());
        //.catch((error) => console.log(error));
}

export async function addSharePointListItem(accessToken, listId, fieldValues){
    const headers = new Headers();
    const bear = `Bearer ${accessToken}`;
    headers.append("Authorization",bear);
    headers.append("Content-Type","application/json"); 

    const options={
        method: "POST",
        headers: headers,
        body: JSON.stringify(fieldValues)
    };

    const api_url = `${graphConfig.graphSPEndPoint}/${sharePointConfig.siteId}/lists/${listId}/items`
    return fetch(api_url,options)
        .then((response) => response.json())
        .catch((error) => console.log(error));

}

export async function addSharePointListItemBatch(accessToken, listId, fieldsValuesArray) {
    let batchRequest = {
        requests:[]
    };

    //1. Chuẩn bị các request để tạo batch
    const request_url = `${graphConfig.graphSPRelativeEndPoint}/${sharePointConfig.siteId}/lists/${listId}/items`;
    for (let i=0; i<fieldsValuesArray.length; i++) {
        const singleRequest = {
            id: i,
            method: "POST",
            headers: {
                "Content-Type":"application/json"
            },
            url: request_url,
            body: fieldsValuesArray[i],
        }
        batchRequest.requests.push(singleRequest);        
    }

    //2. Thiết đặt và gửi batch
    const options= {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type":"application/json"
        },
        body: JSON.stringify(batchRequest),
    };

    return fetch(`${graphConfig.graphBatchEndPoint}`, options)
        .then((response) => response.json())
        .catch((error) => console.error(error));
}

export async function updateSharePointItem(accessToken, listId, itemId, fieldValues){
    const headers = new Headers();
    const bear = `Bearer ${accessToken}`;
    headers.append("Authorization",bear);
    headers.append("Content-Type","application/json");

    const options={
        method: "PATCH",
        headers: headers,
        body: JSON.stringify(fieldValues)
    };

    const api_url = `${graphConfig.graphSPEndPoint}/${sharePointConfig.siteId}/lists/${listId}/items/${itemId}`
    return fetch(api_url, options)
        .then((response) => response.json())
        .catch((error) => console.log(error));

}

export async function updateSharePointItemBatch(accessToken, listId, itemIdArray, fieldValuesArray){
    let batchRequest = {
        requests:[]
    };

    //1. Chuẩn bị các request để tạo batch
    const request_url = `${graphConfig.graphSPRelativeEndPoint}/${sharePointConfig.siteId}/lists/${listId}/items`;
    for (let i=0; i<fieldValuesArray.length; i++) {
        const singleRequest = {
            id: i,
            method: "PATCH",
            headers: {
                "Content-Type":"application/json"
            },
            url: `${request_url}/${itemIdArray[i]}`,
            body: fieldValuesArray[i],
        }
        batchRequest.requests.push(singleRequest);        
    }
    
    //2. Thiết đặt và gửi batch
    const options= {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type":"application/json"
        },
        body: JSON.stringify(batchRequest),
    };

    return fetch(`${graphConfig.graphBatchEndPoint}`, options)
        .then((response) => response.json())
        .catch((error) => console.error(error));
}

export async function deleteSharePointItem(accessToken, listId, itemId){
    const headers = new Headers();
    const bear = `Bearer ${accessToken}`;
    headers.append("Authorization",bear);
    headers.append("Content-Type","application/json");

    const options={
        method: "DELETE",
        headers: headers,
    };

    const api_url = `${graphConfig.graphSPEndPoint}/${sharePointConfig.siteId}/lists/${listId}/items/${itemId}`
    return fetch(api_url, options)
        .then((response) => response)
        .catch((error) => console.log(error));

}

export async function deleteSharePointItems(accessToken, listId, itemIdArray){
    const headers = new Headers();
    const bear = `Bearer ${accessToken}`;
    headers.append("Authorization",bear);
    headers.append("Content-Type","application/json");

    const options={
        method: "DELETE",
        headers: headers,
    };

    for(let i=0; i<itemIdArray.length; i++){
        const api_url = `${graphConfig.graphSPEndPoint}/${sharePointConfig.siteId}/lists/${listId}/items/${itemIdArray[i]}`
        fetch(api_url, options)
    }
}

export async function deleteSharePointItemBatch(accessToken, listId, itemIdArray){
    let batchRequest = {
        requests:[]
    };

    //1. Chuẩn bị các request để tạo batch
    const request_url = `${graphConfig.graphSPRelativeEndPoint}/${sharePointConfig.siteId}/lists/${listId}/items`;
    for (let i=0; i<itemIdArray.length; i++) {
        const singleRequest = {
            id: i,
            method: "DELETE",
            headers: {
                "Content-Type":"application/json"
            },
            url: `${request_url}/${itemIdArray[i]}`,
        }
        batchRequest.requests.push(singleRequest); 
    }

    //console.log('Batch Delete ', batchRequest);
    
    //2. Thiết đặt và gửi batch
    const options= {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type":"application/json"
        },
        body: JSON.stringify(batchRequest),
    };

    return fetch(`${graphConfig.graphBatchEndPoint}`, options)
        .then((response) => response.json())
        .catch((error) => console.error(error));
}

/**
 * Thêm mới, sửa, và xóa các items trong cùng một SharePoint List
 * @param {} accessToken 
 * @param {*} listId : SharePoint List Id đích
 * @param {*} newItemFieldsValuesArray : Mảng chứa giá trị các trường của các mục cần thêm
 * @param {*} existingItemFieldsValuesArray : Mảng chứa các trường của các mục cần sửa
 * @param {*} updateItemIdArray: Mảng chứa các item id cần update
 * @param {*} deleteItemIdArray: Mảng chứa các item id cần xóa
 */
export async function batchSharePointItemProcessing(accessToken, listId, newItemFieldsValuesArray, 
    existingItemFieldsValuesArray, updateItemIdArray, 
    deleteItemIdArray = []) {

    let batchRequest = {
        requests:[]
    };

    //1. Chuẩn bị các request để tạo batch
    const request_url = `${graphConfig.graphSPRelativeEndPoint}/${sharePointConfig.siteId}/lists/${listId}/items`;    
    for (let i=0; i<newItemFieldsValuesArray.length; i++) {
        const singleRequest = {
            id: i+1,
            method: "POST",
            headers: {
                "Content-Type":"application/json"
            },
            url: request_url,
            body: newItemFieldsValuesArray[i],
        }
        batchRequest.requests.push(singleRequest);        
    }

    let count = newItemFieldsValuesArray.length;

    for (let i=0; i<existingItemFieldsValuesArray.length; i++) {
        const singleRequest = {
            id: i + count + 1,
            method: "PATCH",
            headers: {
                "Content-Type":"application/json"
            },
            url: `${request_url}/${updateItemIdArray[i]}`,
            body: existingItemFieldsValuesArray[i],
        }
        batchRequest.requests.push(singleRequest);        
    }

    count = count + existingItemFieldsValuesArray.length;
    for (let i=0; i<deleteItemIdArray.length; i++) {
        const singleRequest = {
            id: i + count + 1,
            method: "DELETE",
            headers: {
                "Content-Type":"application/json"
            },
            url: `${request_url}/${deleteItemIdArray[i]}`,
        }
        batchRequest.requests.push(singleRequest);   
    }

    //2. Thiết đặt và gửi batch
    const options= {
        method: "POST",
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type":"application/json"
        },
        body: JSON.stringify(batchRequest),
    };

    return fetch(`${graphConfig.graphBatchEndPoint}`, options)
        .then((response) => response.json())
        .catch((error) => console.error(error));
}



//***************************************************************** */
// SharePoint Document Library function
//***************************************************************** */

export async function GetSharePointSiteDrive(accessToken) {
    const headers = new Headers();
    const bear = `Bearer ${accessToken}`;
    headers.append("Authorization", bear);

    const options = {
        method: "GET",
        headers: headers
    };

    const api_url = `${graphConfig.graphSPEndPoint}/${sharePointConfig.siteId}/drive`
    return fetch(api_url, options)
        .then(response=>response.json())
        .catch(error=>console.log(error));
}

export async function GetSharePointSiteDriveChildren(accessToken, sourceFolder) {
    const headers = new Headers();
    const bear = `Bearer ${accessToken}`;
    headers.append("Authorization", bear);

    const options = {
        method: "GET",
        headers: headers
    };

    const api_url = `${graphConfig.graphSPEndPoint}/${sharePointConfig.siteId}/drives/${sharePointConfig.SiteDriveId}/root:/${sourceFolder}:/children`
    return fetch(api_url, options)
        .then(response=>response.json())
        .catch(error=>console.log(error));

}

//  =============================================================================================
//  UploadFile
//  Upload 1 file Document Library mặc định (Drive mặc định) của Site
//  - fileToUpload: file cần upload dạng stream
//  - fieldUpdateValues: thư mục chứa file cần upload
//  =============================================================================================
export async function UploadFile(accessToken, fileToUpload, destinationFolder){
    const headers = new Headers();
    const bear = `Bearer ${accessToken}`;
    headers.append("Authorization", bear);
    const options = {
        method: "PUT",
        headers: headers,
        body: fileToUpload,
    };

    // const api_url = `${graphConfig.graphSPEndPoint}/${sharePointConfig.siteId}/drive/root:/${fileToUpload.name}:/content`
    // const api_url = `${graphConfig.graphSPEndPoint}/${sharePointConfig.siteId}/drive/items/${sharePointConfig.SiteDriveId}:/${fileToUpload.name}:/content`
    const api_url = `${graphConfig.graphSPEndPoint}/${sharePointConfig.siteId}/drive/root:/${destinationFolder}/${fileToUpload.name}:/content`

    return fetch(api_url, options)
        .then(response=>response.json())
        .catch(error=>{
            console.log("ERROR UPLOADING");
            console.log(error)
        });
}

//  =============================================================================================
//  UpdateFileMeta
//  Cập nhật các trường của 1 file trong Drive/Document Library khi biết driveItemId
//  - driveItemId: id của drive item cần update
//  - fieldUpdateValues: là giá trị các trường cần update
//  =============================================================================================
export async function UpdateFileMeta(accessToken, driveItemId, fieldUpdateValues){
    const headers = new Headers();
    const bear = `Bearer ${accessToken}`;
    headers.append("Authorization", bear);
    headers.append("Content-Type","application/json");

    const options = {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify(fieldUpdateValues),
    };

    const api_url = `${graphConfig.graphSPEndPoint}/${sharePointConfig.siteId}/drive/items/${driveItemId}/ListItem`

    console.log('update field request url', api_url);
    console.log('fieldUpdateValues', fieldUpdateValues);
    console.log('options', options);

    return fetch(api_url, options)
        .then(response=>response.json())
        .catch(error=>{
            console.log("ERROR UPLOADING");
            console.log(error)
        });
}