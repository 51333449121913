import React, {useState} from "react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import vi from 'date-fns/locale/vi';

import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import SchoolYearWeek from "./SchoolYearWeek";
import { getSchoolWeeks } from "../../globalFuntions";

function SchoolYearItem(props){ 
    registerLocale('vi', vi);
    dayjs.extend(isoWeek);
    
    const [item, setItem] = useState(props.item);
    const [beforeEditItem, setBeforeEditItem] = useState({...props.item});

    const [action, setAction] = useState(props.action);

    const handleEdit = () =>{
        setBeforeEditItem({...item});
        setAction('edit');
    }

    const handleSave = () => {
        const sem1_StartIsoWeek = dayjs(item.Sem1_StartDay).isoWeek();
        const sem2_StartIsoWeek = dayjs(item.Sem2_StartDay).isoWeek();

        let updateItem = {};

        //Generate các tuần học cho các học kỳ nếu chưa được khởi tạo mặc định
        if(item.Note===null || item.Note==="") {
            const schoolYearWeeks = {
                "sem1": getSchoolWeeks(item.Sem1_StartDay, item.Sem1_TotalWeeks,1),
                "sem2": getSchoolWeeks(item.Sem2_StartDay, item.Sem2_TotalWeeks,item.Sem1_TotalWeeks+1)
            }  
            updateItem = {
                ...item,
                Title: item.StartYear + '-' + item.EndYear,
                Sem1_StartIsoWeek: sem1_StartIsoWeek*1,
                Sem2_StartIsoWeek: sem2_StartIsoWeek*1,
                Note: schoolYearWeeks
            } 
        } else {
            updateItem = {
                ...item,
                Title: item.StartYear + '-' + item.EndYear,
                Sem1_StartIsoWeek: sem1_StartIsoWeek*1,
                Sem2_StartIsoWeek: sem2_StartIsoWeek*1
            }
        }

        setItem(updateItem);
        props.onSaveItemClick(updateItem);
        setAction('');
    }

    const handleCancel = () =>{
        if(action==='new'){
            handleDelete(item.SettingId);
        }else{ //edit action nên hủy các sửa đổi, khôi phục về giá trị trước khi sửa
            setItem(beforeEditItem);
        }
        setAction('');
    }

    const handleDelete = ()=>
    {
        if (action!=='edit' && action !=='new') {  
            if ( window.confirm(`Bạn chắc chắn xóa mục năm học ${item.Title} không?`)) {
                props.onDeleteItemClick(item); 
            }            
        } else {
            props.onDeleteItemClick(item); 
        }  
    }

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.type==='checkbox'?target.checked:target.value;
        setItem({
            ...item,
            [name]:value
        });
    }

    const renderWeeks = (weeks) => {
        let htmlContent = "<ul class='ps-3'>";
        weeks.forEach((item) => {
            const zero = item.week < 10 ? "0":"";
            htmlContent += `<li>Tuần ${zero}${item.week}:<span class="ms-2">${dayjs(item.start).format('DD/MM/YYYY')} - ${dayjs(item.end).format("DD/MM/YYYY")}</span>}</li>`;
        })
        return htmlContent+="</ul>";
    }

    const rowElement = (action==='edit' || action==='new')
        ? <tr>
            {/* <td>
                <input type='text' name='Title' style={{'width':'100%'}} className="form-control form-control-sm"
                    value={item.Title} onChange={(e)=>handleChange(e)} />
            </td> */}
            <td>
                <input type='number' name='StartYear' min="2021" style={{'width':'100%'}} className="form-control form-control-sm"
                    value={item.StartYear} onChange={(e)=>handleChange(e)} />
            </td>
            <td>
                <input type='number' name='EndYear' min={item.StartYear*1+1} style={{'width':'100%'}} className="form-control form-control-sm"
                    value={item.EndYear} onChange={(e)=>handleChange(e)} />
            </td>
            <td>
                <DatePicker name="Sem1_StartDate" className="form-control form-control-sm"
                    locale='vi' dateFormat="dd/MM/yyyy" 
                    selected={item.Sem1_StartDay}                       
                    onChange={(d)=>{setItem({
                        ...item,
                        Sem1_StartDay: d})}
                    }>
                </DatePicker>
            </td>
            <td>
                <input type='number' name='Sem1_TotalWeeks' style={{'width':'100%'}} className="form-control form-control-sm"
                    value={item.Sem1_TotalWeeks} onChange={(e)=>handleChange(e)} />
            </td>
            <td>
                <DatePicker name="Sem2_StartDate" className="form-control form-control-sm"
                    locale='vi' dateFormat="dd/MM/yyyy"   
                    selected={new Date(item.Sem2_StartDay)}                         
                    onChange={(d)=>{setItem({
                        ...item,
                        Sem2_StartDay: d})}
                    }>                    
                </DatePicker>
            </td>
            <td>
                <input type='number' name='Sem2_TotalWeeks' style={{'width':'100%'}} className="form-control form-control-sm"
                    value={item.Sem2_TotalWeeks} onChange={(e)=>handleChange(e)} />
            </td>
            <td style={{"backgroundColor":"orange"}}>
                {/* <textarea name='Note' rows='24' style={{'width':'100%'}} className="form-control form-control-sm"
                    value={item.Note} 
                    onChange={(e)=>handleChange(e)} /> */}
                {
                    item.Note ? <SchoolYearWeek weeks = {item.Note.sem1}/> : ""
                }                
            </td>
            <td style={{"backgroundColor":"silver"}}> 
                {/* <textarea name='Note' rows='24' style={{'width':'100%'}} className="form-control form-control-sm"
                    value={item.Note} 
                    onChange={(e)=>handleChange(e)} /> */}
                {
                    item.Note ? <SchoolYearWeek weeks = {item.Note.sem2}/> : ""
                }
            </td>            
            <td className="text-end" style={{"whiteSpace":"nowrap"}}>
                <button type="button" onClick={handleSave} className="btn btn-primary btn-sm"><i className="fa fa-floppy-o"></i></button>&nbsp;
                <button type="button" onClick={handleCancel} className="btn btn-secondary btn-sm"><i className="fa fa-ban"></i></button>                
            </td>            
        </tr>
        :<tr>
            {/* <td className="text-center">{item.Title}</td> */}
            <td className="text-center">{item.StartYear}</td>
            <td className="text-center">{item.EndYear}</td>
            <td className="text-center">{dayjs(item.Sem1_StartDay).format('DD/MM/YYYY')}</td>
            <td className="text-center">{item.Sem1_TotalWeeks}</td>
            <td className="text-center">{dayjs(item.Sem2_StartDay).format('DD/MM/YYYY')}</td>
            <td className="text-center">{item.Sem2_TotalWeeks}</td>
            <td>
                <div dangerouslySetInnerHTML={{ __html: item.Note?renderWeeks(item.Note.sem1): "" }}></div>
                {/* https://www.kindacode.com/article/ways-to-render-html-content-in-react-and-jsx/#google_vignette */}
            </td>
            <td>
            <div dangerouslySetInnerHTML={{ __html: item.Note?renderWeeks(item.Note.sem2): "" }}></div>
            </td>
            <td className="text-end" style={{"whiteSpace":"nowrap"}}>
                <button type="button" onClick={handleEdit} className="btn btn-warning btn-sm"><i className="fa fa-pencil"></i></button>&nbsp;
                <button type="button" onClick={handleDelete} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i></button>                
            </td>
        </tr>;

    return(
        <>
            {rowElement}
        </>
    )
}

export default SchoolYearItem;