import React from "react";
import SubjectItem from "./SubjectItem";

function SubjectList({data, onAddItemClick, onSaveItemClick, onDeleteItemClick}){
    return(        
        <form className="form-inline" name="frmSubjects">       
            <table className="table table-hover table-sm caption-top">
                <caption>
                    Danh sách môn học - Tổng số: {data.length} môn
                </caption>
                <thead>
                    <tr>
                        <th style={{width:'10%'}}>Tên môn học</th>
                        <th style={{width:'10%'}}>Tên xếp TKB</th>
                        <th style={{width:'8%'}}>Nhóm</th>
                        <th style={{width:'8%'}}>Đang sử dụng</th>
                        <th style={{width:'8%'}} className='text-end'>
                            <button type="button" 
                                onClick={onAddItemClick} className="btn btn-primary btn-sm">&nbsp;&nbsp;Thêm&nbsp;
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody className="align-middle">
                    { 
                        data.map((item) => 
                            <SubjectItem 
                                key={item.id}
                                item = {item} 
                                subjectItems = {data}
                                action = {item.Title === ''? 'new' : ''} 
                                onSaveItemClick = {onSaveItemClick}
                                onDeleteItemClick={onDeleteItemClick}                                
                            />
                        )
                    }
                </tbody>
            </table>
        </form>
    )
}

export default SubjectList;