import React, {useState} from "react";
import Multiselect from "multiselect-react-dropdown";

function TeacherItem(props){ 
    const [item, setItem] = useState(props.item);
    const {subjectList, departmentList, titleList, teacherList, roleList} = props;
    const [beforeEditItem, setBeforeEditItem] = useState({...props.item});

    const [action, setAction] = useState(props.action);

    const handleEdit = () =>{
        setBeforeEditItem({...item});
        setAction('edit');
    }

    const handleSave = () => {
        props.onSaveItemClick(item);
        setAction('');
    }

    const handleCancel = () =>{
        if(action==='new'){
            handleDelete(item.TeacherId);
        }else{ //edit action nên hủy các sửa đổi, khôi phục về giá trị trước khi sửa
            setItem(beforeEditItem);
        }
        setAction('');
    }

    const handleDelete = () =>
    {
        if (action!=='edit' && action !=='new') {  
            if ( window.confirm(`Bạn chắc chắn xóa giáo viên ${item.FullName} không?`) ) {
                props.onDeleteItemClick(item); 
            }            
        } else {
            props.onDeleteItemClick(item); 
        }        
    }

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.type==='checkbox'?target.checked:target.value;

        if(name==="UserEmail") { //Kiểm tra xem có bị nhập trùng email không
            if(teacherList.findIndex(t=>t.UserEmail===value && t.TeacherId !== item.TeacherId) >= 0) {
                alert(`Địa chỉ email ${value} này đã có. Kiểm tra và nhập lại`);
                return;
            }
        }

        if(name==="Title") { //Kiểm tra xem nhập trùng tên dùng để xếp thời khóa biểu (Title)
            if(teacherList.findIndex(t=>t.Title===value && t.TeacherId !== item.TeacherId) >= 0) {
                alert(`Tên xếp TKB ${value} này đã có. Kiểm tra và nhập lại`);
                return;
            }
        }

        setItem({
            ...item,
            [name]:value
        });
    }

    const handleSubjectSelectChange = (selectedList, selectedValues) => {
        let selectedSubjectIdsArray = [];
        for (let i=0; i<selectedList.length; i++) {
            selectedSubjectIdsArray.push(selectedList[i].SubjectId);
        }
        setItem({
            ...item,
            SubjectId: selectedSubjectIdsArray
        })
    }

    const handleRoleSelectChange = (selectedList, selectedValues) => {
        let selectedRoleIdsArray = [];
        for (let i=0; i<selectedList.length; i++) {
            selectedRoleIdsArray.push(selectedList[i].Name);
        }
        setItem({
            ...item,
            Roles: selectedRoleIdsArray
        })
    }

    const handleKeyUp = (event, nextElementId) => {
        if (event.keyCode===13){
            const nextElm = document.getElementById(nextElementId);
            if (nextElm) nextElm.focus();
        }
    }

    const getDepartmentTitle = (departmentId) => {
        const idx = departmentList.findIndex(item=>item.DepartmentId === departmentId)
        const title = idx < 0 ? "": departmentList[idx].Title;
        return title;
    }

    const getSubjectTitle = (selectedSubjectIdArray) => {
        let subjectTitles ="";
        for (let i=0; i<selectedSubjectIdArray.length; i++) {
            const idx = subjectList.findIndex(item=>item.SubjectId === selectedSubjectIdArray[i]);
            if (idx>=0) subjectTitles = subjectTitles +  subjectList[idx].Title + ", ";           
        }   
        const len = subjectTitles.length; 
        return len > 0 ? subjectTitles.substring(0,len-2): "" ;
    }

    const getRoleDisplayNames = (roleNameArray) => {
        let roleDisplayNames = "";
        let idx;
        for (let i=0; i< roleNameArray.length; i++){
            idx = roleList.findIndex(item=> item.Name===roleNameArray[i]);
            if(idx>-1) roleDisplayNames = roleDisplayNames + roleList[idx].DisplayName + ", ";
            const len = roleDisplayNames.length;
            return len>0 ? roleDisplayNames.substring(0,len-2) : "";
        }
    }

    const getSubjectTitlePreSelectedValues = (selectedSubjectIdArray) => {
        const selectedValues = [];
        for (let i=0; i<selectedSubjectIdArray.length; i++) {
            const idx = subjectList.findIndex(item=>item.SubjectId === selectedSubjectIdArray[i]);
            if (idx >=0) selectedValues.push(subjectList[idx]);            
        }
        return selectedValues;
    }

    const getRoleDisplayNamePreSelectedValues = (selectedRoleIdArray) => {
        const selectedValues = [];
        for (let i=0; i<selectedRoleIdArray.length; i++) {
            const idx = roleList.findIndex(item=>item.Name === selectedRoleIdArray[i]);
            if (idx >=0) selectedValues.push(roleList[idx]);            
        }
        return selectedValues;
    }    

    const getTitleTitle = (titleId) => {
        const idx = titleList.findIndex(item=>item.TitleId === titleId)
        const title = idx < 0 ? "": titleList[idx].Title;
        return title;
    }

    const rowElement = (action==='edit' || action==='new')
        ? <tr>
            <td>
                <input type='text' name='FullName' style={{'width':'100%'}} 
                    className="form-control form-control-sm" autoFocus
                    value={item.FullName} 
                    onKeyUp = {(event)=>handleKeyUp(event, "Title")}
                    onChange={(e)=>handleChange(e)} />
            </td>
            <td>
                <input type='text' name='Title' id='Title' style={{'width':'100%'}} 
                    className="form-control form-control-sm" 
                    value={item.Title} 
                    onKeyUp = {(event)=>handleKeyUp(event, "DepartmentId")}
                    onChange={(e)=>handleChange(e)} />
            </td>
            <td>
                <select name="DepartmentId" id="DepartmentId"  className="form-select form-select-sm"
                    value={item.DepartmentId} onChange={(e) => handleChange(e)}>
                    <option value='0'></option>
                    {                        
                        departmentList.map((d) => 
                            <option key={d.DepartmentId} value={d.DepartmentId}>{d.Title}</option>
                        )
                    }
                </select>
            </td> 
            <td>
                <Multiselect options={subjectList} className="form-control form-control-sm"
                    selectedValues={getSubjectTitlePreSelectedValues(item.SubjectId)}
                    onSelect = {handleSubjectSelectChange} 
                    onRemove = {handleSubjectSelectChange} 
                    showCheckbox = {true}
                    placeholder="Chọn môn học"
                    displayValue="Title"/>
            </td>
            {/* <td>
                <select name="TitleId" className="form-select form-select-sm"
                    value={item.TitleId} onChange={(e) => handleChange(e)}>
                    <option value='0'></option>
                    {                        
                        titleList.map((t) => 
                            <option key={t.TitleId} value={t.TitleId}>{t.Title}</option>
                        )
                    }
                </select>
            </td> */}
            <td>
                <input type='text' name='PhoneNumber' id='PhoneNumber' style={{'width':'100%'}} className="form-control form-control-sm"
                    value={item.PhoneNumber}
                    onKeyUp = {(event)=>handleKeyUp(event, "UserEmail")}
                    onChange={(e)=>handleChange(e)} />
            </td>
            <td>
                <input type='email' name='UserEmail' id='UserEmail' style={{'width':'100%'}} className="form-control form-control-sm"
                    value={item.UserEmail}
                    onKeyUp = {(event) => handleKeyUp(event, "Roles")}
                    onChange={(e)=>handleChange(e)} />
            </td>
            {/* <td>
                <input type='text' name='UserName' id='UserName' style={{'width':'100%'}} className="form-control form-control-sm"
                    value={item.UserName}
                    onKeyUp = {(event) => handleKeyUp(event, "Address")}
                    onChange={(e)=>handleChange(e)} />
            </td> */}
            <td>
                {/* <input type='text' name='Roles' id='Roles' style={{'width':'100%'}} className="form-control form-control-sm"
                    value={item.Roles}
                    onKeyUp = {(event) => handleKeyUp(event, "IsActive")}
                    onChange={(e)=>handleChange(e)} /> */}

                <Multiselect options={roleList} className="form-control form-control-sm"
                    selectedValues={getRoleDisplayNamePreSelectedValues(item.Roles)}
                    onSelect = {handleRoleSelectChange} 
                    onRemove = {handleRoleSelectChange} 
                    showCheckbox = {true}
                    placeholder="Chọn vai trò"
                    displayValue="DisplayName"/>                
            </td>
            {/* <td>
                <input type='text' name='Address' id='Address'  style={{'width':'100%'}} className="form-control form-control-sm"
                    value={item.Address}
                    onKeyUp = {(event) => handleKeyUp(event, "IsActive")}
                    onChange={(e)=>handleChange(e)} />
            </td> */}
            <td>
                <select name="IsActive" id="IsActive" className="form-select form-select-sm"
                    value={item.IsActive} 
                    onChange={(e)=>handleChange(e)}
                >
                    <option value={true}>Có</option>
                    <option value={false}>Không</option>
                </select>
            </td>
            <td className="text-end">
                <button type="button" onClick={handleSave} id="btnSaveItem" className="btn btn-primary btn-sm"><i className="fa fa-floppy-o"></i></button>&nbsp;
                <button type="button" onClick={handleCancel} className="btn btn-secondary btn-sm"><i className="fa fa-ban"></i></button>                
            </td>            
        </tr>
        :<tr>
            <td>{item.FullName}</td>
            <td>{item.Title}</td>
            <td>{getDepartmentTitle(item.DepartmentId)}</td>
            <td>{getSubjectTitle(item.SubjectId)}</td>
            {/* <td>{getTitleTitle(item.TitleId)}</td> */}
            <td>{item.PhoneNumber}</td>
            <td>{item.UserEmail}</td>
            {/* <td>{item.UserName}</td> */}
            <td>
                {getRoleDisplayNames(item.Roles)}
            </td>
            {/* <td>{item.Address}</td> */}
            <td>{item.IsActive==='true' || item.IsActive===true?'Có':'Không'}</td>
            <td className="text-end">
                <button type="button" onClick={handleEdit} className="btn btn-warning btn-sm"><i className="fa fa-pencil"></i></button> &nbsp;
                <button type="button" onClick={ handleDelete } className="btn btn-danger btn-sm"><i className="fa fa-trash"></i></button>            
            </td>
        </tr>;

    return(
        <>
            {rowElement}
        </>
    )
}

export default TeacherItem;