import React, {useState} from 'react';
import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import * as isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear'; 
import * as isLeapYear from 'dayjs/plugin/isLeapYear';

const WeekPicker = (props) => {
    dayjs.extend(isoWeek);
    dayjs.extend(isoWeeksInYear);    
    dayjs.extend(isLeapYear);  

    const [selectedIsoWeekStartDay, setSelectedIsoWeekStartDay] = useState(dayjs().isoWeekday(1).format('YYYY-MM-DD 00:00:00')); 

    const iso_weeks = (start,end) => Array(end -start).fill().map((_,idx)=>start+idx); 

    const weeksInYear1 = dayjs().year(props.selectedYear).isoWeeksInYear();

    const currentYear = new Date().getFullYear();

    return(
        <select className='from-select form-select-sm'
            value={selectedIsoWeekStartDay}
            onChange = {e=>{
                            setSelectedIsoWeekStartDay(e.target.value);
                            props.onDateChange(e.target.value);
                        }}
        >
            {
                iso_weeks(26, weeksInYear1+1).map((w)=> 
                    <option key={w} 
                        value= {dayjs().year(props.selectedYear).isoWeek(w).isoWeekday(1).format('YYYY-MM-DD 00:00:00')}>
                        Tuần từ {dayjs().year(props.selectedYear).isoWeek(w).isoWeekday(1).format("DD/MM/YYYY")} - {dayjs().year(props.selectedYear).isoWeek(w).isoWeekday(7).format("DD/MM/YYYY")}
                    </option>
                )
            }
            {
                currentYear > props.selectedYear &&  // end year
                iso_weeks(1, 26).map((w)=> 
                    <option key={w} 
                        value= {dayjs().year(currentYear).isoWeek(w).isoWeekday(1).format('YYYY-MM-DD 00:00:00')}>
                        Tuần từ {dayjs().year(currentYear).isoWeek(w).isoWeekday(1).format("DD/MM/YYYY")} - {dayjs().year(currentYear).isoWeek(w).isoWeekday(7).format("DD/MM/YYYY")}
                    </option>
                )
            }
        </select>
    )


}

export default WeekPicker;