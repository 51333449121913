import React, {useState} from "react";

function SubjectPlanDetailItem(props){ 
    const [item, setItem] = useState(props.item);
    const [beforeEditItem, setBeforeEditItem] = useState({...props.item});

    const [action, setAction] = useState(props.action);

    const handleEdit = () => {
        setBeforeEditItem({...item});
        setAction('edit');
    }

    const handleSave = () => {
        if(item.Title!=="" && item.Week >0 && item.Period!=="") {
            props.onSaveItemClick(item);
            setAction('');
        } else {
            alert("Chưa nhập đủ giá trị trong các mục: Tuần, tiết, bài giảng. Kiểm tra và nhập lại");
        }
    }

    const handleCancel = () => {
        if(action==='new'){
            handleDelete("cancel-newitem");
        } else { //edit action nên hủy các sửa đổi, khôi phục về giá trị trước khi sửa
            setItem(beforeEditItem);
        }
        setAction('');
    }

    const handleDelete = (action) => {
        if(window.confirm(`Bạn có chắc chắn muốn xóa mục ${item.Title} không?\nNếu muốn xóa, bấm OK`)){
            props.onDeleteItemClick(item, action);    
        }
    }

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.type==='checkbox'?target.checked:target.value;
        setItem({
            ...item,
            [name]:value
        });
    }
    
    const rowElement = (action==='edit' || action==='new') ?
        <tr>
            <td>
                <input type='number' min='1' name='Week' className="form-control form-control-sm"
                    value={item.Week} onChange={e=>handleChange(e)} />
            </td>
            <td>
                <input type='text'  name='Period' className="form-control form-control-sm"
                    value={item.Period} onChange={e=>handleChange(e)} />
            </td>
            <td>
                <textarea  name='Title' style={{width:'100%'}} 
                    rows="2"
                    value={item.Title} onChange={e=>handleChange(e)} />                
            </td>
            {/* <td>
                <textarea  name='AdjustContent' style={{width:'100%'}} 
                    rows="4"
                    value={item.AdjustContent} onChange={e=>handleChange(e)} />                 
            </td> */}
            <td>
                <textarea  name='GuideLine' style={{width:'100%'}} rows="2"
                    value={item.GuideLine} onChange={e=>handleChange(e)} />   
            </td>
            <td>
                <input type='text' name='Category' className="form-control form-control-sm"
                    value={item.Category} onChange={e=>handleChange(e)} />
            </td>             
            {/* <td>
                <input type='checkbox' checked={item.IsActive} onChange={e=>handleChange(e)}  />
            </td> */}
            <td className="text-center">
                <button type="button" onClick={handleSave} className="btn btn-primary btn-sm"><i className="fa fa-floppy-o"></i></button>&nbsp;
                <button type="button" onClick={handleCancel} className="btn btn-secondary btn-sm"><i className="fa fa-ban"></i></button>                
            </td>             
        </tr>
        :
        <tr>
            <td className="text-center">{item.Week}</td>
            <td className="text-center"> {item.Period}</td>
            <td className={item.IsActive===true?"":"text-danger"}>{item.Title}</td>
            {/* <td>{item.AdjustContent}</td> */}
            <td>{item.GuideLine}</td>
            <td className="text-center">{item.Category}</td>            
            {/* <td>{item.IsActive}</td> */}
            <td className="text-center">
                <button type="button" onClick={handleEdit} className="btn btn-warning btn-sm"><i className="fa fa-pencil"></i></button>&nbsp;
                <button type="button" onClick={handleDelete} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i></button>
            </td>
        </tr>

    return(
        <>
            {rowElement}
        </>
        
    )
}

export default SubjectPlanDetailItem;