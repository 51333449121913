import  {useEffect, useState} from 'react';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { v4 as uuidv4 } from 'uuid';
import {orderBy as _orderBy, remove} from 'lodash';

import { loginRequest, sharePointConfig } from '../../authConfig';
import {
    getSharePointListItems,
    getSharePointListItemsBatch,
    updateSharePointItem,
    addSharePointListItem,
    addSharePointListItemBatch,
    deleteSharePointItem
} from '../../graph';

import {getSchoolYear} from '../../globalFuntions';
import { schoolConfig } from '../../appConfig';

import {ClassList} from '../../mock/MockData';


export const createBlankClassItem = () =>{
    return [
        {
            id:0,
            ClassId: uuidv4(),
            Title:'',
            StartYear:'',
            EndYear:'',
            Students:'',            
            Grade:'',
            HeadTeacher:'',
            Note:'',
            IsActive: true
        }
    ]
}

export const useClassDataApiMock = () => {
    const [classItems, setClassItems] = useState(ClassList);
    const [action, setAction] = useState({type:'', value:''});
 
    useEffect(() => { 
        const fetchData = async () => {              
            setClassItems(ClassList);           
        };

        const addBlankItem = async (item) => {
            setClassItems(item.concat(classItems));
        };

        const submitData = async (item) => {
            console.log('Submit data');
        };

        const deleteItem = async (item) => {
            let items = Array.from(classItems);
            remove(items, i=>i.ClassId===item.ClassId);        
            setClassItems(items); 
        }    

        const processData =() => {
            switch(action.type){
                case 'add':
                    addBlankItem(action.value);
                    break;
                case 'save':
                    submitData(action.value);
                    break;
                case 'del':
                    deleteItem(action.value);
                    break;
                default:
                    fetchData();
            }
        }

        processData();

    },[action]);

    return [{classItems},  setAction];
}

export const useClassDataApi = (startYear=0) => {
    const [classItems, setClassItems] = useState([]);
    const [action, setAction] = useState({type:'', value:startYear});
    const [flagReloadAfterAddNew, setFlagReloadAfterAddNew] = useState(false);
    const [classApiMessage, setClassApiMessage] = useState("");

    const [activeSchoolYear,] = useState(getSchoolYear());
    const {instance, accounts} = useMsal();

    const fetchData = (accessToken, startYear) =>{
        getSharePointListItems(
            accessToken, 
            sharePointConfig.ClassesListId,
            "ClassId,Title,Grade,StartYear,EndYear,Students,HeadTeacher,Note,IsActive,id",
            startYear ===0 ? `(fields/StartYear eq ${activeSchoolYear})` : `(fields/StartYear eq ${startYear})`
        ).then(response => {  
            let items = [];
            const listItemArray = response.value;
            for(let i=0; i<listItemArray.length; i++){
                items.push(listItemArray[i].fields);
            }
            items =_orderBy(items,['Title'], ['ASC']);
            setClassItems(items);
        })
    }

    const addBlankItem = (item) => {
        setClassItems(item.concat(classItems));
    };

    const saveItem = (accessToken, item) => {
        setFlagReloadAfterAddNew(false);

        let fieldValues = {};
        if(item.id>0){ //Nếu đã có thì cập nhật nội dung 
            fieldValues = {
                fields: {
                    Title: item.Title,
                    Grade: item.Grade,
                    StartYear: item.StartYear,
                    EndYear: item.StartYear*1+1,
                    Students: item.Students,
                    HeadTeacher: item.HeadTeacher,
                    Note: item.Note,
                    IsActive: item.IsActive==='true' || item.IsActive===true ? true:false
                }
            };  
            updateSharePointItem(accessToken, sharePointConfig.ClassesListId, item.id, fieldValues)
                .then(response => {
                    //console.log(response)
                });
        }
        else{ //Nếu chưa có thì thêm vào 
            fieldValues = {
                fields: {
                    ClassId: item.ClassId,
                    Title: item.Title,
                    Grade: item.Grade,
                    StartYear: item.StartYear,
                    EndYear: item.EndYear,
                    Students: item.Students,
                    HeadTeacher: item.HeadTeacher,
                    Note: item.Note,
                    IsActive: item.IsActive==='true' || item.IsActive===true ? true:false
                }
            };
            addSharePointListItem(accessToken, sharePointConfig.ClassesListId, fieldValues)
            .then(response=>{
                if(response.id) {
                    setFlagReloadAfterAddNew(true);
                } else {
                    console.log('Có lỗi khi thêm mới môn học (response', response);
                }
            });
        }
    };

    const generateClassesForSchoolYear = (accessToken, schoolYear) => {
        console.log('generateClassesForSchoolYear', schoolYear);
        const schoolYearString = `${schoolYear}-${schoolYear+1}`;
        setClassApiMessage(`Bắt đầu tiến hành khởi tạo danh sách lớp học năm ${schoolYearString}`);
        //1 Lấy thông tin các lớp học của năm trước đó và năm cần lấy schoolYear (thường là năm học mới)
        let spListIds = [sharePointConfig.ClassesListId, sharePointConfig.ClassesListId];
        let requestIds = ["preclasses","currentclasses"];
        let selectFields = ["Title,ClassId,Grade,StartYear,EndYear,HeadTeacher,Students","Title,ClassId,Grade,StartYear,EndYear,HeadTeacher,Students"];
        let queryClauses = [`(fields/StartYear eq ${schoolYear-1})`,`(fields/StartYear eq ${schoolYear})`];
        //let orderClauses = ["fields/Grade asc","fields/Grade asc"];
        
        getSharePointListItemsBatch(accessToken, requestIds, spListIds, selectFields, queryClauses)
            .then(response => {
                let preClassItems=[];
                let currentClassItems = [];
                response.responses.forEach(r=>{
                    if(r.status===200){
                        let items=[];
                        let listItemArray = r.body.value;
                        for(let i=0; i<listItemArray.length; i++){
                            items.push(listItemArray[i].fields);
                        }
                        if(items.length>0){
                            items = _orderBy(items, ['Grade','Title'], ['asc','asc']);
                        }
                        if(r.id==="preclasses") {
                            preClassItems = items;
                        }else {
                            currentClassItems = items;
                        }
                    } else{
                        console.log(`Lỗi khi generate classes for year: ${schoolYear}`, r.body.error);
                        setClassApiMessage(`Lỗi khi generate classes for year: ${schoolYear}`);
                    }
                });
                //2 Khởi tạo lớp học cho năm học mới schoolYear nếu chưa có
                if(currentClassItems && currentClassItems.length>0){
                    console.log(`Lớp học của năm học ${schoolYear}-${schoolYear+1} đã có, không khởi tạo nữa. Nếu thiếu, đề nghị nhập bằng tay`);
                    setClassApiMessage(`Lớp học của năm học ${schoolYear}-${schoolYear+1} đã có, không khởi tạo nữa. Nếu thiếu, đề nghị nhập bằng tay`);
                } else {
                    //2 Tính toán để khởi tạo các lớp học tiếp theo dựa trên các lớp học của năm trước đó
                    //  - Từ lớp 6-8: Sẽ khởi tạo lớp tiếp theo dạng lên lớp: 6->7, 7->8, 8->9
                    //  - Lớp 9 thì khởi tạo lớp 6

                    //Duyệt để khởi tạo các lớp học theo khối lớp
                    const gradeArray = schoolConfig.Grades;
                    for(let g=0; g < gradeArray.length; g++){
                        const grade = gradeArray[g]*1;
                        let fieldsValuesArray=[];
                        const preClassItemsByGrade = preClassItems.filter(item=>item.Grade*1===grade);
                        preClassItemsByGrade.forEach(classItem => {
                            const newGrade = classItem.Grade === 9 ? 6: classItem.Grade + 1; //Lớp 6 lên 7, 7 lên 8, 8 lên 9, 9 thành 6
                            const fieldValues = {
                                fields: {
                                    ClassId: uuidv4(),
                                    Title: classItem.Title.replace(classItem.Grade, newGrade), 
                                    StartYear: schoolYear,
                                    EndYear: schoolYear +1,
                                    Students: classItem.Students,            
                                    Grade: newGrade,
                                    HeadTeacher: classItem.HeadTeacher,
                                    Note: `Khởi tạo từ ${classItem.Title}`,
                                    IsActive: true
                                }
                            }
                            fieldsValuesArray.push(fieldValues);
                        });  
                        addSharePointListItemBatch(accessToken, sharePointConfig.ClassesListId, fieldsValuesArray)
                        .then(response => {
                            console.log(`Khởi tạo xong các lớp khối ${grade}`, response);
                            setClassApiMessage(`Khởi tạo xong các lớp khối ${grade}`);
                            setClassApiMessage(`Xong`);
                        });
                    }
                }
        });
    }

    const deleteItem = (accessToken, item) => {
        let items = Array.from(classItems);
        remove(items, i=>i.ClassId===item.ClassId);

        //Xóa phía server nếu item này đã tồn tại trên server dựa vào SharePoint List Item ID field
        if(item.id){
            deleteSharePointItem(accessToken,sharePointConfig.ClassesListId,item.id).then(response =>{
                console.log('Completed delete item ' + item.ClassId);
                console.log(response);
            });
        }
        setClassItems(items);
    }

    useEffect(() => {     
        const accessTokenRequest = {
            ...loginRequest,
            account: accounts[0]
        }

        const processData = async () => {  
            instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
                const accessToken = accessTokenResponse.accessToken;
                switch(action.type){
                    case 'add':
                        addBlankItem(action.value);
                        break;
                    case 'save':
                        saveItem(accessToken, action.value);
                        break;
                    case 'del':
                        deleteItem(accessToken, action.value);
                        break;
                    case 'generate':
                        setClassApiMessage("");
                        generateClassesForSchoolYear(accessToken, action.value);
                        break;
                    default:                        
                        fetchData(accessToken, action.value);
                }
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest);                    
                }
                console.log(error);
            }) 
        }; 

        processData();  

    },[accounts, action, instance]);

    return [{classItems, flagReloadAfterAddNew, classApiMessage},  setAction];
}
