import React from "react";
import {orderBy as _orderBy} from 'lodash';
import ClassItem from "./ClassItem";


function ClassList({data, teachers, onAddItemClick, onSaveItemClick, onDeleteItemClick}){
    return(        
        <form className="form-inline" name="frmTeacherList">       
            <table className="table table-hover table-sm caption-top">
                <caption>
                    Danh sách lớp học - Tổng số: {data.length} lớp
                </caption>
                <thead>
                    <tr>
                        <th style={{width:'6%'}}>Tên lớp</th>
                        <th style={{width:'6%'}}>Khối</th>
                        <th style={{width:'6%'}}>Năm BĐ</th>
                        <th style={{width:'6%'}}>Năm KT</th>
                        <th style={{width:'6%'}}>Sĩ số</th>                        
                        <th style={{width:'12%'}}>Gv chủ nhiệm</th>
                        <th style={{width:'40%'}}>Ghi chú</th>
                        <th style={{width:'8%'}}>Hiện dùng</th>
                        <th style={{width:'10%'}} className='text-end'>
                            <button type="button" 
                                onClick={onAddItemClick} className="btn btn-primary btn-sm">&nbsp;&nbsp;Thêm&nbsp;&nbsp;
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody className="align-middle">
                    { 
                        data.map((item) => 
                            <ClassItem 
                                key={item.id}
                                item = {item} 
                                teachers = {_orderBy(teachers,['Title'],['asc'])}
                                action = {item.Title === ''? 'new' : ''} 
                                onSaveItemClick = {onSaveItemClick}
                                onDeleteItemClick={onDeleteItemClick}                                
                            />
                        )
                    }
                </tbody>
            </table>
        </form>
    )
}

export default ClassList;