import React, {useState} from 'react';
import { read, utils } from 'xlsx';
import { useSubjectPlanDataApi, createBlankSubjectPlanDetailItem } from './SubjectPlanData.js';

import SubjectPlanImportList from './SubjectPlanImportList.js';
import MessageBox from '../common/MessageBox.js';

const SHEET_DEFAULT_CELL_VALUE = '???';

function SubjectPlanImportDlg({subjectPlanItem, subjectItems, onSaveImportedPlanDetailItems}) 
{    
    const [isValidFile, setIsValidFile] = useState(false);
    const [workbook, setWorkbook] = useState(null);
    const [sheetNames, setSheetNames] = useState([]);
    const [loadedSubjectPlanInfo, setLoadedSubjectPlanInfo] = useState(null);
    const [ppctItems, setPPCTItems] = useState([]);

    const [isValidToImport, setIsValidToImport] = useState(false);
   
    const handleReadFile = ($event) => {
        const files = $event.target.files;  
        let fileName = $event.target.value;
        fileName = fileName.substring(fileName.lastIndexOf('\\')+1);
        
        document.getElementById('lblFile').innerHTML = fileName;

        if(files.length) {
            const file = files[0];
            const reader = new FileReader();

            reader.onload = (event) => {
                let wb;
                try {
                    wb = read(event.target.result);
                    setWorkbook(wb);
                    setIsValidFile(true);
                }
                catch {                    
                    return;
                }

                const sheets = wb.SheetNames;
                setSheetNames(sheets);
                console.log('sheets', sheets);

                if (sheets.length) {                    
                    hanldeSheetSelection(sheets[0], wb);
                    
                } else {
                    setIsValidFile(false);
                }              
            }
            reader.readAsArrayBuffer(file);
        }
    }

    const hanldeSheetSelection = (sheetName, wb=null) =>{        
        const sheet = wb?wb.Sheets[sheetName] : workbook.Sheets[sheetName];

        //Range de lay thong tin ve mon hoc (mon hoc, khoi lop, nam hoc)
        sheet['!ref'] = "H5:I7"; 
        const subjectRows = utils.sheet_to_json(sheet, {header:1,defval:SHEET_DEFAULT_CELL_VALUE});

        //Range de lay thong tin ve hoc ky (hoc ky, so tiet)
        sheet['!ref'] = "C41:C42"; 
        const semesterRows = utils.sheet_to_json(sheet, {header:1,defval:SHEET_DEFAULT_CELL_VALUE});

        //Range để lấy số tiết một tuần
        sheet['!ref'] = "G41:G42"; 
        const periodRows = utils.sheet_to_json(sheet, {header:1,defval:SHEET_DEFAULT_CELL_VALUE});   

        const subjectPlanInfo = ExtractLoadedSubjectPlanInfo(subjectRows, semesterRows, periodRows);
        setLoadedSubjectPlanInfo(subjectPlanInfo);

        if(subjectPlanItem.SubjectId === getSubjectId(subjectPlanInfo.subjectTitle) 
            && subjectPlanInfo.grade === subjectPlanItem.Grade  && subjectPlanInfo.startYear === subjectPlanItem.StartYear) {
            setIsValidToImport(true);
        } else {
            setIsValidToImport(false);
        }

        //Range de lay du lieu ke hoach giang day
        sheet['!ref'] = "A49:O250"; 
        //const dataRows = utils.sheet_to_json(sheet, {number:1, header:1, defval:SHEET_DEFAULT_CELL_VALUE});
        const dataRows = utils.sheet_to_json(sheet, {header:1, defval:SHEET_DEFAULT_CELL_VALUE});

        //console.log('dataRows', dataRows);
        //setPPCTRows(dataRows.slice(1));                    

        // let colNames = [];
        // Object.keys(dataRows[0]).forEach(key=> colNames.push(dataRows[0][key]));
        // console.log('colNames', colNames);
        // console.log('dataRows', dataRows.slice(1));  //Bo qua dong dau tien la header

        setPPCTItems(convertPPCTRowsToPPCTItems(dataRows));
    }

    const ExtractLoadedSubjectPlanInfo = (subjectRows, semesterRows, periodRows) => {
        let subjectPlanInfo = {};
        if(subjectRows.length===3) {
            const schoolYears = subjectRows[2][1].split('-');
            subjectPlanInfo = {
                subjectTitle:subjectRows[0][1],
                grade: subjectRows[1][1]*1,
                startYear: schoolYears[0]*1,
                endYear: schoolYears[1]*1
            }
        }

        if(semesterRows.length===2) {  //C41:C24
            subjectPlanInfo = {
                ...subjectPlanInfo,
                semester1_weeks:semesterRows[0][0]*1,
                semester2_weeks:semesterRows[1][0]*1,
            }
        }

        if (periodRows.length===2) { //D41:D42
            subjectPlanInfo = {
                ...subjectPlanInfo,
                semester1_periodperweek: periodRows[0][0]*1,
                semester2_periodperweek: periodRows[1][0]*1
            }
        }
        return subjectPlanInfo;
    }

    const convertPPCTRowsToPPCTItems = (ppctRows) => {        
        let ppctItems = [];
        let lectureTitle = "";
        let subjectCategory = "";

        let prePeriod=""; //Bien tam luu gia tri tiet truoc
        let subPriod = 1; //
        ppctRows.forEach(row=> {
            const lastColIndex = row.length-1; 
            if(row[0]*1>0 && row[1]!==SHEET_DEFAULT_CELL_VALUE) { //Chi xet cac row co truong tuan va tiet cac row khac bo qua
                const newPPCTItem = {
                    ...createBlankSubjectPlanDetailItem()[0],
                    Week: row[0],
                    Period: row[1],
                    Title: row[2],
                    Purpose: row[5]!==SHEET_DEFAULT_CELL_VALUE ? row[5] : "",
                    AdjustContent: row[8]!==SHEET_DEFAULT_CELL_VALUE ? row[8] : "",
                    GuideLine: row[11]!==SHEET_DEFAULT_CELL_VALUE ? row[11] : "",
                    Category:  row[lastColIndex]!==SHEET_DEFAULT_CELL_VALUE ? row[lastColIndex] : ""
                }
                //Kiem tra truong hop 1 bai giang chia lam nhieu tiet, cell ten bai giang duoc merge lai
                if(row[2] !== SHEET_DEFAULT_CELL_VALUE) {
                    lectureTitle=row[2];
                } else {
                    newPPCTItem.Title = lectureTitle;
                }
                if( row[lastColIndex]!==SHEET_DEFAULT_CELL_VALUE) {
                    subjectCategory=row[lastColIndex];
                } else {
                    newPPCTItem.Category = subjectCategory;
                }

                //16/10/2023 Fix: Xu ly truong hop 1 tiet gom nhieu bai giang/bai hoc, neu dung. Gop thong tin bai giang vao tiet chu khong them moi
                // if(newPPCTItem.Period===prePeriod && prePeriod!=="") {                    
                //     const idxPrePPCTItem = ppctItems.findIndex(item=>item.Week === newPPCTItem.Week && item.Period===prePeriod);
                //     if(idxPrePPCTItem>=0){
                //         ppctItems[idxPrePPCTItem].Title += "; [" + newPPCTItem.Title + "]";
                //     }
                    
                // } else {
                //     ppctItems.push(newPPCTItem);
                //     prePeriod = newPPCTItem.Period;
                // }
                //End of 16/10/2023 Fix

                //16/10/2023 Fix: Xu ly cung so hieu tiet ung voi nhieu bai, danh lai so tiet tu bai thu 2 tro di
                //theo quy tac bai 1, so hieu tiet du nguyen, bai 2 se co them .2 vao ky hieu tiet, bai 3 them .3, ...
                if(newPPCTItem.Period===prePeriod) { 
                    subPriod +=1;
                    newPPCTItem.Period += "."+subPriod;
                } else {
                    subPriod=1;  
                    prePeriod = newPPCTItem.Period;
                }
                // End of 16/10/2023 Fix

                ppctItems.push(newPPCTItem);
                
            }
        })

        return ppctItems;
    }

    const getSubjectId = (subjectTitle)=> {
        const lcSubjectTitle = subjectTitle.toLowerCase()
        const subjectObj = subjectItems.find((item)=>item.Title.toLowerCase() === lcSubjectTitle);
        if(subjectObj!==undefined){
            return subjectObj.SubjectId;
        } else {
            return "";
        }

    }

    const handleSubmitImportedData = () => {
        if (ppctItems.length>0) {
            let updateSubjectPlan = subjectPlanItem.id === 0  
                ? {//Subject Plan nay chua co, trường hợp này để đây chứ chưa thực hiện cho phép lấy đủ thông tin từ file excel
                    ...subjectPlanItem,
                    SubjectId: getSubjectId(loadedSubjectPlanInfo.subjectTitle),
                    Title: loadedSubjectPlanInfo.subjectTitle + ` ${loadedSubjectPlanInfo.grade} (${loadedSubjectPlanInfo.startYear}-${loadedSubjectPlanInfo.endYear})`,
                    Grade: loadedSubjectPlanInfo.grade,
                    StartYear: loadedSubjectPlanInfo.startYear,
                    EndYear: loadedSubjectPlanInfo.endYear,
                    Sem1_TotalWeeks: loadedSubjectPlanInfo.semester1_weeks,
                    Sem1_PeriodsPerWeek: loadedSubjectPlanInfo.semester1_periodperweek,
                    Sem2_TotalWeeks: loadedSubjectPlanInfo.semester2_weeks,
                    Sem2_PeriodsPerWeek: loadedSubjectPlanInfo.semester2_periodperweek,
                    RefDocument:'',
                    IsActive: true,
                    PlanDetail: ppctItems,      
                }
                : { //Đây là trường hợp import PPTC vào một KHDH đã khai báo => Đang sử dụng trường hợp này
                    ...subjectPlanItem,
                    Sem1_TotalWeeks: loadedSubjectPlanInfo.semester1_weeks,
                    Sem1_PeriodsPerWeek: loadedSubjectPlanInfo.semester1_periodperweek,
                    Sem2_TotalWeeks: loadedSubjectPlanInfo.semester2_weeks,
                    Sem2_PeriodsPerWeek: loadedSubjectPlanInfo.semester2_periodperweek,
                    IsActive: true,
                    PlanDetail: ppctItems,
                }

            if(updateSubjectPlan.SubjectId !== "") {
                //Xử lý nếu SubjectPlan hiện tại đã có PlanDetail - trường hợp import lại dữ liệu từ file PPTC excel
                if(subjectPlanItem.PlanDetail && subjectPlanItem.PlanDetail.length>0) {
                    let existingPlanDetailItemsToUpdate = subjectPlanItem.PlanDetail;
                    
                    //Dựng cờ IsActive=false để chỉ có cái nào mới hoặc có thay đổi thì mới IsActive=true
                    existingPlanDetailItemsToUpdate = existingPlanDetailItemsToUpdate.map(item =>  {return {...item, IsActive: false}});

                    //Duyệt từng mục để 
                    //- Nếu mục đã có thì update giá trị mới và giữ nguyên LectureId
                    //- Nếu chưa có thì thêm vào
                    const numOfNewItems = ppctItems.length;

                    for(let i=0; i< numOfNewItems; i++){
                        const newItem = ppctItems[i];
                        const existingItemIndex = existingPlanDetailItemsToUpdate.findIndex(exitingItem => 
                            exitingItem.Week == newItem.Week && exitingItem.Period == newItem.Period && exitingItem.SubjectId===newItem.SubjectId); //cố tình dùng ==

                        if(existingItemIndex>=0) { //đã có thì cập nhật, không thay đổi LectureId
                            existingPlanDetailItemsToUpdate[existingItemIndex].Title = newItem.Title;
                            existingPlanDetailItemsToUpdate[existingItemIndex].Purpose = newItem.Purpose;
                            existingPlanDetailItemsToUpdate[existingItemIndex].AdjustContent = newItem.AdjustContent;
                            existingPlanDetailItemsToUpdate[existingItemIndex].GuideLine =newItem.GuideLine;
                            existingPlanDetailItemsToUpdate[existingItemIndex].Category = newItem.Category;
                            existingPlanDetailItemsToUpdate[existingItemIndex].IsActive = newItem.IsActive;  //Mặc định có là true
                            //console.log('Update item hien có', i, existingPlanDetailItemsToUpdate[existingItemIndex], newItem);
                        } else { //Chưa có thì thêm vào
                            existingPlanDetailItemsToUpdate.push({...newItem});
                            console.log('Them moi new item vào pcct da co', newItem);
                        }
                    };
                    updateSubjectPlan.PlanDetail = existingPlanDetailItemsToUpdate
                }   

                //Submit to backend
                onSaveImportedPlanDetailItems(updateSubjectPlan);                
            } else {
                console.warn(`Không tìm thấy môn học ${loadedSubjectPlanInfo.subjectTitle} trong danh sách Môn học. Kiểm tra để thực hiện lại.`);
            }
        }       
    }

    return (
        <div className="modal fade" id="dlgImportPPCT" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" 
             aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog  modal-xl modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Import dữ liệu PPCT môn học {subjectPlanItem?.Title}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>                        
                    </div>
                    <div className="modal-body">
                        <div className='pt-3'>
                            <div className="col-md-12">
                                <div className="input-group pb-3">
                                    <div className="custom-file">
                                        <label className="custom-file-label" htmlFor="inputGroupFile" id="lblFile">Bấm để chọn tệp dữ liệu PPCT</label>
                                        <input type="file" className="custom-file-input"  name="file" id="inputGroupFile" required onChange={handleReadFile}
                                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>                                    
                                    </div>
                                </div> 
                                <div>
                                {
                                    <ul style={{listStyleType:'none'}} className='me-0 pe-0'>                                  
                                    {
                                        sheetNames.map((sheet,index)=>{
                                            return <li key={index} style={{display:'inline-block'}} className='me-3'>
                                                <input type="checkbox" onClick={()=>hanldeSheetSelection(sheet)}/>&nbsp;{sheet}
                                            </li>
                                        })
                                    }
                                    </ul>
                                }
                                </div>                       
                            </div>                       
                        </div>
                        <SubjectPlanImportList 
                            subjectPlanInfoItem={loadedSubjectPlanInfo}
                            ppctItems={ppctItems}
                        />
                    </div>
                    <div className="modal-footer">
                        {
                            isValidToImport
                            ?<button className="btn btn-primary btn-sm" type="button"
                                disabled = {isValidFile  ? "" : "disabled" }
                                onClick={handleSubmitImportedData}>
                                Import&nbsp;<i className="fa fa-download"></i>
                            </button>
                            :
                            sheetNames.length>0
                            ?
                            <MessageBox formatClass="text-center text-normal bg-warning px-3 py-1">
                            File dữ liệu PPCT không đúng với môn học, khối lớp và năm học cần Import. Kiểm tra và chọn lại!
                            </MessageBox>
                            :""
                        }   
                        
                        <button 
                            type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"
                            // disabled = {currentImportBatchNumber>=numOfBatchImporting ? "" : "disabled"}                         
                        >Đóng lại</button>
                    </div>
                </div>
            </div>
        </div> 
    )
}

export default SubjectPlanImportDlg;

