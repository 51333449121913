import React,{useContext, useEffect} from "react";
import ClassList from "./ClassList";
import {createBlankClassItem, useClassDataApi} from './ClassData';
import { MyAppContext } from '../../context/MyAppContext';
import MessageBox from '../common/MessageBox';
import { getSchoolYear } from "../../globalFuntions";

function ClassPage() {
    const [appContext,] =  useContext(MyAppContext);
    const teacherItems = appContext.Teachers;
    const [{classItems, flagReloadAfterAddNew, classApiMessage}, setAction] = useClassDataApi();

    const handleAdd = () => {
        let blankItem = createBlankClassItem();
        setAction({
            type:'add',
            value: blankItem
        });      
    }

    const handleSave = (item) => {
        setAction({
            type:'save',
            value: item
        });
    }

    const handleDelete = (item) => {
        setAction({
            type:'del',
            value: item
        });
    }

    const handleGenerateClassess = () => {
        setAction({
            type:'generate',
            value: getSchoolYear()
        });
    }

    useEffect(()=>{
        if(flagReloadAfterAddNew){
            setAction({type:'', value:0});
        }
    },[flagReloadAfterAddNew]);

    useEffect(()=>{
        if(classApiMessage.toLowerCase()==="xong"){
            setAction({type:'', value:0});
        }
    },[classApiMessage]);

    return(
       <>
        <div className="row">
            <div className="col-md-7">
                <h4 className="pt-3">Thông tin Lớp học</h4>
            </div>
            <div className="col-md-5 text-end">
                {
                    classApiMessage!=="" && classApiMessage.toLowerCase()!=="xong" &&
                    <MessageBox format="text-center fw-normal mt-4 me-2">
                        {classApiMessage}
                    </MessageBox>
                }
                {
                    classItems && classItems.length<=0 &&
                    <button type="button" className="btn btn-info btn-sm mt-4 me-1 ms-2"
                        onClick={()=>handleGenerateClassess()}
                    >
                        Khởi tạo danh sách lớp học
                    </button>
                }
            </div>
        </div>
        <div className="row">
            <ClassList 
                data={classItems}
                teachers ={teacherItems} 
                onAddItemClick = {handleAdd}
                onSaveItemClick = {handleSave}
                onDeleteItemClick = {handleDelete}
            />
        </div>
       </>
    )
}

export default ClassPage;