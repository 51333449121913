 // Cú pháp khai báo định nghĩa List và column: 
 // https://docs.microsoft.com/en-us/graph/api/resources/columndefinition?view=graph-rest-1.0
 // https://docs.microsoft.com/en-us/graph/api/resources/textcolumn?view=graph-rest-1.0

 export const SchoolYearSettingList = {
     "displayName":"SchoolYearSetting",
     "columns": [
        //Title là tên năm học vd: 2021-2022
         {
             "name": "SettingId",
             "indexed": true,
             "text": {}
         },
         {
            "name": "StartYear",  //Năm học bắt đầu
            "indexed": true,
            "number": {}
        },  
        {
            "name": "EndYear",  // Năm học kết thúc
            "indexed": true,
            "number": {}
        },               
         {
            "name": "Sem1_StartDay",
            "dateTime": {
                "format": "dateOnly"
            }
        },     
        {
            "name": "Sem1_TotalWeeks",
            "number": {}
        }, 
        {
            "name": "Sem1_StartIsoWeek", //ứng với tuần bắt đầu của học kỳ 1
            "number": {}
        },         
        {
            "name": "Sem2_StartDay",
            "dateTime": {
                "format": "dateOnly"
            }
        },     
        {
            "name": "Sem2_TotalWeeks",
            "number": {}
        },
        {
            "name": "Sem2_StartIsoWeek",  //ứng với tuần bắt đầu của học kỳ 2
            "number": {}
        },
        {
            "name": "Note",  //ứng với tuần bắt đầu của học kỳ 2
            "text": {
                "allowMultipleLines": true,
                "maxLength":0                
            }            
        }              
     ]
 }
 
 export const ClassNotebookList = {
    "displayName": "ClassNotebook",
    "columns": [
        {
            "name": "ClassId",
            "indexed": true,
            "text": {}
        },
        {
            "name": "SchoolYear",
            "indexed": true,
            "number":{}
        },
        {
            "name": "SchoolWeek",
            "indexed": true,
            "number":{}
        },        
        {
            "name":"Records",
            "text": {
                "allowMultipleLines": true,
                "maxLength": 0
            }
        }
    ],
    "list":{
        "template": "genericList"
    }
 }

 export const ScheduleList = {
    "displayName": "Schedule",
    "columns": [
        {
            "name": "ClassId",
            "indexed": true,
            "text": {}
        },
        {
            "name": "Grade",
            "indexed": true,
            "number": {}
        },        
        {
            "name": "SchoolYear",
            "indexed": true,
            "number":{}
        },
        {
            "name": "SchoolWeek",
            "indexed": true,
            "number":{}
        },  
        {
            "name": "StartDateOfWeek",  //Ngày đầu tuần
            "indexed": true,
            "dateTime": {"format": "dateOnly"}
        },  
        {
            "name": "ApplySchoolWeek",  //Là tuần áp dụng dữ liệu TKB tương ứng với mục bên ScheduleImported List
            "indexed": true,
            "number":{}
        },    
        {
            "name":"Records",
            "text": {
                "allowMultipleLines": true,
                "maxLength": 0
            }
        }
    ],
    "list":{
        "template": "genericList"
    }
 }

 export const ScheduleLogList = {  //Ghi các thay đổi ứng với một tiết học cụ thể trong TKB
    "displayName": "ScheduleLog",
    "columns": [
        {
            "name": "ClassId",
            "indexed": true,
            "text": {"maxLength": 128 }
        },
        {
            "name": "SchoolYear",
            "indexed": true,
            "number":{}
        },
        {
            "name": "SchoolWeek",
            "indexed": true,
            "number":{}
        }, 
        {
            "name": "ScheduleDate",  //Ngày đầu của tuần ApplySchoolWeek
            "indexed": true,
            "dateTime": {"format": "dateOnly"}
        }, 
        {
            "name": "Period",
            "indexed": true,
            "number":{}
        },
        {
            "name": "PartDay",  //Có hai giá trị Sáng, Chiều
            "indexed": true,
            "text":{
                "maxLength": 15
            }
        },
        {
            "name": "SubjectId",  
            "indexed": true,
            "text":{
                "maxLength": 128
            }
        }, 
        {
            "name": "TeacherId",  
            "indexed": true,
            "text":{
                "maxLength": 128
            }
        },  
        {
            "name": "ActionType",  //Dự giờ, Nghỉ lễ, Khóa sửa, Dạy bù, khác
            "indexed": true,
            "text":{
                "maxLength": 50
            }
        }, 
        {
            "name": "ActionRecords",  //Chứa các thông tin chi tiết về sự kiện liên quan
            "text":{
                "allowMultipleLines": true,
                "maxLength": 0
            }
        },    
    ],
    "list":{
        "template": "genericList"
    }     
 }

 export const ScheduleImportedList = {
    "displayName": "ScheduleImported",
    "columns": [  //Title là tên gợi nhớ dạng TKB_1, TKB_2... 1, 2 là tuần áp dụng
        {
            "name": "SchoolYear",
            "indexed": true,
            "number":{}
        },
        {
            "name": "ApplySchoolWeek",  //Là tuần bắt đầu áp dụng dữ liệu TKB này
            "indexed": true,
            "number":{}
        },  
        {
            "name": "StartDateOfWeek",  //Ngày đầu của tuần ApplySchoolWeek
            "indexed": true,
            "dateTime": {"format": "dateOnly"}
        },
        {
            "name":"ScheduleP1",    
            "text": {
                "allowMultipleLines": true,
                "maxLength": 0
            }
        },
        {
            "name":"ScheduleP2",   
            "text": {
                "allowMultipleLines": true,
                "maxLength": 0
            }
        },
        {
            "name":"ScheduleP3",    
            "text": {
                "allowMultipleLines": true,
                "maxLength": 0
            }
        },
        {
            "name":"ScheduleP4",   
            "text": {
                "allowMultipleLines": true,
                "maxLength": 0
            }
        },   
        {
            "name":"ScheduleP5",   
            "text": {
                "allowMultipleLines": true,
                "maxLength": 0
            }
        },     
    ],
    "list":{
        "template": "genericList"
    }
 }
 /**
 * LectureScheduleList
 * Sổ báo giảng của giáo viên, hàng tuần mỗi giáo viên có 1 bản ghi
 * Các trường thông tin:
 * - ScheduleId: Là ID để liên kết với tương ứng trong thời khóa biểu
 * - LecturesRecords:có cấu trúc là JSON ghi lại chi tiết lich giảng của giáo viên trong cả tuần
 * - Các trường khác là trường đơn chi tiết như bên dưới
 */
 export const LectureScheduleList = {
    "displayName": "LectureSchedule2023-2024",
    "columns": [ //Title là trường đặt tên gì đó ví dụ LBG-TenGV-Namhoc-TuanHoc
        {
            "name": "LectureScheduleId",    //Mã định danh của bản ghi
            "indexed": true,
            "text": {}
        },
        {
            "name": "TeacherId",    //Mã giáo viên
            "indexed": true,
            "text": {}
        },   
        {
            "name": "SchoolYear",   //Năm học là năm bắt đầu của năm học mới
            "indexed": true,
            "number":{}
        },
        {
            "name": "SchoolWeek",   //Tuần học (tuần 1 bắt đầu từ ngày khai giảng)
            "indexed": true,
            "number":{}
        },
        {
            "name": "StartDateOfWeek",  //Ngày đầu tuần
            "indexed": true,
            "dateTime": {"format": "dateOnly"}
        },
        {
            "name":"LectureRecords",    //Thông tin chi tiết lịch giảng của giáo viên, được lấy từ TKB và do giáo viên cập nhật
            "text": {
                "allowMultipleLines": true,
                "maxLength": 0
            }
        },                          
    ],    
    "list":{
        "template": "genericList"
    }
 }

 //List chứa thông tin các ngày nghỉ học của trường
 export const WorkOffDayList = {
    "displayName": "WorkOffDay",
    "columns": [ //Cột title ghi tiêu đề ngày nghỉ, ví dụ: Nghỉ tế, quốc khách, 20/11...
        {
            "name": "WorkOfDate",  //Ngày đầu của tuần ApplySchoolWeek
            "indexed": true,
            "dateTime": {"format": "dateOnly"}
        },
        {   //Sẽ lưu dạng mảng - json
            "name": "MorningPeriod",
            "text": {}
        },
        {   //Sẽ lưu dạng mảng - json
            "name": "AfternoonPeriod",
            "text": {}
        }, 
        {   //Sẽ lưu dạng mảng - json
            "name": "Classess", //Sẽ lưu dạng mảng - json
            "text": {}
        },      
    ],
    "list":{
        "template": "genericList"
    }
 }

 export const TeacherList = {
    "displayName": "Teachers",
    "columns": [ //Title fields sẽ là tên giáo viên dùng để sắp và hiện trên thời khóa biểu
        {
            "name": "TeacherId",
            "indexed": true,
            "text": {}
        },
        {
            "name": "FullName",
            "text":{}
        },      
        {
            "name": "FirstName",
            "text":{}
        },
        {
            "name": "LastName",
            "text":{}
        }, 
        {
            "name": "DepartmentId",
            "indexed": true,
            "text": {}
        }, 
        // {
        //     "name": "SubjectId",
        //     "text": {}
        // },  
        {
            "name": "TeachingSubjectIds",
            "text": {
                "allowMultipleLines": true,
                "maxLength": 0
            }
        },        
        {
            "name": "TitleId",
            "indexed": true,
            "text": {}
        },               
        {
            "name": "UserName",
            "indexed": true,
            "text": {}
        },   
        {
            "name": "Roles",
            "text": {}
        },      
        {
            "name": "UserEmail",
            "indexed": true,
            "text":{}
        },
        {
            "name": "PhoneNumber",
            "indexed": true,
            "text":{}
        },
        {
            "name": "Address",
            "text":{}
        },
        {
            "name": "IsActive",
            "indexed": true,
            "boolean": {}
        }
    ],
    "list":{
        "template": "genericList"
    }    
 }

 export const ClassesList = {
    "displayName": "Classes",
    "columns": [
        {
            "name": "ClassId",
            "indexed": true,
            "text": {}
        },
        {
            "name": "StartYear",
            "indexed": true,
            "number":{}
        },
        {
            "name": "EndYear",
            "indexed": true,
            "number":{}
        },
        {
            "name": "Grade",
            "indexed": true,
            "number": {}
        },
        {
            "name": "Students",
            "number":{}
        },
        {
            "name": "HeadTeacher",
            "indexed": true,
            "text": {}
        },
        {
            "name":"Note",
            "text": {
                "allowMultipleLines": true,
                "maxLength": 0
            }
        },
        {
            "name": "IsActive",
            "indexed": true,
            "boolean": {}
        } 
    ],
    "list":{
        "template": "genericList"
    }
 }

 export const SubjectList = {
    "displayName": "Subjects",
    "columns": [ //Title là tên của khóa học
        {
            "name": "SubjectId",
            "indexed": true,
            "text": {}
        },
        {
            "name": "AliasName",  //Tên khóa học dùng để sắp thời khóa biểu
            "indexed": true,
            "text": {}
        },        
        {
            "name": "Category",
            "indexed": true,
            "text": {}
        },  
        {
            "name": "IsActive",
            "indexed": true,
            "boolean": {}
        }             
    ],
    "list":{
        "template": "genericList"
    }
 }


 export const SubjectPlanList = {
    "displayName": "SubjectPlan",
    "columns": [        
        {
            "name": "SubjectPlanId",
            "indexed": true,
            "text": {}
        },
        //Title là tiêu đế của kế hoạch, vd: Kế hoạch giảng dạy năm học 2021-2022
        {
            "name": "SubjectId",
            "indexed": true,
            "text": {}
        },
        {
            "name":"Sem1_TotalWeeks",
            "number":{}
        },        
        {
            "name":"Sem1_PeriodsPerWeek",
            "number":{}
        },
        {
            "name":"Sem2_TotalWeeks",
            "number":{}
        },        
        {
            "name":"Sem2_PeriodsPerWeek",
            "number":{}
        },        
        {
            "name": "RefDocument",  //Theo công văn 4040 Bộ GDĐT
            "text": {}
        }, 

        {
            "name": "Grade",
            "indexed": true,
            "number": {}
        },
        {
            "name": "StartYear",  
            "indexed": true,
            "number": {}
        },  
        {
            "name": "EndYear",  
            "indexed": true,
            "number": {}
        },            
        {
            "name": "PlanDetail",
            "text": {
                "allowMultipleLines": true,
                "maxLength": 0
            }
        },    
        {
            "name": "IsActive",
            "indexed": true,
            "boolean": {}
        }         
    ],
    "list":{
        "template": "genericList"
    }

    
 }

 export const SubjectPlanDetailList = {
    "displayName": "SubjectPlanDetail",
    "columns": [   
        //Tilte là tên bài giảng/bài học  
        {
            "name": "LectureId",    //** Mã của bài giảng */
            "indexed": true,
            "text": {}
        }, 
        {
            "name": "SubjectPlanId",    //** Khóa ngoại để liên kết với SubjectPlan List */
            "indexed": true,
            "text": {}
        },
        {
            "name": "SubjectId", //***duplicate dùng cho báo cáo
            "indexed": true,
            "text": {}
        },        
        {
            "name":"Category",  //** Phân môn */
            "indexed": true,
            "text": {}
        },
        {
            "name":"Semester",  //** học kỳ */
            "indexed": true,
            "number": {}
        },
        {
            "name": "Week",     //***Tuần học là tuần theo kế hoạch dạy học, không phải là ISO Week
            "indexed": true,
            "number": {}
        }, 
        {
            "name": "StartYear", //***duplicate dùng cho báo cáo
            "indexed": true,
            "number": {}
        },    
        {
            "name": "EndYear", //***duplicate dùng cho báo cáo
            "indexed": true,
            "number": {}
        }, 
        {
            "name": "Grade", //***duplicate dùng cho báo cáo
            "indexed": true,
            "number": {}
        },                       
        {
            "name": "Period", //Có các dạng 1,2 ... hoặc "1,2" hoặc "1-2"
            "indexed": true,
            "text": {}
        },
        {
            "name": "AdjustContent",
            "text": {
                "allowMultipleLines": true,
                "maxLength": 0
            }
        },
        {
            "name": "GuideLine",
            "text": {
                "allowMultipleLines": true,
                "maxLength": 0
            }
        },
        {
            "name": "IsActive",
            "indexed": true,
            "boolean": {}
        }      
    ],
    "list":{
        "template": "genericList"
    }
 }

 export const WeeklyCalendarList = {
    "displayName": "WeeklyCalendar",
    "columns": [   
        //Tilte là tiêu đề/tên công việc 
        {
            "name": "StartDate",
            "indexed": true,
            "dateTime": {}
        }, 
        {
            "name": "EndDate",
            "indexed": true,
            "dateTime": {}
        },
        {
            "name": "Detail",
            "text": {
                "allowMultipleLines":true,
                "maxLength":0
            }
        }, 
        {
            "name": "Location",
            "text": {}
        },
        {
            "name": "Responder",
            "text": {
                "allowMultipleLines":true,
                "maxLength":0
            }
        }, 
        {
            "name": "Priority",  //1 cao - 2 bình thường - 3 thấp
            "indexed": true,
            "number": {}
        },   
        {
            "name": "Category",  //Họp, Đi công tác, Khai giảng, Khác...
            "indexed": true,
            "text": {}
        },                        
    ],
    "list":{
        "template": "genericList"
    }    
 }

 export const NewsBoardList = {
    "displayName": "NewsBoard",
    "columns": [ 
        {
            "name": "NewsId",
            "text": {
                "maxLength": 64
            }
        }, 
        {
            "name": "Summary",
            "text": {
                "allowMultipleLines":true,
                "maxLength":300
            }
        }, 
        {
            "name": "Content",
            "text": {
                "allowMultipleLines":true,
                "maxLength":0,
                "textType":"richText"
            }
        },
        {
            "name": "Category",
            "text": {}
        }, 
        {
            "name": "ExpiredDate",
            "dateTime": {}
        }    
    ],
    "list":{
        "template": "genericList"
    }    
 }  
 
 export const DepartmentList = {
    "displayName": "Department",
    "columns": [ 
        {
            "name": "DepartmentId",
            "text": {
                "maxLength": 64
            }
        }, 
        {
            "name": "ShortName",
            "text": {
                "maxLength":50
            }
        }, 
        {
            "name": "Note",
            "text": {
                "allowMultipleLines":true,
                "maxLength":0
            }
        },
    ],
    "list":{
        "template": "genericList"
    } 
 }

 export const TitleList = {
    "displayName": "TitleList",
    "columns": [ 
        {
            "name": "TitleId",
            "text": {
                "maxLength": 64
            }
        }, 
        {
            "name": "ShortName",
            "text": {
                "maxLength":50
            }
        }, 
        {
            "name": "Note",
            "text": {
                "allowMultipleLines":true,
                "maxLength":0
            }
        },
    ],
    "list":{
        "template": "genericList"
    } 
 }

 export const WorkingPlanList = {
    "displayName": "WorkingPlan",
    "columns": [ 
        {
            "name": "WorkingPlanId",
            "indexed": true,
            "text": {
                "maxLength": 64
            }
        }, 
        //Title Column là tên công việc
        {
            "name": "PlanType",  //Theo kế hoạch, Phát sinh, Khác
            "text": {
                "maxLength":50
            }
        }, 
        {
            "name": "Result",
            "text": {
                "allowMultipleLines":true,
                "maxLength":0
            }
        },
        {
            "name": "StartDate",
            "indexed": true,
            "dateTime": {}
        }, 
        {
            "name": "EndDate",
            "indexed": true,
            "dateTime": {}
        },    
        {
            "name": "CompletedDate",
            "indexed": true,
            "dateTime": {}
        },   
        {
            "name": "ProcessingDuration", //Thời gian yêu cầu của nhiệm vụ
            "text": {
                "maxLength":60
            }
        },    
        {
            "name": "CompletedDuration", //Tiến độ thời gian hoàn thành
            "text": {
                "maxLength":60
            }
        },   
        {
            "name": "AssignedTo", //Người thực hiện một hoặc nhiều
            "text": {
                "allowMultipleLines":true,
                "maxLength":0
            }
        },   
        {
            "name": "Leader", //Người phụ trách
            "text": {                
                "maxLength":60
            }
        }, 
        {
            "name": "Note", //Ghi chú
            "text": {      
                "allowMultipleLines":true,          
                "maxLength":0
            }
        }, 
        {
            "name": "DepartmentId", //Tổ/nhóm/bộ phận
            "indexed": true,
            "text": {                 
                "maxLength":64
            }
        },                             
    ],
    "list":{
        "template": "genericList"
    } 
 }