import  {useEffect, useState, useContext} from 'react';
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { v4 as uuidv4 } from 'uuid';
import * as dayjs from 'dayjs';
//import * as weekOfYear from 'dayjs/plugin/weekOfYear';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import { orderBy as _orderBy } from 'lodash';

import { loginRequest, sharePointConfig } from '../../authConfig';
import { getSharePointListItemsBatch } from '../../graph';
import { MyAppContext } from '../../context/MyAppContext';
import { getSchoolWeek } from '../../globalFuntions';

export const createBlankDelayedLectureItem = () => {
    return {
        ItemId: '',
        Subject:'',
        Lecture:'',
        Week:0,
        WeekPlan:0,
        Period:0,
        PeriodPlan:'',
        Class: '',
        Teacher:'',
        NoteDate: '',
        PartDay:''
    }
}

export const createDemoDelayedSLectureItems = () =>{
    return [
        {
            ItemId: uuidv4(),
            Subject:'Ngữ Văn',
            Lecture:'Phong cách Hồ Chí Minh',
            Week:16,
            WeekPlan:1,
            Period:1,
            PeriodPlan:'1,2',
            Class: '9A1',
            Teacher:'Nguyễn Việt Hải',
            NoteDate: new Date().toISOString(),
            PartDay:'Sáng'
        },
        {
            ItemId: uuidv4(),
            Subject:'Toán',
            Lecture:'Căn bậc 2',
            Week:16,
            WeekPlan:5,
            Period:3,
            PeriodPlan:'3',
            Class:'9A3',
            Teacher:'Hoàng Minh Tú',
            NoteDate: new Date().toISOString(),
            PartDay:'Sáng'
        }        
    ]
}

export const LectureScheduleRptDataApi = (defaultAction={type:'', value:{startDate:null, endDate:null, schoolYear:0}}) => {
    dayjs.extend(isoWeek);
    const [appContext,] = useContext(MyAppContext);
    const [lectureScheduleReportItems, setLectureScheduleReportItems] = useState([]);
    const [missingLectureScheduleItems, setMissingLectureScheduleItems] = useState([]);
    const [action, setAction] = useState(defaultAction);
    const [isNotebookRptLoading, setIsNotebookRptLoading] = useState(false);

    const {instance, accounts} = useMsal();

    //==========================================================================================
    //  fetchLectureSchedule
    //  Lấy báo danh các bài giảng bị dạy chậm so với phân phối chương trình
    //==========================================================================================
    const fetchLectureSchedule = (accessToken, startDate, endDate, schoolYear) => {
        setIsNotebookRptLoading(true);             
        // console.log(`schoolYear: ${schoolYear}; startDate: ${startDate}; endDate: ${endDate}` );
        const spListIds = [
            // sharePointConfig.ClassesListId,
            sharePointConfig.SubjectPlanListId,
            sharePointConfig.LectureScheduleListId
        ];
        const requestIds = [
            // "0",
            "1",
            "2"]; //,"3","4"]
        const selectFields = [
            // "ClassId,Title,Grade,StartYear,EndYear,IsActive",
            "SubjectPlanId,SubjectId,PlanDetail,Grade,Title",
            "Title,TeacherId,SchoolYear,SchoolWeek,StartDateOfWeek,LectureRecords,id"
        ];

        //Lọc theo ngày đầu tuần nên cần mốc trước và sau lấy bớt và tăng thêm 1 ngày so với ngày đầu tuần
        const startDateIsoString = dayjs(startDate).isoWeekday(0).toISOString();
        const endDateIsoString = dayjs(endDate).isoWeekday(2).toISOString();
        const queries = [
            // "",
            `(fields/StartYear eq ${schoolYear})`,
            `(fields/StartDateOfWeek gt '${startDateIsoString}' and fields/StartDateOfWeek lt '${endDateIsoString}')`,
        ];

        // let classItems=[];
        let subjectPlanDetailItems=[];
        let lectureScheduleItems = [];

        getSharePointListItemsBatch(accessToken, requestIds, spListIds, selectFields, queries)
        .then(response => {
            response.responses.forEach(r=> {
                if (r.status===200){
                    let items = [];
                    const listItemArray = r.body.value;
                    for (let i = 0; i < listItemArray.length; i++){
                        items.push(listItemArray[i].fields);
                    }
                    switch (r.id) {
                        case "0":
                            // classItems = items;
                            break;
                        case "1":
                            items.forEach(item => {
                                let sbPlanDetail = JSON.parse(item.PlanDetail);
                                sbPlanDetail = sbPlanDetail.map(element => { return {...element, SubjectId: item.SubjectId}});
                                subjectPlanDetailItems = subjectPlanDetailItems.concat(sbPlanDetail);
                            });                 
                            break;
                        case "2":
                            lectureScheduleItems = items;
                            break;
                        default:
                    }
                }
                else {
                    console.log('lỗi: ', r.body.error);                    
                }
                    
            });

            const subjectItems = appContext.Subjects;
            const teacherItems = appContext.Teachers;

            let lectureScheduleRecords = []
            if (lectureScheduleItems.length>0){
                lectureScheduleItems.forEach(r=>{
                    let records = JSON.parse(r.LectureRecords);
                    lectureScheduleRecords = lectureScheduleRecords.concat(records);     
                });           
            }

            //Lọc lại chính xác theo ngày yêu cầu
            lectureScheduleRecords = lectureScheduleRecords.filter(item=>
                dayjs(item.schedule_date).isAfter(dayjs(startDate).subtract(1,"d"))  
                && dayjs(item.schedule_date).isBefore(dayjs(endDate).add(1,"d"))
            )
            const delayItems = lectureScheduleRecords.filter(item=>item.is_late===true && item.school_week>item.lecture_week)
            let delayedLectureScheduleItems = [];
            delayItems.forEach(item => {
                let delayedItem = createBlankDelayedLectureItem();
                const subjectItemObj = subjectItems.find(s=>s.SubjectId===item.subject_id);

                //const subjectPlanDetailObj = subjectPlanDetailItems.find(l=>l.LectureId===item.lecture_id); 
                const subjectPlanDetailObj = subjectPlanDetailItems.find(l=>(l.LectureId===item.lecture_id) || (l.SubjectId === item.subject_id 
                    && l.Week==item.lecture_week 
                    && l.Period==item.lecture_period 
                    && l.Category == item.category)); 

                const teacherObj = teacherItems.find(t=> t.TeacherId===item.teacher_id);

                delayedItem = {
                    ...delayedItem,
                    ItemId: item.item_id,
                    Subject: subjectItemObj===undefined || subjectItemObj===null ? item.subject_id : subjectItemObj.Title,
                    Lecture: subjectPlanDetailObj===undefined || subjectPlanDetailObj===null ?  item.lecture_id : subjectPlanDetailObj.Title,
                    Week: item.school_week,
                    WeekPlan: item.lecture_week,
                    Period: item.period,
                    PeriodPlan: item.lecture_period,
                    Class: item.class_name,
                    Teacher: teacherObj===undefined || teacherObj===null ? item.teacher_id : teacherObj.FullName,
                    NoteDate: item.schedule_date,
                    PartDay:item.part_day,
                    Category: item.category
                }
                delayedLectureScheduleItems.push(delayedItem);
            });

            delayedLectureScheduleItems = _orderBy(delayedLectureScheduleItems,['NoteDate'],['desc'])
            setIsNotebookRptLoading(false);
            
            setLectureScheduleReportItems(delayedLectureScheduleItems);    
            //console.log('delayedLectureScheduleItems',delayedLectureScheduleItems)       

        })
    }

    //==========================================================================================
    //  fetchMissingLectureSchedule
    //  Lấy báo danh sách giao viên chưa báo giảng
    //==========================================================================================
    const fetchMissingLectureSchedule = (accessToken, startDate, endDate, schoolYear) => {
        setIsNotebookRptLoading(true);        


        // console.log(`schoolYear: ${schoolYear}; startDate: ${startDate}; endDate: ${endDate}` );
        const spListIds = [
            sharePointConfig.SubjectPlanListId,
            sharePointConfig.LectureScheduleListId
        ];
        const requestIds = [
            "1",
            "2"]; //,"3","4"]
        const selectFields = [
            "SubjectPlanId,SubjectId,PlanDetail,Grade,Title",
            "Title,TeacherId,SchoolYear,SchoolWeek,StartDateOfWeek,LectureRecords,id"
        ];

        //Lọc theo ngày đầu tuần nên cần mốc trước và sau lấy bớt và tăng thêm 1 ngày so với ngày đầu tuần
        const startDateIsoString = dayjs(startDate).isoWeekday(0).toISOString();
        const endDateIsoString = dayjs(endDate).isoWeekday(2).toISOString();

        const schoolYearObject = appContext.SchoolYearInfo.find(element=>element.StartYear === schoolYear);
        const fromSchoolWeek = getSchoolWeek(schoolYearObject, startDateIsoString);
        const toSchoolWeek = getSchoolWeek(schoolYearObject, endDateIsoString);

        const queries = [
            // "",
            `(fields/StartYear eq ${schoolYear})`,
            `(fields/StartDateOfWeek lt '${endDateIsoString}')`,
            // `(fields/StartDateOfWeek gt '${startDateIsoString}' and fields/StartDateOfWeek lt '${endDateIsoString}')`,
        ];

        // let classItems=[];
        let subjectPlanDetailItems=[];
        let lectureScheduleItems = [];

        getSharePointListItemsBatch(accessToken, requestIds, spListIds, selectFields, queries)
        .then(response => {
            response.responses.forEach(r=> {
                if (r.status===200){
                    let items = [];
                    const listItemArray = r.body.value;
                    for (let i = 0; i < listItemArray.length; i++){
                        items.push(listItemArray[i].fields);
                    }
                    switch (r.id) {
                        case "0":
                            // classItems = items;
                            break;
                        case "1":
                            items.forEach(item => {
                                let sbPlanDetail = JSON.parse(item.PlanDetail);
                                sbPlanDetail = sbPlanDetail.map(element => { return {...element, SubjectId: item.SubjectId}});
                                subjectPlanDetailItems = subjectPlanDetailItems.concat(sbPlanDetail);
                            });                 
                            break;
                        case "2":
                            lectureScheduleItems = items;
                            break;
                        default:
                    }
                }
                else {
                    console.log('lỗi: ', r.body.error);                    
                }
                    
            });

            //const subjectItems = appContext.Subjects;
            const teacherItems = appContext.Teachers.filter(t=>t.IsActive===true);

            let missingItems = []; 
            teacherItems.forEach(teacher=>{
                for(let week= fromSchoolWeek; week < toSchoolWeek; week++){ //Chi thong ke cac tuan truoc tuan hien tai
                    const idx = lectureScheduleItems.findIndex(item=>item.SchoolWeek === week && item.TeacherId === teacher.TeacherId);
                    if(idx<0){
                        if(missingItems.length===0){
                            missingItems.push({
                                Idx: idx,
                                TeacherId: teacher.TeacherId,
                                TeacherName: teacher.FullName,
                                Weeks: week + '',
                                NumOfWeeks: 1,
                            });
                        } else {
                            const existingIdx = missingItems.findIndex(item=>item.TeacherId===teacher.TeacherId);
                            if(existingIdx<0){
                                missingItems.push({
                                    Idx:idx,
                                    TeacherId: teacher.TeacherId,
                                    TeacherName: teacher.FullName,
                                    Weeks: week + '',
                                    NumOfWeeks: 1,
                                });                                
                            } else {
                                const numOfWeeks =  missingItems[existingIdx].Week = missingItems[existingIdx].NumOfWeeks + 1;
                                missingItems[existingIdx].Weeks = missingItems[existingIdx].Weeks + "; " + week;
                                missingItems[existingIdx].NumOfWeeks = missingItems[existingIdx].NumOfWeeks = numOfWeeks;
                            }
                        }
                    }
                }
            });

            missingItems = _orderBy(missingItems,['NumOfWeeks','TeacherName'],['desc','asc']);
            setIsNotebookRptLoading(false);            
            setMissingLectureScheduleItems(missingItems);  
        })
    }

    useEffect(() => {     
        const accessTokenRequest = {
            ...loginRequest,
            account: accounts[0]
        }

        const processData = async () => {  
            instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {                
                if(action.value.schoolYear>0) {
                    const accessToken = accessTokenResponse.accessToken;
                    switch(action.type.toLocaleLowerCase()){
                        case "fetch-missing-lecture-schedule":
                            fetchMissingLectureSchedule(accessToken, action.value.startDate, action.value.endDate, action.value.schoolYear);
                            break;
                        case "fetch-delayed-lecture-schedule":
                            fetchLectureSchedule(accessToken, action.value.startDate, action.value.endDate, action.value.schoolYear);
                            break;
                        default:
                            //Do nothing
                    }
                }
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest);                    
                }
                setIsNotebookRptLoading(false); 
                console.error(error);
            }) 
        }; 

        processData();


    },[action]);

    return [{lectureScheduleReportItems, missingLectureScheduleItems ,isNotebookRptLoading},  setAction];
}




