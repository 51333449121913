import React, {useState, useEffect} from 'react';
import { read, utils } from 'xlsx';
import { requestConfig } from '../../appConfig.js';
import { useTeacherDataApi, isProcessingTeacherData } from './TeacherData.js';
import MessageBox from  '../common/MessageBox';

const SHEET_DEFAULT_CELL_VALUE = '???';

const TeacherImportDlg = () => {    
    const [isValidFile, setIsValidFile] = useState(false);
    const [teacherRows, setTeacherRows] = useState([]);
    const [importedteacherItems, setImportedTeacherItems] = useState([]);
    const [columnNames, setColumnNames] = useState([]);
    const [numOfBatchImporting, setNumOfBatchImporting] = useState(0);
    const[{currentImportBatchNumber}, setTeacherAction] = useTeacherDataApi();

    const convertTeacherRowsToTeacherItems = (teacherRows, columnNames) => {
        let teacherItems = [];
        teacherRows.forEach(row=> {
            const newTeacherItem = {
                TeacherId: row[7],
                Title: row[2],
                FirstName: row[2],
                LastName: row[3],
                FullName: row[1],
                DepartmentId: "",
                SubjectId: [],
                TitleId: "",
                PhoneNumber: "0" + row[6],
                UserName: row[13],
                UserEmail: row[13],
                Address:"",
                IsActive: true,
            }
            teacherItems.push(newTeacherItem);
        })
        return teacherItems;
    }

    const handleImport = ($event) => {
        const files = $event.target.files;  
        let fileName = $event.target.value;
        fileName = fileName.substring(fileName.lastIndexOf('\\')+1);
        
        document.getElementById('lblFile').innerHTML = fileName;

        if(files.length) {
            const file = files[0];
            const reader = new FileReader();

            reader.onload = (event) => {
                let wb;
                try {
                    wb = read(event.target.result);
                    setIsValidFile(true);
                }
                catch {                    
                    return;
                }

                const sheets = wb.SheetNames;
                console.log('sheets', sheets);

                if (sheets.length) {
                     //Đọc dữ liệu trong sheet từ dòng đầu tiên trở đi, dòng đầu tiên là dòng header
                    const dataRows = utils.sheet_to_json(wb.Sheets[sheets[0]], {number:1, header:1, defval:SHEET_DEFAULT_CELL_VALUE});
                    setTeacherRows(dataRows.slice(1));                    

                    let colNames = [];
                    Object.keys(dataRows[0]).forEach(key=> colNames.push(dataRows[0][key]));
                    setColumnNames(colNames);

                    console.log('am_rows', dataRows.slice(1));
                    console.log('colNames', colNames);
                } else {
                    setIsValidFile(false);
                }              
            }
            reader.readAsArrayBuffer(file);
        }
    }

    const handleUpdateData = () => {
        let teacherItems = convertTeacherRowsToTeacherItems(teacherRows, columnNames);
        if (teacherItems.length>0) {
            const maxRequest = requestConfig.MAX_REQUEST_PER_BATCH;
            const numOfBatch = teacherItems.length % maxRequest === 0 ? teacherItems.length/maxRequest : Math.floor(teacherItems.length/maxRequest) + 1;
            setImportedTeacherItems(teacherItems);
            setNumOfBatchImporting(numOfBatch);
        }
    }

    useEffect(()=>{
        if (numOfBatchImporting>0 && currentImportBatchNumber < numOfBatchImporting){
            console.log(`currentImportBatchNumber of ${currentImportBatchNumber} of ${numOfBatchImporting}`);
            const maxRequest = requestConfig.MAX_REQUEST_PER_BATCH;
            const importItems = importedteacherItems.slice(currentImportBatchNumber * maxRequest, (currentImportBatchNumber+1)*maxRequest);
            console.log('importing items: ', importItems);

            setTeacherAction({
                type:"importteacheritems",
                value: importItems,
            });
        }
    },[currentImportBatchNumber, numOfBatchImporting])

    return (
        <div className="modal fade" id="dlgImportTeacher" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" 
             aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Import dữ liệu giáo viên</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>                        
                </div>
                <div className="modal-body">
                    <div className='pt-3'>
                        <div className="input-group pb-3">
                            <div className="custom-file">
                                <label className="custom-file-label" htmlFor="inputGroupFile" id="lblFile">Bấm để chọn tệp dữ liệu giáo viên</label>
                                <input type="file" className="custom-file-input"  name="file" id="inputGroupFile" required onChange={handleImport}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>                                    
                            </div>
                        </div>
                        
                        {
                            numOfBatchImporting > 0
                            && 
                            <div className="progress">
                                <div className="progress-bar" role="progressbar"                                     
                                    aria-valuemin="0" aria-valuemax="4" 
                                    aria-valuenow={currentImportBatchNumber} 
                                    style= {{"width": (currentImportBatchNumber+1)*100/numOfBatchImporting +"%"}}>
                                    <span>{currentImportBatchNumber<numOfBatchImporting?(currentImportBatchNumber+1)*100/numOfBatchImporting : currentImportBatchNumber*100/numOfBatchImporting} %</span>
                                </div>
                            </div>
                        }                        
                    </div>     
                </div>
                <div className="modal-footer">
                    <button className="btn btn-primary btn-sm" type="button"
                        disabled = {isValidFile ||  currentImportBatchNumber>=numOfBatchImporting ? "" : "disabled" }
                        onClick={handleUpdateData}>
                        Import&nbsp;<i className="fa fa-download"></i>
                    </button>
                    <button 
                        type="button" className="btn btn-secondary btn-sm" data-bs-dismiss="modal"
                        disabled = {currentImportBatchNumber>=numOfBatchImporting ? "" : "disabled" }
                    >Đóng lại</button>
                </div>
            </div>
        </div>
        </div> 
    )
}

export default TeacherImportDlg;