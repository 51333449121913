import React from 'react';
import * as dayjs from 'dayjs';

import LSListItemLectureDialog from './LSListItemLectureDialog';
import LSListItemMakeupLessonDialog from './LSListItemMakeupLessonDialog';
import { scheduleConfig } from '../../appConfig';

const LSListItem = (props) => {
    const {isOwner, scheduleItem, subjects, classItems, teacherSubjects, subjectPlanDetails, schoolYear, schoolWeek, freePeriodItems, onSaveCellClick} = props; 
   
    let subject_name = "";
    if (subjects.length > 0){
        if (scheduleItem.is_free) {
            const idx = subjects.findIndex(sbItem=>sbItem.SubjectId===scheduleItem.subject_id);
            subject_name = idx >-1 ? subjects[idx].Title : "";

        } else {
            subject_name = subjects[0].Title;
        }
    }

    const dlgId = `dlg${scheduleItem.id}`;

    let lectureObj = null;
    if (scheduleItem.lecture_id!==""){
        lectureObj = subjectPlanDetails.find(l=>(l.LectureId===scheduleItem.lecture_id) || (l.SubjectId === scheduleItem.subject_id 
            && l.Week==scheduleItem.lecture_week 
            && l.Period==scheduleItem.lecture_period 
            && l.Category == scheduleItem.category)); 
    }    

    let rowColor="";
    let showDialog = scheduleItem.subject_id===""? false : true;
    switch (scheduleItem.type) {
        case scheduleConfig.LectureScheduleType_DayBu:
            rowColor="#e6f2ff"; //#dee2eb
            showDialog=false;
            break;
        case "":
            if (scheduleItem.is_late && scheduleItem.lecture_id!=="") {
                rowColor="#ffffcc"; //#ffc107
            } else rowColor="";
            break;
        default:            
    }

    //if(subjectPlanDetails.length<=0)
    //    console.log('scheduleItem in LSListItem with length=0', scheduleItem);

    return (
        <tr className="align-middle p-0" 
            style={{"fontSize":"0.8em","backgroundColor": rowColor}}>
            {
                scheduleItem.period === 1 && scheduleItem.part_day==="Sáng"
                ? <td rowSpan="10" className='text-center' style={{"backgroundColor": "#ffffff"}}>
                    <span className='fw-bold fs-5'>{parseInt(dayjs(scheduleItem.schedule_date).format("d"))+1}</span>
                    <br/><br/>
                    <span className='pt-3'>{dayjs(scheduleItem.schedule_date).format("DD/MM")}</span>
                  </td>
                : null
            } 
            {
                scheduleItem.period === 1
                ? <td rowSpan="5" className='text-center fw-bold' style={{"backgroundColor": "#ffffff", "color":scheduleItem.part_day==="Sáng"?"#0d6efd":"#6f42c1"}}>
                    {scheduleItem.part_day}
                  </td>
                : null
            }
            <td className='text-center fw-bold' style={{"color":scheduleItem.part_day==="Sáng"?"#0d6efd":"#6f42c1"}}>
                {scheduleItem.period}
            </td>
            <td className='text-center'>
                {scheduleItem.class_name}
            </td>
            <td className='text-center'>
                {subject_name}
            </td>
            <td className='text-center'>
                {
                    showDialog ? 
                    <a className='text-decoration-none item-link'                             
                        href={`#${dlgId}`} data-bs-toggle="modal" 
                        data-bs-target={`#${dlgId}`}> 
                        { scheduleItem.category ? scheduleItem.category : scheduleItem.lecture_id ? "" : "[ ? ]" } 
                    </a>
                    : <span>{ scheduleItem.category } </span>
                }                
            </td>
            <td className='text-center'>
                {
                    showDialog ?
                    <a className='text-decoration-none item-link'                             
                        href={`#${dlgId}`} data-bs-toggle="modal" 
                        data-bs-target={`#${dlgId}`}> 
                        { scheduleItem.lecture_week ? scheduleItem.lecture_week : "[ ? ]" } 
                    </a>
                    : <span>{ scheduleItem.lecture_week ? scheduleItem.lecture_week : ""}</span>
                }
            </td> 
            <td className='text-center'>
                {
                    showDialog ?
                    <a className='text-decoration-none item-link'                             
                        href={`#${dlgId}`} data-bs-toggle="modal" 
                        data-bs-target={`#${dlgId}`}> 
                        { scheduleItem.lecture_period ? scheduleItem.lecture_period : "[ ? ]" } 
                    </a>
                    : <span>{ scheduleItem.lecture_period ? scheduleItem.lecture_period : ""}</span>
                }
            </td>                        
            <td>
                {
                    showDialog ?
                    <a className='text-decoration-none item-link'                        
                            href={`#${dlgId}`} 
                            data-bs-toggle="modal" 
                            data-bs-target={`#${dlgId}`}> 
                            { lectureObj !==null ? lectureObj?.Title : "[ ? ]" } 
                    </a>
                   : <span>{ lectureObj !==null ? lectureObj?.Title : "" }</span>

                }
            </td>
            <td>
                {scheduleItem.comment}
                {
                    scheduleItem.is_free || scheduleItem.type === scheduleConfig.LectureScheduleType_DayBu ?
                    <LSListItemMakeupLessonDialog 
                        dialogId = {dlgId}
                        title={`${scheduleItem.part_day} ${dayjs(scheduleItem.schedule_date).locale('vi').format('dddd, DD/MM/YYYY')} - Tiết ${scheduleItem.period}`}
                        action="edit"
                        isOwner = {isOwner}
                        scheduleItem={scheduleItem} 
                        subjectPlanDetails={subjectPlanDetails}
                        classItems = {classItems}
                        teacherSubjects = {teacherSubjects}
                        currentSubjectPlanDetailObj = {scheduleItem.lecture_id==="" 
                            ? {} 
                            : subjectPlanDetails.filter(sp=>sp.LectureId===scheduleItem.lecture_id)[0]}
                        schoolYear = {schoolYear}
                        schoolWeek =  {schoolWeek}
                        freePeriodItems = {freePeriodItems.filter(item=>dayjs(item.schedule_date).isAfter(dayjs(scheduleItem.schedule_date).subtract(1,'d')))}
                        onSaveCellClick = {onSaveCellClick}
                    />
                    :
                    <LSListItemLectureDialog 
                        dialogId = {dlgId}
                        title={`${scheduleItem.part_day} ${dayjs(scheduleItem.schedule_date).locale('vi').format('dddd, DD/MM/YYYY')} - Tiết ${scheduleItem.period} - Lớp ${scheduleItem.class_name}  - Môn ${subject_name}`}
                        action="edit"
                        isOwner = {isOwner}
                        scheduleItem={scheduleItem} 
                        subjectPlanDetails={subjectPlanDetails}
                        currentSubjectPlanDetailObj = {scheduleItem.lecture_id==="" 
                            ? {}
                            : subjectPlanDetails.filter(sp=>sp.LectureId===scheduleItem.lecture_id)[0]}
                        schoolYear = {schoolYear}
                        schoolWeek =  {schoolWeek}
                        freePeriodItems = {freePeriodItems.filter(item=>dayjs(item.schedule_date).isAfter(dayjs(scheduleItem.schedule_date).subtract(1,'d')))}
                        onSaveCellClick = {onSaveCellClick}
                    />                    
                }
            </td>
            <td className='text-center'>
                {
                    showDialog ?
                    <a className='text-decoration-none item-link'                             
                        href={`#${dlgId}`} data-bs-toggle="modal" 
                        data-bs-target={`#${dlgId}`}> 
                        { scheduleItem.status ? scheduleItem.status : scheduleItem.lecture_id ? "" : "[ ? ]" } 
                    </a>
                    :<span> {scheduleItem.status} </span>
                }
            </td>
            <td className='text-center'>   
                <a className='text-decoration-none d-print-none'                             
                    href={`#${dlgId}`} data-bs-toggle="modal" 
                    data-bs-target={`#${dlgId}`}> 
                    {
                        scheduleItem.subject_id!=="" ?
                        <i className="fa fa-pencil" aria-hidden="true" style={{"color":"orange"}}></i>
                        :
                        <i className="fa fa-plus" aria-hidden="true"style={{"color":"blue"}}></i>
                    } 
                </a>             

            </td>
        </tr>
    )

}

export default LSListItem;