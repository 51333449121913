import React, { useState, useContext} from "react";

import { MyAppContext } from '../../context/MyAppContext';
import { useFileDataApi } from "./FileData";
import { useCategoryDataApi } from "../category/CategoryData";

import YearSchoolSelection from '../common/YearSchoolSelection'; 
import FileList from "./FileList";
import UploadFileDlg from "./UploadFileDlg";
import { schoolConfig } from '../../appConfig';


function FilePage(){
    const [appContext,] =  useContext(MyAppContext);
    const CurrentUser = appContext.CurrentUser;   
    const teacherItems = appContext.Teachers;
    const [schoolYear, setSchoolYear] = useState(appContext.SchoolYear);  
    const [selectedTeacherId, setSelectedTeacherId] = useState(appContext.CurrentUser.TeacherId); 
    const [selectedTeacherObj, setSelectedTeacherObj] = useState (teacherItems.find(t=>t.TeacherId === appContext.CurrentUser.TeacherId));

    const [{departmentItems, titleItems, subjectItems}] = useCategoryDataApi(['department','titlelist','subjects']);
    const [{driveItems, driveChildrenItems, fileListItems},setAction] = useFileDataApi({type:'fetch',value:{schoolYear:schoolYear, teacherId: selectedTeacherId}});

    // console.log('driveItems', driveItems);
    // console.log('driveChildrenItems', driveChildrenItems);
    
    const handleSchoolYearChange = (yearValue) => {   
        setSchoolYear(1*yearValue);
        handleRefreshData(1*yearValue, selectedTeacherId);    
    }

    const handleTeacherSelectionChange = (teacherId) => {
        setSelectedTeacherId(teacherId); 
        const teacherObj = teacherItems.find(t=>t.TeacherId === teacherId);
        setSelectedTeacherObj (teacherObj);
        handleRefreshData(schoolYear, teacherId)
    }

    const handleRefreshData = (yearValue=0, teacherId='') => {
        setAction({
            type: 'fetch',
            value: {
                schoolYear: yearValue!==0 ? yearValue : schoolYear,
                teacherId: teacherId!=='' ? teacherId : selectedTeacherId
            }
        });
    }

    const handleUploadFile = (filesToUpload, fieldValues) => {
        setAction({
            type:'upload',
            value: {
                file: filesToUpload,
                schoolYear: schoolYear,
                fieldValues: {...fieldValues, SchoolYear: schoolYear, TeacherId: selectedTeacherId, DepartmentId: selectedTeacherObj?.DepartmentId}
            }
        })
    }

    return(
        <div className='ms-3 px-2'>
            <div className="row pt-3">
                <div className="col-md-7">
                    <h4>TÀI LIỆU</h4>
                    <p>
                    </p>       
                </div>
                <div className="col-md-5 pt-4 d-print-none">
                    <div className="row">
                        <div className="col-xs-3 col-md-3 px-1">
                            <YearSchoolSelection 
                                year={schoolYear} onYearChange = {handleSchoolYearChange}
                            >
                                Năm học
                            </YearSchoolSelection>
                        </div> 
                        <div className='col-xs-5 col-md-5 px-2'>
                            Giáo viên
                            <select name="sl-teacher" disabled= {appContext.CurrentUser.Roles.includes('admin') ? '' : 'disabled'}                              
                                className="form-select form-select-sm"
                                value = {selectedTeacherObj?selectedTeacherObj.TeacherId:""}
                                onChange = {(e) => handleTeacherSelectionChange (e.target.value)}
                            >
                                <option value=""></option>
                                {
                                    teacherItems.map((t,idx) =>
                                        <option key={idx} value={t.TeacherId}>{t.FullName}</option>
                                    )
                                }
                            </select>
                        </div>
                        <div className="col-xs-4 col-md-4 ps-1 pt-3 mt-1 text-end">
                            <button className='btn btn-info btn-sm' title="Nạp lại dữ liệu"
                                onClick={handleRefreshData}
                            >
                                <i className="fa fa-refresh" aria-hidden="true"></i>
                            </button>                            
                            <button type="button" className="btn btn-primary btn-sm ms-2" title="Tải file lên..."
                                    data-bs-toggle="modal" data-bs-target="#dlgFileManUpload">Upload<i className="fa fa-cloud-upload ms-1"></i>
                            </button>             
                        </div> 
                        <UploadFileDlg documenttypeItems = {schoolConfig.DocumentType} handleUploadFile={handleUploadFile} />  
                    </div>
                </div>              
            </div>
            <div className="row pt-2">
                <div className="col-md-12">
                    <FileList 
                        fileItems = {fileListItems.map(item=>{
                            return {...item, 
                                    DocTypeName : schoolConfig.DocumentType.find(element=>element.TypeId===item.DocTypeId)?.Name,
                                    DepartmentName : departmentItems.find(element=>element.DepartmentId===item.DepartmentId)?.Title,
                                    TeacherName: selectedTeacherObj.FullName,
                            }
                        })} 
                        documentTypeItems = {schoolConfig.DocumentType.map(item=> {return {id:item.TypeId, label: item.Name}})}
                        currentTeacher = {selectedTeacherObj}
                        departmentItems = {departmentItems}
                    />
                </div>
            </div>            
        </div>
    )   
}

export default FilePage