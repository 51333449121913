import {useEffect, useState} from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import {v4 as uuidv4} from "uuid";
import {remove, orderBy as _orderBy} from "lodash";

import {loginRequest, sharePointConfig} from "../../authConfig";

import { getSharePointListItems, 
    updateSharePointItem,
    addSharePointListItem,
    deleteSharePointItem } from "../../graph";  


import { WorkPlans } from "../../mock/MockData";

export const creatBlankWorkPlanItem = ()=>{
    return [{
        WorkingPlanId: uuidv4(),
        PlanType:'',
        Title:'',
        Result:'',
        ProcessingDuration:'',
        CompletedDuration:'',
        AssignedTo:'',
        Leader:'',
        Note:'',
        DepartmentId:'',
        StartDate: new Date(),
        EndDate: new Date(),
        CompletedDate: new Date(9999,11,31),
    }]
}

export const useWorkPlanDataApiMockup = () => {
    const [workPlanItems, setWorkPlanItems] = useState(WorkPlans);
    const [action, setAction] = useState({type:'', value:''})

    useEffect(()=>{
        const fetchData = async ()=>{
            setWorkPlanItems(WorkPlans);
        }
    
        const addBlankItem = async (item) => {
            let items = item.concat(workPlanItems);
            setWorkPlanItems(items);
        }
    
        const submitData = async (item)=>{
            console.log('Sumit workplan item to backend');
        }
    
        const deleteItem = async (item)=>{
            let items = Array.from(workPlanItems);
            remove(items, i=>i.WorkingPlanId===item.WorkingPlanId);
            setWorkPlanItems(items);
        }

        const processData = () => {
            switch(action.type){
                case 'add':
                    addBlankItem(action.value);
                    break;
                case 'save':
                    submitData(action.value);
                    break;
                case 'del':
                    deleteItem(action.value);
                    break;
                default:
                    fetchData();

            }
        }
        processData();
    },[action]);

    return [{workPlanItems}, setAction];
}


export const useWorkPlanDataApi = (defaultValue={type:'fetchdata', value:{departmentId:'', firstDayOfMonth: (new Date()).toISOString(), lastDayOfMonth:(new Date()).toISOString()}}) => {
    const [workPlanItems, setWorkPlanItems] = useState([]);
    const [action, setAction] = useState(defaultValue);
    const [isProcessingWorkPlanData, setIsProcessingWorkPlanData] = useState(false);
    const {instance, accounts} = useMsal();

    const addBlankItem = (item) => {
        setWorkPlanItems(item.concat(workPlanItems));
    }

    //****************************************************************************************************/
    //  fetchData 
    //  Lay du lieu ke hoach cong tac
    //  - departmentId: neu co lay theo to/bo phan, neu == all lay ta ca, tong hop thanh ke hoach truong
    //  - firstDayOfMonth: ngay dau thang
    //  - lastDayOfMonth: ngay cuoi thang
    //****************************************************************************************************/
    const fetchData = (accessToken, departmentId, firstDayOfMonth, lastDayOfMonth) => {
        let filterClause ="";
        if(departmentId==="All"){
            filterClause = `((fields/StartDate ge '${firstDayOfMonth}') and (fields/EndDate lt '${lastDayOfMonth}'))`;
        } else {
            filterClause = `((fields/DepartmentId eq '${departmentId}') and (fields/StartDate ge '${firstDayOfMonth}') and (fields/EndDate lt '${lastDayOfMonth}'))`;
        }

        getSharePointListItems(accessToken, 
            sharePointConfig.WorkingPlanListId,
            "id,WorkingPlanId,Title,PlanType,Result,ProcessingDuration,CompletedDuration,AssignedTo,Leader,Note,DepartmentId,StartDate,EndDate,CompletedDate",
            filterClause, //`((fields/DepartmentId eq '${departmentId}') and (fields/StartDate ge '${firstDayOfMonth}') and (fields/EndDate lt '${lastDayOfMonth}'))`,
            "fields/StartDate, fields/EndDate"
        ).then(response=>{
            let items = [];
            const listItemArray = response.value;
            for(let i=0; i<listItemArray.length;i++){
                const spListItem = listItemArray[i];
                items.push({
                    ...spListItem.fields,
                    StartDate: new Date(spListItem.fields.StartDate),
                    EndDate: new Date(spListItem.fields.EndDate),
                    CompletedDate: spListItem.fields.CompletedDate===undefined||spListItem.fields.CompletedDate===""?new Date(9999,11,31):new Date(spListItem.fields.CompletedDate),
                    AssignedTo: spListItem.fields.AssignedTo===undefined||spListItem.fields.AssignedTo===""?[]:JSON.parse(spListItem.fields.AssignedTo),
                    Leader: spListItem.fields.Leader===undefined||spListItem.fields.Leader===""?[]:JSON.parse(spListItem.fields.Leader),
                })
            }
            console.log('Workplan response.value', response.value);
            setWorkPlanItems(items);
        })
    }

    const saveItem = (accessToken, item) => {
        getSharePointListItems(
            accessToken, 
            sharePointConfig.WorkingPlanListId,
            "WorkingPlanId,Title,PlanType,DepartmentId,StartDate,EndDate,CompletedDate", 
            `(fields/WorkingPlanId eq '${item.WorkingPlanId}')`
        ).then(response => {
            console.log('response to check', response);
            let fieldValues = {};
            const listItems = response.value;
            if(listItems.length>0){ //Nếu đã có thì cập nhật   
                fieldValues = {
                    fields: {
                        Title: item.Title,
                        Result: item.Result,
                        StartDate: item.StartDate,
                        EndDate: item.EndDate,
                        CompletedDate: item.CompletedDate,                        
                        ProcessingDuration: item.ProcessingDuration,
                        CompletedDuration: item.CompletedDuration,
                        AssignedTo: JSON.stringify(item.AssignedTo),
                        Leader: JSON.stringify(item.Leader),
                        Note: item.Note,
                        DepartmentId: item.DepartmentId,
                    }
                };  
                updateSharePointItem(accessToken, sharePointConfig.WorkingPlanListId, listItems[0].id, fieldValues)
                    .then(response => {
                        console.log('Sua xong working plan item',response)
                        setIsProcessingWorkPlanData(false);
                    });
            }
            else{ //Nếu chưa có thì thêm moi
                fieldValues = {
                    fields: {
                        WorkingPlanId: item.WorkingPlanId,
                        Title: item.Title,
                        Result: item.Result,
                        StartDate: item.StartDate,
                        EndDate: item.EndDate,
                        CompletedDate: item.CompletedDate,         
                        ProcessingDuration: item.ProcessingDuration,
                        CompletedDuration: item.CompletedDuration,
                        AssignedTo: JSON.stringify(item.AssignedTo),
                        Leader: JSON.stringify(item.Leader),
                        Note: item.Note,
                        DepartmentId: item.DepartmentId
                    }
                };
                addSharePointListItem(accessToken, sharePointConfig.WorkingPlanListId, fieldValues)
                    .then(response=>{
                        console.log('them xong working plan item', response)
                        setIsProcessingWorkPlanData(false);
                    });
            }
        }) 
    };

    //===============================================================
    //  createWorkPlanForMonth
    //  Tạo kế hoạch công tác cho 1 tháng bằng cách copy các công việc
    //  của tháng trước.
    //  - departmentId: Mã tổ/đơn vị
    //  - firstDayOfMonth: Ngày đầu tiên của tháng cần tạo work plan
    //===============================================================
    const createWorkPlanForMonth = (accessToken, departmentId, firstDayOfMonth) => {
        //1. Đọc các hạng mục công việc của tháng trước đó (workPlanMonth-1)
        // getSharePointListItems(accessToken, 
        //     sharePointConfig.WorkingPlanListId,
        //     "id,WorkingPlanId,Title,PlanType,Result,ProcessingDuration,CompletedDuration,AssignedTo,Leader,Note,DepartmentId,StartDate,EndDate,CompletedDate",
        //     `((fields/DepartmentId eq '${departmentId}') and (fields/StartDate ge '${firstDayOfMonth}') and (fields/EndDate lt '${lastDayOfMonth}'))`,
        //     "fields/StartDate, fields/EndDate"
        // ).then(response=>{
        //     let items = [];
        //     const listItemArray = response.value;
        //     for(let i=0; i<listItemArray.length;i++){
        //         const spListItem = listItemArray[i];
        //         items.push({
        //             ...spListItem.fields,
        //             StartDate: new Date(spListItem.fields.StartDate),
        //             EndDate: new Date(spListItem.fields.EndDate),
        //             CompletedDate: spListItem.fields.CompletedDate===undefined||spListItem.fields.CompletedDate===""?new Date(9999,11,31):new Date(spListItem.fields.CompletedDate),
        //             AssignedTo: spListItem.fields.AssignedTo===undefined||spListItem.fields.AssignedTo===""?[]:JSON.parse(spListItem.fields.AssignedTo),
        //             Leader: spListItem.fields.Leader===undefined||spListItem.fields.Leader===""?[]:JSON.parse(spListItem.fields.Leader),
        //         })
        //     }
        //     console.log('items from sharepoint', response.value);
        // })
        //2. Thêm các công việc lấy được ở bước 1 và chuyển thành hạng mục công việc của tháng cần tạo
    }

    const deleteItem = (accessToken, item) => {
        let items = Array.from(workPlanItems);
        remove(items, i=>i.WorkingPlanId===item.WorkingPlanId);
        //Xóa phía server nếu item này đã tồn tại trên server
        if(item.id){
            deleteSharePointItem(accessToken,sharePointConfig.WorkingPlanListId,item.id).then(response =>{
                console.log('Completed delete item ' + item.WorkingPlanId);
                console.log(response);
                setIsProcessingWorkPlanData(false);        
                setWorkPlanItems(items);                        
            });
        }
        else{
            setIsProcessingWorkPlanData(false); 
            setWorkPlanItems(items);
        }
    }

    useEffect(()=>{
        const accessTokenRequest = {
            ...loginRequest,
            account: accounts[0]
        }

        const processData = async () => {
            instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
                const accessToken = accessTokenResponse.accessToken;
                setIsProcessingWorkPlanData(true);
                switch(action.type.toLowerCase()){
                    case 'add':
                        addBlankItem(action.value);
                        break;
                    case 'save':
                        saveItem(accessToken, action.value);
                        break;
                    case 'del':
                        deleteItem(accessToken, action.value);
                        break;
                    case "fetchdata":
                        fetchData(accessToken, action.value.departmentId, action.value.firstDayOfMonth, action.value.lastDayOfMonth);
                        break;
                    default:   
                        setIsProcessingWorkPlanData(false);                 
                        break;
                }
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect(accessTokenRequest);                    
                }
                setIsProcessingWorkPlanData(false);
                console.log(error);
            })             
        };
        processData();
    },[action]);
    

    return[{workPlanItems, isProcessingWorkPlanData}, setAction];
}