import React from 'react';

const ImportScheduleList = ({scheduleRows, classNames, teacherNames, subjectNames, 
    subjectsNotInDb, teachersNotInDb, classesNotInDb, defaultCellValue, partDay}) => {

    return (
        <table className='table table-bordered table-sm border caption-top' style={{"fontSize":"0.65em"}}>
            <caption className='fw-normal' style={{"fontSize":"1.4em"}}>
                Thời khóa biểu buổi {partDay} - Số lớp: {classNames.length>0 ? classNames.length: ""};&nbsp;
                Số GV: {teacherNames.length >0 ? teacherNames.length:""};&nbsp;
                Số Môn học: {subjectNames.length>0 ? subjectNames.length:""} 
                {
                    subjectsNotInDb.length>0 &&
                    <p style={{"color":"red"}}>
                        Các môn học chưa có trong CSDL của App ({subjectsNotInDb.length}): &nbsp;
                        {
                            subjectsNotInDb.map((s,idx) =>
                                <span className='pe-1 fw-bold' key={idx}>{s};&nbsp;</span>
                            )
                        }
                    </p>
                }
                {
                    teachersNotInDb.length>0 &&
                    <p style={{"color":"red"}}>
                        Các giáo viên chưa có trong CSDL của App ({teachersNotInDb.length}): &nbsp;
                        {
                            teachersNotInDb.map((t,idx) =>
                                <span className='pe-1 fw-bold' key={idx}>{t};&nbsp;</span>
                            )
                        }
                    </p>
                }
                                {
                    classesNotInDb.length>0 &&
                    <p style={{"color":"red"}}>
                        Các lớp chưa có trong CSDL của App ({classesNotInDb.length}): &nbsp;
                        {
                            classesNotInDb.map((c,idx) =>
                                <span className='pe-1 fw-bold' key={idx}>{c};&nbsp;</span>
                            )
                        }
                    </p>
                }
            </caption>
            <thead className='table-light'>
                <tr className='align-middle'>
                    {
                        classNames.length?
                        <>
                            <th className='text-center' style={{"widht":"15px"}}>THỨ</th>
                            <th className='text-center' style={{"widht":"15px"}}>TIẾT</th>
                            {
                                classNames.map((clsItem,idx) =>
                                    <th style={{"widht":"40px"}} key={idx} className='text-center'>{clsItem.className}<br/>{clsItem.teacherName}</th>
                                )
                            }
                        </> 
                        :
                        <th>Chưa có dữ liệu</th> 
                    }
                </tr>
            </thead>
            <tbody>
                {
                    scheduleRows.length?
                    scheduleRows.map((row, rIndex) =>
                        <tr key={rIndex} style={{"lineHeight":"0.9"}}>
                            {
                                row.period===1 ? <td rowSpan="5" className='text-center align-middle fw-bold fs-5' >{row.schedule_date}</td>:null
                            }
                            <td className='text-center align-middle'>{row.period}</td>
                            {                                            
                                row.class_subjects.map((item,cIndex) =>
                                    <td nowrap="nowrap" className='text-center' key={rIndex +'-'+cIndex}>{item.subject_name===defaultCellValue?'':item.subject_name}- {item.teacher_name}</td>
                                )
                            }
                        </tr>
                    )
                    :
                    null
                }
            </tbody>
        </table>
    )    
}

export default ImportScheduleList;