import React, { useState } from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import moment from 'moment';

const wH = window.innerHeight;

const gridStyle = { minHeight: wH<=768 ? 490:  wH<=900? 570: wH <1200 ? 750:950, marginTop: 0, fontSize: 12 };

function LectureScheduleRptGrid({data, reportType}){
    // const [gridRef, setGridRef] = useState(null);

    window.moment = moment;    
    const columns = reportType==='missing-lecture-schedule'
        ? [
            { name: 'Idx', header: 'ID',type: 'number', maxWidth: 50,  defaultVisible: false },
            { name: 'TeacherName', header:'Giáo viên', defaultFlex:1, maxWidth:160,},
            { name: 'NumOfWeeks', header:'Số tuần chưa BG', type:'number', maxWidth: 200},
            { name: 'Weeks', header:'Các tuần chưa báo giảng', defaultFlex:1},
            
          ]
        : [
            { name: 'idx', header: 'ID', type: 'number', maxWidth: 50,  defaultVisible: false },
            { name: 'Teacher', header:'Giáo viên', defaultFlex:1, maxWidth:150,},
            { name: 'Class', header:'Lớp', defaultFlex:1, maxWidth: 65},
            { name: 'Subject', header: 'Môn học', defaultFlex: 1, maxWidth: 105 },
            { name: 'Lecture', header: 'Bài giảng', defaultFlex: 1 },
            { name: 'Category', header: 'PM', defaultFlex: 1, maxWidth: 80 },
            { name: 'PartDay', header:'Buổi', defaultFlex:1, maxWidth: 70},
            { name: 'NoteDate', header: 'Ngày học', defaultFlex:1,maxWidth: 105,
            //   filterEditor: DateFilter,
            //   filterEditorProps: (props, {index}) => {
            //     return {
            //         dateFormat: 'MM-DD-YYYY',
            //         cancelButton: false,
            //         highlightWeekends: false,
            //         placeholder: index === 1 ? "Trước ngày...": "Sau ngày..."
            //     }
            //   },
            render: ({ value }) => moment(value).format('DD-MM-YYYY')
            },
            { name: 'Week', header:'Tuần', type: 'number',maxWidth: 80},       
            { name: 'Period', header:'Tiết', defaultFlex:1, maxWidth: 65},            
            { name: 'WeekPlan', header: 'Tuần PPCT', type:'number', maxWidth: 105},
            { name: 'PeriodPlan', header:'Tiết PPCT', defaultFlex:1,maxWidth: 100},  
            ];

    return(
        <div className="mt-1">
            { reportType!=="" &&
                <ReactDataGrid idProperty="id"
                    // handle={setGridRef}
                    style={gridStyle}
                    columns={columns}
                    pagination
                    dataSource={data}
                    defaultLimit={10} 
                />
            }
        </div> 
    )
}

export default LectureScheduleRptGrid;