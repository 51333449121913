import React, {useState} from "react";
import dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';

import WeekPicker from "../common/WeekPicker";
import YearPicker from "../common/YearPicker";

import WeeklyCalendarList  from "./WeeklyCalendarList";
import {createBlankWeeklyCalendarItem, useWeeklyCalendarDataApi} from './WeeklyCalendarData';


function WeeklyCalendarPage() { 
    dayjs.extend(isoWeek);
    
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedWeek, setSelectedWeek] = useState(dayjs().isoWeek());

    const [{weeklyCalendarItems}, setAction] = useWeeklyCalendarDataApi({
        type:'', 
        value:{
            StartDate: dayjs(dayjs().format('YYYY-MM-DD 00:00:00')).isoWeekday(1).toISOString(),
            EndDate:   dayjs(dayjs().format('YYYY-MM-DD 23:59:59')).add(7,'day').toISOString(),
            ViewMode: 'edit'
        }
    });

    const handleAdd = (startDate) => {
        const d = new Date(startDate);
        const dateString = d.getFullYear() + '-' + (d.getMonth() +1) + '-' + d.getDate() + ' ' + dayjs().hour() + ':' + dayjs().minute();

        let blankItem = createBlankWeeklyCalendarItem();
        blankItem[0] = {
            ...blankItem[0],
            StartDate: dayjs(dateString).toISOString(),
            EndDate: dayjs(dateString).add(2,'hour').toISOString(),
            NewRecord: 1
        }

        setAction({
            type:'add',
            value: blankItem
        });
    }

    const handleSave = (item) => {
        setAction({
            type:'save',
            value: item
        });
    }

    const handleDelete = (item) =>{
        setAction({
            type:'del',
            value: item
        });
    }

    const handleYearChange = (date, isoWeek) => {
        setSelectedYear(date.getFullYear());
    }

    const handleWeekChange = (dateString) => {
        setSelectedWeek(dayjs(dateString).isoWeek());
        setAction({
            type:'', 
            value:{
                StartDate: dayjs(dateString).toISOString(),
                EndDate:   dayjs(dateString).add(7,'day').toISOString(),
                ViewMode: 'edit'
            }            
        })
    }

    return (
        <div>
            <div className="row">
                <h4>Lịch công tác tuần</h4>
            </div>
            <div className="row">
                <div className="col-sm-2">
                    <YearPicker 
                        selectedDate = {new Date()}
                        onDateChange = {handleYearChange}
                    />
                </div>
                <div className="col-sm-2">
                    <WeekPicker 
                        selectedYear = {selectedYear}
                        onDateChange={handleWeekChange}                           
                    />
                </div>
                <div className="col-sm-8">

                </div>                                
            </div>
            <div className="row pt-3">
                <WeeklyCalendarList 
                    data={weeklyCalendarItems}
                    onAddItemClick = {handleAdd}
                    onSaveItemClick = {handleSave}
                    onDeleteItemClick = {handleDelete}
                />
            </div>
        </div>
    )
}

export default WeeklyCalendarPage;