//Thông tin thời khóa biểu của người hiện tại - Lịch báo giảng cá nhân
import React, {useContext} from 'react';
import { MyAppContext } from '../../context/MyAppContext';

const MySchedule = () => {
    const [appContext] = useContext(MyAppContext);
    
    return(
        <h6>Lịch báo giảng của {appContext.CurrentUser.DisplayName}</h6>
    )
}

export default MySchedule;