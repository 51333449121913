import React from "react";
import MessageBox from "../common/MessageBox";
import SubjectPlanDetailItem from './SubjectPlanDetailItem';
import {maxBy} from 'lodash';
import { useState } from "react";

function SubjectPlanDetailList({subjectPlandetail, sem1PeriodPerWeek, sem2PeriodPerWeek, sem1TotalWeeks, sem2TotalWeeks,
    onAddItemClick, onSaveItemClick, onDeleteItemClick, onColumnSortClick, onConvertButtonClick, startYear})
{
    const [weekSortDirection, setWeekSortDirection] = useState(true);
    const [periodSortDirection, setPeriodSortDirection] = useState(true);
    const [categorySortDirection, setCategorySortDirection] = useState(true);

    const handleAddButtonClick = (multiRows) => {
        let nextWeek = 1;       
        let nextPeriod = 1;

        if(subjectPlandetail?.length>0){
            const maxItemByWeek  =  maxBy(subjectPlandetail, (o) => o.Week);
            nextWeek = maxItemByWeek ? maxItemByWeek.Week + 1 : 1;
            const maxItemByPeriod  =  maxBy(subjectPlandetail, (o) => o.Period);
            nextPeriod = maxItemByPeriod ? maxItemByPeriod.Period + 1 : 1;
        }
        let numOfRows = multiRows ? (nextWeek <= sem1TotalWeeks ? sem1PeriodPerWeek: sem2PeriodPerWeek):1
        onAddItemClick(numOfRows, nextWeek*1, nextPeriod*1);
    }

    const handleColumnSortingClick = (colName) => {
        let sortDirection;
        switch(colName.toLowerCase()) {
            case "category":
                sortDirection = !categorySortDirection;
                setCategorySortDirection(sortDirection);
                break;
            case "week":
                sortDirection = !weekSortDirection;
                setWeekSortDirection(sortDirection)
                break;
            case "period":
                sortDirection = !periodSortDirection;
                setPeriodSortDirection(sortDirection)
                break;
            default:
        }        
        
        onColumnSortClick(colName, sortDirection ? "asc":"desc");
    }

    return(        
        <table className="table table-hover table-sm table-bordered">
            <thead className="table-light">
                <tr className="align-middle">
                    <th style={{width:'5%'}} className="text-center">
                        <a onClick={() => handleColumnSortingClick("Week")} className="nav-link" style={{"color":"blue"}} role="button">Tuần</a>                        
                    </th>
                    <th style={{width:'5%'}} className="text-center">
                        <a onClick={() => handleColumnSortingClick("Week")} className="nav-link" style={{"color":"blue"}} role="button">Tiết</a>
                    </th>
                    <th style={{width:'42%'}}>Bài học</th>
                    {/* <th style={{width:'25%'}}>Nội dung điều chỉnh</th> */}
                    <th style={{width:'30%'}}>Ghi chú/hướng dẫn</th>
                    <th style={{width:'6%'}} className='text-center'>
                        <a onClick={() => handleColumnSortingClick("Category")} className="nav-link" style={{"color":"blue"}} role="button">P. môn</a>
                    </th>
                    {/* <th style={{width:'7%'}}>Còn dùng</th> */}
                    <th style={{width:'8%'}} className='text-center'>
                        <button type="button" className="btn btn-primary btn-sm" title="Thêm một dòng để nhập bài học theo kế hoạch giảng dạy"
                            onClick={() => handleAddButtonClick(false)}>
                            <i className="fa fa-plus" aria-hidden="true"></i>
                        </button> &nbsp;
                        <button type="button" className="btn btn-primary btn-sm" title = {`Thêm nhiều dòng ứng với số tiết của 1 tuần học`}
                            onClick={() => handleAddButtonClick(true)} >
                            <i className="fa fa-list" aria-hidden="true"></i>
                        </button>
                    </th>
                </tr>
            </thead>
            <tbody>
                { 
                    subjectPlandetail!=null && subjectPlandetail.length>0
                    ?
                        subjectPlandetail.map((item, index) => 
                            <SubjectPlanDetailItem 
                                key = {item.LectureId}
                                item = {item} 
                                action = {item?.Title === ''? 'new' : ''} 
                                onSaveItemClick = {onSaveItemClick}
                                onDeleteItemClick={onDeleteItemClick}                                
                            />
                        )
                    :
                        <tr>
                            <td colSpan={7}>
                                <MessageBox>Chưa có thông tin PPCT của KHGD này. Bấm nút + để nhập dữ liệu bằng tay hoặc Import để tự động nhập dữ liệu từ file...</MessageBox>
                            </td>
                        </tr>
                        
                }
            </tbody>
        </table>

    )
}

export default SubjectPlanDetailList;