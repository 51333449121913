import React, {useContext, useState, useEffect} from "react";
import dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import { MyAppContext } from "../../context/MyAppContext";
import { getSchoolWeek } from "../../globalFuntions";

import WeekPicker from "../common/WeekPicker";
//import YearPicker from "../common/YearPicker";
import WeeklyCalendarItemView from './WeeklyCalendarItemView';
//import WeeklyCalendarItemView_Mb from './WeeklyCalendarView_Mb';  

import { useWeeklyCalendarDataApi } from "./WeeklyCalendarData";

function WeeklyCalendarView(props) {
    dayjs.extend(isoWeek);
    const[appContext,] = useContext(MyAppContext);
    const [selectedYear, setSelectedYear] = useState(appContext.SchoolYear);
    const[schoolWeek, setSchoolWeek] = useState(appContext.SchoolWeek);   
    
    const [selectedWeek, setSelectedWeek] = useState(dayjs().isoWeek());
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [{weeklyCalendarItems}, setAction] = useWeeklyCalendarDataApi({
        type:'', 
        value:{
            StartDate: dayjs(dayjs().format("YYYY/MM/DD 00:00:00")).isoWeekday(1).toISOString(),
            EndDate:   dayjs(dayjs().format("YYYY/MM/DD 23:59:59")).add(7,"day").toISOString()
        }
    });

    const handleYearChange = (date, isoWeek) => {
        setSelectedYear(date.getFullYear());
    }

    const handleWeekChange = (dateString) => {
        setSelectedDate(dayjs(dateString));
        setSelectedWeek(dayjs(dateString).isoWeek());
        setSchoolWeek(getSchoolWeek(appContext.SchoolYearInfo[0], dateString));
        setAction({
            type:'', 
            value:{
                StartDate: dayjs(dateString).toISOString(),
                EndDate:   dayjs(dateString).add(7,'day').toISOString()
            }            
        })
    }   
    
    useEffect(()=>{
        if(appContext.SchoolYearInfo.length>0) {
            setSchoolWeek(getSchoolWeek(appContext.SchoolYearInfo[0], selectedDate.toISOString()));
        }
    },[appContext.SchoolYearInfo.length])
    
    return( 
        <>
            <div className="row">
                <h5>Lịch công tác tuần {selectedWeek}/{selectedDate.year()} {schoolWeek>0 ? '(tuần học ' + schoolWeek + ' năm học ' + selectedYear + '-' + (selectedYear+1) + ')' : ''}</h5>
                {/* <h5>Lịch công tác tuần {selectedWeek} năm {selectedDate.year()}</h5> */}
            </div>
            <div className="row pt-2 pe-3"> 
                <div className="col-sm-9"></div>               
                {/* <div className="col-sm-1 pe-0 text-end">
                    <YearPicker 
                        selectedDate = {new Date()}
                        onDateChange = {handleYearChange}
                    />
                </div> */}
                <div className="col-sm-3 ps-4 pe-1 text-end">
                    <WeekPicker 
                        selectedYear = {selectedYear}
                        onDateChange={handleWeekChange}>                                
                    </WeekPicker>
                </div>                
            </div>
            <div className="row pt-3">
                <table className="table table-hover table-bordered border table-sm" style={{"fontSize":"0.9em"}}>
                    <thead className="table-light">
                        <tr className="align-middle">
                            <th style={{width:'8%'}} className='text-center' rowSpan="2">THỨ</th>
                            <th style={{width:'46%'}} className='text-center' colSpan="2">SÁNG</th>                            
                            <th style={{width:'46%'}} className="text-center" colSpan="2">CHIỀU</th>
                        </tr>
                        <tr>
                            <th style={{width:'34%'}} className='text-center'>Nội dung công việc</th> 
                            <th style={{width:'12%'}} className='text-center'>Thực hiện</th> 
                            <th style={{width:'34%'}} className='text-center'>Nội dung công việc</th> 
                            <th style={{width:'12%'}} className='text-center'>Thực hiện</th> 
                        </tr>
                    </thead>
                    <tbody>
                        { 
                            weeklyCalendarItems.length>0 &&
                            weeklyCalendarItems.map((item, idx)=> 
                                <WeeklyCalendarItemView
                                    key={item.UID} 
                                    item={item} 
                                    onSaveItemClick = {props.onSaveItemClick}
                                />
                            )
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default WeeklyCalendarView;