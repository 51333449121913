import React from "react";
import * as dayjs from 'dayjs';
import vi from 'dayjs/locale/vi'
//import { CallEndOutlined, ResetTvOutlined } from "@mui/icons-material";

class NoteItem extends React.Component{
    constructor(props){
        super(props);

        let item = props.item;
        this.state={
            item_action:'',
            id: item.id,
            note_date: item.note_date,
            part_day: item.part_day,
            period: item.period,
            subject_id: item.subject_id,
            period_plan: item.period_plan,
            lecture_id: item.lecture_id,
            absen_student: item.absen_student,
            comment: item.comment,
            teacher_id: item.teacher_id,
            note: item.note,
            is_late: item.is_late,
            week_plan: item.week_plan,
            force_save: false
        }
    }

    handleEdit = (e) => {
        this.setState({
            item_action:'edit'
        });
    }

    handleCancel = ()=>{
        this.setState({
            item_action:''
        });
    }

    handleClear=()=>{
        this.setState({
            item_action:'edit',
            force_save:true,
            period_plan:'',
            subject_id:'',
            lecture_id:'',
            absen_student:'',
            comment:'',            
            note:'',
            teacher_id:'',
            is_late:false,
            week_plan:0
        });
        //this.handleSaveItem(true);
    }


    handleSaveItem = (forceSave)=> {
        
        const {part_day, period, subject_id, period_plan, lecture_id, comment, teacher_id, absen_student, force_save} = this.state;

        if((force_save===false|| force_save==='false') && (part_day===''|| period===0 || subject_id==='' || period_plan==='' || lecture_id===''
            || comment==='' || teacher_id==='' || absen_student==='')){
            alert('Chưa nhập đủ thông tin, kiểm tra và nhập lại');
            return;
        }    

        //Call parent submit hanlder here
        this.props.onSaveItemClick(this.state);

        //Reset action status
        this.setState({
            item_action:'submit',
            force_save:false
        }); 
    }   

    handleChange = (e)=>{
        const target = e.target;
        const value = target.type==="checkbox"?target.checked: target.value;
        const name = target.name;    

        this.setState({
            [name]: value
        }) ;
        if(name==='lecture_id'){
            const currentPlanItem =  this.props.subjectPlanDetails.find(p=>p.PlanDetailId===value);
            this.setState({
                period_plan : currentPlanItem.Period,
                week_plan: currentPlanItem.Week,
                is_late: currentPlanItem.Week < this.props.item.week? true:false
            })
        }
    }
        
    shouldComponentUpdate(nextProps, nextState){ 
        if(this.state.item_action!=='') return true;    

        const {item_action} = nextState;
        const {period, part_day, note_date, subject_id, lecture_id} = nextProps.item;
        const view = nextProps.view;
        if(period !== this.state.period || part_day !== this.state.part_day 
            || note_date!== this.state.note_date || item_action!==this.state.item_action 
            || subject_id !== this.state.subject_id || lecture_id !== this.state.lecture_id || view!==this.props.view)
        {
            return true;
        }
        else {
            return false;
        }
    }

    componentDidUpdate(){
        //console.log('Item_action: ' + this.state.item_action);
        if(this.state.item_action!=='') return;
        
        //Chỉ cập nhật thông tin vào state từ props nếu không ở chế độ edit
        const item = this.props.item;
        this.setState({
            id: item.id,
            note_date: item.note_date,
            part_day: item.part_day,
            period: item.period,
            subject_id: item.subject_id,
            period_plan: item.period_plan,
            lecture_id: item.lecture_id,
            absen_student: item.absen_student,
            comment: item.comment,
            teacher_id: item.teacher_id,
            note: item.note,
            is_late: item.is_late,
            week_plan: item.week_plan
        });   
    }

    render(){ 
        const {subjects, subjectPlanDetails, teachers} = this.props;
        const {note_date, part_day, period, period_plan, subject_id, absen_student, lecture_id, comment, teacher_id, note} = this.state;
        const subjectPlanDetailItems = subjectPlanDetails.filter(s=>s.SubjectId===subject_id);

        const subjectObj = subjects.find(s=>s.SubjectId===subject_id);
        const subject_title = subjectObj != null ? subjectObj.Title:subject_id;

        const lectureObj = subjectPlanDetailItems.find(l=>l.PlanDetailId===lecture_id);
        const lecture =  lectureObj != null ? lectureObj.Title : lecture_id;

        const teacherObj = teachers.find(t=>t.TeacherId===teacher_id);
        const teacher_fullname = teacherObj !=null ? teacherObj.FullName : teacher_id;     
        
        const note_date_string = dayjs(note_date).isValid() 
            ? dayjs(note_date).locale('vi').format('dddd') + ' ' + dayjs(note_date).format('DD/MM/YYYY')
            : note_date;

        const firstColElement = (this.props.view==='all')
            ? (period === 1 && part_day==='Sáng')
                ?
                <td rowSpan='10' className="text-center bg-secondary bg-gradient text-white align-middle">
                    {note_date_string}
                </td>
                : null
            : (period === 1)     
                ? <td rowSpan='5' className="text-center bg-secondary bg-gradient text-white align-middle">
                    {note_date_string}
                  </td>
                : null

        const secondColElement = (period === 1)
            ? <td rowSpan='5' className={part_day==='Sáng'?'text-center text-white bg-info bg-gradient align-middle': 'text-center text-white bg-warning bg-gradient align-middle'}>
                {part_day}
            </td>
            : null      

        const todayBgColor = dayjs().isSame(dayjs(note_date),'day')?'silver':'#fff';
        const rowElement = this.state.item_action==='edit'
            ?<tr className="align-middle" style={{'backgroundColor':'pink', 'fontSize':'0.8em'}}>
                {firstColElement}
                {secondColElement}
                {/* <td className="text-center">
                    <select name="part_day" value={part_day} onChange={this.handleChange} className='form-control form-control-sm'>
                        <option value={'Sáng'}>Sáng</option>
                        <option value={'Chiều'}>Chiều</option>
                    </select>
                </td> */}
                <td className="text-center">
                    <select name="period" value= {period} onChange={this.handleChange} className='form-select form-select-sm'>
                        <option value={0}></option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                    </select>
                </td>
                <td>
                    <select name="subject_id" value={subject_id} onChange={this.handleChange} className='form-select form-select-sm'>
                        <option value={''}></option>
                        {
                            subjects.map((subject,index)=>
                                <option key={subject.SubjectId} value={subject.SubjectId}>{subject.Title}</option>)
                        }
                    </select>
                </td>
                <td className="text-center">
                    <span className='form-control form-control-sm'>{period_plan}</span>
                </td>
                <td>
                    <select name="lecture_id" value= {lecture_id} onChange={this.handleChange} className='form-select form-select-sm'>
                        <option value=''></option>
                        {
                            subjectPlanDetailItems.map((p)=>
                                <option key={p.PlanDetailId} value={p.PlanDetailId}>
                                    {p.Period + '- ' + (p.Category===undefined?'':p.Category + " - ") + p.Title}
                                </option>
                            )
                        }
                    </select>
                </td>                                                
                <td className='text-center'>
                    <input name="absen_student" type='number' min='0' value= {absen_student} onChange={this.handleChange} className='form-control form-control-sm'/>                    
                </td>
                <td>
                    <input name="comment"  type="text" value= {comment} onChange={this.handleChange} className='form-control form-control-sm' />
                </td>  
              
                <td>
                    <select name="teacher_id" value= {teacher_id} onChange={this.handleChange} className='form-control form-control-sm'>
                        <option value=''></option>
                        {
                            teachers.map((t)=>
                                <option key={t.TeacherId} value={t.TeacherId}>{t.FullName}</option>
                            )
                        }
                    </select>                    
                </td>
                <td>
                    <input name="note" type="text" value= {note} onChange={this.handleChange} className='form-control form-control-sm' />
                </td> 
                <td style={{'whitespace':'nowrap'}} className="text-end">
                    <button type='button' className="btn btn-primary btn-sm" onClick={this.handleSaveItem}>Lưu</button>&nbsp;
                    <button type='button' className="btn btn-secondary btn-sm" onClick={this.handleCancel}>Thôi</button>
                </td>   
            </tr> 
            :
            <tr className="align-middle" style={{'backgroundColor': todayBgColor, 'fontSize':'0.8em'}}>
                {firstColElement}
                {secondColElement}
                {/* <td className="text-center">{part_day}</td> */}
                <td className="text-center">{period}</td>
                <td>{subject_title}</td>
                <td className="text-center">{period_plan==='0'||period_plan===0?"":period_plan}</td>
                <td>{lecture}</td>                                
                <td className='text-center'>{absen_student===0 && subject_id===''?'': absen_student}</td>
                <td>{comment}</td>
                <td>{teacher_fullname}</td>
                <td>{note}</td>               
                <td style={{'whitespace':'nowrap'}} className="text-end">
                    <button type='button' className="btn btn-primary btn-sm" onClick={this.handleEdit}>Nhập</button>&nbsp;
                    <button type='button' className="btn btn-danger btn-sm" onClick={this.handleClear}>Xóa</button>
                </td>                          
            </tr> ;
       return (
           <>     
             {rowElement}
           </>
     
       );
    }           
}

export default NoteItem;