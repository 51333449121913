import React, {useState, useEffect, useContext} from "react";
import {write, utils} from 'xlsx';
import * as FileSaver from 'file-saver';

import * as dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import vi from 'date-fns/locale/vi';

import {LectureScheduleRptDataApi} from './LectureScheduleRptData';
// import LectureScheduleRptList from './LectureScheduleRptList'
import MessageBox from "../common/MessageBox";
import YearSchoolSelection from "../common/YearSchoolSelection";
import { MyAppContext } from '../../context/MyAppContext';
import LectureScheduleRptGrid from "./LectureScheduleRptGrid";

const downloadBlob = (blob, fileName='du-lieu.csv') => {
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);

    link.setAttribute('href', url);
    link.setAttribute('download', fileName);
    link.style.position='absolute';
    link.style.visibility='hidden';
    link.click();
    document.body.removeChild(link);
};

function LectureScheduleRptPage() { 
    registerLocale('vi', vi);
    const [appContext,] = useContext(MyAppContext);

    const [startDate, setStartDate] = useState(new Date(dayjs().date(1).toISOString())); 
    const [endDate, setEndDate] = useState(new Date(dayjs().add(1,'M').date(1).subtract(1,'d').toISOString()));
    const [schoolYear, setSchoolYear] = useState(appContext.SchoolYear); //ok
    const [reportType, setReportType] = useState("");
    const[{lectureScheduleReportItems, missingLectureScheduleItems, isNotebookRptLoading}, setAction] = LectureScheduleRptDataApi();
    const [delayedLecutureReportItems, setDelayedLecutureReportItems] = useState(lectureScheduleReportItems);
    const [missingLecutureReportItems, setMissingLecutureReportItems] = useState(missingLectureScheduleItems);

    const handleTeacherChange = (event) => {
        const value = event.target.value;
        if (value || value.trim() === "") {
            setDelayedLecutureReportItems(lectureScheduleReportItems.filter(item=>item.Teacher.includes(value)||item.Teacher === value));
        } else {
            setDelayedLecutureReportItems(lectureScheduleReportItems);
        }
    }

    const exportCSV = () => {
        let contents="";
        let dataRows = [];
        let header=[];
        switch (reportType){
            case "delayed-lecture-schedule":
                header=['']
                break;
            case "missing-lecture-schedule":
                header=['Giáo viên,Số tuần chưa báo giảng,Các tuần chưa báo giảng'];
                missingLectureScheduleItems.forEach(row => {
                    dataRows.push(`${row.TeacherName},${row.NumOfWeeks},${row.Weeks}`);
                });
                contents = header.concat(dataRows).join("\n");
                break;
            default:
        }
         const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });    
         downloadBlob(blob, reportType==='missing-lecture-schedule'?'chua-bao-giang.csv':'cham-tiet.csv');
    };

    const exportXLSX = () => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        let jsonData = [];
        let count;
        switch (reportType){
            case "delayed-lecture-schedule":
                count=0;
                lectureScheduleReportItems.forEach(row => {
                    count = count+1;
                    jsonData.push({
                        'STT': count,
                        'Giáo viên': row.Teacher,
                        'Lớp': row.Class,
                        'Môn học': row.Subject,
                        'Bài giảng': row.Lecture,
                        'Phân môn': row.Category,
                        'Buổi': row.PartDay,
                        'Ngày học': row.NoteDate.substring(0,10),
                        'Tuần': row.Week,
                        'Tiết': row.Period,
                        'Tuần PPCT': row.WeekPlan,
                        'Tiết PPCT': row.PeriodPlan,
                    });      
                });
                break;
            case "missing-lecture-schedule":
                count=0;
                missingLectureScheduleItems.forEach(row => {
                    count = count+1;
                    jsonData.push({
                        'STT': count,
                        'Giáo viên': row.TeacherName,
                        'Số tuần chưa báo giảng': row.NumOfWeeks,
                        'Các tuần chưa báo giảng': row.Weeks,
                    });        
                });
                break;
            default:
        }

        const ws = utils.json_to_sheet(jsonData);
        const wb = {Sheets: {'data':ws}, SheetNames:['data']};
        const excelBuffer = write(wb, {bookType:'xlsx', type: 'array'});
        const data = new Blob([excelBuffer],{type:fileType});
        FileSaver.saveAs(data, reportType==='missing-lecture-schedule'?'chua-bao-giang.xlsx':'cham-tiet.xlsx');

    };

    useEffect(()=>{
        setMissingLecutureReportItems(missingLectureScheduleItems);
    },[missingLectureScheduleItems])

    useEffect(()=>{
        setDelayedLecutureReportItems(lectureScheduleReportItems);
    },[lectureScheduleReportItems])

    return (
        <div className="px-2 pt-2">
            <div className="row">
                <h4>
                    Thống kê {reportType ==='' ? "báo giảng theo PPCT" : reportType==="delayed-lecture-schedule" ? "giáo viên chậm tiết":"giáo viên chưa báo giảng"}
                </h4>
                {/* <h6>Các bài giảng bị chậm so với phân phối chương trình</h6> */}
            </div>
            <div className="row">
                <div className='col-md-2'>
                    Năm học
                    <YearSchoolSelection  
                        onYearChange = {(yearValue)=> setSchoolYear(yearValue*1)}>
                    </YearSchoolSelection>
                </div>
                <div className='col-md-2'>
                    Từ ngày 
                    <DatePicker className="form-control form-control-sm"
                        selected={reportType==="missing-lecture-schedule"?null:startDate} 
                        locale='vi' dateFormat="dd/MM/yyyy"  
                        disabled={reportType==="missing-lecture-schedule"?"diabled":""}                      
                        onChange={(date)=>setStartDate(date)}></DatePicker>
                </div>
                <div className='col-md-2'>
                    đến ngày 
                    <DatePicker className="form-control form-control-sm"
                        selected={reportType==="missing-lecture-schedule"?new Date():endDate}
                        locale='vi' dateFormat="dd/MM/yyyy"  
                        disabled={reportType==="missing-lecture-schedule"?"diabled":""}                      
                        onChange={(date)=> setEndDate(date)}></DatePicker>
                </div>
                <div className='col-md-4 pt-3 mt-1'>
                    <button type='button' id="btnMissingLecture" className="btn btn-warning btn-sm me-1"                  
                        onClick={(e)=>{
                            setReportType('missing-lecture-schedule');
                            document.getElementById('btnDelayedLecture').classList.remove('fw-bold');
                            document.getElementById('btnMissingLecture').classList.add('fw-bold');
                            setAction({
                                type:'fetch-missing-lecture-schedule', 
                                value:{
                                    startDate: new Date(schoolYear,9,5), //ngay bat dau cua nam hoc
                                    endDate: new Date(), //ngay hien tai
                                    schoolYear: schoolYear
                                }
                            }
                        )}}>Chưa báo giảng                       
                    </button>
                    <button type='button' id="btnDelayedLecture" className="btn btn-info btn-sm me-1"                  
                        onClick={(e)=>{
                            setReportType('delayed-lecture-schedule');
                            document.getElementById('btnDelayedLecture').classList.add('fw-bold');
                            document.getElementById('btnMissingLecture').classList.remove('fw-bold');
                            setAction({
                                type:'fetch-delayed-lecture-schedule', 
                                value:{
                                    startDate: startDate,
                                    endDate: endDate,
                                    schoolYear: schoolYear
                                }
                            }                            
                        )}}>Dạy chậm tiết
                    </button>
                    <button type='button' id="btnDelayedLecture" className="btn btn-secondary btn-sm" 
                        disabled={missingLectureScheduleItems.length>0 || lectureScheduleReportItems.length>0?"":"disabled"}
                        onClick={e=>{
                            exportXLSX();
                        }}
                    >
                        Xuất ra file
                    </button>                
                </div>
                <div className='col-md-2'>
                    <label>Giáo viên</label>
                    <input type="text" className="form-control form-control-sm" onChange={handleTeacherChange}/>
                </div>
            </div>
            <div className="row pt-3">
                { 
                    isNotebookRptLoading?
                    <MessageBox>
                        Đang kiểm tra và lấy dữ liệu...
                    </MessageBox>
                    :
                    // <LectureScheduleRptList data={reportItems} />
                    <LectureScheduleRptGrid 
                        data={reportType==='delayed-lecture-schedule'? delayedLecutureReportItems : reportType==="missing-lecture-schedule" ? missingLecutureReportItems : {}} 
                        reportType={reportType}/>
                }
            </div>
        </div>
    )
}

export default LectureScheduleRptPage;